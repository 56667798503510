import { CreateCallOrder,ConfirmCallOrder, getRandomUnset } from "Services/Orders/CallOrderCalls";
import OrderStore from "Store/OrderStore";
import { CreateOrderTypes, DataFieldsTypes, HandleConfirmTypes } from "../Components/OrderTypes";

interface GetCallOrderProps {
    (setCount: React.Dispatch<React.SetStateAction<number>>,
    count : number  ,
    orderStore: typeof OrderStore ,
    setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    setDataSource: React.Dispatch<React.SetStateAction<DataFieldsTypes[]>>,
    setPagesize: React.Dispatch<React.SetStateAction<number>>):void
}

export const handleGet : GetCallOrderProps = (setCount , count , orderStore , setLoading , setDataSource , setPagesize)=>{
    getRandomUnset().then(result =>{
        setLoading(true);
        orderStore.getCallData(1).then(()=>{
            setDataSource(orderStore.CallData);
            setPagesize(orderStore.Callcount);
            setLoading(false);
            setCount(count-1);
        });
    })
}

export const HandleConfirm : HandleConfirmTypes = (Data , setconfirmLoading ,setLoading , orderStore ,setDataSource , setPageSize , setOpen , pagination , setData,setDataCounter,setLimitOpen,setLimitMess,setLivr) =>{
    setconfirmLoading(true);
    ConfirmCallOrder(Data , setconfirmLoading ,setLoading , orderStore ,setDataSource , setPageSize , setOpen,pagination , setData,setDataCounter,setLimitOpen,setLimitMess,setLivr);
}

export const HandleOk:CreateOrderTypes = async (Data , setconfirmLoading , orderStore ,setLoading , setDataSource , setPageSize , setOpen , pagination , setData,setDataCounter,setLimitOpen,setLimitMess ,setLivr)=>{
    
    setconfirmLoading(true);
    CreateCallOrder(Data , setconfirmLoading ,setLoading , orderStore ,setDataSource , setPageSize , setOpen,pagination , setData,setDataCounter,setLimitOpen,setLimitMess,setLivr);
}