import { Input, Select } from "antd";
import i18n from "i18nextinit";
import React, { useState } from "react";
import { Cities } from "../Constants/EmployeData";
import { EmployeType } from "../Constants/EmployeTypes";

interface EmployeModalProps {
    type : String,
    Data: EmployeType | undefined,
    setData: React.Dispatch<React.SetStateAction<EmployeType | undefined>>,
    EmailErr: boolean
    setEmailErr: React.Dispatch<React.SetStateAction<boolean>>
    
}
 
const EmployeModal: React.SFC<EmployeModalProps> = ({Data,setData,EmailErr,setEmailErr,type}) => {

    const { Option } = Select;

    return ( 
    <div className='EmployeContainer'>
        <div className='Row2' style={{width : '90%'}}>
        {type === 'Employe' && (
            
            
                <Select
                showSearch
                className="EmployeSelect"
                style={{ width: "45%" }}
                optionFilterProp="children"
                dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                placeholder={i18n.t("Pages.Staff.Modal.TypePH")}
                onChange={(value : string)=>{setData({...Data , Type : value})}}
                >
                    <Option key='Livr' value='Livr'>
                        {i18n.t("Pages.Staff.Modal.Deliv")}
                    </Option>
                    <Option key='Maga' value='Maga'>
                        {i18n.t("Pages.Staff.Modal.StoreKepper")}

                    </Option>
                    <Option key='Call' value='Call'>
                        {i18n.t("Pages.Staff.Modal.Call")}

                    </Option>
                </Select> 
            )}
                {(Data?.Type  ==='Livr' || Data?.Type  ==='LivrExt') && (
                        <Select
                        showSearch
                        className="EmployeSelect"
                        style={{ width: "45%" }}
                        optionFilterProp="children"
                        dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                        placeholder={i18n.t("Pages.Clients.Modal.CityPH")}
                        onChange={(value : string)=>{setData({...Data , City : value})}}
                        >
                        {Cities.map(city =>(
                            <Option key={city} value={city}>{city}</Option> 
                        ))}
                        </Select> 
                )}
            </div>
        
        
        {(Data?.Type !== undefined || type==='LivrExt') &&(
            <div className='back'>
                <div className='Row2'>
                    <div className='Row1' style={{width : '45%'}}>
                        <label>{i18n.t("Pages.Clients.Modal.Firstname")}</label>
                        <input className='Field edit' value={Data?.FirstName} type='text' onChange={(e)=>{setData({...Data , FirstName : e.target.value})}} />
                    </div>
                    <div className='Row1' style={{width : '45%'}}>
                        <label>{i18n.t("Pages.Clients.Modal.Lastname")}</label>
                        <input className='Field edit' value={Data?.LastName} type='text' onChange={(e)=>setData({...Data, LastName : e.target.value})} />
                    </div>
                </div>
                <div className='Row2' style={{marginBottom : '0px'}}>
                    <div className='Row1' style={{width : '45%',marginBottom : '0px'}}>
                        <label>{i18n.t("Auth.Form.Email")}</label>
                        <input className={EmailErr ? 'Field edit err'  : 'Field edit' } value={Data?.Email} type='email' onChange={(e)=>{setEmailErr(false);setData({...Data , Email : e.target.value })}} />
                    </div>
                    <div className='Row1' style={{width : '45%',marginBottom : '0px'}}>
                        <label>{i18n.t("Auth.Form.Password")}</label>
                        <input className='Field edit' value={Data?.Password} type='password' onChange={(e)=>setData({...Data, Password : e.target.value})} />
                    </div>
                </div>
                <span className={EmailErr ? 'Error' : 'empty'}>{i18n.t("Auth.Errors.InvEmail")}</span>
                <div className='Row2'>
                        <div className='Row1' style={{width : '45%'}}>
                            <label>{i18n.t("Auth.Actions.SignUp.Screen1.Phone")}</label>
                            <input className='Field edit' value={Data?.Tel} type='tel' onChange={(e)=>{setData({...Data , Tel : e.target.value })}} />
                        </div>
                    {Data?.Type === 'LivrExt' &&(
                        <div className='Row1' style={{width : '45%'}}>
                            <label>{i18n.t("Pages.Staff.Modal.DelCut")}</label>
                            <Input 
                            placeholder={i18n.t("Pages.Staff.Modal.DelPH")}
                            style={{width : '100%'}} addonAfter="Dh" 
                            type='number'
                            value={Data.Price !== '0' ? Data.Price : ''}
                            onChange={(e)=>setData({...Data, Price : e.target.value})}
                            />
                        </div>
                    )}
                    
                </div>
            </div>
        )}
    </div>
    );
}
 
export default EmployeModal;