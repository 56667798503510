import { Select } from "antd";
import i18n from "i18nextinit";
import { Cities } from "Pages/Staff/Constants/EmployeData";
import React from "react";
import { ClientType } from "../Constants/ClientTypes";

export interface AddClientModalProps {
    Data: ClientType,
    setData: React.Dispatch<React.SetStateAction<ClientType>>
    phoneErr: boolean
    setPhoneErr: React.Dispatch<React.SetStateAction<boolean>>
}
 
const AddClientModal: React.SFC<AddClientModalProps> = ({Data,setData,phoneErr,setPhoneErr}) => {
    const { Option } = Select;

    return ( 
        <div className='ClientContainer'>
            <div className='back'>
                <div className='Row2 '>
                    <div className='Row1' style={{width : '45%'}}>
                        <label>{i18n.t("Pages.Clients.Modal.Firstname")}</label>
                        <input className='Field edit' value={Data.Firstname} type='text' onChange={(e)=>setData({...Data, Firstname : e.target.value})} />
                        
                    </div>
                    <div className='Row1' style={{width : '45%'}}>
                        <label>{i18n.t("Pages.Clients.Modal.Lastname")}</label>
                        <input className='Field edit' value={Data.Lastname} type='text' onChange={(e)=>setData({...Data, Lastname : e.target.value})} />
                    </div>
                </div>
                <div className='Row2 ' style={{marginBottom : '0px'}}>
                    <div className='Row1' style={{width : '45%'}}>
                        <label>{i18n.t("Pages.Clients.Modal.Phone")}</label>
                        <input className={phoneErr ? 'Field edit err'  : 'Field edit' } value={Data.Phone} type='tel' onChange={(e)=>{setPhoneErr(false);setData({...Data, Phone : e.target.value})}} />
                    </div>
                    <div className='Row1' style={{width : '45%'}}>
                        <label>{i18n.t("Pages.Clients.Modal.City")}</label>
                        <Select
                        showSearch
                        style={{ width: "90%" }}
                        optionFilterProp="children"
                        dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                        placeholder={i18n.t("Pages.Clients.Modal.CityPH")}
                        onChange={(value : string)=>{setData({...Data , City : value})}}
                        >
                        {Cities.map(city =>(
                            <Option key={city} value={city}>{city}</Option> 
                        ))}
                        </Select> 
                    </div>
                </div>
                <div style={{width : '45%'}}>
                    <span className={phoneErr ? 'Error' : 'empty'}>{i18n.t("Pages.Clients.Create.AlrExist")}</span>
                </div>
            </div>
            
        </div>
     );
}
 
export default AddClientModal;