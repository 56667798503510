export  const Plans = [
    {
      Plan: "Free (only 100 order)",
      TotalDue: 0,
      PriceId: "free_price",
    },
    {
      Plan: "Standard",
      TotalDue: 9.99,
      PriceId: "price_1JTSPUEwTdG6Upen8pc6F5X9",
    },
    {
      Plan: "Premium",
      TotalDue: 34.99,
      PriceId: "price_1JTSREEwTdG6UpenDoarpZ7d",
    },
    {
      Plan: "Advanced",
      TotalDue: 54.99,
      PriceId: "price_1JXPcYEwTdG6UpenJTDTksH6",
    }
  ];