import i18n from "i18nextinit";

export const ClientTableColumns = [
    {
      title: i18n.t("Pages.Clients.Modal.ClientId"),
      dataIndex: 'Client_Id',
      key: 'Client_Id'
    },
    {
      title: i18n.t("Pages.Clients.Modal.Firstname"),
      dataIndex: 'Firstname',
      key: 'Firstname'
    },
    {
      title: i18n.t("Pages.Clients.Modal.Lastname"),
      dataIndex: 'Lastname',
      key: 'Lastname'
    },
    {
      title: i18n.t("Pages.Clients.Modal.Phone"),
      dataIndex: 'Phone',
      key: 'Phone'
    }
  ];