import firebase from "firebase/app";
import "firebase/storage";
import 'firebase/messaging';
var config = {
    apiKey: "AIzaSyD-rFfbsPwDeCWPqaCHtvzpEGXvRR-A4-I",
    authDomain: "cod-scale.firebaseapp.com",
    projectId: "cod-scale",
    storageBucket: "cod-scale.appspot.com",
    messagingSenderId: "456069765876",
    appId: "1:456069765876:web:44df133f49a7e3a2d21ca1",
    measurementId: "G-YXTKE2VZNE"
};

firebase.initializeApp(config);
var storage = firebase.storage();

let messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    if (messaging !== null) {
      messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
    } else {
      resolve('null')
    }
});

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (messaging !== null) {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
      console.log('listening');
    } 
});

export { storage, firebase as default };
