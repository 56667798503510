import { ReactComponent as FrLogo } from "Assets/Svgs/MyProfile/france.svg";
import { ReactComponent as EngLogo } from "Assets/Svgs/MyProfile/united-states.svg";
import { ReactComponent as AraLogo } from "Assets/Svgs/MyProfile/morocco.svg";
import { AdminType } from "Components/Constants/UserTypes";
import { Skeleton } from "antd";
import i18n from "i18nextinit";
import { useHistory } from "react-router-dom";
import { AvRoutes, useQuery } from "../ProfileSidebar";
import { useState } from "react";

interface LangProps {
        Data: AdminType | undefined
        Loading: boolean
}
 
const Lang: React.SFC<LangProps> = ({Data,Loading}) => {

    let history = useHistory();
    let from = useQuery().get('from');
    const [lang, setLang] = useState(i18n.resolvedLanguage);

    console.log(i18n.resolvedLanguage);
    const changeLanguage = (lng:string) => {
        i18n.changeLanguage(lng);
        setLang(lng);

        // if (from !== undefined && from !== null && AvRoutes.includes(from)) {
        //     history.push(`/${from}`)
        // }else
        // {
        //     history.push("/orders");
        // }
    }

    return ( 
        <div className='Account'>
            <Skeleton loading={Loading} active round={true}>   
                <span className='Heading'>{i18n.t("Pages.MyProfile.MyAccount.Lang.Title")}</span>
                <div className='LangContainer'>
                    <form>
                        <div className='LangRow'>
                            <div className='val'>
                                <input type="radio" id="fr" name="lang_fr" value="fr" style={{marginRight : '15px'}} checked={lang === 'fr'} onChange={()=>{changeLanguage('fr')}} />
                                <label>{i18n.t("Pages.MyProfile.MyAccount.Lang.fr")}</label>
                            </div>
                            <FrLogo height='30' width='30' />
                        </div>
                        <div className='LangRow'>
                            <div className='val'>
                                <input type="radio" id="en" name="lang_en" value="en" style={{marginRight : '15px'}} checked={lang === 'en'} onChange={()=>{changeLanguage('en')}}/>
                                <label>{i18n.t("Pages.MyProfile.MyAccount.Lang.en")}</label>
                            </div>
                            <EngLogo height='30' width='30' />
        
                        </div>
                        <div className='LangRow'>
                            <div className='val'>
                                <input type="radio" id="ar" name="lang_ar" value="ar" style={{marginRight : '15px'}} checked={lang === 'ar'} onChange={()=>{changeLanguage('ar')}}/>
                                <label>{i18n.t("Pages.MyProfile.MyAccount.Lang.ar")}</label>
                            </div>
                            <AraLogo height='30' width='30' />
        
                        </div>  
                    </form>
                    <div className="editButton actButton" style={{marginTop:'50px'}}>
                    <button
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        {i18n.t("Pages.MyProfile.MyAccount.PopUps.Modal.save")}
                    </button>
                    </div>
                </div>
            </Skeleton>
        </div>
     );
}
 
export default Lang;