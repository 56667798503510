import { DataFieldsTypes } from "../../OrderTypes";
import {  Select } from 'antd';
import { StatusAvailable } from "../../Consts";
import { useEffect } from "react";
import i18n from "i18nextinit";


interface ActionComponentProps {
    setData: React.Dispatch<React.SetStateAction<DataFieldsTypes>>;
    Data: DataFieldsTypes
}
const { Option } = Select;
const ActionComponent: React.SFC<ActionComponentProps> = ({Data , setData}) => {

    const handleStatusChange =(value : string)=>{
        setData({...Data , Status : value});
    }
    return ( 
        <div className="Recap RowR">
            <span className="smallTitle">{i18n.t("Pages.Orders.Action")} :</span><br/><br/>
            <span style={{ fontFamily: "CatamaranBold" , marginRight : '35px'}}>{i18n.t("Pages.Orders.OrderStat")} :</span>
                <Select
                    placeholder={i18n.t("Status.PH")}
                    style={{ width: "70%" }}
                    dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                    onChange={handleStatusChange}
                    defaultValue={(Data.Status ===  '' || Data.Status ===  undefined) ? undefined : Data.Status}
                >     
                    {StatusAvailable.map(element =>(
                            <Option key={element.status} value={element.status}>{element.value}</Option>
                    ))}
                </Select>
            <br/><br/><br/>
            <span className='Label'>{i18n.t("Pages.Invoices.Modal.TotalDesc2")} : </span>
            <span className='Value' >{Data.OrderPrice} Dh</span><br/>
        </div>
     );
}
 
export default ActionComponent;