import { Input, Select } from "antd";
import i18n from "i18nextinit";
import { EmployeType } from "Pages/Orders/Components/OrderTypes";
import { ProductTypes } from "Pages/Products/Components/ProductTypes";
import React, { useEffect, useState } from "react";
import { getProductsNotInLivrStock } from "Services/Inventory/LivrStockCalls";
import { getProductsNotInWarehouse } from "Services/Inventory/WarehouseCalls";
import { getAllProducts, getLivr } from "Services/Orders/CreateOrderCalls";
import { getProducts } from "Services/Products/ProductCalls";
import { LivrStock, WarehouseTypes } from "../Constants/InventoryTypes";

export interface InventoryModalProps {

        Data: WarehouseTypes | LivrStock,
        setData: React.Dispatch<React.SetStateAction< WarehouseTypes | LivrStock>>
        Type: string | undefined
        setType: React.Dispatch<React.SetStateAction<string | undefined>>,
        Checker : boolean | undefined,
        usertype ?: string
}
interface ProductsfetchedType 
{
    result : ProductTypes[],
    count : number 
}


const InventoryModal: React.SFC<InventoryModalProps> = ({Data,setData,Type,setType,Checker,usertype}) => {

    const { Option } = Select;
    const [Productsource,setProductSource] = useState<ProductTypes[]>();
    const [SelectedProduct , setSelected] = useState<ProductTypes>();
    const [Loading, setloading] = useState<boolean>();
    const [Employes, setEmployes] = useState<EmployeType[]>([]);

    useEffect(()=>{
        setloading(true);
        getAllProducts().then((response:ProductTypes[])=>{
            setProductSource(response);
        }).then(()=>setloading(false));
        if (Type === "LivrStock") {
            getLivr().then(response =>{
                setEmployes(response);
            });
        }
    },[Type]);

    useEffect(()=>{
        setSelected(undefined);
        setData({...Data, Quantity : ''});
    },[Checker]);

    const handleChange = (value:string)=>{
        const Prod = Productsource?.find(element => element.ProductId === value);
        setSelected(Prod);
        setData({...Data , ProductId : Prod?.ProductId , ProductName : Prod?.ProductName , ProductPrice : Prod?.ProductPrice})
    }

    const handleEmployeChange = (value : string) =>{
        setData({...Data, LivrId : value});
    }

    return ( 
        <div className="InventoryContainer">
            <div className="Row2">
                {(!Checker && usertype === "Admin" ) &&(
                    <Select
                    className="InventorySelect"
                    dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                    placeholder={i18n.t("Pages.Inventory.Modal.InvPH")}
                    style={{ width: "40%" }}
                    onChange={(value : string)=>{setType(value)}}
                    >
                        <Option key="Warehouse" value="Warehouse">
                        {i18n.t("Pages.Inventory.Modal.Warehouse")}
                        </Option>
                        <Option key="LivrStock" value="LivrStock">
                        {i18n.t("Pages.Inventory.Modal.LivrStock")}
                        </Option>
                    </Select>
                )}
                
                {(Type === "LivrStock" || Type === "Warehouse") &&(
                    <Select
                    className="InventorySelect"
                    showSearch
                    style={{ width: "40%" }}
                    placeholder={i18n.t("Pages.Inventory.Modal.ProdPH")}
                    loading={Loading}
                    optionFilterProp="children"
                    dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                    onChange={handleChange}
                    >
                        {Productsource?.map(product =>(
                            <Option key={product.ProductId} value={product.ProductId}>
                                {product.ProductName}
                            </Option>
                        ))}
                    </Select>
                )}
            </div>

            <br/>
            
            {(Type === "LivrStock" || Type === "Warehouse") &&(
                <div className="Row2 back">
                    <div className="Row1" style={{width : "40%"}}>
                        <label>{i18n.t("Pages.Inventory.Modal.ProductPrice")}</label>
                        <Input readOnly
                         style={{width : "100%"}} addonAfter="Dh" 
                         value={SelectedProduct?.ProductPrice !== 0 ? SelectedProduct?.ProductPrice : ""}/>
                    </div>
                    <div className="Row1" style={{width : "40%"}}>
                        <label>{i18n.t("Pages.Inventory.Modal.Qte")}</label>
                        <input className="Field edit" value={Data.Quantity} min="0" type="number" onChange={(e)=>setData({...Data, Quantity : e.target.value})} />
                    </div>
                    
                </div>
            )}
            <br/>

            {(Type === "LivrStock" && usertype === "Admin") &&(
                    <Select
                    className="InventorySelect"
                    showSearch
                    style={{ width: "50%" }}
                    placeholder={i18n.t("Pages.Invoices.Modal.DelivPH")}
                    optionFilterProp="children"
                    dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                    onChange={handleEmployeChange}
                    >
                        {Employes.map((Employe) => (
                            <Option key={Employe.EmplyeId} value={Employe.EmplyeId}>
                            {Employe.FirstName+" "+Employe.LastName+" | "+Employe.City}
                            </Option>
                        ))}
                    </Select>
            )}

        </div>
     );
}
 
export default InventoryModal;