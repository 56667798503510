import { notification } from 'antd';
import axios from 'axios';
import { FormikValues } from 'formik';
import i18n from 'i18nextinit';
import {externals} from 'webpack.config';

export const UpcomingInvoice  = async (data : any) => {
    try {
        const response = await  axios.post(`${externals.config.apiUrl}/Auth/retrieve-upcoming-invoice`,data,{withCredentials : true}) ;
        if (response.status === 200) {
            return response.data
        } else {
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description:
                i18n.t("Services.Errors.ErrorDesc"),
            });
        }
    } catch (error) {
        console.log(error);
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description:
            i18n.t("Services.Errors.ErrorDesc"),
        });
    }
}

export const UpgradeSUb  = async (data : any) => {
    try {
        const response = await  axios.post(`${externals.config.apiUrl}/Auth/upgradeSub`,data,{withCredentials : true}) ;
            return response
    } catch (error) {
        console.log(error);
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description:
            i18n.t("Services.Errors.ErrorDesc"),
        });
    }
}

export const IsCustomerExist  = async () => {
    try {
        const response = await  axios.get(`${externals.config.apiUrl}/Auth/IsCustomerExist`,{withCredentials : true}) ;
        return response.data ;
    } catch (error) {
        console.log(error);
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description:
            i18n.t("Services.Errors.ErrorDesc"),
        });
    }
}

export const CancelSub = async ()=>{
    try {
        const response = await  axios.get(`${externals.config.apiUrl}/Auth/CancelSub`,{withCredentials : true}) ;
        return response.status ;
    } catch (error) {
        console.log(error);
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description:
            i18n.t("Services.Errors.ErrorDesc"),
        });
    }
}


export const getPaymentMethod  = async () => {
    try {
        const response = await  axios.get(`${externals.config.apiUrl}/Auth/retrieve-customer-payment-method`,{withCredentials : true}) ;
        return response.data ;
    } catch (error) {
        console.log(error);
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description:
            i18n.t("Services.Errors.ErrorDesc"),
        });
    }
}