import { Provider } from 'mobx-react';
import ListFactures from './Components/ListFactures';
import FactureStore from 'Store/FacturesStore';
import i18n from 'i18nextinit';
export interface MainFacturesProps {
    type ?: string
}
 
const MainFactures: React.SFC<MainFacturesProps> = ({type}) => {


    return (
        <Provider FacturesStore={FactureStore}>
            <title>{i18n.t("Pages.Invoices.TabTitle")} | CodScale</title>
            <div className="MainContainer">
                <ListFactures type={type} FacturesStore={FactureStore}  />
            </div>
        </Provider>
    );
}
 
export default MainFactures;