import {ReactComponent as ShpoifyIcon } from 'Assets/Svgs/Sales/shopify.svg';
import {ReactComponent as SheetIcon } from 'Assets/Svgs/Sales/sheets.svg';
import {ReactComponent as WooCommerceIcon } from 'Assets/Svgs/Sales/woocommerce.svg';
import {ReactComponent as YouCan } from 'Assets/Svgs/Sales/ucan.svg';

import i18n from 'i18nextinit';

interface VendorComponentProps {
    setSelected: React.Dispatch<React.SetStateAction<string>>,
    selected: string
}
interface VendorTypes {
    icon: JSX.Element
    name : string
    color : string
    setSelected: React.Dispatch<React.SetStateAction<string>>
    selected: string
}

const Vendors = [{
    name : 'Shopify',
    icon : <ShpoifyIcon />,
    color : '#CEE8BB',
    accent : '#558b2f'
},
{
  name : 'Woo Commerce',
  icon : <WooCommerceIcon />,
  color : '#DFC7FB' ,
  accent : '#9B5C8F' 
}];

const sheet = {
  name : 'YouCan',
  icon : <YouCan />,
  color : '#cc7bac',
  accent : '#803160'
}

const VendorItem : React.SFC<VendorTypes> = ({icon,name,color,setSelected,selected})=>{

    return (
      <div
        onClick={() => {
          setSelected(name);
        }}
        className={selected === name ? "VendorItem selected" : "VendorItem"}
        style={{ backgroundColor: color }}
      >
        {name === "YouCan" ? (
          <span  style={{ color: "#803160" , fontFamily: 'CatamaranBlack'}}>
            YouCan
          </span>
        ) : (
          icon
        )}
      </div>
    );
}
const VendorComponent: React.SFC<VendorComponentProps> = ({selected,setSelected}) => {
    return (
      <div
        className="menu vendCont"
        style={{
          justifyContent: "space-between",
        }}
      >
        <div className="VendDesc">
          <span className="title">{i18n.t("Pages.SalesChannel.SaleModal.ChooseVend")} :</span>
          <div className="VendorsContainer">
            {Vendors.map((vendor) => (
              <VendorItem
                icon={vendor.icon}
                key={vendor.name}
                name={vendor.name}
                color={vendor.color}
                setSelected={setSelected}
                selected={selected}
              />
            ))}
          </div>
          <span className="title" style={{ fontSize: "17px", color: "grey" }}>
            {i18n.t("Pages.SalesChannel.SaleModal.Imp")} :
          </span>
          <div className="VendorsContainer">
            <VendorItem
              icon={sheet.icon}
              key={sheet.name}
              name={sheet.name}
              color={sheet.color}
              setSelected={setSelected}
              selected={selected}
            />
          </div>
        </div>

        {selected !== "" && (
          <div
            style={
              selected !== "YouCan"
                ? {
                    width: "45%",
                    backgroundColor: Vendors.find((v) => v.name === selected)
                      ?.color,
                  }
                : { width: "45%", backgroundColor: sheet.color }
            }
            className="StorePreview"
          >
            {selected !== 'YouCan' ? Vendors.find((v) => v.name === selected)?.icon : null}
            <span
              className="StoreTitle"
              style={
                selected !== "YouCan"
                  ? { color: Vendors.find((v) => v.name === selected)?.accent }
                  : { color: sheet.accent }
              }
            >
              {selected}
            </span>
          </div>
        )}
      </div>
    );
}
 
export default VendorComponent;