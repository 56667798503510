import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import  CheckoutForm  from './Components/CheckoutForm';

// const PUBLIC_KEY = "pk_live_a3k3YuSLjHnYmvSTbwtJgZAa";

const PUBLIC_KEY = "pk_test_FUo5Zuk3BiQWMZJjNdznbcxO";


const stripeTestPromise = loadStripe(PUBLIC_KEY);

interface StripeProps {
    email : string,
    data: {
        values: {};
    },
    setScreen: React.Dispatch<React.SetStateAction<number>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    Loading: boolean
}
 
const Stripe: React.SFC<StripeProps> = ({email,data,setLoading,setScreen,Loading}) => {
    return ( 
        <Elements stripe={stripeTestPromise}>
            <CheckoutForm email={email} data={data} setLoading={setLoading} setScreen={setScreen} Loading={Loading} />
        </Elements>
     );
}
 
export default Stripe;