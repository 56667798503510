import { useState } from "react";
import { ReactComponent as AddIcon } from "Assets/Svgs/addIcon.svg";
import { observer, inject } from "mobx-react";
import Modal from "antd/lib/modal/Modal";
import { Button } from "antd";
import InventoryModal from "./InventoryModal";
import {
  CreateInventoryProps,
  WarehouseTypes,
} from "../Constants/InventoryTypes";
import { WarehouseData } from "../Constants/InventoryData";
import { AddToWarehouseHelper } from "../Helpers/WarehouseHelpers";
import i18n from "i18nextinit";

const CreateInventory: React.SFC<CreateInventoryProps> = inject(
  "InventorysStore"
)(
  observer(
    ({
      InventorysStore,
      setLoading,
      dataSource,
      setDataSource,
      pageSize,
      setPagesize,
      pagination,
      LivrstockData,
      setLivrstockData,
      LivrSize,
      setLivrSize,
      usertype
    }) => {
      const [open, setOpen] = useState(false);
      const [Data, setData] = useState<WarehouseTypes>(WarehouseData);
      const [confirmLoading, setconfirmLoading] = useState(false);
      const [Type , setType] = useState<string|undefined>(usertype === 'Livr' ? 'LivrStock' : '');
      const [Checker , setChecker] = useState<boolean>(false);
      const WarehouseDisabled : boolean = (Data.ProductName === "" || Data.ProductPrice === 0 || Data.Quantity === "" ) ;
      const LivrStockDisabled : boolean = usertype === 'Livr' ?(Data.ProductName === "" || Data.ProductPrice === 0 ||Data.Quantity === "" ) : (Data.ProductName === "" || Data.ProductPrice === 0 ||Data.Quantity === "" || Data.LivrId === '' || Data.LivrId === undefined)
      return (
        <>
          <div className="CreateOrder" style={{ float: "right" }}>
            <button onClick={() => setOpen(!open)}>
              <AddIcon />
              <span>{i18n.t("Pages.Inventory.Create.Add")}</span>
            </button>
          </div>
          <Modal
            title={i18n.t("Pages.Inventory.Create.Add")}
            visible={open}
            destroyOnClose
            onCancel={() => {
              setOpen(!open)
            }}
            footer={[
              <Button key="back" type="default" onClick={() => {
                setOpen(!open)
              }}>
                {i18n.t("Pages.Inventory.Create.cancel")}
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={confirmLoading}
                disabled={Type === 'Warehouse' ? WarehouseDisabled:LivrStockDisabled}
                onClick={() =>{
                    if (Type === 'Warehouse') {
                      AddToWarehouseHelper(
                        Data,
                        setconfirmLoading,
                        setLoading,
                        InventorysStore,
                        setDataSource,
                        setPagesize,
                        setOpen,
                        pagination,
                        setType,
                        setChecker,
                        Type
                      );
                    }
                    if (Type === 'LivrStock') {
                      AddToWarehouseHelper(
                        Data,
                        setconfirmLoading,
                        setLoading,
                        InventorysStore,
                        setLivrstockData,
                        setLivrSize,
                        setOpen,
                        pagination,
                        setType,
                        setChecker,
                        Type
                      );
                    }
                }   
                }
              >
                {i18n.t("Pages.Inventory.Create.create")}
              </Button>,
            ]}
          >
            <InventoryModal
              Data={Data}
              setData={setData}
              Type={Type}
              setType={setType}
              Checker={Checker}
              usertype={usertype}
            />
          </Modal>
        </>
      );
    }
  )
);

export default CreateInventory;
