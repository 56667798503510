import Auth from 'Pages/Auth/Auth';
import EmailVerif from 'Pages/Auth/EmailVerif';
import ResetPwd from 'Pages/Auth/ResetPwd';
import Stripe from 'Pages/Payments/Stripe';
import {Switch , Route} from 'react-router-dom';
import { useEffect } from 'react';
import {ROOT , SIGNIN , SIGNUP , EMAILVERIF, RESET} from './CONSTANTS' ;
import {PrivateRouter} from './PrivateRouter';

         
import 'moment/locale/fr';
import 'moment/locale/ar';
import 'moment/locale/en-au';
import moment from 'moment';
import i18n from 'i18nextinit';

const RouterConfig = ()=>{

    useEffect(() => {
        moment.locale(i18n.resolvedLanguage);
    }, [])

    return(
        <div>
            <Switch>
                <Route exact path={'/stripe'} component={Stripe} />
                <Route exact path={SIGNIN} component={Auth} />
                <Route exact path={SIGNUP} component={Auth} />
                <Route exact path={EMAILVERIF} component={EmailVerif} />
                <Route exact path={RESET} component={ResetPwd} />
                <Route  path={ROOT}>
                    <PrivateRouter />
                </Route>
            </Switch>
        </div>
    )
}

export default RouterConfig ;
