import { Carousel, message } from 'antd';
import i18n from 'i18nextinit';
import { config } from 'react-transition-group';
import YoutubeImage from 'Assets/res/youtube.png';
interface ShopifyHowtoProps {
}
 
const ShopifyHowto: React.SFC<ShopifyHowtoProps> = () => {
    
    return (
      <div className="GoogleSheetsContainer">
        <div className="DemoContainer" style={{width:'60%'}}>
          <div className="VideoContainer">
            <span className="tipTitle">
              {i18n.t("Pages.SalesChannel.GoogleComponent.videoTitle")}
            </span>
            <br />
            <div className='YoutubeLink'>
                <img src={YoutubeImage} alt="youtube image" width={30} height={30} />
                <span><a href="https://www.youtube.com/watch?v=b0IZo2Aho9Y" target='_blank'>Click on the link</a></span>
            </div>
          </div>
        </div>
      </div>
    );
}
 
export default ShopifyHowto;