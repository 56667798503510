import { Input } from "antd";
import { AdminType } from "Components/Constants/UserTypes";
import i18n from "i18nextinit";
import { useState } from "react";

interface EditTelProps {
    EditedData: AdminType
    setEditedData: React.Dispatch<React.SetStateAction<AdminType>>
    IsPasswd: boolean
    setIsPasswd: React.Dispatch<React.SetStateAction<boolean>>
    IsTel: boolean
    setIsTel: React.Dispatch<React.SetStateAction<boolean>>
}
 
const EditTel: React.SFC<EditTelProps> = ({EditedData,setEditedData,IsPasswd,setIsPasswd,IsTel,setIsTel}) => {
    const [msg , setmsg] = useState('');
    return ( 
        <>
            <p>{i18n.t("Pages.MyProfile.MyAccount.EditTel.Paragraph")}</p>
            <div className="Field1" style={{width : '100%'}}>
                <label className="label">{i18n.t("Pages.MyProfile.MyAccount.Form.Phone")}</label>
                <Input
                    type="Tel"
                    name="Phone"
                    // className={
                    //     !IsTel
                    //     ? "Field normal inv"
                    //     : "Field normal"
                    // } 
                    addonBefore="+212" 
                    onChange={(e)=>{
                        setmsg('');
                        setEditedData({...EditedData , Tel : e.target.value})
                        setIsTel(true)
                    }}
                    onBlur={(e)=>{
                        if(e.target.value.length !== 9)
                        {
                            
                            setmsg('Invalid phone number')
                            setIsTel(false)
                        }
                    }}
                    value={EditedData.Tel}
                />
                {!IsTel ? (
                <div className="inv_msg">{msg === '' ? 'phone already exist' : msg}</div>
                ) : null}
            </div>
            <div className="Field1" style={{width : '100%'}}>
                <label className="label">{i18n.t("Pages.MyProfile.MyAccount.EditTel.LabelCurr")}</label>
                <input
                    type="password"
                    name="password"
                    className={
                        !IsPasswd
                        ? "Field normal inv"
                        : "Field normal"
                    }
                    onChange={(e)=>{
                        setEditedData({...EditedData , Password : e.target.value})
                        setIsPasswd(true);
                    }}
                    
                    value={EditedData.Password}
                />
            </div>
            {!IsPasswd ? (
                <div className="inv_msg">{i18n.t("Pages.MyProfile.MyAccount.EditTel.InvCurrLabel")}</div>
            ) : null}
        </>
     );
}
 
export default EditTel;