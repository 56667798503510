import { Message, Phone } from "@material-ui/icons";

import React from "react";
import { DataFieldsTypes, handleDataChangeTypes } from "../../OrderTypes";
import { Select } from 'antd';
import { ClientType } from "Pages/Clients/Constants/ClientTypes";
import i18n from "i18nextinit";

interface CustomersDetailsProps {
    Data: DataFieldsTypes ,
    loading: boolean ,
    setData: React.Dispatch<React.SetStateAction<DataFieldsTypes>>
    handleDataChange: handleDataChangeTypes
    Clients : ClientType[]
    Client: ClientType | undefined
    setCLient: React.Dispatch<React.SetStateAction<ClientType | undefined>>
}
 
const { Option } = Select;

const CustomersDetails: React.SFC<CustomersDetailsProps> = ({Data , loading , setData , handleDataChange ,Clients, Client , setCLient}) => {

    const handleClients = (value : string) =>{
        setCLient(Clients.find(element => element.Client_Id === value));
        setData({...Data , Client_Id : value ,
         City : Clients.find(element => element.Client_Id === value)?.City,
         Phone : Clients.find(element => element.Client_Id === value)?.Phone
        })
    }
    return ( 
        <div className="CustomerDetails">
          <div style={{display:'flex',flexDirection:"row",justifyContent:"space-between",alignItems:'center'}}>
            <span className="smallTitle">{i18n.t("Pages.Orders.CustomerDet")} :</span>
            <div style={{display:'flex',flexDirection:"row",justifyContent:"space-between",alignItems:'center'}}>
                <a href={`tel:${Data.Phone}`} >
                  <Phone style={{width:"1.25rem",height:"1.25rem",marginRight:"10px",fill:"#6D2EAB"}}/>
                </a>
                <a href={`sms:${Data.Phone}`}>
                  <Message style={{width:"1.25rem",height:"1.25rem",fill:"#6D2EAB"}}/>
                </a>
            </div>
          </div>
          <div className="dataFields">
            
            <div className="Row1">
              {Data.Client_Id === '' &&(
                <Select
                showSearch
                placeholder={i18n.t("Pages.Orders.clientph")}
                optionFilterProp="children"
                loading={loading}
                style={{width: '100%'}}
                dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                onChange={handleClients}
              >
                {Clients?.map(client =>
                    <Option key={client.Client_Id} value={client.Client_Id}>{client.Firstname + ' ' + client.Lastname}</Option>
                  )}
                
              </Select>
              )}
              {Data.Client_Id !== '' &&(
                <Select
                showSearch
                placeholder={i18n.t("Pages.Orders.clientph")}
                optionFilterProp="children"
                loading={loading}
                style={{width: '100%'}}
                dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                onChange={handleClients}
                defaultValue={Data.Client_Id}
              >
                {Clients?.map(client =>
                    <Option key={client.Client_Id} value={client.Client_Id}>{client.Firstname + ' ' + client.Lastname}</Option>
                  )}
                
              </Select>
              )}
            </div>
            <div className="Row2">
              <input placeholder={i18n.t("Pages.Clients.Modal.Phone")} className="Field Row"  type="tel" name="Phone" value={Data.Phone} 
                onChange={(e) => setData({...Data, Phone :e.target.value})}
                style={{ marginRight: "10px" , width : '50%' }}
              />
              <input placeholder={i18n.t("Pages.Clients.Modal.City")} className="Field Row" onChange={(e) => setData({...Data, City :e.target.value})} type="text" name="City" value={Data.City} style={{ width : '50%' }}
                
              />
            </div>      
            <textarea placeholder={i18n.t("Pages.Orders.Address")} className="Field Create" name="Address" value={Data?.Address} 
              onChange={(e) => handleDataChange(e, setData, Data)}
            ></textarea>
          </div>
        </div>
     );
}
 
export default CustomersDetails;