

const IllusComp = ()=>{
    return(
        <div className='Illus'>
            <div className="test">
            </div>
            
        </div>
    )
}
export default IllusComp ;