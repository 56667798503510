import {FormikValues} from 'formik';
import Lottie from 'react-lottie';
import verifiedAnimation from 'Animations/verified.json';
import animationData from 'Animations/Loading2.json';

import { NavLink } from "react-router-dom";
import i18n from 'i18nextinit';

export interface Screen2Props {
    formik : FormikValues ,
    Loading : boolean ,
    setScreen : React.Dispatch<React.SetStateAction<number>>,
    setSwitch : React.Dispatch<React.SetStateAction<boolean>>
}
 
const Screen2: React.SFC<Screen2Props> = ({ formik , Loading ,setScreen , setSwitch}) => {
    const verifOptions = {
        loop: false,
        autoplay: true,
        animationData: verifiedAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid",
        },
    };
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid",
        },
    };

    return (
        <form className="menu" onSubmit={formik.handleSubmit}>
                <span className='Title'>{i18n.t('Auth.Actions.SignUp.Screen2.VerifnumTitle')}</span>
                <p className='Description' style={{marginTop : '15px'}}>
                {i18n.t('Auth.Actions.SignUp.Screen2.VerifnumDesc')}
                </p><br/>       
                <div className='Password'>
                        <input type="text" name="codeverif" className={
                            formik.touched.codeverif && formik.errors.codeverif
                            ? "Field normal inv"
                            : "Field normal"
                        }

                        onChange={formik.handleChange}
                        value={formik.values.codeverif}
                        id="codeverif"
                        placeholder="Verification code"
                        maxLength={6}
                        />
                        {formik.errors.codeverif ? (
                            <div className="inv_msg">{formik.errors.codeverif}</div>
                        ) : null}
                </div>
                <div className="SigninButton">
                <button onClick={()=>{setScreen(0); setSwitch(false)}} className="Button back"  >{i18n.t('Auth.Actions.SignUp.Previous')}</button>
            {Loading &&(
                <button className="Button loading" type="submit" disabled>
                <Lottie options={defaultOptions} height={35} width={110} />
                </button>
            )}
            {!Loading &&(
                <button type='submit' className={formik.values.codeverif.length <6 ? "Button  back" : "Button next" } style={{float : 'right'}} >{i18n.t('Auth.Actions.SignUp.Next')}</button>
            )}
            </div>
            
        </form>
    );
}
 
export default Screen2;