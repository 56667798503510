import {observable  , action , computed} from 'mobx'; 
import { SalesTypes } from 'Pages/SalesChannel/Constants/SalesTypes';
import { getAllSales } from 'Services/Sales/SalesChannelServices';


interface datafetchedType {
    result : SalesTypes[],
    count : number
}
class SaleStore {

    // Admin data 
    @observable dataSource : SalesTypes[] = [];
    @observable count : number = 0 ;
    

    

    @action getDataSource = (pageg : number | undefined) => {
        return new Promise ((resolve,reject) =>{
            getAllSales(pageg).then((data : datafetchedType) => {
                this.count = data.count ;
                this.dataSource = data.result ;
                resolve(this.dataSource);
            })
            .catch(err =>{
                reject(err);
            })
        })
    }
    
}

const SalesStore = new SaleStore();
export default SalesStore ;