import { Provider } from 'mobx-react';
import {useEffect } from 'react';
import ListOrders from './Components/Admin/ListOrders';
import OrderStore from 'Store/OrderStore';
import CallListOrders from './Components/Call/CallListOrders';
import MagaListOrders from './Components/Maga/MagaListOrders';
import LivrListOrders from './Components/Livr/LivrListOrders';
import { DataCounter } from 'Components/Routes/CounterComponent';
import i18n from 'i18nextinit';
export interface MainOrdersProps {
    type ?: string,
    setLimitOpen: React.Dispatch<React.SetStateAction<boolean>>
    setDataCounter: React.Dispatch<React.SetStateAction<DataCounter | undefined>>,
    setLimitMess: React.Dispatch<React.SetStateAction<string>>,
}
 
const MainOrders: React.SFC<MainOrdersProps> = ({type,setLimitOpen,setDataCounter,setLimitMess}) => {
    
    return (
        <>
        <title>{i18n.t("Pages.Orders.Title")} | CodScale</title>
            <div className='MainContainer'>
            <Provider orderStore={OrderStore} >
                {type === 'Admin' && (
                    <ListOrders orderStore={OrderStore} setLimitOpen={setLimitOpen} setDataCounter={setDataCounter} setLimitMess={setLimitMess} />
                )}
                {(type === 'Call' || type === 'CallH')&& (
                    <CallListOrders orderStore={OrderStore}  setLimitOpen={setLimitOpen} setDataCounter={setDataCounter}  setLimitMess={setLimitMess} Type={type} />
                )}
                {type === 'Maga' && (
                    <MagaListOrders orderStore={OrderStore}  setLimitOpen={setLimitOpen} setDataCounter={setDataCounter} setLimitMess={setLimitMess} />
                )}
                {type === 'Livr' &&(
                    <LivrListOrders orderStore={OrderStore}  setLimitOpen={setLimitOpen} setDataCounter={setDataCounter} setLimitMess={setLimitMess} />
                )}
            </Provider>
            
          </div>  
        </>
    );
}
 
export default MainOrders;