import axios from 'axios';
import { CheckDate } from 'Components/Auth/helpers/Handlers';
import { AdminType } from 'Components/Constants/UserTypes';
import config from 'webpack.config';
import { notification } from 'antd';
import i18n from 'i18nextinit';

export const getAdminData = async () => {
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Users/admin/getMydata`,{withCredentials : true})
        return response.data ;
    } catch (error) {
        return [];
    }
}
export const getEmpData = async () => {
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Users/employes/getMyDataMob`,{withCredentials : true})
        return response.data.result ;
    } catch (error) {
        return [];
    }
}
export const EditAdminData = async(Data : AdminType , SetbuttonLoad: React.Dispatch<React.SetStateAction<boolean>>,
     setIsPasswd: React.Dispatch<React.SetStateAction<boolean>>,setDate: React.Dispatch<React.SetStateAction<boolean>>,
     setLoading: React.Dispatch<React.SetStateAction<boolean>>,setOpen: React.Dispatch<React.SetStateAction<boolean>>,
     setIsStore: React.Dispatch<React.SetStateAction<boolean>>,Active: "Del" | "Name" | "Tel" | "Date" | "pwd" | "Store"
) =>{
    if ((Data.date !== undefined && Data.date !== null) || Active !== "Date" ) {
        console.log("we're in")
        if (CheckDate(Data.date) || Active !== "Date") {
            axios.post(`${config.externals.config.apiUrl}/Users/admin/editAccount`,Data , {withCredentials : true})
            .then(result => {
                if (result.status === 200) {
                    notification['success']({
                        style : {fontFamily : 'CatamaranBold'},
                        message: i18n.t("Services.Success.ProfileUp")
                    })
                    SetbuttonLoad(false);
                    setOpen(false);
                    setLoading(true);
                }
            })
            .catch(err => {
                if (err.response.data === 'password') {
                    SetbuttonLoad(false);
                    setIsPasswd(false);
                }
                if (err.response.data === 'storename') {
                    SetbuttonLoad(false);
                    setIsStore(false);
                }
            })
        }else {
            SetbuttonLoad(false);
            setDate(false);
        }
          
    } else {
        SetbuttonLoad(false);
        setDate(false);
    }
    
}

export const ChangePhone = async(Data : AdminType , SetbuttonLoad: React.Dispatch<React.SetStateAction<boolean>>,
    setIsPasswd: React.Dispatch<React.SetStateAction<boolean>>,setIsTel: React.Dispatch<React.SetStateAction<boolean>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,setOpen: React.Dispatch<React.SetStateAction<boolean>>
) =>{
   if (Data.Tel !== undefined && Data.Tel !== null) {

           axios.post(`${config.externals.config.apiUrl}/Users/admin/editAccount`,Data , {withCredentials : true})
           .then(result => {
               if (result.status === 200) {
                   notification['success']({
                       style : {fontFamily : 'CatamaranBold'},
                       message: i18n.t("Services.Success.ProfileUp")
                   })
                   SetbuttonLoad(false);
                   setOpen(false);
                   setLoading(true);
               }
           })
           .catch(err => {
               if (err.response.data === 'password') {
                   SetbuttonLoad(false);
                   setIsPasswd(false);
               }
               if (err.response.data === 'phone') {
                   SetbuttonLoad(false);
                   setIsTel(false);
                }
           })
         
   } else {
       SetbuttonLoad(false);
       setIsTel(false);
   }
   
}


export const CheckPwd = async (
    Data : AdminType , SetbuttonLoad: React.Dispatch<React.SetStateAction<boolean>>,
    setIsPasswd: React.Dispatch<React.SetStateAction<boolean>>, setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setpopVis: React.Dispatch<React.SetStateAction<boolean>>) =>{
            
            axios.post(`${config.externals.config.apiUrl}/Users/admin/CheckPwd`,Data , {withCredentials : true})
            .then(result => {
                if (result.status === 200) {
                    
                    SetbuttonLoad(false);
                    setOpen(false);
                    setpopVis(true);
                }
            })
            .catch(err => {
                if (err.response.data === 'password') {
                    SetbuttonLoad(false);
                    setIsPasswd(false);
                }
            })
              
}

export const DeleteAccount = async (
    Data : AdminType , SetbuttonLoad: React.Dispatch<React.SetStateAction<boolean>>,
    ) =>{
            
            axios.post(`${config.externals.config.apiUrl}/Users/admin/deleteAccount`,Data , {withCredentials : true})
            .then(result => {
                if (result.status === 200) {
                    
                    SetbuttonLoad(false);
                    window.location.assign('/signin');
                }
            })
            .catch(err => {
                notification["error"]({
                    style: { fontFamily: "CatamaranBold" },
                    message: i18n.t("Services.Errors.ErrorOccur"),
                    description:
                      i18n.t("Services.Errors.ErrorDesc"),
                });
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
              
}

export const ChangePwd = async(Data : AdminType , SetbuttonLoad: React.Dispatch<React.SetStateAction<boolean>>,
    setIsPasswd: React.Dispatch<React.SetStateAction<boolean>>,setnewpasswd: React.Dispatch<React.SetStateAction<boolean>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,setOpen: React.Dispatch<React.SetStateAction<boolean>>
) =>{
   if (Data.NewPassword !== undefined) {
       if (Data.NewPassword?.length > 8) {
            axios.post(`${config.externals.config.apiUrl}/Users/admin/changepwd`,Data , {withCredentials : true})
           .then(result => {
               if (result.status === 200) {
                   notification['success']({
                       style : {fontFamily : 'CatamaranBold'},
                       message: i18n.t("Services.Success.ProfileUp")
                   })
                   SetbuttonLoad(false);
                   setOpen(false);
                   setLoading(true);
               }
           })
           .catch(err => {
               if (err.response.data === 'password') {
                   SetbuttonLoad(false);
                   setIsPasswd(false);
               }
               if (err.response.data === 'newpassword') {
                SetbuttonLoad(false);
                setnewpasswd(false);
            }
           })
       }else {
           SetbuttonLoad(false);
           setnewpasswd(false);
       }
         
   } else {
       SetbuttonLoad(false);
       setnewpasswd(false);
   }
   
}