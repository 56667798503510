import { DataFieldsTypes, EmployeType, handleDataChangeTypes } from "../../OrderTypes";
import { Select } from 'antd';
import i18n from "i18nextinit";
import { useEffect, useState } from "react";

interface ShippiComponentProps {
    Data: DataFieldsTypes ,
    Employes: EmployeType[] | undefined ,
    ExtEmp: EmployeType[] | undefined ,
    loading: boolean ,
    setData: React.Dispatch<React.SetStateAction<DataFieldsTypes>>
    handleDataChange: handleDataChangeTypes;
    Assigned : boolean;
    LivrStatus: boolean;
    setLivr: React.Dispatch<React.SetStateAction<boolean>>
}
const { Option ,OptGroup } = Select;
 
const ShippiComponent: React.SFC<ShippiComponentProps> = ({Data , Employes ,ExtEmp, loading , setData , handleDataChange ,LivrStatus,setLivr}) => {

    const handleChange = (value: string) => {
        setLivr(false);
        setData({...Data, Livr_Id: value})
    };
    console.log(Data);
    return ( 
        <div className="Shipping">
            <span className="smallTitle">{i18n.t("Pages.Orders.Shipping")}</span>
            <div className="dataFields">
              
                <Select
                  showSearch
                  loading={loading}
                  placeholder={i18n.t("Pages.Orders.empPh")}
                  optionFilterProp="children"
                  style={{width: '100%'}}
                  dropdownStyle={{ fontFamily: "CatamaranRegular"}}
                  onChange={handleChange}
                  defaultValue={Data.Livr_Id === '' ? 'Not assigned' : Data.Livr_Id}
                >
                  <OptGroup label={i18n.t("Pages.Bon.Form.StaffDelv")}>
                    {Employes?.map((Employe) => (
                    <Option key={Employe.EmplyeId} value={Employe.EmplyeId}>
                      {Employe.FirstName+" "+Employe.LastName+" | "+Employe.City}
                    </Option>
                    ))}
                  </OptGroup>
                  <OptGroup label={i18n.t("Pages.Bon.Form.ExtDelv")}>
                    {ExtEmp?.map((Employe) => (
                    <Option key={Employe.EmplyeId} value={Employe.EmplyeId}>
                      {Employe.FirstName+" "+Employe.LastName+" | "+Employe.City}
                    </Option>
                    ))}
                  </OptGroup>
                </Select><br/>
                <span style={LivrStatus ? {color:"red",fontFamily:"CatamaranBold"} : {display:"none"}}>Invalid livr</span>
              <textarea placeholder={i18n.t("Pages.Bon.Form.DelNotes")} className="Field Create" name="LivrNotes" onChange={(e) => handleDataChange(e, setData, Data)} value={Data?.LivrNotes}
              ></textarea>
            </div>
          </div>
     );
}
 
export default ShippiComponent;