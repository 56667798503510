import { Message, Phone } from "@material-ui/icons";
import { Result, Select } from "antd";
import i18n from "i18nextinit";
import { ClientType } from "Pages/Clients/Constants/ClientTypes";
import { useEffect, useState } from "react";
import { getLivrById } from "Services/Bon/BonLivrCalls";
import { getClientData } from "Services/Orders/CreateOrderCalls";
import RowInputConponent from "./NestedComponent/RowSpan";
import { DataFieldsTypes, EmployeType, newProductTypes } from "./OrderTypes";

export interface RowOrderModalProps {
    productRows: newProductTypes[] 
    Data: DataFieldsTypes  ,
    setData: React.Dispatch<React.SetStateAction<DataFieldsTypes >>,
    type ?: string 
}
 
const {Option} = Select ;
const RowOrderModal: React.SFC<RowOrderModalProps> = ({
    productRows,
    Data,
    setData,
    type
}) => {
    const [loading , setLoading] = useState<boolean>();
    const [Employe , setEmploye] = useState<EmployeType>();
    const [Client , setCLient] = useState<ClientType>();
    useEffect(()=>{

        setLoading(true);
        getClientData(Data.Client_Id).then((result : ClientType[]) =>{
            if (result !== undefined) {
              setCLient(result[0]);
            }
        })
        getLivrById(Data.Livr_Id)
        .then(result => {
            setEmploye(result);
            setLoading(false);
        }).catch(err => console.log(err))
    },[Data])
    const handleChange =(value : string)=>{
        setData({...Data , Status : value});
    }

    return ( 
        <div className="OrderContainer">
      <div className="LeftSide">
        <div className="Details">
          <span className="smallTitle">{i18n.t("Pages.Orders.Details")} :</span>
          <div className="dataFields">
            <div className="Row1">
              <RowInputConponent Title='Source' value={Data?.Source}  /><br/>
              <RowInputConponent Title='Order note' value={Data?.Notes} type='Address'/>

            </div>
          </div>
        </div>
      </div>
      <div className="MiddleSide">
        <div className="Shipping">
            <span className="smallTitle">{i18n.t("Pages.Orders.Shipping")}</span>
                <div className="dataFields">
                    <span className="Field Row"> {Employe?.FirstName + ' ' + Employe?.LastName + ' | '+Employe?.City} </span>
                </div>  
                <br/>
                <RowInputConponent Title='Delivery note' value={Data?.LivrNotes} type='Address'/>
            </div>
      </div>
      <div className="RightSide">
        <div className="CustomerDetails">
          <div style={{display:'flex',flexDirection:"row",justifyContent:"space-between",alignItems:'center'}}>
            <span className="smallTitle">{i18n.t("Pages.Orders.CustomerDet")} :</span>
            <div style={{display:'flex',flexDirection:"row",justifyContent:"space-between",alignItems:'center'}}>
                <a href={`tel:${Data.Phone}`} >
                  <Phone style={{width:"1.25rem",height:"1.25rem",marginRight:"10px",fill:"#C52233"}}/>
                </a>
                <a href={`sms:${Data.Phone}`}>
                  <Message style={{width:"1.25rem",height:"1.25rem",fill:"#C52233"}}/>
                </a>
            </div>
          </div>
          <div className="dataFields">
            <div className="Row1">
              <RowInputConponent Title={i18n.t("Pages.Clients.Modal.Firstname")} value={Client?.Firstname} />
              <RowInputConponent Title={i18n.t("Pages.Clients.Modal.Lastname")} value={Client?.Lastname} />
              
            </div>
            <div className="Row2">
              <RowInputConponent Title={i18n.t("Pages.Clients.Modal.Phone")} value={Client?.Phone} />
              <RowInputConponent Title={ i18n.t("Pages.Clients.Modal.City")} value={Client?.City} />

            </div>
            <RowInputConponent Title={i18n.t("Pages.Orders.Address")} value={Data?.Address} type='Address' />

          </div>
        </div>
      </div>
      <div className="BottomPart">
        <div className="Products RowP">
            <span className="smallTitle">{i18n.t("Pages.Orders.Products")} :</span>
            <table id="ListOfProducts">
              <thead>
                <tr>
                  <th>{i18n.t("Pages.Orders.Product")}</th>
                  <th>{i18n.t("Pages.Orders.Variant")}</th>
                  <th>{i18n.t("Pages.Orders.Quantity")}</th>
                  <th>{i18n.t("Pages.Orders.Cost")}</th>
                </tr>
              </thead>
              <tbody>
                {productRows.map((product, index) => (
                  <tr key={index} className={product.upsell ? 'UpsellRow' : ''} >
                    {product.upsell && (
                      <td>
                        <span className="Field Row">{product.name}</span>
                      </td>
                    )}
                    {!product.upsell && (
                      <td>
                        <span className="Field Row">{product.name}</span>
                      </td>
                    )}
                    <td>
                      <span className="Field Row" style={{ width: "100px" }}>{product.variant}</span>
                    </td>
                    <td>
                      <span className="Field Row" style={{ width: "100px" }} >{product.quantity}</span>
                    </td>
                    <td>
                      <span className="Field Row"   style={{ width: "100px" }}>{product.cost_per_unit}</span>
                    </td>
                    <br/>
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
        <div className="Recap RowR">
          <span className="smallTitle">{i18n.t("Pages.Orders.Action")} :</span><br/><br/>
          <span style={{ fontFamily: "CatamaranBold" , marginRight : '35px'}}>{i18n.t("Pages.Orders.OrderStat")} :</span>
            {type === 'Maga' && (
              <Select
                  style={{ width: "70%" }}
                  placeholder={i18n.t("Status.PH")}
                  dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                  onChange={handleChange}
                  defaultValue={Data.Status}
                >
                <Option key="Confirmed" value="Confirmed">{i18n.t("Status.Confirm")}</Option>
                <Option key="Packaging" value="Packaging">{i18n.t("Status.Packaging")}</Option>
                <Option key="Declined" value="Declined">{i18n.t("Status.Declined")}</Option>
              </Select>
            )}
            {type === 'Livr' && (
              <Select
              style={{ width: "70%" }}
              placeholder={i18n.t("Status.PH")}
              dropdownStyle={{ fontFamily: "CatamaranRegular" }}
              onChange={handleChange}
              defaultValue={Data.Status}
                >
                <Option key="Confirmed" value="Confirmed">{i18n.t("Status.Confirm")}</Option>
                <Option key="Delivry" value="Delivry">{i18n.t("Status.Delivry")}</Option>
                <Option key="Declined" value="Declined">{i18n.t("Status.Declined")}</Option>
              </Select>
            )}
            {type === 'Call' && (
              <Select
              style={{ width: "70%" }}
              placeholder="Order Status"
              dropdownStyle={{ fontFamily: "CatamaranRegular" }}
              onChange={handleChange}
              defaultValue={Data.Status}
                >
                <Option key="Confirmed" value="Confirmed">{i18n.t("Status.Confirm")}</Option>
                <Option key="Pending" value="Pending">{i18n.t("Status.Pending")}</Option>
                <Option key="Declined" value="Declined">{i18n.t("Status.Declined")}</Option>
              </Select>
            )}
            {type === 'CallH' && (
              <span className='Label'>{Data.Status}</span>
            )}
          <br/><br/><br/>
            <span className='Label'>{i18n.t("Pages.Invoices.Modal.TotalDesc2")} : </span>
          <span className='Value' >{Data.OrderPrice} Dh</span><br/>
        </div>
      </div>
      
    </div>
     );
}
 
export default RowOrderModal;