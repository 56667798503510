import axios from 'axios';
import { notification } from 'antd';
import config from 'webpack.config';


// Types ----------------------------
// ----------------------------------

import { DataFieldsTypes, HandleConfirmTypes } from 'Pages/Orders/Components/OrderTypes';
import StatusComponent from 'Components/Routes/StatusComponent';
import { DataFields } from 'Pages/Orders/Components/Consts';
import { GetCountersData } from 'Services/Auth/AuthCalls';
import { DataCounter } from 'Components/Routes/CounterComponent';
import i18n from 'i18nextinit';

// Services -------------------------
// ---------------------------------

export const getAllProducts = async () =>{
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Products/getAllProducts`,{withCredentials : true})
        return response.data.result ;
    } catch (error) {
        
    }
}


export const CreateOrder:HandleConfirmTypes = (Data , setconfirmLoading ,setLoading , orderStore ,setDataSource , setPageSize , setOpen,pagination,setData,setDataCounter,setLimitOpen,setLimitMess) => {
    axios.post(`${config.externals.config.apiUrl}/Content/Orders/createOrder`,Data , {withCredentials : true})
    .then(result => {
        if (result.status === 200) {
            notification['success']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Success.SuccessMess"),
                description: i18n.t("Services.Success.OrderCreated"),
            })
            setconfirmLoading(false);
                setOpen(false);
                setLoading(true);

                // this section have been comented out to avoid refetching data after creating an order

                // orderStore.getDataSource(pagination).then(()=>{

                //     setDataSource(orderStore.dataSource);
                //     setPageSize(orderStore.count);

                    GetCountersData().then((result : DataCounter) =>{
                        setDataCounter(result);
                        
                        // stop the loading of table data
                        setLoading(false);
                        setData(DataFields);
                    })

                // }).finally(()=>{
                //     setLoading(false);
                //     setData(DataFields);
                // });
        } else {
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            setconfirmLoading(false);
            setOpen(false);
            setLoading(true);
            orderStore.getDataSource(pagination).then(()=>{
                setDataSource(orderStore.dataSource);
                setPageSize(orderStore.count);

                GetCountersData().then((result : DataCounter) =>{
                    setDataCounter(result);
                })

            }).finally(()=>{
                setLoading(false);
                setData(DataFields);
            });
        }
    })
    .catch(err => {
        setconfirmLoading(false);
        setLoading(false);
        console.log(err.response);
        if (err.response.data.error === 'Orders' || err.response.data.error === 'Payment') {
            setLimitMess(err.response.data.error);
            setLoading(false);
            setLimitOpen(true);
        }else
        {
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
        
    })
}

export const getLivr = async () => {
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Users/employes/getLivreurs?type=Livr`,{withCredentials : true}) ;
        return response.data.result ;
    } catch (error) {
        return [] ;
    }
}
export const getLivrExtern = async () => {
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Users/employes/getLivreurs?type=LivrExt`,{withCredentials : true}) ;
        return response.data.result ;
    } catch (error) {
        return [] ;
    }
}
export const getOrders = async () => {
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Orders/getOrder`,{withCredentials : true})
        return response.data ;
    } catch (error) {
        return [];
    }
}
export const getSearchOrders = async (phone:string) => {
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Orders/getSearchOrders?phone=${phone}`,{withCredentials : true})
        return response.data ;
    } catch (error) {
        return [];
    }
}

export const getSearchOrdersByPage = async (phone:string,page:number) => {
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Orders/getSearchOrdersByPage?phone=${phone}&page=${page}`,{withCredentials : true})
        return response.data ;
    } catch (error) {
        return [];
    }
}
export const getNextOrders = async (page : number | undefined, status ?: string |undefined)=>{
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Orders/getOrder?page=${page}&status=${status}`,{withCredentials : true})
        return response.data ;
    } catch (error) {
        
    }
}

export const getClientData = async (Client_Id : string | undefined)=>{
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Clients/findClient?ClientId=${Client_Id}`,{withCredentials : true}) ;
        return response.data.result ;
    } catch (error) {
        
    }
}

export const ConfirmOrder:HandleConfirmTypes = (Data , setconfirmLoading ,setLoading , orderStore ,setDataSource , setPageSize , setOpen,pagination,setData,setDataCounter,setLimitOpen,setLimitMess) => {
    axios.post(`${config.externals.config.apiUrl}/Content/Orders/updateOrder?OrderId=${Data?.Num_Order}`,Data , {withCredentials : true})
    .then(result => {
        if (result.status === 200) {
            notification['success']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Success.SuccessMess"),
                description: i18n.t("Services.Success.OrderUpdate"),
            })
                setconfirmLoading(false);
                setOpen(false);
                setLoading(true);
                orderStore.getDataSource(pagination).then(()=>{
                    setDataSource(orderStore.dataSource);
                    setPageSize(orderStore.count);

                    GetCountersData().then((result : DataCounter) =>{
                        setDataCounter(result);
                    })
                    
                }).finally(()=>{
                    setLoading(false);
                    setData(DataFields);
                    
                });
        } else {
            setconfirmLoading(false);
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: 'An error occured while sending your request , please try again later !',
            })
            
        }
    })
    .catch(err => {
        setconfirmLoading(false);
        setLoading(false);
        console.log(err.response.data);
        if (err.response.data.error === 'Orders' || err.response.data.error === 'Payment') {
            setLimitMess(err.response.data.error);
            setLoading(false);
            setLimitOpen(true);
        }else
        {
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
       
    })
}

export const AdminConfirmOrder:HandleConfirmTypes = (Data , setconfirmLoading ,setLoading , orderStore ,setDataSource , setPageSize , setOpen,pagination,setData,setDataCounter,setLimitOpen,setLimitMess,setLivr) => {
    axios.post(`${config.externals.config.apiUrl}/Content/Orders/AdUpdateOrder?OrderId=${Data?.Num_Order}`,Data , {withCredentials : true})
    .then(result => {
        if (result.status === 200) {
            notification['success']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Success.SuccessMess"),
                description: i18n.t("Services.Success.OrderUpdate"),
            })
            setconfirmLoading(false);
            setOpen(false);
            setLoading(true);
            GetCountersData().then((result : DataCounter) =>{
                setDataCounter(result);
                setLoading(false);
            })

        } else {
            setconfirmLoading(false);
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: 'An error occured while sending your request , please try again later !',
            })
            
        }
    })
    .catch(err => {
        setconfirmLoading(false);
        setLoading(false);
        console.log(err.response.data);
        if (err.response.data.error === 'Orders' || err.response.data.error === 'Payment') {
            setLimitMess(err.response.data.error);
            setLoading(false);
            setLimitOpen(true);
        }else
        {
           if(err.response.data === 'ErrLivr'){
            setLivr(true);
            setLoading(false);
           }else{
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            // setTimeout(() => {
            //     window.location.reload();
            // }, 1000);
            }
        }
       
    })
}