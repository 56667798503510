import { Table } from "antd";
import i18n from "i18nextinit";
import { useEffect } from "react";
import { useState } from "react";
import { GetStockBylivrId } from "Services/Inventory/LivrStockCalls";
import { InventoryTableColumns } from "../Constants/Columns";
import { LivrAdStock, LivrStock } from "../Constants/InventoryTypes";

export interface StockAdModalProps {
    Data : LivrAdStock | undefined,
}
 
const StockAdModal: React.SFC<StockAdModalProps> = ({Data}) => {
    const [dataLivr , setdataLivr] = useState<LivrStock[]>([]);
    const [Loading , setLoading] = useState<boolean>(true);
    useEffect(()=>{
        GetStockBylivrId(Data?.LivrId).then(result =>{
            console.log("🚀 ~ file: StockAdModal.tsx ~ line 18 ~ GetStockBylivrId ~ result", result);    
            setLoading(false);
            setdataLivr(result);
        }).catch(err =>{
            console.log(err);
        })
    },[])
    return ( 
        <div className='InventoryContainer'>
            <div className='Row2'>
                <div className='Row1' style={{width : '40%'}}>
                        <label>{i18n.t("Pages.Inventory.Modal.EmpId")}</label>
                        <span className='Field'>{Data?.LivrId}</span>
                </div>
                <div className='Row1' style={{width : '40%'}}>
                        <label>{i18n.t("Pages.Inventory.Modal.EmpName")}</label>
                        <span className='Field'>{Data?.FirstName + ' ' + Data?.LastName}</span>
                </div>
            </div><br/>
            <span className="smallTitle">{i18n.t("Pages.Inventory.Modal.Products")} </span><br/><br/>
            <div className='back'>
                <Table
                    loading={Loading}
                    dataSource={dataLivr}
                    columns={InventoryTableColumns}
                    style={{width : '100%'}}
                    rowKey={(row: LivrStock) => row.ProductId}
                />
            </div>
        </div>
     );
}
 
export default StockAdModal;