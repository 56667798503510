import {CSSTransition} from 'react-transition-group';
import { ShoppingOutlined,CheckCircleFilled,QuestionCircleOutlined,FormOutlined} from '@ant-design/icons';
import {Steps , Select, Input, message } from 'antd';
import {ReactComponent as CopyIcon } from 'Assets/Svgs/Sales/copy.svg';
import VendorComponent from './VendorComponent';
import { FieldsType, SalesTypes } from '../Constants/SalesTypes';

import ShopifyHowto from './ShopifyHowto';
import i18n from 'i18nextinit';
import GoogleSheetsComponents from './GoogleSheetsComponents';
import { Values } from '../Constants/Consts';


interface SaleModalProps {
    Screen: number,
    switchScreen: boolean,
    setSelected: React.Dispatch<React.SetStateAction<string>>,
    selected: string
    Data: SalesTypes | undefined
    setData: React.Dispatch<React.SetStateAction<SalesTypes | undefined>>
    isShopValid: boolean
    setShopStatus: React.Dispatch<React.SetStateAction<boolean>>
    shopMsg: "Exist" | "Ivalid" | undefined
    setScreen: React.Dispatch<React.SetStateAction<number>>
    setSwitch: React.Dispatch<React.SetStateAction<boolean>>
    sheetHeader: string[]
    Fields: FieldsType | undefined
    setFields: React.Dispatch<React.SetStateAction<FieldsType>>
    requiredFields: string[]
    isSheetIdValid: boolean,
    setIsSheetIdValid: React.Dispatch<React.SetStateAction<boolean>>
    isSheetNameValid: boolean,
    setIsSheetNameValid: React.Dispatch<React.SetStateAction<boolean>>
}

 

const { Step } = Steps;
const { Option } = Select;



const SaleModal: React.SFC<SaleModalProps> = ({
    Screen,setSwitch,setScreen,switchScreen,requiredFields,
    selected,setSelected,Data,setData,isShopValid,
    setShopStatus,shopMsg,sheetHeader,
    Fields,setFields,isSheetIdValid,setIsSheetIdValid,isSheetNameValid,setIsSheetNameValid
}) => {
    
    // Google sheet hooks and states ***
  
    const handleSelectChange = (value : string,key:string)=>{
        setData({...Data, Headers: {...Data?.Headers, [key]: value}});
        setFields({...Fields , [key] : value})
    }

    const webhookLink=`https://api.codscale.com/webhooks/${
      selected === "Shopify"
        ? "shopifyorders"
        : "WoocommerceOrders"
    }`;

    const config = {
      content : i18n.t("Pages.SalesChannel.GoogleComponent.clipboardSuccess"),
      duration : 2,
      style : {
      fontFamily : "CatamaranRegular",
      }
    }
    return (
      <div className="ModalContainer">
        <Steps current={Screen}>
          <Step
            title={i18n.t("Pages.SalesChannel.SaleModal.Title0")}
            icon={Screen === 0 ? <ShoppingOutlined /> : <CheckCircleFilled />}
          />
          <Step
            icon={
              Screen <= 1 ? <QuestionCircleOutlined /> : <CheckCircleFilled />
            }
            title={
              selected === "YouCan"
                ? i18n.t("Pages.SalesChannel.SaleModal.Title1")
                : i18n.t("Pages.SalesChannel.SaleModal.Title2")
            }
          />
          <Step
            icon={Screen <= 2 ? <FormOutlined /> : <CheckCircleFilled />}
            title={
              selected === "YouCan"
                ? i18n.t("Pages.SalesChannel.SaleModal.Title3")
                : i18n.t("Pages.SalesChannel.SaleModal.Title4")
            }
          />
        </Steps>
        <CSSTransition
          in={Screen === 0}
          unmountOnExit
          timeout={500}
          classNames="menu-primary"
        >
          <VendorComponent selected={selected} setSelected={setSelected} />
        </CSSTransition>

        <CSSTransition
          in={Screen === 1}
          unmountOnExit
          timeout={500}
          classNames={!switchScreen ? "menu-third" : "menu-primary"}
        >
          <div
            className="menu"
            style={{ width: "100%", maxWidth: "100%", marginTop: "30px" }}
          >
            {selected === "YouCan" && (
              <>
                <div className="FormContainer">
                  <GoogleSheetsComponents
                    Data={Data}
                    setData={setData}
                    isSheetIdValid={isSheetIdValid}
                    isSheetNameValid={isSheetNameValid}
                    setIsSheetIdValid={setIsSheetIdValid}
                    setIsSheetNameValid={setIsSheetNameValid}
                  />
                </div>
              </>
            )}
            {(selected === "Shopify" || selected === "Woo Commerce") && (
              <>
                <div className="FormContainer">
                  <ShopifyHowto/>
                </div>
              </>
            )}
          </div>
        </CSSTransition>

        <CSSTransition
          in={Screen === 2}
          unmountOnExit
          timeout={500}
          classNames="menu-third"
        >
          <div
            className="menu vendCont"
            style={{
              justifyContent: "center",
            }}
          >
            {selected === "YouCan" && (
              <>
                <div className="FormContainer">
                  <span className="tipTitle">
                    {i18n.t("Pages.SalesChannel.SaleModal.TipTitle")}
                  </span>
                  <div className="SelectContainer">
                    {Object.keys(Values).map(function (key, index) {
                      return (
                        <div className="ShopUrl">
                          <label className="label">
                            {Values[key]}
                            {requiredFields.includes(key) && (
                              <span
                                style={{ color: "red" }}
                                title={i18n.t(
                                  "Pages.SalesChannel.SaleModal.required"
                                )}
                              >
                                *
                              </span>
                            )}
                          </label>
                          <Select
                            placeholder={i18n.t(
                              "Pages.SalesChannel.SaleModal.GoogleSelectPH"
                            )}
                            showSearch
                            dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                            className="selectForm"
                            onChange={(value: string) => {
                              handleSelectChange(value, key);
                            }}
                          >
                            {sheetHeader.map((sheet) => (
                              <Option key={sheet} value={sheet}>
                                {sheet}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}

            {(selected === "Shopify" || selected === "Woo Commerce") && (
              <div className="FormContainer">
                <span className="tipTitle">
                  {i18n.t("Pages.SalesChannel.SaleModal.WebhookInfo")}
                </span>

                <div className="shopsUrl">
                  <label className="label">
                    {i18n.t("Pages.SalesChannel.SaleModal.Shop")}{" "}
                  </label>
                  <Input
                    addonBefore="https://www."
                    name="shopUrl"
                    addonAfter={
                      selected === "Shopify" ? ".myshopify.com" : undefined
                    }
                    className={isShopValid ? "" : "inv"}
                    value={Data?.ShopUrl}
                    onChange={(e) => {
                      setShopStatus(true);
                      setData({ ...Data, ShopUrl: e.target.value });
                    }}
                  />
                  {!isShopValid ? (
                    <div className="inv_msg">
                      {shopMsg === "Exist"
                        ? i18n.t("Pages.SalesChannel.SaleModal.InvMsg1")
                        : i18n.t("Pages.SalesChannel.SaleModal.InvMsg2")}
                    </div>
                  ) : null}
                </div>
                <div className="SecretKey">
                  <label className="label">
                    {i18n.t("Pages.SalesChannel.SaleModal.Secret")}
                  </label>
                  <input
                    type="text"
                    name="secretKey"
                    className="Field normal"
                    value={Data?.Key}
                    onChange={(e) => {
                      setData({ ...Data, Key: e.target.value });
                    }}
                  />
                </div>
                <div className="WebhookLinkContainer">
                  <span className="label">
                    {i18n.t("Pages.SalesChannel.GoogleComponent.webhookLink")}
                  </span>
                  <div className="WebhookLink">
                    <span className="text">{webhookLink}</span>
                    <span
                      className="Copy"
                      onClick={() => {
                        navigator.clipboard.writeText(webhookLink);
                        message.success(config);
                      }}
                    >
                      <CopyIcon />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </CSSTransition>
      </div>
    );
}
 
export default SaleModal;