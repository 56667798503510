import { Button, Menu, Modal } from "antd"
import { useState } from "react"
import { Provider } from 'mobx-react';
import BonLivr from "./BonLivr"
import BonRetour from "./BonRetour"
import BonStock from "./BonStock"
import CreateBon from "./CreateBon"
import BonsStore from "Store/BonStore";
import { BonTypes } from "../Constants/BonTypes";
import i18n from "i18nextinit";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { handleDeleteBon } from "../Helpers/BonHandlers";

export interface ListBonProps {
  type ?: string
}
 
const ListBon: React.SFC<ListBonProps> = ({type}) => {
    const [current , setCurrent] = useState<string>('Livr');
    const [loading , setLoading ] = useState<boolean>();
    const [dataSource ,setDataSource] = useState<BonTypes[]>([]);
    const [pageSize , setPagesize] = useState<number>(0);
    const [selectedRowKeys , setSelectedRowKeys] = useState<React.Key[]>([]);
    const [popVis , setPopVis] = useState(false);
    const [PopConfirm , setPopConfirm] = useState(false);
    const [pagination , setPagination] = useState<number|undefined>(1);
    
    const { confirm } = Modal;

    function showDeleteConfirm() {
      confirm({
        title: i18n.t("Pages.Inventory.Errors.Title"),
        style : {fontFamily : 'CatamaranBold'},
        icon: <ExclamationCircleOutlined />,
        content: `${i18n.t("Pages.Inventory.Errors.content1")} ${selectedRowKeys.length} ${i18n.t("Pages.Inventory.Errors.content2")}` ,
        okText: i18n.t("Pages.Inventory.Errors.Yes"),
        okType: 'danger',
        cancelText: i18n.t("Pages.Inventory.Errors.No"),
        visible : popVis ,
        okButtonProps : {loading : PopConfirm},
        onOk() {
          handleDeleteBon('',setPopConfirm,BonsStore,setLoading,setPopVis,dataSource,setDataSource,pageSize,setPagesize,pagination,current,type,selectedRowKeys);
          setSelectedRowKeys([]);
        },
        onCancel() {
          setPopVis(false);
        },
      });
    }

    return (
      <Provider BonStore={BonsStore}>
        <div className="MenuContainer">
          <Menu
            onClick={(e) => {
              setCurrent(e.key.toString());
            }}
            style={{ width: '50%' }}
            selectedKeys={[current]}
            mode="horizontal"
          >
            <Menu.Item key="Livr">{i18n.t("Pages.Bon.Form.DelSheet")}</Menu.Item>
            <Menu.Item key="Ret">{i18n.t("Pages.Bon.Form.RetSheet")}</Menu.Item>
            <Menu.Item key="Stock">{i18n.t("Pages.Bon.Form.StockSheet")}</Menu.Item>
          </Menu>
              <div className='ButtonsContainer'>
                    <Button style={{marginRight : '15px'}} type="dashed" onClick={showDeleteConfirm}  danger disabled={selectedRowKeys.length === 0} >
                        {i18n.t("Pages.Inventory.Errors.Delete")}
                    </Button>
                    <CreateBon
                      BonStore={BonsStore}
                      setLoading={setLoading}
                      dataSource={dataSource}
                      setDataSource={setDataSource}
                      pageSize={pageSize}
                      setPagesize={setPagesize}
                      type={type}
                      current={current}
                    />
                    
              </div>
            
          
          
        </div>

        {current === "Livr" && (
          <BonLivr
            pagination={pagination}
            setPagination={setPagination}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            BonStore={BonsStore}
            loading={loading}
            setLoading={setLoading}
            dataSource={dataSource}
            setDataSource={setDataSource}
            pageSize={pageSize}
            setPagesize={setPagesize}
            type={type}
          />
        )}
        {current === "Ret" && (
          <BonRetour
            pagination={pagination}
            setPagination={setPagination}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            BonStore={BonsStore}
            loading={loading}
            setLoading={setLoading}
            dataSource={dataSource}
            setDataSource={setDataSource}
            pageSize={pageSize}
            setPagesize={setPagesize}
            type={type}

          />
        )}
        {current === "Stock" && (
          <BonStock
            pagination={pagination}
            setPagination={setPagination}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            BonStore={BonsStore}
            loading={loading}
            setLoading={setLoading}
            dataSource={dataSource}
            setDataSource={setDataSource}
            pageSize={pageSize}
            setPagesize={setPagesize}
            type={type}

          />
        )}
      </Provider>
    );
}
 
export default ListBon;