import { AutoComplete, Button, Table, TablePaginationConfig } from "antd";
import React, {useEffect , useState} from 'react';
import Modal from "antd/lib/modal/Modal";
import {DataFieldsTypes , newProductTypes } from '../OrderTypes';
import {observer , inject } from 'mobx-react';
import OrderStore from 'Store/OrderStore';
import {DataFields, ListOrderscolumns, newProduct} from '../Consts';
import OrderModal from "../OrderModal";
import { handleAdd, HandleConfirm, handleDataChange, handleRemove, updateRowProduct } from "../../helpers/AdminOrdersHandlers";
import CreateOrder from "./CreateOrder";
import {ReactComponent as RefreshIcon} from 'Assets/Svgs/refreshIcon.svg';
import { DataCounter } from "Components/Routes/CounterComponent";
import i18n from "i18nextinit";
import { getSearchOrders, getSearchOrdersByPage } from "Services/Orders/CreateOrderCalls";
import {ReactComponent as SearchIcon} from 'Assets/Svgs/search.svg';
import { getOrdersByCallId } from "Services/Orders/CallOrderCalls";
// import 'Sass/ant-picker.scss';
export interface ListOrderProps {
  orderStore : typeof OrderStore
  setLimitOpen: React.Dispatch<React.SetStateAction<boolean>>
  setDataCounter: React.Dispatch<React.SetStateAction<DataCounter | undefined>>
  setLimitMess: React.Dispatch<React.SetStateAction<string>>,
  Type ?:string
}


const { Option } = AutoComplete;

const ListOrders: React.SFC<ListOrderProps> = inject(
  'orderStore'
)(
  observer(({orderStore,setLimitOpen,setDataCounter,setLimitMess,Type}) => {

    
    const [NumSearchData,setNumSearchData] = useState<string>('');
    const [SearchedData , setSearchedData]= useState<string[]>([]);
    const [LivrStatus , setLivr] = useState(false);

    const [Data , setData ] = useState<DataFieldsTypes>(DataFields);
    const [pagination , setPagination] = useState<number|undefined>(1);
    const [pageSize , setPagesize] = useState<number>(orderStore.count);
    const [productRows , setRows] = useState<newProductTypes[]>([]);
    const [Loading , setLoading] = useState<boolean>();
    const [Open , setOpen] = useState(false);

    const [dataSource , setDataSource] = useState<DataFieldsTypes[]>(orderStore.dataSource);
    const [confirmLoading , setConfirmLoading] = useState<boolean>(false);

    useEffect(()=>{
      setLoading(true);
      if(NumSearchData === '')
      {
        if (Type === "CallH") {

          getOrdersByCallId(1).then(res => {
            setDataSource(res.result);
            setPagesize(res.count);
            setLoading(false);
          });

        } else {
          orderStore.getDataSource(1).then(()=>{
            setDataSource(orderStore.dataSource);
            setPagesize(orderStore.count);
            setLoading(false);
          });
        }
      }else
      {
        handleSearch(1);
      }
     
    },[]);

    
    const HandleRefresh = () =>{
      setLoading(true);
      if (Type === "CallH") {

        getOrdersByCallId(pagination).then(res => {
          setDataSource(res.result);
          setPagesize(res.count);
          setLoading(false);
        });

      } else {
        orderStore.getDataSource(pagination).then(()=>{
          setDataSource(orderStore.dataSource);
          setPagesize(orderStore.count);
          setLoading(false);
        });
      }
      
    }

    const handleChange = (pagination: TablePaginationConfig) => {
        if(NumSearchData === '')
        {
          setPagination(pagination.current);
          setLoading(true);
          if (Type === "CallH") {

            getOrdersByCallId(1).then(res => {
              setDataSource(res.result);
              setPagesize(res.count);
              setLoading(false);
            });
    
          } else {
            orderStore.getDataSource(pagination.current).then(()=>{
              setDataSource(orderStore.dataSource);
              setPagesize(orderStore.count);
              setLoading(false);
            });
          }
        }else
        {
          handleSearch(pagination.current);
        }
    }

    const HandelRow = (row : DataFieldsTypes )=>{
      setData(row) ;
      setRows(row.Products);
      setOpen(true);

    }
    
    
    const onSelectPhone = (value: string) => {
      setNumSearchData(value);
    }

    const onSearchPhone = (value: string) => {
      setNumSearchData(value);
      getSearchOrders(value).then(res => {
        setSearchedData(res.newResult);
      }).catch(err => console.log(err));
    }

    const handleSearch = (pagination:number)=>{
      setLoading(true);
      getSearchOrdersByPage(NumSearchData,pagination).then(res => {
        setDataSource(res.result);
        setPagesize(res.count);
        setLoading(false);
      }).catch(err => console.log(err));
    }

    const handleClearSearch = ()=>{
      setNumSearchData('');
      setSearchedData([]);
      setLoading(true);
      
      orderStore.getDataSource(1).then(()=>{
        setDataSource(orderStore.dataSource);
        setPagesize(orderStore.count);
        setLoading(false);
      });
    }
    
    return (
      <>
      <div className="headCont" style={{display:'flex',justifyContent:"space-between",alignItems:"baseline"}}>
        <div className="ButtonsContainer">
          <CreateOrder setLimitMess={setLimitMess} setDataCounter={setDataCounter} setLimitOpen={setLimitOpen} orderStore={orderStore} setLoading={setLoading} setDataSource={setDataSource} setPagesize={setPagesize} pagination={pagination} />
          <RefreshIcon width='15px' height='15px' className='refresh' onClick={()=>{HandleRefresh()}}/>
        </div>
        {Type !== "CallH" &&(
          <div className="ButtonsContainer autoComp" >
            <span className='ClearFilter' onClick={handleClearSearch}>{i18n.t('Pages.Orders.ClearTxt')}</span>
            <AutoComplete
                style={{width: '100%',paddingTop:"5px",paddingBottom:"5px",paddingRight:"10px",paddingLeft:"10px",marginRight:"20px"}}
                onSelect={onSelectPhone}
                onSearch={onSearchPhone}
                value={NumSearchData}
                dropdownStyle={{fontFamily:"CatamaranBold"}}
                placeholder={i18n.t('Pages.Orders.SearchPH')}
              >
                {SearchedData?.map(element =>(
                  <Option key={element} value={element}>
                    {element}
                  </Option>
                ))}
            </AutoComplete>
            <SearchIcon width='1.25rem' height='1.25rem' className='Search' onClick={()=>{handleSearch(1)}}/>
          </div>
        )}
        
      </div>
      <div className="ListContainer">
        <Table
          
          dataSource={dataSource}
          columns={ListOrderscolumns}
          style={{overflowX:"auto"}}
          onChange={(pagination) => handleChange(pagination)}
          pagination={{ pageSize: 10, total: pageSize , showSizeChanger : false}}
          loading={Loading}
          rowKey={(row: DataFieldsTypes) => Math.random() }
          onRow={(row) => ({ onClick: () => HandelRow(row) })}
        />
        <Modal
          title={Data?.Num_Order}
          visible={Open}
          destroyOnClose 
          onOk={() => {
            HandleConfirm(
              Data,
              setConfirmLoading,
              setLoading,
              orderStore,
              setDataSource,
              setPagesize,
              setOpen,
              pagination,
              setData,setDataCounter,setLimitOpen,setLimitMess,setLivr
            );
          }}
          onCancel={()=>{
            setOpen(!Open);
            setRows([]);
            setData(DataFields);
          }}
          style={{ top: "9%" }}
          width="85vw"
          bodyStyle={{ height: "60vh", overflowY: "scroll" }}
          footer=
          {
                [
                  <div style={(Data.ConfirmedBy !== undefined && Data.ConfirmedBy !== '') ?{display : "flex" , justifyContent : 'space-between' , alignItems : 'baseline'}:{}}>
                      {(Data.ConfirmedBy !== undefined && Data.ConfirmedBy !== '') && (
                        <div
                          style={{
                            fontFamily: "CatamaranBold",
                            color: "#2a2a2a",
                          }}
                        >
                          {i18n.t("Pages.Orders.Create.content1")} {Data.Status === "Declined" ? i18n.t("Status.Declined") : i18n.t("Status.Confirmed")} {i18n.t("Pages.Orders.Create.by")} : {Data.ConfirmedBy}
                        </div>
                      )}
                      <div style={{alignSelf : 'flex-end'}}>
                        <Button
                          key="back"
                          type="default"
                          onClick={()=>{
                            setOpen(!Open);
                            setRows([]);
                            setData(DataFields);
                          }}
                        >
                          {i18n.t("Pages.Orders.Create.cancel")}
                        </Button>
                        <Button
                          key="submit"
                          type="primary"
                          loading={confirmLoading}
                          onClick={() => {
                            HandleConfirm(
                              Data,
                              setConfirmLoading,
                              setLoading,
                              orderStore,
                              setDataSource,
                              setPagesize,
                              setOpen,
                              pagination,
                              setData,setDataCounter,setLimitOpen,setLimitMess,setLivr
                            );
                          }}
                        >
                          {i18n.t("Pages.Orders.Create.submit")}
                        </Button>
                      </div>
                     
                  </div>
                ]
          }
        >

          
            <OrderModal
            LivrStatus={LivrStatus}
            setLivr={setLivr}
            productRows={productRows}
            newProduct={newProduct}
            Data={Data}
            setData={setData}
            setRows={setRows}
            handleDataChange={handleDataChange} 
            handleAdd={handleAdd} 
            handleRemove={handleRemove}
            updateRowProduct={updateRowProduct}
            type="Edit"
            />
          
        </Modal>
      </div>
      </>
    );
}))
 
export default ListOrders;