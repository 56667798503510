import React, { useState } from "react";
import { ReactComponent as AddIcon } from "Assets/Svgs/addIcon.svg";
import Modal from "antd/lib/modal/Modal";
import { Button } from "antd";
import { ClientType } from "../Constants/ClientTypes";
import AddClientModal from "./AddClientModal";
import { CLientData } from "../Constants/ClientData";
import ClientsStore from "Store/ClientStore";
import { HandleAddClient } from "../Helpers/ClientHelper";
import i18n from "i18nextinit";

export interface AddCLientProps {
    ClientsStore : typeof ClientsStore
    setDataSource: React.Dispatch<React.SetStateAction<ClientType[]>>
    setPagesize: React.Dispatch<React.SetStateAction<number>>
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}
 
const AddCLient: React.SFC<AddCLientProps> = ({ClientsStore,setDataSource,setPagesize,setLoading}) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setconfirmLoading] = useState(false);
    const [phoneErr , setPhoneErr] = useState(false);
    const [pagination , setPagination] = useState<number | undefined>();
    const [Data , setData] = useState<ClientType>(CLientData);
    const disabledButton : boolean = 
    (
      Data?.City === undefined || Data?.Firstname === undefined || 
      Data?.Lastname === undefined || Data?.Phone === undefined 
    )
    const disabledButton2 : boolean = 
    (
        Data?.City === '' || Data?.Firstname === '' || 
        Data?.Lastname === '' || Data?.Phone === ''  
    )
    return ( 
        <>
            <div className="CreateOrder" style={{marginRight : '15px'}} >
                <button onClick={() => setOpen(!open)}>
                <AddIcon />
                <span>{i18n.t("Pages.Clients.Create.AddClient")}</span>
                </button>
            </div>
            <Modal
                visible={open}
                destroyOnClose
                title={i18n.t("Pages.Clients.Create.AddClient")}
                onCancel={() => {
                  setOpen(!open);
                  setData(CLientData)
                }}
                footer={[
                    <Button key="back" type="default" onClick={() => {
                      setOpen(!open);
                      setData(CLientData)
                    }}>
                      {i18n.t("Pages.Clients.Create.cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      loading={confirmLoading}
                      disabled={disabledButton || disabledButton2}
                      onClick={()=>HandleAddClient(Data,setconfirmLoading,setLoading,ClientsStore,setDataSource,
                        setPagesize,setOpen,pagination,setPhoneErr,setData)}
                    >
                      {i18n.t("Pages.Clients.Create.Add")}
                    </Button>,
                  ]}
            >
                <AddClientModal setPhoneErr={setPhoneErr} phoneErr={phoneErr} Data={Data} setData={setData} />
            </Modal>
        </>
     );
}
 
export default AddCLient;