
import { Select , Table } from "antd";
import { OrderfetchedType } from "Pages/Bon/Constants/BonTypes";
import { Ordercolumns } from "Pages/Bon/Constants/Columns";
import { DataFieldsTypes, EmployeType } from "Pages/Orders/Components/OrderTypes";
import React, { useDebugValue, useEffect, useState } from "react";
import { getLivrById, getOrdersById } from "Services/Bon/BonLivrCalls";
import { getShippedOrders } from "Services/Factures/FactureCalls";
import { getLivr, getLivrExtern } from "Services/Orders/CreateOrderCalls";
import { FactureDataTypes } from "../Constants/FacturesTypes";
import { FacturesTypes } from "../Validators/AvaiFacType";
import {ReactComponent as DownloadIcon} from 'Assets/Svgs/direct-download.svg';
import ReactPDF, { PDFDownloadLink , PDFViewer} from '@react-pdf/renderer';
import PdfExport from "./PdfExport";
import i18n from "i18nextinit";
interface FactureModalProps {
    Data: FactureDataTypes,
    setData: React.Dispatch<React.SetStateAction<FactureDataTypes>>,
    selectedRowKeys: React.Key[],
    setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>,
    type ?: string,
    action ?:'Row' | 'Create' 
}
interface OrderFetchedType{
  result : DataFieldsTypes[] ,
  count : number 
}
const { Option , OptGroup } = Select;

const FactureModal: React.SFC<FactureModalProps> = ({Data,setData,selectedRowKeys,setSelectedRowKeys , type,action}) => {

    const [Loading, setloading] = useState(true);
    const [base , setBase] = useState<'First'|'Second'>();
    const [Employes, setEmployes] = useState<EmployeType[]>([]);
    const [selectedEmploye , setSelectedEmploye] = useState<EmployeType>()
    const [dataSource , setDataSource] = useState<DataFieldsTypes[]>();
    const [selectedOrders , setSelectedOrders] = useState<DataFieldsTypes[]>([]);
    const [countOrders , setOcount] = useState<number>();
    const [externEmp,setExtEm] = useState<EmployeType[]>([]);
    const CalculatePrice = async ()=>{
        var total = 0 ;
        
        for await (let order of selectedOrders)
        {
          total += +order.OrderPrice ;
        }
        return total ;
    
    }

    useEffect(()=>{
        setloading(true);
        setBase("First");
        console.log(type);
        
        if (action === 'Create' && type === 'Livr') {
          getShippedOrders('Shipped',undefined,true).then((response:OrderfetchedType)=>{

            setOcount(response.count);
            setDataSource(response.result);
            setSelectedRowKeys(Data.Num_Order);
            console.log("🚀 ~ file: FactureModal.tsx ~ line 63 ~ getLivrById ~ response.result[0].Livr_Id", response.result[0].Livr_Id)

            getLivrById(response.result[0].Livr_Id).then(result =>{
              setSelectedEmploye(result);
              setloading(false);
            }).catch(err =>{
            console.log(err);
            setloading(false);

            })
          }).catch(err =>{
            console.log(err);
            setloading(false);

          })


        }

        if (action === 'Create' && type === 'Admin') {
          getLivr().then((response) => {
            setEmployes(response);
            setloading(false);
          }).catch((err) => {console.log(err);});
          
          getLivrExtern().then((response) =>{
            setExtEm(response)
          }).catch((err) => {console.log(err);setloading(false);});  
        }

        if (action === 'Row') {

          getOrdersById(Data).then((result : OrderFetchedType) =>{
            setDataSource(result.result);
            setOcount(result.count);
            setloading(false);
          }).catch(err =>{
            console.log(err);
          })
          getLivrById(Data.LivrId).then((result : EmployeType) =>{
            setSelectedEmploye(result);
          }).finally(()=>{
            setloading(false);
          })
          .catch(err =>{
            console.log(err);
          })

        }
        
    },[]);

    useEffect(()=>{
        if (base === 'Second' || action === 'Create') {
          CalculatePrice().then(result =>{
            console.log(result);
            if (!isNaN(result)) {
               setData({...Data , Price : result});
            }
          })
        }
        

    },[selectedOrders])

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[],selectedRows: DataFieldsTypes[]) => {
          setData({...Data , Num_Order : selectedRowKeys });
          setSelectedOrders(selectedRows);
          setBase("Second");
          setSelectedRowKeys(selectedRowKeys);
        } 
    };

    const handleChange = (value : string)=>{
        setloading(true);
        setData({...Data,Num_Order : [],Price : 0});
        setSelectedEmploye(Employes.find(employe => employe.EmplyeId === value) !== undefined ?
        Employes.find(employe => employe.EmplyeId === value) :
        externEmp.find(employe => employe.EmplyeId === value));
        setSelectedRowKeys([]);
        getShippedOrders('Shipped',value,action==='Row'?false:true).then((response:OrderfetchedType)=>{
          setOcount(response.count);
          setDataSource(response.result);
        }).then(()=>{
            setloading(false);
            setData({...Data,LivrId : value , Num_Order : []});
        })
    }
    
    return ( 
        <>
        <div className="BonContainer">
        <div className="LeftSide " style={{display : "flex" , width : '100%',justifyContent:'space-between'}}>
              <div className="Recap RowR" style={{width : '30%' , marginBottom : '1.25rem'}}>
                {type === 'Admin' &&(
                  <>
                    <span className="smallTitle">{i18n.t("Pages.Invoices.Modal.Action")} :</span><br/><br/>
                    <span style={{ fontFamily: "CatamaranBold" , marginRight : '35px'}}>{i18n.t("Pages.Invoices.Modal.InvoiceStat")} :</span>
                        <div style={{margin : '10px 0px'}}>
                          <Select
                            placeholder={i18n.t("Pages.Invoices.Modal.StatusPH")}
                            style={{ width: "70%" }}
                            dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                            onChange={(value : string)=>setData({...Data , Status : value})}
                            defaultValue={(Data.Status !== undefined && Data.Status !== '') ? Data.Status : 'Pending'}
                          >     
                                <Option key={'Decline'} value={'Declined'}>{i18n.t("Status.Decline")}</Option>
                                <Option key={'Pending'} value={'Pending'}>{i18n.t("Status.Pending")}</Option>
                                <Option key={'Waiting'} value={'Waiting'}>{i18n.t("Status.Waiting")}</Option>
                                <Option key={'Confirmed'} value={'Confirmed'}>{i18n.t("Status.Confirmed")}</Option>
                          </Select>
                        </div>
                          
                    <br/>
                  </>
                )}
                
                <span className='Label'>{(selectedEmploye?.Type === 'LivrExt') ? `${i18n.t("Pages.Invoices.Modal.TotalDesc1")}(${selectedEmploye.Price} dh) :` : `${i18n.t("Pages.Invoices.Modal.TotalDesc2")} :`}</span>

                {(selectedEmploye?.Type === 'Livr') && (
                  <>
                  <span className='Value' >
                    {Number(Data.Price).toFixed(2)} Dh
                  </span>
                  <br/>

                  </>
                )}
                {(selectedEmploye !== undefined && selectedEmploye !== null && selectedEmploye?.Type === 'LivrExt') &&(
                  <>
                  <span className='Value' >
                    {Number(Data.Price - (parseFloat(selectedEmploye?.Price)*selectedOrders.length)).toFixed(2)} Dh
                  </span>
                  <br/>

                  </>
                )}
                {(selectedEmploye === undefined || selectedEmploye === null) &&(
                  <>
                  <span className='Value' >
                    {Number(Data.Price).toFixed(2)} Dh
                  </span>
                  <br/>

                  </>
                )}
              </div>
              {type === 'Admin' &&(
                <div className="Recap RowR" style={{width : '50%', marginBottom : '1.25rem'}} >
                  <span className="smallTitle">{i18n.t("Pages.Bon.Form.Options")}</span><br/><br/>

                    {(action === 'Create') && (
                      <>
                      <span style={{ fontFamily: "CatamaranBold" , marginRight : '35px'}}>{i18n.t("Pages.Bon.Form.DelMan")} :</span>
                      <div className="Row1">
                        <Select
                          style={{ width: "50%" }}
                          dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                          placeholder={i18n.t("Pages.Invoices.Modal.DelivPH")}
                          onChange={(value : string) => {
                                handleChange(value)
                          }}
                        >
                          <OptGroup label={i18n.t("Pages.Bon.Form.StaffDelv")}>
                            {Employes?.map((Employe) => (
                            <Option key={Employe.EmplyeId} value={Employe.EmplyeId}>
                              {Employe.FirstName+" "+Employe.LastName+" | "+Employe.City}
                            </Option>
                            ))}
                          </OptGroup>
                          <OptGroup label={i18n.t("Pages.Bon.Form.ExtDelv")}>
                            {externEmp?.map((Employe) => (
                            <Option key={Employe.EmplyeId} value={Employe.EmplyeId}>
                              {Employe.FirstName+" "+Employe.LastName+" | "+Employe.City}
                            </Option>
                            ))}
                          </OptGroup>
                          
                        </Select>
                      </div>
                      </>
                    )}
                    {(action === 'Row') &&(
                      <div >
                            
                          <div className="ligne1">
                            <span style={{ fontFamily: "CatamaranBold" , marginRight : '35px'}}>{i18n.t("Pages.Bon.Form.DelMan")} :</span>
                              {(selectedEmploye !== undefined && selectedEmploye !== null && !Loading) &&(
                              <div className="Field" style={{backgroundColor : '#fff',padding:'5px 10px' , width:'50%'}}> 
                                  {selectedEmploye?.FirstName} {selectedEmploye?.LastName} | {selectedEmploye?.Price} Dh
                                  | {selectedEmploye?.City}
                              </div>
                              )}
                              {((selectedEmploye === undefined || selectedEmploye === null) && !Loading) &&(
                                <div className="Field"> 
                                  {i18n.t("Pages.Bon.Form.UserDel")}
                                </div>
                              )}
                              
                          </div><br/>
                          <div className="ligne2">
                          <span style={{ fontFamily: "CatamaranBold" , marginRight : '35px'}}>{i18n.t("Pages.Bon.Pdf.Type")} :</span>
                              <span className='Field' style={{backgroundColor : '#fff',padding:'5px 10px' , width:'50%'}}>{type === FacturesTypes.Facture ? i18n.t("Pages.Invoices.Modal.Inv") : i18n.t("Pages.Invoices.Modal.InvReq")}</span>
                          </div>
                      </div>
                    )}
                
                </div>
              )}
              
          </div>
          
        <div className="RightSide">
              <span className="smallTitle">{i18n.t("Pages.Bon.Pdf.Orders")} :</span>
              <Table
                rowSelection={action === 'Create' ? {
                  type: "checkbox",
                  ...rowSelection,
                }:undefined}
                style={{overflowX:"auto"}}
                columns={Ordercolumns}
                pagination={{ pageSize: 10, total: countOrders }}
                dataSource={dataSource}
                loading={Loading}
                rowKey={(row: DataFieldsTypes) =>
                  row.Num_Order === undefined ? Math.random() : row.Num_Order
                }
              />
        </div>
        {(!Loading && dataSource !== undefined && action === 'Row') && (
          <div>
            <PDFDownloadLink 
            style={{textDecoration : 'none' , fontFamily :'CatamaranBold'}}
            document={<PdfExport Data={Data} Employe={selectedEmploye} dataSource={dataSource}/>}
            fileName={`${Data.FactureId}.pdf`}
            >
              
              {({ blob, url, loading, error }) =>
                loading ? (
                  <div className='PdfDownload'>
                    <span>{i18n.t("Pages.Bon.Pdf.Loading")}...</span>
                  </div>
                ): (
                  <div className='PdfDownload' >
                    <DownloadIcon  style={{marginRight : '10px'}}/>
                    <span>{i18n.t("Pages.Bon.Pdf.Download")}</span>
                  </div>
                )
              }
            </PDFDownloadLink>
          </div>
        )}
      </div>
        </>
     );
}
 
export default FactureModal;