import React, { useState } from "react";
import {ReactComponent as AddIcon } from 'Assets/Svgs/addIcon.svg';
import BonsStore from "Store/BonStore";
import {observer , inject } from 'mobx-react';
import Modal from "antd/lib/modal/Modal";
import { Button } from "antd";
import BonModal from "./BonModal";
import { BonTypes, SelectedProducts } from "../Constants/BonTypes";
import { DataFields } from "../Constants/DataConsts";
import { CreateBonCalls } from "Services/Bon/BonLivrCalls";
import i18n from "i18nextinit";
export interface CreateBonProps {
    BonStore : typeof BonsStore,
    setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    dataSource: BonTypes[],
    setDataSource: React.Dispatch<React.SetStateAction<BonTypes[]>>,
    pageSize: number,
    setPagesize: React.Dispatch<React.SetStateAction<number>>
    type ?: string,
    current ?: string
}


 
const CreateBon: React.SFC<CreateBonProps> = inject(
    'BonStore'
  )(
    observer(({BonStore,setLoading,dataSource,setDataSource,pageSize ,setPagesize,type,current}) => {
    const [open, setOpen] = useState(false);
    const [Data ,setData] = useState<BonTypes>(DataFields);
    const [selectedRowKeys , setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedProducts , setselectedProducts] = useState<SelectedProducts[]>([]);
    const [confirmLoading , setconfirmLoading] = useState(false);
    const isDisabled = Data.Type === 'Stock' ? 
    (Data.LivrId ==="" || selectedProducts.length === 0 || selectedRowKeys.length === 0)
    : ((Data.LivrId ==="" && Data.Type !== 'Ret') || Data.Num_Order.length === 0 || Data.Type === ""); 
    return (
      <>
        <div className='CreateOrder' style={{float : 'right'}}>
            <button onClick={()=>setOpen(!open)}><AddIcon/><span>{i18n.t("Pages.Bon.Create.CreateSheet")}</span></button>
        </div>  
        <Modal
            style={{top : '9%'}}
            title={i18n.t("Pages.Bon.Create.CreateSheet")}
            visible={open} 
            destroyOnClose
            width='85vw'
            onCancel={()=>{
              setOpen(!open);
              setData(DataFields);
            }}
            bodyStyle={{height: '60vh' , overflowY : 'scroll'}}
            footer={[
                <Button key="back" type="default"  onClick={()=>{
                  setOpen(!open);
                  setData(DataFields);
                }}>
                  {i18n.t("Pages.Bon.Create.cancel")}
                </Button>,
                <Button key="submit" type="primary" loading={confirmLoading}
                  disabled={isDisabled}
                  onClick={()=>{
                  if (Data.Type === 'Livr' || Data.Type === 'Ret') {
                    setSelectedRowKeys([]);
                    CreateBonCalls(Data,setconfirmLoading,BonStore,setLoading,setOpen,dataSource,setDataSource,pageSize ,setPagesize,setData,0,current);
                  }
                  if (Data.Type === 'Stock') {
                    var datas = Data ;
                    datas.Num_Prod = selectedProducts ;
                    setData(datas);
                    CreateBonCalls(Data,setconfirmLoading,BonStore,setLoading,setOpen,dataSource,setDataSource,pageSize ,setPagesize,setData,0,current);
                  }
                  
                  
                }}>
                  {i18n.t("Pages.Bon.Create.create")}
                </Button>,
            ]}
            >
                <BonModal type={type} Data={Data} setData={setData} 
                selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} 
                selectedProducts={selectedProducts} setselectedProducts={setselectedProducts}
                />
        </Modal>
      </>
        
     );
}))
 
export default CreateBon;