import axios from "axios";
import { notification } from "antd";
import { CreateFactureTypes } from "Pages/Factures/Constants/FacturesTypes";
import config from 'webpack.config';
import i18n from "i18nextinit";


export const UpdateFields :CreateFactureTypes = (Data , setconfirmLoading ,setLoading , FactureStore ,setDataSource , setPageSize , setOpen,pagination) => {
    axios.post(`${config.externals.config.apiUrl}/Content/Factures/UpdateFacture?FacId=${Data.FactureId}`,Data , {withCredentials : true})
    .then(result => {
        if (result.status === 200) {
            notification['success']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Success.SuccessMess"),
                description: i18n.t("Services.Success.InvUpd"),
            })
                setconfirmLoading(false);
                setOpen(false);
                setLoading(true);
                FactureStore.getDataSource(1)
                .then(()=>{
                    setDataSource(FactureStore.dataSource);
                    setPageSize(FactureStore.count);
                    setLoading(false);
                })
                .catch(err => console.log(err));
        } else {
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            
        }
    })
    .catch(err => {
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description: i18n.t("Services.Errors.ErrorDesc"),
        })
       
    })
}


export const StatusUpdate :CreateFactureTypes = (Data , setconfirmLoading ,setLoading , FactureStore ,setDataSource , setPageSize , setOpen,pagination) => {
    axios.post(`${config.externals.config.apiUrl}/Content/Factures/statusUpdate?FacId=${Data.FactureId}`,Data , {withCredentials : true})
    .then(result => {
        if (result.status === 200) {
            notification['success']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Success.SuccessMess"),
                description: i18n.t("Services.Success.InvUpd"),
            })
                setconfirmLoading(false);
                setOpen(false);
                setLoading(true);
                FactureStore.getDataSource(1)
                .then(()=>{
                    setDataSource(FactureStore.dataSource);
                    setPageSize(FactureStore.count);
                    setLoading(false);
                })
                .catch(err => console.log(err));
        } else {
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            
        }
    })
    .catch(err => {
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description: i18n.t("Services.Errors.ErrorDesc"),
        })
       
    })
}
