import { useEffect, useState } from "react";
import { getBon } from "Services/Bon/BonLivrCalls";
import BonsStore from "Store/BonStore";
import DataTable from "./DataTable";
import {observer , inject } from 'mobx-react';
import { BonTypes } from "../Constants/BonTypes";
import { ProdTableColumns } from "../Constants/Columns";
import { TablePaginationConfig } from "antd";

export interface BonStockProps {
  BonStore : typeof BonsStore,
  loading : boolean | undefined,
  setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>,
  dataSource: BonTypes[],
  setDataSource: React.Dispatch<React.SetStateAction<BonTypes[]>>,
  pageSize: number,
  setPagesize: React.Dispatch<React.SetStateAction<number>>
  pagination: number,
  setPagination: React.Dispatch<React.SetStateAction<number>>
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
  type ?: string

}
 
const BonStock: React.SFC<BonStockProps> =  inject(
  'BonStore'
)(
  observer(({BonStore,loading,setLoading,dataSource,setDataSource,pageSize ,setPagesize,type,pagination,setPagination,selectedRowKeys,setSelectedRowKeys}) =>  {
    useEffect(()=>{
      setLoading(true);
      BonStore.getDataSource('Stock',1).then(()=>{
        setDataSource(BonStore.dataSource);
        setPagesize(BonStore.count);
        setLoading(false);
      });
     
    },[]);
    const handleChange = (pagination: TablePaginationConfig) => {
      setPagination(pagination.current);
      setLoading(true);
      BonStore.getDataSource('Stock',pagination.current).then(()=>{
        setDataSource(BonStore.dataSource);
        setPagesize(BonStore.count);
        setLoading(false);
      }); 
    }
    return (
      <div className="ListContainer">
        <DataTable
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          type={type}
          handleChange={handleChange}
          pagination={pagination}
          setPagesize={setPagesize}
          setDataSource={setDataSource}
          setLoading={setLoading}
          BonStore={BonStore}
          DataTableColumns={ProdTableColumns}
          Loading={loading}
          dataSource={dataSource}
          pageSize={pageSize}
        />
      </div>
    );
}))
 
export default BonStock;