import { DatePicker } from 'antd';
import {FormikValues} from 'formik';
import i18n from 'i18nextinit';
import  { Moment } from 'moment';

export interface Screen1Props {
    formik : FormikValues ,
}
 
const Screen1: React.SFC<Screen1Props> = ({formik}) => {

    const HandleDateChange = (value: Moment | null, dateString: string)=>{
      
        formik.setFieldValue('birthdateview',value);
        formik.setFieldValue('birthdate',dateString);
        
    }

    return (
      <div>
        
        <div className="TwoFields">
          <div className="Field1">
            <label className="label">{i18n.t('Auth.Actions.SignUp.Screen1.Lastname')}</label>
            <input
              type="text"
              name="lastname"
              className={
                formik.touched.lastname && formik.errors.lastname
                  ? "Field normal inv"
                  : "Field normal"
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastname}
            />

            {formik.touched.lastname && formik.errors.lastname ? (
              <div className="inv_msg">{formik.errors.lastname}</div>
            ) : null}
          </div>

          <div className="Field2">
            <label className="label">{i18n.t('Auth.Actions.SignUp.Screen1.Firstname')}</label>
            <input
              type="text"
              name="firstname"
              className={
                formik.touched.firstname && formik.errors.firstname
                  ? "Field normal inv"
                  : "Field normal"
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstname}
            />

            {formik.touched.firstname && formik.errors.firstname ? (
              <div className="inv_msg">{formik.errors.firstname}</div>
            ) : null}
          </div>
        </div>
        <div className="TwoFields">
          <div className="Field1">
            <label className="label">{i18n.t('Auth.Actions.SignUp.Screen1.Email')}</label>
            <input
              type="email"
              name="email"
              className={
                formik.touched.email && formik.errors.email
                  ? "Field normal inv"
                  : "Field normal"
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />

            {formik.touched.email && formik.errors.email ? (
              <div className="inv_msg">{formik.errors.email}</div>
            ) : null}
          </div>

          <div className="Field2">
            <label className="label">{i18n.t('Auth.Actions.SignUp.Screen1.Storename')}</label>
            <input
              type="text"
              name="storename"
              className={
                formik.touched.storename && formik.errors.storename
                  ? "Field normal inv"
                  : "Field normal"
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.storename}
            />

            {formik.touched.storename && formik.errors.storename ? (
              <div className="inv_msg">{formik.errors.storename}</div>
            ) : null}
          </div>
        </div>
        <div className="TwoFields">
          <div className="Field1">
            <label className="label">{i18n.t('Auth.Actions.SignUp.Screen1.Password')}</label>
            <input
              type="password"
              name="password"
              className={
                formik.touched.password && formik.errors.password
                  ? "Field normal inv"
                  : "Field normal"
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />

            {formik.touched.password && formik.errors.password ? (
              <div className="inv_msg">{formik.errors.password}</div>
            ) : null}
          </div>

          <div className="Field2">
            <label className="label">{i18n.t('Auth.Actions.SignUp.Screen1.ConfirmPwd')}</label>
            <input
              type="password"
              name="confirmpass"
              className={
                formik.touched.confirmpass && formik.errors.confirmpass
                  ? "Field normal inv"
                  : "Field normal"
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmpass}
            />

            {formik.touched.confirmpass && formik.errors.confirmpass ? (
              <div className="inv_msg">{formik.errors.confirmpass}</div>
            ) : null}
          </div>
        </div>

        <div className="TwoFields">
          <div className="Field1">
            <label className="label">{i18n.t('Auth.Actions.SignUp.Screen1.DateBirth')}</label>
            <DatePicker
              onChange={HandleDateChange}
              placeholder="Select a date"
              picker="date"
              className="Field normal"
              name="birthdateview"
              id="birthdateview"
              value={formik.values.birthdateview}
              style={
                !formik.errors.birthdateview
                  ? { }
                  : {
                      backgroundColor: "#d8000b19"
                    }
              }
            />

            {formik.touched.birthdateview && formik.errors.birthdateview ? (
              <div className="inv_msg">{formik.errors.birthdateview}</div>
            ) : null}
          </div>

          <div className="Field2">
            <label className="label">{i18n.t('Auth.Actions.SignUp.Screen1.Phone')}</label>
            <input
              type="text"
              name="tel"
              className={
                formik.touched.tel && formik.errors.tel
                  ? "Field normal inv"
                  : "Field normal"
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.tel}
            />

            {formik.touched.tel && formik.errors.tel ? (
              <div className="inv_msg">{formik.errors.tel}</div>
            ) : null}
          </div>
        </div>
        
        {/* CheckBox input ------------------------------- */}
        <div className="TwoFields" style={{ marginTop: "25px" }}>
          <input className="" type="checkbox" required />
          <label className="privacy" style={{ marginLeft: "25px" }}>
          {i18n.t('Auth.Actions.SignUp.Screen1.Terms')}
          </label>
        </div>
    </div>
    );
}
 
export default Screen1;