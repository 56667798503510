import {observable  , action , computed} from 'mobx'; 
import { LivrAdStock, LivrStock, WarehouseTypes } from 'Pages/Inventory/Constants/InventoryTypes';
import { GetStockLivrData, GetStockLivrDataAd } from 'Services/Inventory/LivrStockCalls';
import { GetWarehouseData } from 'Services/Inventory/WarehouseCalls';

interface WarehousefetchedType {
    result : WarehouseTypes[],
    count : number
}
interface LivrStockfetchedType {
    result : LivrStock[] | LivrAdStock[],
    count : number
}
class InventoryStore {

    // Warehouse ----------------------
    @observable dataSource : WarehouseTypes[]  = [];
    @observable count : number = 0 ;
    @observable dataSize : number = 0 ;

    @action getWarehouseDataSource = (pageg : number | undefined) => {
        return new Promise ((resolve,reject) =>{
            GetWarehouseData(pageg).then((data : WarehousefetchedType)=>{
                this.count = data.count ;
                this.dataSource = data.result ;
                if (data.result !== undefined) {
                    this.dataSize = data.result.length ;   
                }
                resolve(this.dataSource);
            }).catch(err =>{
                reject(err);
            })
            
        })
    }
    @action UpdateDataSize = (dataSize : number)=>{
        this.dataSize = dataSize ;
    } 
    @computed get 
    getTotal(){
        return this.count ;
    }
    @computed get
    HowManyPosts(){
        return this.dataSize ;
    }
    // ---------------------------------------------

    // Stock livreur ----------------------------------

    @observable LivrstockData : LivrStock[] = [] ;
    @observable Livrcount : number = 0 ;
    @observable LivrdataSize : number = 0 ;

    @action getLivrstockDataSource = (pageg : number | undefined) => {
        return new Promise ((resolve,reject) =>{
            GetStockLivrData(pageg).then((data : LivrStockfetchedType)=>{
                this.Livrcount = data.count ;
                this.LivrstockData = data.result ;
                if (data.result !== undefined) {
                    this.LivrdataSize = data.result.length ;   
                }
                resolve(this.LivrstockData);
            }).catch(err =>{
                reject(err);
            })
            
        })
    }

    @action UpdateLivrDataSize = (dataSize : number)=>{
        this.LivrdataSize = dataSize ;
    } 
    @computed get 
    getLivrTotal(){
        return this.Livrcount ;
    }
    @computed get
    HowManyLivrPosts(){
        return this.LivrdataSize ;
    }

    // Stock Admin ----------------------------------

    @observable LivrAdminStock : LivrAdStock[] = [] ;
    @observable LivradCount : number = 0 ;
    @observable LivrdataSizeAd : number = 0 ;

    @action getLivrstockDataAd = () => {
        return new Promise ((resolve,reject) =>{
            GetStockLivrDataAd().then((data : LivrStockfetchedType)=>{
                this.Livrcount = data.count ;
                this.LivrAdminStock = data.result ;
                if (data.result !== undefined) {
                    this.LivrdataSizeAd = data.result.length ;   
                }
                resolve(this.LivrAdminStock);
            }).catch(err =>{
                reject(err);
            })
            
        })
    }

    @action UpdateLivrstockDataAd = (dataSize : number)=>{
        this.LivrdataSize = dataSize ;
    } 
    @computed get 
    getLivrAdTotal(){
        return this.Livrcount ;
    }
    @computed get
    HowManyLivrAdPosts(){
        return this.LivrdataSize ;
    }
}

const InventorysStore = new InventoryStore();
export default InventorysStore ;