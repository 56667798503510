import { PDFDownloadLink , PDFViewer } from "@react-pdf/renderer";
import { Table } from "antd";
import { DataFieldsTypes, EmployeType } from "Pages/Orders/Components/OrderTypes";
import { ProductTypes } from "Pages/Products/Components/ProductTypes";
import React from "react";
import { useState , useEffect} from "react";
import { getLivrById, getOrdersById, getProductsById } from "Services/Bon/BonLivrCalls";
import { BonTypes } from "../Constants/BonTypes";
import { Ordercolumns } from "../Constants/Columns";
import {ReactComponent as DownloadIcon} from 'Assets/Svgs/direct-download.svg';
import PdfOrderExport from "./PdfOrderExport";
import PdfProdExport from "./PdfProdExport";
import PdfTickerOrder from "./PdfOrderTickets";
import PdfTickerProd from "./PdfProdTickets";
import i18n from "i18nextinit";

export interface RowModalProps {
    Data : BonTypes ,
}
interface OrderFetchedType{
    result : DataFieldsTypes[] ,
    count : number 
}
interface ProdFetchedType{
  result : ProductTypes[] ,
  count : number 
}
const RowModal: React.SFC<RowModalProps> = ({Data}) => {
    const [Employe, setEmployes] = useState<EmployeType>();
    const [Loading, setloading] = useState(true);
    const [dataSource , setDataSource] = useState<DataFieldsTypes[]>();
    const [productsource,setProductSource] = useState<ProductTypes[]>();
    const [countProducts , setPcount] = useState<number>();
    const [countOrders , setOcount] = useState<number>();


    useEffect(()=>{
      setloading(true);
      getLivrById(Data.LivrId).then((result : EmployeType) =>{
      console.log("🚀 ~ file: RowModal.tsx ~ line 40 ~ getLivrById ~ result", result)
        
        setEmployes(result);
      }).catch(err =>{
        console.log(err);
      })
      if (Data.Type === 'Livr' || Data.Type === 'Ret') {
        getOrdersById(Data).then((result : OrderFetchedType) =>{
          setDataSource(result.result);
          setOcount(result.count);
          setloading(false);
        }).catch(err =>{
          console.log(err);
        })
      }
      if (Data.Type === 'Stock') {
        getProductsById(Data).then((result : ProdFetchedType)=>{
          setProductSource(result.result);
          setPcount(result.count);
          setloading(false);
        }).catch(err =>{
          console.log(err);
        })
      }
    },[Data])

    const Productcolumns = [
      {
        title: i18n.t("Pages.Bon.ProdColumns.ProdNumb"),
        dataIndex: 'ProductId',
        key: 'ProductId'
      },
      {
        title: i18n.t("Pages.Bon.ProdColumns.Name"),
        dataIndex: 'ProductName',
        key: 'ProductName'
      },
      {
          title: i18n.t("Pages.Bon.ProdColumns.Price"),
          dataIndex: 'ProductPrice',
          key: 'ProductPrice',
          render: (ProductPrice:string) => (
          <span>{ProductPrice} Dh</span>
        ),
      },
      {
        title: i18n.t("Pages.Bon.ProdColumns.UpsellQuant"),
        dataIndex: 'UpSell_Quantity',
        key: 'UpSell_Quantity',
          render: (UpSell_Quantity:string) => (
          <span>{(UpSell_Quantity === "" || UpSell_Quantity=== undefined ) ? "--"  :UpSell_Quantity} </span>
        ),
      },
      {
          title: i18n.t("Pages.Bon.ProdColumns.UpsellPrice"),
          dataIndex: 'UpSell_Price',
          key: 'UpSell_Price',
          render: (UpSell_Price:string) => (
            <span>{(UpSell_Price === "" || UpSell_Price=== undefined ) ? "--"  :UpSell_Price + ' Dh'} </span>
          ),
      },
      {
        title: i18n.t("Pages.Bon.ProdColumns.Quantity"),
        key: 'ProductId',
        dataIndex: 'ProductId',
        render: ( ProductId : string) => (
          <span> {Data.Num_Prod.find(element => element.ProductId === ProductId)?.quantity} </span>
        )
      }
  ];
    return ( 
    <div className="BonContainer">

        <div className="LeftSide">
            {/* <span className="smallTitle">Options :</span> */}
            <div className='DataContainer'>
                <div className="ligne1">
                    <div className='label'>{i18n.t("Pages.Bon.Form.TypeSheet")} </div>
                    {Data.Type === 'Livr' &&(
                      <div className="Field">{i18n.t("Pages.Bon.Form.DelSheet")}</div>
                    )}
                    {Data.Type === 'Stock' &&(
                      <div className="Field">{i18n.t("Pages.Bon.Form.StockSheet")}</div>
                    )}
                    {Data.Type === 'Ret' &&(
                      <div className="Field">{i18n.t("Pages.Bon.Form.RetSheet")}</div>
                    )}
                </div>
                <div className="ligne2">
                    <div className='label'>{i18n.t("Pages.Bon.Form.DelMan")}</div>
                    {((Employe !== undefined && Employe !== null) && !Loading) &&(
                      <div className="Field"> 
                        {Employe?.FirstName} {Employe?.LastName} | {Employe?.Price} Dh
                        | {Employe?.City}
                      </div>
                    )}
                    {((Employe === undefined || Employe === null) && !Loading) &&(
                      <div className="Field"> 
                        {i18n.t("Pages.Bon.Form.UserDel")}
                      </div>
                    )}
                </div>
            </div>
        </div>

        <div className="RightSide">
          {(Data?.Type === "Livr" || Data.Type === 'Ret') && (
            <>
              <span className="smallTitle">{i18n.t("Pages.Bon.Form.Orders")} :</span>
              <Table

                columns={Ordercolumns}
                pagination={{ pageSize: 10, total: countOrders }}
                dataSource={dataSource}
                loading={Loading}
                rowKey={(row: DataFieldsTypes) =>
                  row.Num_Order === undefined ? Math.random() : row.Num_Order
                }
              />
            </>
          )}
          {Data?.Type === "Stock" && (
            <>
              <span className="smallTitle">{i18n.t("Pages.Bon.Form.Products")} :</span>
              <Table

                columns={Productcolumns}
                dataSource={productsource}
                pagination={{ pageSize: 10, total: countProducts }}
                loading={Loading}
                rowKey={(row: ProductTypes) =>
                  row.ProductId === undefined ? Math.random() : row.ProductId
                }
              />
            </>
          )}
        </div>

        {(!Loading && dataSource !== undefined && (Data?.Type === "Livr" || Data.Type === 'Ret')) && (
        <div style={{display : 'flex' , flexDirection : 'row' , justifyContent : 'space-between' , width : '40%'}}>
            <div>
              <PDFDownloadLink 
              style={{textDecoration : 'none' , fontFamily :'CatamaranBold'}}
              document={
                <PdfOrderExport Data={Data} Employe={Employe} dataSource={dataSource} />
              }
              fileName={`${Data.BonId}${'-Sheets'}.pdf`}
              >
                
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <div className='PdfDownload'>
                      <span>{i18n.t("Pages.Bon.Pdf.Loading")}</span>
                    </div>
                  ): (
                    <div className='PdfDownload' >
                      <DownloadIcon  style={{marginRight : '10px'}}/>
                      <span>{i18n.t("Pages.Bon.Pdf.DownloadSheet")}</span>
                    </div>
                  )
                }
              </PDFDownloadLink>
            </div>
            <div>
              <PDFDownloadLink 
              style={{textDecoration : 'none' , fontFamily :'CatamaranBold'}}
              document={
                <PdfTickerOrder Data={Data} Employe={Employe} dataSource={dataSource} />
              }
              fileName={`${Data.BonId}${'-Tickets'}.pdf`}
              >
                
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <div className='PdfDownload'>
                      <span>{i18n.t("Pages.Bon.Pdf.Loading")}</span>
                    </div>
                  ): (
                    <div className='PdfDownload' >
                      <DownloadIcon  style={{marginRight : '10px'}}/>
                      <span>{i18n.t("Pages.Bon.Pdf.DownloadTickets")}</span>
                    </div>
                  )
                }
              </PDFDownloadLink>
            </div>
          </div>
        )}
        {(!Loading && productsource !== undefined &&  Data.Type === 'Stock') && (
          <div>
            <div>
              <PDFDownloadLink 
              style={{textDecoration : 'none' , fontFamily :'CatamaranBold'}}
              document={
                <PdfProdExport dataSource={productsource} Data={Data} Employe={Employe} />
              }
              fileName={`${Data.BonId}${'-Sheets'}.pdf`}
              >
                
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <div className='PdfDownload'>
                      <span>{i18n.t("Pages.Bon.Pdf.Loading")}</span>
                    </div>
                  ): (
                    <div className='PdfDownload' >
                      <DownloadIcon  style={{marginRight : '10px'}}/>
                      <span>{i18n.t("Pages.Bon.Pdf.DownloadSheet")}</span>
                    </div>
                  )
                }
              </PDFDownloadLink>
            </div>
            <div>
              <PDFDownloadLink 
              style={{textDecoration : 'none' , fontFamily :'CatamaranBold'}}
              document={
                <PdfTickerProd dataSource={productsource} Data={Data} Employe={Employe} />
              }
              fileName={`${Data.BonId}${'-Tickets'}.pdf`}
              >
                
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <div className='PdfDownload'>
                      <span>{i18n.t("Pages.Bon.Pdf.Loading")}</span>
                    </div>
                  ): (
                    <div className='PdfDownload' >
                      <DownloadIcon  style={{marginRight : '10px'}}/>
                      <span>{i18n.t("Pages.Bon.Pdf.DownloadTickets")}</span>
                    </div>
                  )
                }
              </PDFDownloadLink>
            </div>
          </div>
        )}
    </div>
    );
}
 
export default RowModal;