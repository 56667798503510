import { EmployeType } from "Pages/Staff/Constants/EmployeTypes";
import StaffStores from "Store/StaffStore";
import ShipDataTable from "./ShippDataTable"

export interface LivrExterneProps {
    StaffStore : typeof StaffStores
    LivrExtData: EmployeType[] 
    pageSize: number
    Loading: boolean | undefined
    setSelected: React.Dispatch<React.SetStateAction<React.Key[]>>
    selectedRowKeys: React.Key[]
    setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>
    setLivrExtData: React.Dispatch<React.SetStateAction<EmployeType[]>>
    setPagesize: React.Dispatch<React.SetStateAction<number>>
    pagination: number | undefined
    setLimitOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setLimitMess: React.Dispatch<React.SetStateAction<string>>
}
 
const LivrExterne: React.SFC<LivrExterneProps> = ({
    StaffStore,LivrExtData,pageSize,Loading,setSelected,
    selectedRowKeys,setLoading,setLivrExtData,setPagesize,pagination,setLimitOpen,setLimitMess
}) => {
    return ( 
        <div className="ListContainer">
            <ShipDataTable setLimitMess={setLimitMess} setLimitOpen={setLimitOpen} StaffStore={StaffStore} selectedRowKeys={selectedRowKeys} setSelected={setSelected} 
                LivrExtData={LivrExtData} pageSize={pageSize} Loading={Loading}  setLoading={setLoading}
                setLivrExtData={setLivrExtData}setPagesize={setPagesize} pagination={pagination}
            />
        </div>
     );
}
 
export default LivrExterne;