import axios from 'axios';
import {externals} from 'webpack.config';
import { notification } from 'antd';
import i18n from 'i18nextinit';

interface datatypes 
{
    email ?: string ,
    codeverif ?: string ,
    newpwd ?: string
}
interface CheckinTypes {
    (data ?: datatypes) : Promise<boolean>
}
export const SendCodeEmail:CheckinTypes = async (data) => {
    
    try {
        const response = await axios.post(`${externals.config.apiUrl}/events/verifEmail`,data,{withCredentials : true})
        if (response.status === 200) {
            return true ;
        } else {
            return false ;
        }
    } catch (error) {
        return false ;
    }
}

export const VerifCode:CheckinTypes = async (data) => {
    
    try {
        const response = await axios.post(`${externals.config.apiUrl}/events/verifcode`,data,{withCredentials : true})
        if (response.status === 200) {
            return true ;
        } else {
            return false ;
        }
    } catch (error) {
        return false ;
    }
}

export const resetPwd:CheckinTypes = async (data) => {
    
    try {
        const response = await axios.post(`${externals.config.apiUrl}/Users/admin/resetPassword`,data,{withCredentials : true})
        if (response.status === 200) {
            notification['success']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Success.SuccessMess"),
                description: i18n.t("Services.Success.PwdChanged"),
            })
            setTimeout(() => {
                window.location.assign('/');
            }, 1500);
            return true ;
        } else {
            return false ;
        }
    } catch (error) {
        return false ;
    }
}


