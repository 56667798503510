
import Lottie from 'react-lottie';
import animationData from 'Animations/LogoLoading.json';

import 'Sass/LoadingPage.scss';
import i18n from 'i18nextinit';
const LoadingPage = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid",
        },
    };

    return ( 
        <div className='LoadingPage'>
            <title>{i18n.t("Loading.Title")} | CodScale</title>
            <Lottie options={defaultOptions} height={100} width={100} />
        </div>
     );
}
 
export default LoadingPage;