import moment from "moment";
import {ReactComponent as Circle } from 'Assets/Svgs/Sales/dry-clean.svg';
import i18n from "i18nextinit";

export const SalesColumns = [
    {
      title: i18n.t("Pages.SalesChannel.SaleModal.Source"),
      dataIndex: 'Source',
      key: 'Source',
      render: (text: string) => <span>{
        /* Uppercase the first letter */
        text.charAt(0).toUpperCase() + text.slice(1)
      }</span>,
    },
    {
      title: i18n.t("Pages.SalesChannel.SaleModal.Created"),
      dataIndex: 'Date',
      key: 'Date',
      render: (Date:Date) => (
        <span>{Date !== undefined ? moment(Date).format('MMMM Do YYYY') : "--"}</span>
      ),
    },
    {
      title: i18n.t("Pages.SalesChannel.SaleModal.Shop"),
      dataIndex: 'ShopUrl',
      key: 'ShopUrl',
      render: (ShopUrl:string) => (
        <span>{ShopUrl !== undefined ?ShopUrl : "--"}</span>
      ),
    },
    {
      title: i18n.t("Pages.SalesChannel.SaleModal.Status"),
      dataIndex: 'Status',
      key: 'Status',
      render: (Status:'Active' | 'Disabled') => (
        <div style={{display : 'flex' , alignItems : 'baseline'}}>
          <Circle width='10px' height='10px' style={Status === 'Active' ? {fill : '#2B9348' , marginRight :'5px'} : {fill : '#C52233', marginRight :'5px'}}  />
          <span style={Status === 'Active' ? {color : '#2B9348'} : {color : '#C52233'}} >{Status}</span>
        </div>
      )
    }
];




export const Values : { [key: string]: any } = {
  Phone :i18n.t("Pages.SalesChannel.SaleModal.Phone"),
  City :i18n.t("Pages.SalesChannel.SaleModal.City"),
  Address :i18n.t("Pages.SalesChannel.SaleModal.Address"),
  ProductName :i18n.t("Pages.SalesChannel.SaleModal.ProductName"),
  ProductQte :i18n.t("Pages.SalesChannel.SaleModal.ProductQte"),
  ProductPrice :i18n.t("Pages.SalesChannel.SaleModal.ProductPrice"),
  Notes :i18n.t("Pages.SalesChannel.SaleModal.Notes"),
  OrderPrice :i18n.t("Pages.SalesChannel.SaleModal.OrderPrice"),
  Variant : i18n.t("Pages.SalesChannel.SaleModal.Variant") ,
  Firstname  :i18n.t("Pages.SalesChannel.SaleModal.Firstname"),
  Lastname :i18n.t("Pages.SalesChannel.SaleModal.Lastname"),
  ProductId : i18n.t("Pages.Bon.ProdColumns.ProdNumb"),
};