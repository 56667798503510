import { TablePaginationConfig } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import InventorysStore from "Store/InventoryStore";
import { WarehouseTypes , LivrAdStock} from "../Constants/InventoryTypes";
import InventoryDataTable from "./InventoryDataTable";

export interface StockLivrProps {
    InventorysStore : typeof InventorysStore,
    loading : boolean | undefined,
    setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    dataSource: WarehouseTypes[] | LivrAdStock[],
    setDataSource: React.Dispatch<React.SetStateAction<WarehouseTypes[]| LivrAdStock[]>>,
    pageSize: number,
    setPagesize: React.Dispatch<React.SetStateAction<number>>,
    pagination: number | undefined,
    setPagination: React.Dispatch<React.SetStateAction<number | undefined>>,
    selectedRowKeys: React.Key[],
    setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
    type ?: string
    current ?:string

}
 
const StockLivr: React.SFC<StockLivrProps> = inject(
    'InventorysStore'
  )(
    observer(({InventorysStore,loading,setLoading,dataSource,setDataSource,pageSize ,setPagesize,pagination,setPagination,selectedRowKeys,
      setSelectedRowKeys,type,current}) => {
        const handleChange = (pagination: TablePaginationConfig) => {
            setPagination(pagination.current);
            setLoading(true);
            InventorysStore.getLivrstockDataSource(pagination.current).then(()=>{
                setDataSource(InventorysStore.dataSource);
                setPagesize(InventorysStore.count);
                
            }).finally(()=>{
                setLoading(false);
            }); 
          }
    return ( 
        <div className="ListContainer">
          <InventoryDataTable
            handleChange={handleChange}
            pagination={pagination}
            setPagesize={setPagesize}
            setDataSource={setDataSource}
            setLoading={setLoading}
            InventorysStore={InventorysStore}
            Loading={loading}
            dataSource={dataSource}
            pageSize={pageSize}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            type={type}
            current={current}
          />
        </div>
     );
}))
 
export default StockLivr;