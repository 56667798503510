import React, { useEffect, useState } from "react";
import AddClient from "./AddClient";
import ClientDataTable from "./ClientDataTable";
import ClientsStore from 'Store/ClientStore';
import { inject, observer } from "mobx-react";
import { ClientType } from "../Constants/ClientTypes";
import { Modal , Button, Pagination, AutoComplete } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { HandleDeleteCLients } from "../Helpers/ClientHelper";
import i18n from "i18nextinit";
import { getSearchClients, getSearchClientsByPage } from "Services/Clients/ClientServices";
import {ReactComponent as SearchIcon} from 'Assets/Svgs/search.svg';

export interface ClientProps {
    ClientsStore : typeof ClientsStore
}

const { Option } = AutoComplete;
 
const Client: React.SFC<ClientProps> = inject(
    'ClientsStore'
  )(
    observer(({ClientsStore}) => {

    const [NumSearchData,setNumSearchData] = useState<string>('');
    const [SearchedData , setSearchedData]= useState<string[]>([]);

    const [Datasource , setDataSource] = useState<ClientType[]>(ClientsStore.dataSource);
    const [Pagesize , setPagesize] = useState<number>(ClientsStore.dataSize);
    const [selectedRowKeys , setSelectedRowKeys] = useState<React.Key[]>([]);
    const { confirm } = Modal;
    const [Loading , setLoading] = useState(false);
    const [popVis , setPopVis] = useState(false);
    const [PopConfirm , setPopConfirm] = useState(false);
    const [pagination , setPagination] = useState<number>();
    function showDeleteConfirm() {
        confirm({
          title: i18n.t("Pages.Clients.Errors.Title"),
          style : {fontFamily : 'CatamaranBold'},
          icon: <ExclamationCircleOutlined />,
          content: `${i18n.t("Pages.Clients.Errors.content1")} ${selectedRowKeys.length} ${i18n.t("Pages.Clients.Errors.content2")}` ,
          okText: i18n.t("Pages.Clients.Errors.Yes"),
          okType: 'danger',
          cancelText: i18n.t("Pages.Clients.Errors.No"),
          visible : popVis ,
          okButtonProps : {loading : PopConfirm},
          onOk() {
           HandleDeleteCLients(selectedRowKeys,setPopConfirm,setPopVis,ClientsStore,setLoading,setDataSource,setPagesize,pagination);
           setSelectedRowKeys([]);
          },
          onCancel() {
          },
        });
      }

    useEffect(()=>{

        setLoading(true);
        if(NumSearchData === '')
        {
            ClientsStore.getDataSource(1).then(()=>{
                setDataSource(ClientsStore.dataSource);
                setPagesize(ClientsStore.count);
                setLoading(false);
            })
            .catch(err =>console.log(err));
        }else
        {
            handleSearch(1);
        }
    },[])


    const onSelectPhone = (value: string) => {
        setNumSearchData(value);
    }
  
    const onSearchPhone = (value: string) => {
      setNumSearchData(value);
      getSearchClients(value).then(res => {          
        setSearchedData(res.newResult);
      }).catch(err => console.log(err));
    }

    const handleSearch = (pagination:number)=>{
      setLoading(true);
      getSearchClientsByPage(NumSearchData,pagination).then(res => {
        setDataSource(res.result);
        setPagesize(res.count);
        setLoading(false);
      }).catch(err => console.log(err));
    }

    const handleClearSearch = ()=>{
      setNumSearchData('');
      setSearchedData([]);
      setLoading(true);
      ClientsStore.getDataSource(1).then(()=>{
          setDataSource(ClientsStore.dataSource);
          setPagesize(ClientsStore.count);
          setLoading(false);
      })
      .catch(err =>console.log(err));
    }

    return ( 
        <>
        <div style={{display:'flex',justifyContent:"space-between",alignItems:"baseline"}} className="ClientHeader">
            <div className="ButtonsContainer">
                <AddClient
                    setDataSource={setDataSource}
                    setPagesize={setPagesize}
                    setLoading={setLoading}
                    ClientsStore={ClientsStore}
                />
                <Button type="dashed" onClick={showDeleteConfirm} style={{ float: "right" }} danger disabled={selectedRowKeys.length === 0} >
                    {i18n.t("Pages.Clients.Errors.Delete")}
                </Button>
                
            </div>
            <div className="ButtonsContainer autoC" >
                <span className='ClearFilter' onClick={handleClearSearch}>{i18n.t('Pages.Orders.ClearTxt')}</span>
                <AutoComplete
                    style={{width: '60%',paddingTop:"5px",paddingBottom:"5px",paddingRight:"10px",paddingLeft:"10px",marginRight:"20px"}}
                    onSelect={onSelectPhone}
                    onSearch={onSearchPhone}
                    value={NumSearchData}
                    dropdownStyle={{fontFamily:"CatamaranBold"}}
                    placeholder={i18n.t('Pages.Orders.SearchPH')}
                >
                    {SearchedData?.map(element =>(
                    <Option key={element} value={element}>
                        {element}
                    </Option>
                    ))}
                </AutoComplete>
                <SearchIcon width='1.25rem' height='1.25rem' className='Search' onClick={()=>{handleSearch(1)}}/>
            </div>
        </div>
        <div className="ListContainer">
            <ClientDataTable
             selectedRowKeys={selectedRowKeys} setSelected={setSelectedRowKeys} Datasource={Datasource} 
             Pagesize={Pagesize} Loading={Loading} setDataSource={setDataSource} setPagesize={setPagesize} 
             setLoading={setLoading} ClientsStore={ClientsStore} NumSearchText={NumSearchData} handleSearch={handleSearch}
            />
        </div>
        </>
     );
}))
 
export default Client;