import { notification } from "antd";
import axios from "axios"
import i18n from "i18nextinit";
import { CLientData } from "Pages/Clients/Constants/ClientData";
import { AddCLientTypes, DeleteClType, UpdateClinetTypes } from "Pages/Clients/Constants/ClientTypes";
import config from 'webpack.config';

export const getClients =async (page : number)=>{
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Clients/GetClients?page=${page}`,{withCredentials : true})
        return response.data ;
    } catch (error) {
        console.log(error);
    }
}

export const getAllClients = async () =>{
  try {
    const response = await axios.get(`${config.externals.config.apiUrl}/Content/Clients/GetAllClients`,{withCredentials : true})
    return response.data.result ;
  } catch (error) {
    console.log(error)
  }
}

export const getSearchClients = async (phone:string) => {
  try {
      const response = await axios.get(`${config.externals.config.apiUrl}/Content/Clients/getClientByPhone?phone=${phone}`,{withCredentials : true})
      return response.data ;
  } catch (error) {
      return [];
  }
}

export const getSearchClientsByPage = async (phone:string,page:number) => {
  try {
      const response = await axios.get(`${config.externals.config.apiUrl}/Content/Clients/getClientByPhoneByPage?phone=${phone}&page=${page}`,{withCredentials : true})
      return response.data ;
  } catch (error) {
      return [];
  }
}


export const AddCLient:AddCLientTypes = (
    Data,setconfirmLoading,setLoading,ClientsStore,setDataSource,
    setPagesize,setOpen,pagination,setPhoneErr,setData
)=>{
    axios.post(`${config.externals.config.apiUrl}/Content/Clients/CreateClient`,Data,{withCredentials:true})
    .then((result) => {
        if (result.status === 200) {
          notification["success"]({
            style: { fontFamily: "CatamaranBold" },
            message: i18n.t("Services.Success.SuccessMess"),
            description: i18n.t("Services.Success.ClientSuccess"),
          });
          setconfirmLoading(false);
          setOpen(false);
          setLoading(true);
          ClientsStore.getDataSource(1).then(()=>{
            setDataSource(ClientsStore.dataSource);
            setPagesize(ClientsStore.dataSize);
            setLoading(false);
          }).catch(err =>console.log(err)); 
        }
        if (result.status === 500) {
          notification["error"]({
            style: { fontFamily: "CatamaranBold" },
            message: i18n.t("Services.Errors.ErrorOccur"),
            description:
              i18n.t("Services.Errors.ErrorDesc"),
          });
          setconfirmLoading(false);
          setOpen(false);
          setLoading(true);
          ClientsStore.getDataSource(1).then(()=>{
            setDataSource(ClientsStore.dataSource);
            setPagesize(ClientsStore.dataSize);
            setLoading(false);
          }).catch(err =>console.log(err));
        }
        if (result.status === 409) {
          setconfirmLoading(false);
          setPhoneErr(true);
        }
        setData(CLientData);
      })
      .catch(function (error) {
        if (error.response.status === 409) {
          setconfirmLoading(false);
          setPhoneErr(true);
        }else{
          notification["error"]({
            style: { fontFamily: "CatamaranBold" },
            message: i18n.t("Services.Errors.ErrorOccur"),
            description:
              i18n.t("Services.Errors.ErrorDesc"),
          });
          setconfirmLoading(false);
          setOpen(false);
          setLoading(true);
          ClientsStore.getDataSource(1).then(()=>{
            setDataSource(ClientsStore.dataSource);
            setPagesize(ClientsStore.dataSize);
            setLoading(false);
          }).catch(err =>console.log(err));
        }
      });
      
}

export const UpdateClient:UpdateClinetTypes = (
    Data,setconfirmLoading,setLoading,ClientsStore,setDataSource,
    setPagesize,setOpen,pagination
)=>{
    axios.post(`${config.externals.config.apiUrl}/Content/Clients/UpdateClient?ClientId=${Data.Client_Id}`,Data,{withCredentials:true})
    .then((result) => {
        if (result.status === 200) {
          notification["success"]({
            style: { fontFamily: "CatamaranBold" },
            message: i18n.t("Services.Success.SuccessMess"),
            description:
            i18n.t("Services.Success.ClientSuccess"),
          });
          setconfirmLoading(false);
          setOpen(false);
          setLoading(true);
          ClientsStore.getDataSource(1).then(()=>{
            setDataSource(ClientsStore.dataSource);
            setPagesize(ClientsStore.dataSize);
            setLoading(false);
          }).catch(err =>console.log(err)); 
        }
        if (result.status === 500) {
          notification["error"]({
            style: { fontFamily: "CatamaranBold" },
            message: i18n.t("Services.Errors.ErrorOccur"),
            description:
              i18n.t("Services.Errors.ErrorDesc"),
          });
          setconfirmLoading(false);
          setOpen(false);
          setLoading(true);
          ClientsStore.getDataSource(1).then(()=>{
            setDataSource(ClientsStore.dataSource);
            setPagesize(ClientsStore.dataSize);
            setLoading(false);
          }).catch(err =>console.log(err));
        }
        
      })
      .catch(function (error) {

          notification["error"]({
            style: { fontFamily: "CatamaranBold" },
            message: i18n.t("Services.Errors.ErrorOccur"),
            description:
              i18n.t("Services.Errors.ErrorDesc"),
          });
          setconfirmLoading(false);
          setOpen(false);
          setLoading(true);
          ClientsStore.getDataSource(1).then(()=>{
            setDataSource(ClientsStore.dataSource);
            setPagesize(ClientsStore.dataSize);
            setLoading(false);
          }).catch(err =>console.log(err));

      });
}

export const DeleteClients:DeleteClType = (
    Url , setconfirmLoading , setOpen,ClientsStore, setLoading, setDataSource, setPagesize, pagination
)=>{
    axios.delete(`${config.externals.config.apiUrl}/Content/Clients/deleteCLients?${Url}`,{withCredentials:true})
    .then((result) => {
        if (result.status === 200) {
          notification["success"]({
            style: { fontFamily: "CatamaranBold" },
            message: i18n.t("Services.Success.SuccessMess"),
            description:
            i18n.t("Services.Success.ClientDelSuccess"),
          });
          setconfirmLoading(false);
          setOpen(false);
          setLoading(true);
          ClientsStore.getDataSource(1).then(()=>{
            setDataSource(ClientsStore.dataSource);
            setPagesize(ClientsStore.dataSize);
            setLoading(false);
          }).catch(err =>console.log(err)); 
        }
        if (result.status === 500) {
          notification["error"]({
            style: { fontFamily: "CatamaranBold" },
            message: i18n.t("Services.Errors.ErrorOccur"),
            description:
              i18n.t("Services.Errors.ErrorDesc"),
          });
          setconfirmLoading(false);
          setOpen(false);
          setLoading(true);
          ClientsStore.getDataSource(1).then(()=>{
            setDataSource(ClientsStore.dataSource);
            setPagesize(ClientsStore.dataSize);
            setLoading(false);
          }).catch(err =>console.log(err));
        }
        
      })
      .catch(function (error) {

          notification["error"]({
            style: { fontFamily: "CatamaranBold" },
            message: i18n.t("Services.Errors.ErrorOccur"),
            description:
              i18n.t("Services.Errors.ErrorDesc"),
          });
          setconfirmLoading(false);
          setOpen(false);
          setLoading(true);
          ClientsStore.getDataSource(1).then(()=>{
            setDataSource(ClientsStore.dataSource);
            setPagesize(ClientsStore.dataSize);
            setLoading(false);
          }).catch(err =>console.log(err));

      });
}