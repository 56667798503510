import { Input, message } from 'antd';
import i18n from 'i18nextinit';
import React from 'react';
import { SalesTypes } from '../Constants/SalesTypes';
import {ReactComponent as CopyIcon } from 'Assets/Svgs/Sales/copy.svg';
import YoutubeImage from 'Assets/res/youtube.png';
// TODO: Add i18n to this component

type Props = {
    
    Data: SalesTypes | undefined
    setData: React.Dispatch<React.SetStateAction<SalesTypes | undefined>>
    isSheetIdValid: boolean,
    setIsSheetIdValid: React.Dispatch<React.SetStateAction<boolean>>
    isSheetNameValid: boolean,
    setIsSheetNameValid: React.Dispatch<React.SetStateAction<boolean>>
};



export default function GoogleSheetsComponents({
  Data,
  setData,
  isSheetIdValid,
  isSheetNameValid,
  setIsSheetIdValid,
  setIsSheetNameValid,
}: Props) {
  const webhookLink = "googlesheetsserviceaccount@cod-scale.iam.gserviceaccount.com";
  const config = {
    content : i18n.t("Pages.SalesChannel.GoogleComponent.clipboardSuccess"),
    duration : 2,
    style : {
      fontFamily : "CatamaranRegular",
    }
  }
  return (
    <div className="GoogleSheetsContainer">
      <div className='infoCont'>
        <span className="tipTitle">
          {i18n.t("Pages.SalesChannel.Create.SpreadsheetInfo")}
        </span>
        <div className="ShopUrl">
          <span className="label">
            {i18n.t("Pages.SalesChannel.Create.SpreadId")}
          </span>
          <Input
            name="SheetId"
            placeholder="Enter the spreadsheet ID"
            value={Data?.SheetId}
            onChange={(e) => {
              setIsSheetIdValid(true);
              setData({ ...Data, SheetId: e.target.value });
            }}
          />
          {!isSheetIdValid ? (
            <div className="inv_msg">
              {i18n.t("Pages.SalesChannel.Create.InvSpread")}
            </div>
          ) : null}
        </div>

        <div className="ShopUrl">
          <span className="label">
            {i18n.t("Pages.SalesChannel.Create.SpreadName")}
          </span>
          <Input
            name="SheetName"
            placeholder="Enter the spreadsheet name"
            value={Data?.SheetName}
            onChange={(e) => {
              setIsSheetNameValid(true);
              setData({ ...Data, SheetName: e.target.value });
            }}
          />
          {!isSheetNameValid ? (
            <div className="inv_msg">
              {i18n.t("Pages.SalesChannel.Create.InvSpreadName")}
            </div>
          ) : null}
        </div>
      </div>
      <div className="DemoContainer">
        <div className="VideoContainer">
          <span className="tipTitle">
            {i18n.t("Pages.SalesChannel.GoogleComponent.videoTitle")}
          </span><br/>
          <div className='YoutubeLink'>
                <img src={YoutubeImage} alt="youtube image" width={30} height={30} />
                <span><a href="https://www.youtube.com/watch?v=b0IZo2Aho9Y" target='_blank'>Click on the link</a></span>
          </div>
        </div>
        <div className='WebhookLinkContainer'> 
            <span className="label">
            {i18n.t("Pages.SalesChannel.GoogleComponent.emailTitle")}
            </span>
            <div className='WebhookLink'>
              <span className='text'>{webhookLink}</span>
              <span className='Copy' onClick={() => {
                  navigator.clipboard.writeText(webhookLink);
                  message.success(config);
                }}>
                <CopyIcon />
              </span>
            </div>
        </div>
      </div>
    </div>
  );
}
