import axios from 'axios';
import { notification } from 'antd';
import { HandleConfirmTypes } from 'Pages/Orders/Components/OrderTypes';
import config from 'webpack.config';
import i18n from 'i18nextinit';

export const getCallOrders = async (page : number | undefined) => {
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Orders/getMyOrder?page=${page}`,{withCredentials : true})
        return response.data ;
    } catch (error) {
        return [];
    }
}

export const getOrdersByCallId = async (page : number | undefined) => {
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Orders/getOrdersByCallId?page=${page}`,{withCredentials : true})
        return response.data ;
    } catch (error) {
        return [];
    }
}

export const CountUnsetOrders = async ()=>{
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Orders/CountUnsetOrders`,{withCredentials : true})
        return response.data.result ;
    } catch (err) {
        return [];
        
    }
}

export const getRandomUnset = async () =>{
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Orders/getRandomUnset`,{withCredentials : true})
        return response.data.userdata ;
    } catch (err) {
        return [];   
    }
}

export const ConfirmCallOrder:HandleConfirmTypes = (Data , setconfirmLoading ,setLoading , orderStore ,setDataSource , setPageSize , setOpen,pagination) => {
    axios.post(`${config.externals.config.apiUrl}/Content/Orders/updateOrder?OrderId=${Data?.Num_Order}`,Data , {withCredentials : true})
    .then(result => {
        if (result.status === 200) {
            notification['success']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Success.SuccessMess"),
                description: i18n.t("Services.Success.OrderUpdate") ,
            })
                setconfirmLoading(false);

                if (Data.Stamp === 'Maga') {
                    setOpen(false);
                    setLoading(true);
                    orderStore.getDataSource(1,'Packaging').then(()=>{
                        setDataSource(orderStore.dataSource);
                        setPageSize(orderStore.count);
                        setLoading(false);
                    });
                }
                if (Data.Stamp === 'Livr' || Data.Stamp === 'LivrExt') {
                    setOpen(false);
                    setLoading(true);
                    orderStore.getDataSource(1).then(()=>{
                        setDataSource(orderStore.dataSource);
                        setPageSize(orderStore.count);
                        setLoading(false);
                    });
                }
                if (Data.Stamp === 'Call') {
                    setconfirmLoading(false);
                    setOpen(false);
                    setLoading(true);
                    orderStore.getCallData(1).then(()=>{
                        setDataSource(orderStore.CallData);
                        setPageSize(orderStore.Callcount);
                        setLoading(false);
                    });
                }

        } else {
            setconfirmLoading(false);
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            
        }
    })
    .catch(err => {
        setconfirmLoading(false);
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description: i18n.t("Services.Errors.ErrorDesc"),
        })
       
    })
}

export const CreateCallOrder:HandleConfirmTypes = (Data , setconfirmLoading ,setLoading , orderStore ,setDataSource , setPageSize , setOpen,pagination) => {
    axios.post(`${config.externals.config.apiUrl}/Content/Orders/createOrder`,Data , {withCredentials : true})
    .then(result => {
        if (result.status === 200) {
            notification['success']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Success.SuccessMess"),
                description: i18n.t("Services.Success.OrderCreated"),
            })
            setconfirmLoading(false);
                setOpen(false);
                setLoading(true);
                orderStore.getCallData(1).then(()=>{
                    setDataSource(orderStore.CallData);
                    setPageSize(orderStore.Callcount);
                    setLoading(false);
                });
        } else {
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            setconfirmLoading(false);
            setOpen(false);
            setLoading(true);
            orderStore.getCallData(1).then(()=>{
                setDataSource(orderStore.CallData);
                setPageSize(orderStore.Callcount);
                setLoading(false);
            });
        }
    })
    .catch(err => {
        setconfirmLoading(false);
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description: i18n.t("Services.Errors.ErrorDesc"),
        })
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    })
}