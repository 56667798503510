import {observable  , action , computed} from 'mobx'; 
import { FactureDataTypes } from 'Pages/Factures/Constants/FacturesTypes';
import { getFacturesByType } from 'Services/Factures/FactureCalls';


interface datafetchedType {
    result : FactureDataTypes[],
    count : number
}
class FacturesStore {

    // Admin data 
    @observable dataSource : FactureDataTypes[] = [];
    @observable count : number = 0 ;
    @observable dataSize : number = 0 ;
    @observable loading : boolean = true ;

    @action UpdateDataSize = (dataSize : number)=>{
        this.dataSize = dataSize ;
    } 
    @computed get 
    getTotal(){
        return this.count ;
    }
    @computed get 
    LoadingState(){
        return this.loading ;
    }
    @computed get
    HowManyPosts(){
        return this.dataSize ;
    }

    @action getDataSource = (pageg : number | undefined) => {
        return new Promise ((resolve,reject) =>{
            this.loading = true ;
            getFacturesByType(pageg).then((data : datafetchedType) => {
                this.count = data.count ;
                this.dataSource = data.result ;
                if (data.result !== undefined) {
                    this.dataSize = data.result.length ;
                }

                
                this.loading = false;
                resolve(this.dataSource);
            })
            .catch(err =>{
                reject(err);
            })
        })
    }
    
}

const FactureStore = new FacturesStore();
export default FactureStore ;