import { useState } from "react";
import { ReactComponent as AddIcon } from "Assets/Svgs/addIcon.svg";
import {observer , inject } from 'mobx-react';
import { EmployeType } from "Pages/Staff/Constants/EmployeTypes";
import StaffStores from "Store/StaffStore";
import Modal from "antd/lib/modal/Modal";
import { Button } from "antd";
import EmployeModal from "Pages/Staff/Components/EmloyeModal";
import { AddStaff } from "Pages/Staff/Helpers/EmployesHandlers";
import i18n from "i18nextinit";

export interface AddToShippingProps {
    StaffStore : typeof StaffStores
    setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>
    setLivrExtData: React.Dispatch<React.SetStateAction<EmployeType[]>>
    setPagesize: React.Dispatch<React.SetStateAction<number>>
    pagination: number | undefined
    setLimitOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLimitMess: React.Dispatch<React.SetStateAction<string>>
}
 
const AddToShipping: React.SFC<AddToShippingProps> = inject(
    'StaffStore'
  )(
    observer(({StaffStore,setLoading,setPagesize,pagination,setLivrExtData,setLimitMess,setLimitOpen}) =>  {

    const [Data , setData] = useState<EmployeType>();
    const [open, setOpen] = useState(false);
    const [confirmLoading, setconfirmLoading] = useState(false);
    const [EmailErr , setEmailErr] = useState<boolean>(false);
    const disabledButton : boolean = 
    (
      Data?.Email === undefined || 
      Data?.FirstName === undefined || Data?.LastName === undefined ||
      Data?.Password === undefined || Data?.Tel === undefined || Data?.Price === undefined
      
    )
    const disabledButton2 : boolean = 
    (
      Data?.Email === '' || 
      Data?.FirstName === '' || Data?.LastName === '' ||
      Data?.Password === '' || Data?.Tel === '' || Data?.Price === ''
    )
    const HandleAdd = ()=>{
        if (Data?.Type==='LivrExt') {
            AddStaff(Data,setconfirmLoading,setLoading,StaffStore,setLivrExtData,setPagesize,setOpen,pagination,setEmailErr,setData,setLimitOpen,setLimitMess);    
        }
    }
    return ( 
        <>
            <div className="CreateOrder" style={{ float: "right" }}>
                <button onClick={() =>{setOpen(!open);setData({...Data , Type : 'LivrExt'});}} >
                <AddIcon />
                <span>{i18n.t("Pages.Shipping.Create.Add")}</span>
                </button>
            </div>
            <Modal
            title={i18n.t("Pages.Shipping.Create.Add")}
            visible={open}
            destroyOnClose
            onCancel={() => {
              setOpen(!open);
              setData(undefined);
            }}
            footer={[
              <Button key="back" type="default" onClick={() => {
                setOpen(!open);
                setData(undefined);
                }}>
                {i18n.t("Pages.Clients.Create.cancel")}
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={confirmLoading}
                disabled={disabledButton || disabledButton2}
                onClick={()=>HandleAdd()}
              >
                {i18n.t("Pages.Clients.Create.Add")}
              </Button>,
            ]}
            >
                <EmployeModal type='LivrExt' EmailErr={EmailErr} Data={Data} setData={setData} setEmailErr={setEmailErr} />
            </Modal>
        </>
     );
}))
 
export default AddToShipping;