import axios from 'axios';
import { notification } from 'antd';
import config from 'webpack.config';
import { HandleCreateSale, HandleCreateSheetorder, HandleDeleteSales } from 'Pages/SalesChannel/Constants/SalesTypes';
import i18n from 'i18nextinit';

// requires admin authentification ----

export const getAllSales = async (pageg : number | undefined) =>{
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Sales/getSales?page=${pageg}`,{withCredentials : true})
        return response.data ;
    } catch (error) {
        
    }
}

export const CreateOrders:HandleCreateSheetorder = (
    Data , setconfirmLoading ,setLoading ,
    SaleStore ,setDataSource , setPageSize ,
    setOpen,pagination,setLimitOpen,setLimitMess) => {
        
    axios.post(`${config.externals.config.apiUrl}/Content/Orders/sheetsOrders`,Data , {withCredentials : true})
    .then(result => {
        notification['success']({
            style : {fontFamily : 'CatamaranBold'},
            message:  i18n.t("Services.Success.SuccessMess"),
            description: `${result.data.counter} ${i18n.t("Services.Success.OrderCre")}`,
        })
        setconfirmLoading(false);
        setOpen(false);
        setLoading(true);
        SaleStore.getDataSource(pagination).then(()=>{
            setDataSource(SaleStore.dataSource);
            setPageSize(SaleStore.count);
            setLoading(false);
        })
    })
    .catch(err => {
        setconfirmLoading(false);
        setLoading(false);
        console.log(err.response);
        if (err.response.data.error === 'SalesChanCount' || err.response.data.error === 'Payment') {
            setLimitMess(err.response.data.error);
            setLoading(false);
            setLimitOpen(true);
        }else
        {
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
        
    })
}


export const CreateSale:HandleCreateSale = (
        Data , setconfirmLoading ,setLoading ,
        SaleStore ,setDataSource , setPageSize ,
        setOpen,pagination ,setShopStatus,setShopMsg,setLimitOpen,setLimitMess,Source) => {
                
    axios.post(`${config.externals.config.apiUrl}/Content/Sales/${Source}`,Data , {withCredentials : true})
    .then(result => {
        if (result.status === 200) {
            notification['success']({
                style : {fontFamily : 'CatamaranBold'},
                message:  i18n.t("Services.Success.SuccessMess"),
                description: i18n.t("Services.Success.SaleCreated"),
            })
            setconfirmLoading(false);
            setOpen(false);
            setLoading(true);
            SaleStore.getDataSource(pagination).then(()=>{
                setDataSource(SaleStore.dataSource);
                setPageSize(SaleStore.count);
                setLoading(false);
            })
        } else {
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            setconfirmLoading(false);
            setOpen(false);
            setLoading(true);
            SaleStore.getDataSource(pagination).then(()=>{
                setDataSource(SaleStore.dataSource);
                setPageSize(SaleStore.count);
                setLoading(true);
            })
        }
    })
    .catch(err => {
        setconfirmLoading(false);
        
        if (err.response.data === 'Internal error') {

            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            setTimeout(() => {
                window.location.reload();
            }, 1000);
            
        }else
        {
            if (err.response.data.error === 'SalesChanCount' || err.response.data.error === 'Payment') {
                setLimitMess(err.response.data.error);
                setLoading(false);
                setLimitOpen(true);
            }
            if (err.response.data.Message === 'Shop already exist') {
                setShopStatus(false);
                setShopMsg('Exist');
            }
            if (err.response.data.Message === 'Invalid shop url') {
                setShopStatus(false);
                setShopMsg('Ivalid');
            }
        }
        
    })
}


export const UpdateSale:HandleCreateSale = (
    Data , setconfirmLoading ,setLoading ,
    SaleStore ,setDataSource , setPageSize ,
    setOpen,pagination ,setShopStatus,setShopMsg,setLimitOpen,setLimitMess,Source) => {
        
axios.post(`${config.externals.config.apiUrl}/Content/Sales/${Source}`,Data , {withCredentials : true})
.then(result => {
    if (result.status === 200) {
        notification['success']({
            style : {fontFamily : 'CatamaranBold'},
            message:  i18n.t("Services.Success.SuccessMess"),
            description:  i18n.t("Services.Success.SaleCreated"),
        })
        setconfirmLoading(false);
        setOpen(false);
        setLoading(true);
        SaleStore.getDataSource(pagination).then(()=>{
            setDataSource(SaleStore.dataSource);
            setPageSize(SaleStore.count);
            setLoading(false);
        })
    } else {
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description: i18n.t("Services.Errors.ErrorDesc"),
        })
        setconfirmLoading(false);
        setOpen(false);
        setLoading(true);
        SaleStore.getDataSource(pagination).then(()=>{
            setDataSource(SaleStore.dataSource);
            setPageSize(SaleStore.count);
            setLoading(true);
        })
    }
})
.catch(err => {
    setconfirmLoading(false);
    
    if (err.response.data === 'Internal error') {

        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description: i18n.t("Services.Errors.ErrorDesc"),
        })
        setTimeout(() => {
            window.location.reload();
        }, 1000);
        
    }else
    {
        if (err.response.data.error === 'SalesChanCount' || err.response.data.error === 'Payment') {
            setLimitMess(err.response.data.error);
            setLoading(false);
            setLimitOpen(true);
        }
        if (err.response.data.Message === 'Shop already exist') {
            setShopStatus(false);
            setShopMsg('Exist');
        }
        if (err.response.data.Message === 'Invalid shop url') {
            setShopStatus(false);
            setShopMsg('Ivalid');
        }
    }
    
})
}

export const DeleteSales : HandleDeleteSales= (Url,setconfirmLoading,setLoading,SaleStore,setDataSource,setPageSize,setOpen,pagination)=>{
    axios.delete(`${config.externals.config.apiUrl}/Content/Sales/DeleteSales?${Url}` ,{withCredentials : true} )
    .then(result => {
        if (result.status === 200) {
            notification['success']({
                style : {fontFamily : 'CatamaranBold'},
                message:  i18n.t("Services.Success.SuccessMess"),
                description:  i18n.t("Services.Success.SaleDeleted"),
            })
            setconfirmLoading(false);
            setOpen(false);
            setLoading(true);
            SaleStore.getDataSource(pagination).then(()=>{
                setDataSource(SaleStore.dataSource);
                setPageSize(SaleStore.count);
                setLoading(false);
            })
        } else {
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            setconfirmLoading(false);
            setOpen(false);
            setLoading(true);
            SaleStore.getDataSource(pagination).then(()=>{
                setDataSource(SaleStore.dataSource);
                setPageSize(SaleStore.count);
                setLoading(true);
            })
        }
    })
    .catch(err => {
        setconfirmLoading(false);
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description: i18n.t("Services.Errors.ErrorDesc"),
        })
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    })
}

export const checkSheet = async(sheetId : string) =>{
    return await axios.get(`${config.externals.config.apiUrl}/Content/Sales/CheckSheet?SheetId=${sheetId}` ,{withCredentials : true} )
    .then(result => {
        console.log("🚀 ~ file: SalesChannelServices.ts ~ line 245 ~ checkSheet ~ result", result)
        
        if (result.status === 200) {
            return result;
        } else {
            return false;
        }
    })
    .catch(err => {
        return false;
    })
}