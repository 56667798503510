import StatusComponent from 'Components/Routes/StatusComponent';
import i18n from 'i18nextinit';
import moment from 'moment';
import { FacturesTypes } from 'Pages/Factures/Validators/AvaiFacType';
import { ProductTypes } from 'Pages/Products/Components/ProductTypes';
import {newProductTypes , DataFieldsTypes } from './OrderTypes';

import 'moment/locale/fr';
import 'moment/locale/ar';
import 'moment/locale/en-au';


moment.locale(i18n.resolvedLanguage);



export const StatusAvailable = [{
  status : 'Pending',
  value :i18n.t("Status.Pending")
},{
  status : 'Packaging',
  value :i18n.t("Status.Packaging")
},
{
  status : 'Shipped',
  value :i18n.t("Status.Shipped")
},
{
  status : 'Delivry',
  value :i18n.t("Status.Delivry")
},{
  status : 'Confirmed',
  value :i18n.t("Status.Confirmed")
},{
  status : 'Declined',
  value :i18n.t("Status.Declined")
}];


export const newProduct :newProductTypes = {
    id : '',
    upsell : false,
    cost_per_unit : 0,
    name : '',
    variant : '',
    quantity : 1,
    price : 0
}
export const FieldProduct : ProductTypes = {
    ProductName : '' ,
    UpSell : false,
    UpSell_Quantity : '' ,
    UpSell_Price : 0 ,
    ProductPrice : 0 ,
    ProductId : ''
}
export const UpsellProduct : ProductTypes = {
    ProductName : '' ,
    UpSell : true,
    UpSell_Quantity : '' ,
    UpSell_Price : 0 ,
    ProductPrice : 0 ,
    ProductId : ''
}
export const newUpsellProduct :newProductTypes = {
    id : '',
    upsell : true,
    cost_per_unit : 0,
    name : '',
    variant : '',
    quantity : 0,
    price : 0
}
export const DataFields : DataFieldsTypes  = {
    Num_Order : '',
    Status : '',
    Products : [] ,
    Source : '',
    Notes : '',
    City : '',
    Address : '',
    Livr_Id : '',
    Client_Id : '',
    OrderPrice : 0
}

export const Columns = [
    {
      title: i18n.t("Pages.Bon.Pdf.OrderId"),
      dataIndex: 'Num_Order',
      key: 'Num_Order'
    },
    {
      title: i18n.t("Pages.Bon.Pdf.Products"),
      dataIndex: 'Products',
      key: 'Products',
      render : (Product : newProductTypes[] ) => (
        <span>{Product.length}</span>
      )
    },
    {
      title: i18n.t("Pages.Bon.Pdf.City"),
      dataIndex: 'City',
      key: 'City',
    },
    {
      title: i18n.t("Pages.Bon.Pdf.Date"),
      dataIndex: 'Date',
      key: 'Date',
      render: (Date:Date) => (
        <span>{Date !== undefined ? moment(Date).format('MMMM Do YYYY') : "--"}</span>
      ),
    },
    {
      title: i18n.t("Pages.Bon.Pdf.Status"),
      key: 'Status',
      dataIndex: 'Status',
      render: (status:string) => (
        <StatusComponent  Type={status}/>
      ),
    },
    {
      title: i18n.t("Pages.Bon.Pdf.Address"),
      key: 'Address',
      dataIndex: 'Address'
    },
];

export const ListOrderscolumns = [
  {
    title:  i18n.t("Pages.Bon.Pdf.OrderId"),
    dataIndex: 'Num_Order',
    key: 'Num_Order'
  },
  {
    title: i18n.t("Pages.Bon.Pdf.Products"),
    dataIndex: 'Products',
    key: 'Products',
    render : (Product : newProductTypes[] ) => (
      <span>{Product.length}</span>
    )
  },
  {
    title: i18n.t("Pages.Bon.Pdf.City"),
    dataIndex: 'City',
    key: 'City',
  },
  {
    title: i18n.t("Pages.Bon.Pdf.Date"),
    dataIndex: 'Date',
    key: 'Date',
    render: (Date:Date) => (
      <span>{Date !== undefined ? moment(Date).format('MMMM Do YYYY') : "--"}</span>
    ),
  },
  {
    title: i18n.t("Pages.Bon.Pdf.Status"),
    key: 'Status',
    dataIndex: 'Status',
    render: (status:string) => (
      <StatusComponent  Type={status}/>
    ),
  },
  {
    title:i18n.t("Pages.Bon.Pdf.Address"),
    key: 'Address',
    dataIndex: 'Address'
  },
];

export const FacturesColumns = [
  {
    title: i18n.t("Pages.Invoices.Pdf.InvoiceId"),
    dataIndex: 'FactureId',
    key: 'FactureId'
  },
  {
    title: i18n.t("Pages.Orders.Title"),
    dataIndex: 'Num_Order',
    key: 'Num_Order',
    render : (Order : string[] ) => (
      <span>{Order.length}</span>
    )
  },
  {
    title: i18n.t("Pages.Bon.Pdf.Type"),
    dataIndex: 'type',
    key: 'type',
    render : (type : string ) => (
      <span>{type === FacturesTypes.Facture ? 'Facture' : 'Demande de facture'}</span>
    )
  },
  {
    title: i18n.t("Pages.Bon.Pdf.Price"),
    dataIndex: 'Price',
    key: 'Price',
    render : (Price : string ) => (
      <span>{Price + ' Dh'}</span>
    )
  },
  {
    title: i18n.t("Pages.Bon.Pdf.Status"),
    key: 'Status',
    dataIndex: 'Status',
    render: (status:string) => (
      <StatusComponent  Type={status}/>
    ),
  }
];