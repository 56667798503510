import { ProductTypes } from "../Components/ProductTypes";

export const ProductFields : ProductTypes =  {
    ProductId : '',
    ProductName : '' ,
    UpSell : false,
    UpSell_Quantity : '' ,
    UpSell_Price : 0 ,
    ProductPrice : 0 ,
}

export const EditProductFields : ProductTypes = {
    ProductName : '' ,
    UpSell : false,
    UpSell_Quantity : '' ,
    UpSell_Price : 0 ,
    ProductPrice : 0 ,
    ProductId : ''
}

export const ProductSource = ["Shopify" , "Woocommerce","YouCan" , "Local"]