import i18n from 'i18nextinit';
import { Provider } from 'mobx-react';
import ClientsStore from 'Store/ClientStore';
import Client from './Components/Client';
export interface MainClientsProps {
     
}
 
const MainClients: React.SFC<MainClientsProps> = () => {


    return (
        <>
            <title>{i18n.t("Pages.Clients.TabTitle")} | CodScale</title>
                <div className='MainContainer'>
                    <Provider ClientsStore={ClientsStore}>
                        <Client ClientsStore={ClientsStore} />
                    </Provider>
                </div> 
        </>
    );
}
 
export default MainClients;