// Modules -------------------------------
import { NavLink } from "react-router-dom";
import {useState , useEffect } from 'react';
import {CSSTransition} from 'react-transition-group';
import Lottie from 'react-lottie';
import {FormikValues, useFormik} from 'formik';
import i18n  from "i18nextinit";

//----------------------------------------

// Imports  -------------------------------------

import animationData from 'Animations/Loading2.json';
import SalesAnimation from 'Animations/SalesAnimation.json';
import {validate , validate2} from '../Middlewares/ValidateSignup';
import Screen1 from "./Screen1";
import Screen2 from "./Screen2";
import firebase from 'Utils/firebase-config';
import { handleSubmit , HandleLastSubmit } from "../helpers/Handlers";
import Stripe from "Pages/Payments/Stripe";

require('firebase/auth')
declare global {
    interface Window {
        recaptchaVerifier:any;
        confirmationResult : any
    }
}

const SignUp = ()=>{

    const [Screen , setScreen] =  useState(0) ;
    const [Loading , setLoading] =  useState(false);
    const [data,setData]= useState({values : {}});
    const [switchScreen , setSwitch] = useState(false);
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid",
        },
    };

    const verifOptions = {
        loop: false,
        autoplay: true,
        animationData: SalesAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid",
        },
    };

    useEffect(()=>{
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': () => {
            }
          });
    },[])

    const formik = useFormik({
        initialValues : {
            email: "",
            password: "",
            confirmpass : "",
            storename: "",
            firstname: "",
            lastname: "",
            birthdate: "",
            birthdateview : "",
            tel: "",
            payment_method:"",
            priceId:"",
            captchaState : true
        },validate,
        onSubmit: values => {handleSubmit(values,setLoading,formik,setScreen,setSwitch,setData)}
    });
    
    const formik2 :FormikValues = useFormik({
        initialValues: {
          codeverif : ''
        },validate(){
          return validate2(formik2.values);
        },
        onSubmit: values => {HandleLastSubmit(values , setLoading , formik2 ,setScreen , data )}
    });
    
    return(
        <div className='Signup Container'>
            <title>{i18n.t('Auth.TabTitle_SignUp')} | CodScale</title>
            <div className='SignForm' >
                    <CSSTransition
                    in={Screen === 0}
                    unmountOnExit
                    timeout={500}
                    classNames="menu-primary"
                    >
                        <form className="menu" onSubmit={formik.handleSubmit} >
                            <span className='Title'>{i18n.t('Auth.Title_SignUp')}</span>
                            <p className='Description'>{i18n.t('Auth.Description_SignUp')}</p>
                            <Screen1 formik={formik} />
                            <div>
                                <div
                                    id="sign-in-button"
                                ></div>
                            </div>
                            <div className="SigninButton">
                            {Loading &&(
                                <button className="Button loading" type="button" disabled>
                                <Lottie options={defaultOptions} height={35} width={110} />
                                </button>
                            )}
                            {!Loading &&(
                                <button type='submit' className="Button next"  >{i18n.t('Auth.Actions.SignUp.Next')}</button>
                            )}
                                
                            </div>
                            <span className="smalltext">{i18n.t('Auth.Actions.SignUp.AlreadyAccount')}<NavLink to='/signin'>{i18n.t('Auth.Actions.Signin')}</NavLink> </span><br/>
                        </form>

                        

                    </CSSTransition>

                    <CSSTransition
                    in={Screen === 1}
                    unmountOnExit
                    timeout={500}
                    classNames={!switchScreen ? "menu-third" : "menu-primary"}
                    >
                        <Screen2 setScreen={setScreen} setSwitch={setSwitch} formik={formik2} Loading={Loading} />

                    </CSSTransition>

                    <CSSTransition
                    in={Screen === 2}
                    unmountOnExit
                    timeout={500}
                    classNames={!switchScreen ? "menu-third" : "menu-primary"}
                    >
                        <Stripe email={formik.values.email}  data={data} setLoading={setLoading} setScreen={setScreen} Loading={Loading} />

                    </CSSTransition>

                    <CSSTransition
                    in={Screen === 3}
                    unmountOnExit
                    timeout={500}
                    classNames="menu-third"
                    >
                       <div className="menu">
                            <h1 className='Title' style={{textAlign : 'center'}}>{i18n.t('Auth.Actions.SignUp.EmailSentScreen.EmTitle')}</h1>   
                            <Lottie options={verifOptions} height={300} width={300} />
                            
                            <p className='Description' style={{textAlign : 'center'}}>
                            {i18n.t('Auth.Actions.SignUp.EmailSentScreen.EmDesc1')}<span style={{color : 'rgb(0, 122, 194)'}}>{formik.values.email}</span> <br />
                            {i18n.t('Auth.Actions.SignUp.EmailSentScreen.EmDesc2')}
                            </p>
                       </div>
                    </CSSTransition>
            </div>
        </div>
    )
}
export default SignUp ;