export const Cities : string[] = ["Casablanca"
,"Fes"
,"Tangier"
,"Marrakesh"
,"Salé"
,"Meknes"
,"Rabat"
,"Oujda"
,"Kenitra"
,"Agadir"
,"Souss Massa"
,"Tetouan"
,"Temara"
,"Safi"
,"Mohammedia"
,"Khouribga"
,"El Jadida"
,"Beni Mellal"
,"Aït Melloul"
,"Nador"
,"Dar Bouazza"
,"Taza"
,"Settat"
,"Berrechid"
,"Khemisset"
,"Inezgane"
,"Ksar El Kebir"
,"Larache"
,"Guelmim"
,"Khenifra"
,"Berkane"
,"Taourirt"
,"Bouskoura"
,"Fquih"
,"Ben Salah"
,"Dcheira"
,"El Jihadia"
,"Oued Zem"
,"Marrakesh-Safi"
,"Sidi Slimane"
,"Errachidia"
,"Guercif"
,"Oulad Teima"
,"Ben Guerir"
,"Tifelt"
,"Lqliaa"
,"Taroudant"
,"Sefrou"
,"Essaouira"
,"Fnideq"
,"Sidi Kacem"
,"Tiznit"
,"Tan-Tan"
,"Ouarzazate"
,"SoukElArbaa"
,"Youssoufia"
,"Lahraouyine"
,"Martil"
,"AinHarrouda"
,"Suqas-SabtAwladanNama"
,"Skhirat"
,"Ouazzane"
,"Benslimane"
,"AlHoceima"
,"BeniAnsar"
,"Mdiq"
,"SidiBennour"
,"Midelt"
,"Azrou"
,"Drargua"]