import i18n from 'i18nextinit';
import { MainOrdersProps } from 'Pages/Orders/MainOrders';
import ListBon from './Components/ListBon';


 
const MainBon: React.SFC<MainOrdersProps> = ({type} ) => {



    return (
        <>
            <title>{i18n.t("Pages.Bon.TabTitle")} | CodScale</title>
            <div className='MainContainer'>
                <ListBon type={type} />
            </div>
        </>
    );
}
export default MainBon;