import { Button, Modal, Menu, TablePaginationConfig } from "antd"
import {observer , inject } from 'mobx-react';
import React, { useEffect, useState } from "react";
import StaffStores from "Store/StaffStore";
import { EmployeType } from "../Constants/EmployeTypes";
import AddToStaff from "./AddToStaff";
import Employe from "./Employe";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DeleteEmployes, handleChange } from "../Helpers/EmployesHandlers";
import { StaffTableColumns ,StaffLivrColumns } from "../Constants/Columns";
import i18n from "i18nextinit";

interface ListStaffProps {
    StaffStore : typeof StaffStores,
    setLimitOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLimitMess: React.Dispatch<React.SetStateAction<string>>
}
 
const ListStaff: React.SFC<ListStaffProps> =  inject(
    'StaffStore'
  )(
    observer(({StaffStore,setLimitMess,setLimitOpen}) =>  {

    const [current , setCurrent] = useState<string>('Call');
    const [loading , setLoading ] = useState<boolean | undefined>();
    const [selectedRowKeys , setSelected] = useState<React.Key[]>([]);
    const [pagination , setPagination] = useState<number|undefined>(1);
    // Call center data source ------

    const [CallData ,setCallData] = useState<EmployeType[]>(StaffStore.CallCenter);
    const [pageSize , setPagesize] = useState<number>(StaffStore.Callcount);
    const [popVis , setPopVis] = useState(false);
    const [PopConfirm , setPopConfirm] = useState(false);

    // ------------------------
    // Magasinier Data
    const [MagaData ,setMagaData] = useState<EmployeType[]>(StaffStore.MagaCenter);
    const [MagaSize , setMagaSize] = useState<number>(StaffStore.MagadataSize);

    // ------------------------
    // Magasinier Data
    const [LivrData ,setLivrData] = useState<EmployeType[]>(StaffStore.LivrCenter);
    const [LivrSize , setLivrSize] = useState<number>(StaffStore.LivrdataSize);
    const { confirm } = Modal;

    function showDeleteConfirm() {
        confirm({
            title: i18n.t("Pages.Shipping.Errors.Title"),
            style : {fontFamily : 'CatamaranBold'},
            icon: <ExclamationCircleOutlined />,
            content: `${i18n.t("Pages.Shipping.Errors.content1")} ${selectedRowKeys.length} ${i18n.t("Pages.Shipping.Errors.content2")}` ,
            okText: i18n.t("Pages.Shipping.Errors.ok"),
            okType: 'danger',
            cancelText: i18n.t("Pages.Shipping.Errors.cancel"),
            visible : popVis ,
            okButtonProps : {loading : PopConfirm},
          onOk() {
            if (current === 'Call') {
                DeleteEmployes(
                selectedRowKeys , setPopConfirm , setPopVis,StaffStore, 
                setLoading, setCallData, setPagesize, pagination,current
            );
            }
            if (current === 'Maga') {
               DeleteEmployes(
                selectedRowKeys , setPopConfirm , setPopVis,StaffStore, 
                setLoading, setMagaData, setMagaSize, pagination,current
            );
            }
            if (current === 'Livr') {
               DeleteEmployes(
                selectedRowKeys , setPopConfirm , setPopVis,StaffStore, 
                setLoading, setLivrData, setLivrSize, pagination,current
                );
            }
            
            setSelected([]);
          },
          onCancel() {
          },
        });
      }
    useEffect(()=>{
        setSelected([]);
        if (StaffStore.HowManyCall === 0 && current=== 'Call') {
            setLoading(true)
            StaffStore.getStaffData(1,'Call').then(()=>{
                
                setCallData(StaffStore.CallCenter)
                console.log("🚀 ~ file: ListStaff.tsx ~ line 90 ~ StaffStore.getStaffData ~ StaffStore.CallCenter", StaffStore.CallCenter)
                setPagesize(StaffStore.Callcount);
            }).finally(()=>{
                setLoading(false);
            });  
        }else
        {
            if (StaffStore.HowManyMaga === 0 && current=== 'Maga') {

                setLoading(true)
                StaffStore.getStaffData(1,'Maga').then(()=>{
                    setMagaData(StaffStore.MagaCenter)
                    setMagaSize(StaffStore.Magacount);
                }).finally(()=>{
                    setLoading(false);
                });  
            }else
            {
                if (StaffStore.HowManyLivr === 0 && current=== 'Livr') {

                    setLoading(true)
                    StaffStore.getStaffData(1,'Livr').then(()=>{
                        setLivrData(StaffStore.LivrCenter)
                        setLivrSize(StaffStore.Livrcount);
                    }).finally(()=>{
                        setLoading(false);
                    });  
                }else
                {

                    setLoading(false)
                }
            }
        }
        
        
    },[current]);

    const handleInstantChange = (pagination:TablePaginationConfig , type : string) =>{
        handleChange(
            pagination,type,setSelected,setPagination,setLoading,setCallData
            ,setPagesize,StaffStore,setMagaData,setMagaSize,setLivrData,setLivrSize
        )
    }

    return ( 
        <>
        <div className="MenuContainer">
                <Menu 
                    selectedKeys={[current]}
                    mode="horizontal"
                    style={{width:"30%"}} 
                    onClick={(e) => {
                        setCurrent(e.key.toString());
                    }}
                >
                    <Menu.Item key="Call">{i18n.t("Pages.Staff.Modal.Call")}</Menu.Item>
                    <Menu.Item key="Maga">{i18n.t("Pages.Staff.Modal.StoreKepper")}</Menu.Item>
                    <Menu.Item key="Livr">{i18n.t("Pages.Staff.Modal.Deliv")}</Menu.Item>
                </Menu>
                <div className='ButtonsContainer'>
                    <Button style={{marginRight : '15px'}} type="dashed" onClick={showDeleteConfirm}  danger disabled={selectedRowKeys.length === 0} >
                        {i18n.t("Pages.Inventory.Errors.Delete")}
                    </Button>
                    <AddToStaff 
                        StaffStore={StaffStore} 
                        setLoading={setLoading} 
                        setCallData={setCallData}
                        setPagesize={setPagesize}
                        setMagaData={setMagaData}
                        setMagaSize={setMagaSize}
                        setLivrData={setLivrData}
                        setLivrSize={setLivrSize}
                        pagination={pagination}
                        setLimitMess={setLimitMess}
                        setLimitOpen={setLimitOpen}
                    />
                    
                </div>

            </div>
            {current === "Call" &&(
                <Employe 
                        setLimitMess={setLimitMess} setLimitOpen={setLimitOpen}
                        StaffTableColumns={StaffTableColumns} selectedRowKeys={selectedRowKeys} 
                        setSelected={setSelected} handleChange={handleInstantChange} CallData={CallData} 
                        StaffStore={StaffStore} pageSize={pageSize} Loading={loading}  type='Call' setLoading={setLoading} 
                        setCallData={setCallData}setPagesize={setPagesize}setMagaData={setMagaData}setMagaSize={setMagaSize}
                        setLivrData={setLivrData} setLivrSize={setLivrSize} pagination={pagination}
                />
            )}

            {current === "Maga" &&(

                <Employe setLimitMess={setLimitMess} setLimitOpen={setLimitOpen} StaffStore={StaffStore} StaffTableColumns={StaffTableColumns} selectedRowKeys={selectedRowKeys} setSelected={setSelected} 
                handleChange={handleInstantChange} CallData={MagaData} pageSize={MagaSize} Loading={loading} type='Maga'
                setLoading={setLoading} setCallData={setCallData}setPagesize={setPagesize}setMagaData={setMagaData}
                setMagaSize={setMagaSize} setLivrData={setLivrData} setLivrSize={setLivrSize} pagination={pagination} />

            )}
            {current === "Livr" &&(
                <Employe setLimitMess={setLimitMess} setLimitOpen={setLimitOpen} StaffStore={StaffStore} StaffTableColumns={StaffLivrColumns} selectedRowKeys={selectedRowKeys} 
                setSelected={setSelected} handleChange={handleInstantChange} CallData={LivrData} pageSize={LivrSize} 
                Loading={loading} type='Livr' setLoading={setLoading} setCallData={setCallData} setPagesize={setPagesize}
                setMagaData={setMagaData} setMagaSize={setMagaSize} setLivrData={setLivrData} setLivrSize={setLivrSize} pagination={pagination} />

            )}
        </>
     );
}))
 
export default ListStaff;