import { Modal } from "antd";
import Lottie from 'react-lottie';
import animationData from 'Animations/LimitReached.json';
import PayAnimation from 'Animations/PaymentFailed.json';

import {ReactComponent as PremiumIcon} from 'Assets/Svgs/PremiumIcon.svg';
import i18n from "i18nextinit";

interface ILimitReachedModalProps {
    setLimitOpen: React.Dispatch<React.SetStateAction<boolean>>,
    LimitOpen : boolean,
    LimitMess: string,
    setLimitMess: React.Dispatch<React.SetStateAction<string>>
}

const LimitReachedModal: React.FunctionComponent<ILimitReachedModalProps> = ({setLimitOpen,LimitOpen,LimitMess,setLimitMess}) => {

  const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: (LimitMess === 'Orders' || LimitMess === 'ExternalDel' || LimitMess === 'StaffCount' || LimitMess === 'SalesChanCount') ? animationData : PayAnimation ,
      rendererSettings: {
          preserveAspectRatio: "xMidYMid",
      },
  };

  return (
      <>
        <Modal visible={LimitOpen} title={null} footer={null} onCancel={()=>setLimitOpen(!LimitOpen)} >
            <div className='LimitContainer'>
                <div className='IllusLimit'>
                    <Lottie options={defaultOptions} width='200px'/>
                </div>
                {(LimitMess === 'Orders' || LimitMess === 'ExternalDel' || LimitMess === 'StaffCount' || LimitMess === 'SalesChanCount') && (
                    <>
                        <h2 className='TitleLimit'>{i18n.t("Limit.Title")}</h2>
                        <p className='DescriptionLimit'>{i18n.t("Limit.Heading")}</p>
                        <div className="CreateOrder">
                            <button onClick={()=>{
                                setLimitOpen(!LimitOpen)
                                window.location.replace('/myprofile?tab=Pla')
                            }}>
                                <i className='Icon'><PremiumIcon/></i>
                                <span>{i18n.t("Limit.Upgrade")}</span>
                            </button>
                        </div>
                    </>
                )}
                {LimitMess === 'Payment' && (
                    <>
                        <h2 className='TitleLimit'>{i18n.t("Limit.Title2")}</h2>
                        <p className='DescriptionLimit'>{i18n.t("Limit.Heading2")}</p>
                        
                    </>
                )}
                
            </div>
        </Modal>
      </>
  );
};

export default LimitReachedModal;
