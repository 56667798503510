import { Button, Modal, Menu } from "antd"
import {observer , inject } from 'mobx-react';
import React, { useEffect, useState } from "react";
import CreateInventory from "./CreateInventory";
import StockLivr from "./StockLivr";
import WareHouse from "./WareHouse";
import InventorysStore from "Store/InventoryStore";
import { LivrAdStock, LivrStock, WarehouseTypes } from "../Constants/InventoryTypes";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DeleteFromWarehouseById } from "../Helpers/WarehouseHelpers";
import i18n from "i18nextinit";

export interface ListInventoryProps {
    InventorysStore : typeof InventorysStore,
    type ?: string
}
 
const ListInventory: React.SFC<ListInventoryProps> =  inject(
    'InventorysStore'
  )(
    observer(({InventorysStore , type}) =>  {
    const [current , setCurrent] = useState<string>(type === 'Livr'?'LivrStock':'Warehouse');
    const [loading , setLoading ] = useState<boolean | undefined>(true);
    // warehouse 

    const [dataSource ,setDataSource] = useState<WarehouseTypes[]>(InventorysStore.dataSource);
    const [pageSize , setPagesize] = useState<number>(InventorysStore.count);

    // stock livreur 
    const [LivrstockData , setLivrstockData] = useState<LivrStock[] | LivrAdStock[]>([]);
    const [LivrSize , setLivrSize] = useState<number>(0);

    const [pagination , setPagination] = useState<number|undefined>(1);
    const [selectedRowKeys , setSelectedRowKeys] = useState<React.Key[]>([]);
    const [popVis , setPopVis] = useState(false);
    const [PopConfirm , setPopConfirm] = useState(false);
    const { confirm } = Modal;
        
    function showDeleteConfirm() {
        confirm({
          title: i18n.t("Pages.Inventory.Errors.Title"),
          style : {fontFamily : 'CatamaranBold'},
          icon: <ExclamationCircleOutlined />,
          content: `${i18n.t("Pages.Inventory.Errors.content1")} ${selectedRowKeys.length} ${i18n.t("Pages.Inventory.Errors.content2")}` ,
          okText: i18n.t("Pages.Inventory.Errors.Yes"),
          okType: 'danger',
          cancelText: i18n.t("Pages.Inventory.Errors.No"),
          visible : popVis ,
          okButtonProps : {loading : PopConfirm},
          onOk() {
              if (current === 'Warehouse') {
                DeleteFromWarehouseById(
                    selectedRowKeys,
                    setPopConfirm,
                    setLoading ,
                    InventorysStore,
                    setDataSource,
                    setPagesize,
                    setPopVis,
                    pagination,
                    current
                ) 
              }
              if (current === 'LivrStock') {
                DeleteFromWarehouseById(
                    selectedRowKeys,
                    setPopConfirm,
                    setLoading ,
                    InventorysStore,
                    setLivrstockData,
                    setLivrSize,
                    setPopVis,
                    pagination,
                    current,
                ) 
              } 
            
            setSelectedRowKeys([]);
          },
          onCancel() {
            
          },
        });
    }

    useEffect(()=>{
        setSelectedRowKeys([]);
        if (current === 'Warehouse') {
            setLoading(true);
            InventorysStore.getWarehouseDataSource(pagination).then(()=>{
                setDataSource(InventorysStore.dataSource);
                setPagesize(InventorysStore.count);
            }).finally(()=>{
                setLoading(false);
            });  
        }else{
            if (current === 'LivrStock') {
                setLoading(true);
                if (type === "Admin") {
                    InventorysStore.getLivrstockDataAd().then(()=>{
                       setLivrstockData(InventorysStore.LivrAdminStock);
                       console.log("🚀 ~ file: ListInventory.tsx ~ line 102 ~ InventorysStore.getLivrstockDataAd ~ InventorysStore.LivrAdminStock", InventorysStore.LivrAdminStock)
                       
                       setLivrSize(InventorysStore.LivradCount);
                    }).finally(()=>{
                       setLoading(false);
                    });
                }else
                {
                    InventorysStore.getLivrstockDataSource(pagination).then(()=>{
                        setLivrstockData(InventorysStore.LivrstockData);
                        setLivrSize(InventorysStore.Livrcount);
                    }).finally(()=>{
                        setLoading(false);
                    }); 
                }
                 
            }else
            {
                setLoading(false);
            }
        }
        
    },[current])

    return ( 
        <>
            <div className="MenuContainer">
                {type !== "Livr" &&(
                    <Menu 
                    selectedKeys={[current]}
                    mode="horizontal"
                    style={{ width: '30%' }}
                    onClick={(e) => {
                        setCurrent(e.key.toString());
                    }}
                    >
                        <Menu.Item key="Warehouse">{i18n.t("Pages.Inventory.Modal.Warehouse")}</Menu.Item>
                        <Menu.Item key="LivrStock">{i18n.t("Pages.Inventory.Modal.LivrStock")}</Menu.Item>
                    </Menu>
                )}
                

                {(type === 'Admin' || 'Livr' || 'LivrExt')&& (
                    <div className='ButtonsContainer'>
                        <Button style={{marginRight : '15px'}} type="dashed"  danger onClick={showDeleteConfirm} disabled={!(selectedRowKeys.length > 0)}>
                            {i18n.t("Pages.Inventory.Errors.Delete")}
                        </Button>
                        <CreateInventory
                            InventorysStore={InventorysStore}
                            setLoading={setLoading}
                            dataSource={dataSource}
                            setDataSource={setDataSource}
                            pageSize={pageSize}
                            setPagesize={setPagesize}
                            pagination={pagination}
                            LivrstockData={LivrstockData}
                            setLivrstockData={setLivrstockData}
                            LivrSize={LivrSize}
                            setLivrSize={setLivrSize}
                            usertype={type}
                        />
                    </div>
                )}

            </div>
            {(current === "Warehouse" && type !== "Livr") &&(
                <WareHouse
                    InventorysStore={InventorysStore}
                    loading={loading}
                    setLoading={setLoading}
                    dataSource={dataSource}
                    setDataSource={setDataSource}
                    pageSize={pageSize}
                    setPagesize={setPagesize}
                    pagination={pagination}
                    setPagination={setPagination}
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                    type={type}
                    current={current}
                />
            )}

            {(current === "LivrStock" || type === "Livr") &&(
                <StockLivr 
                    InventorysStore={InventorysStore}
                    loading={loading}
                    setLoading={setLoading}
                    dataSource={LivrstockData}
                    setDataSource={setLivrstockData}
                    pageSize={LivrSize}
                    setPagesize={setLivrSize}
                    pagination={pagination}
                    setPagination={setPagination}
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                    type={type}
                    current={current}
                /> 
            )}
        </>
     );
}))
 
 
export default ListInventory;