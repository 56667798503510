// Modules -------------------------------

import { NavLink } from "react-router-dom"
import Lottie from 'react-lottie';
import {useState  } from 'react';
import {useFormik} from 'formik';
import i18n  from "i18nextinit";
  //----------------------------------------

// Imports  -------------------------------------
import {validate} from '../Middlewares/ValidateSignin' ;
import animationData from 'Animations/Loading2.json';
import { HandleSignin } from "../helpers/Handlers";
import { Alert } from "antd";

const SignIn = ()=>{

    const changeLanguage = (lng: string) => {
      i18n.changeLanguage(lng);
    };
    
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
          preserveAspectRatio: "xMidYMid",
      },
    };
    const [Loading , setLoading] =  useState(false);
    const [emailError,setEmail] = useState(false);
    const formik = useFormik({
        initialValues : {
            storename : '',
            email : '',
            password : ''
        },validate ,
        onSubmit: values => {
          HandleSignin(values,setLoading,formik,setEmail);
        },
    })
    return (
      <div className="Signin Container">
        <title>{i18n.t('Auth.TabTitle')} | CodScale</title>
        <span className="Title">{i18n.t('Auth.Title')}</span>
        <p className="Description">
          {i18n.t('Auth.Description')}
        </p>

        {emailError && (
          <Alert
            style={{fontFamily : 'CatamaranBold',marginTop : '10px' , marginBottom : '10px',borderRadius : '10px'}}
            message="Unverified account"
            description="Your email address is not verified . Please check your inbox"
            type="error"
            showIcon
            onClose={()=>{
              setEmail(false);
            }}
            closable
          />
        )}
        
        <form className="SignForm" onSubmit={formik.handleSubmit}>
          <div className="TwoFields">
            <div className="Field1">
              <label className="label">{i18n.t('Auth.Form.Email')}</label>
              <input
                type="email"
                name="email"
                className={
                    formik.touched.email && formik.errors.email
                      ? "Field normal inv"
                      : "Field normal"
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="inv_msg">{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="Field2">
              <label className="label">{i18n.t('Auth.Form.Store')}</label>
              <input
                type="text"
                name="storename"
                className={
                    formik.touched.storename && formik.errors.storename
                      ? "Field normal inv"
                      : "Field normal"
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.storename}
              />
              {formik.touched.storename && formik.errors.storename ? (
                <div className="inv_msg">{formik.errors.storename}</div>
              ) : null}
            </div>
          </div>

          <div className="Password">
            <div style={{display : 'flex' , justifyContent : 'space-between',alignItems : 'center',marginBottom : '5px'}}>
              <label className="label">{i18n.t('Auth.Form.Password')}</label>
              <span style={{color : '#355070' , display :'block',cursor : 'pointer',fontFamily :'CatamaranBold',margin : '14px 0 4px',fontSize : '15px'}}>
                <NavLink to="/resetpassword">{i18n.t('Auth.Form.ForgotPwd')}</NavLink>{" "}
              </span>
            </div>
            <input
              type="password"
              name="password"
              className={
                formik.touched.password && formik.errors.password
                  ? "Field normal inv"
                  : "Field normal"
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="inv_msg">{formik.errors.password}</div>
            ) : null}
          </div>
          
          <div className="SigninButton">
            {Loading &&(
                  <button className="Button loadsignin" type="submit" disabled>
                  <Lottie options={defaultOptions} height={35} width={110} />
                  </button>
            )}
            {!Loading &&(
                <button type='submit' className="Button signin"  >{i18n.t('Auth.Actions.Signin')}</button>
            )}
          </div>
          <span className="smalltext">
          {i18n.t('Auth.Actions.NoAccount')}{" "}
            <NavLink to="/signup">{i18n.t('Auth.Actions.regisNow')}</NavLink>{" "}
          </span>
        </form>
      </div>
    );
}
export default SignIn ;