import { AdminType } from "Components/Constants/UserTypes";
import { useEffect ,useState } from "react";
import { getAdminData, getEmpData } from "Services/MyProfile/AccountServices";
import Lang from "./InnerComponents/Lang";
import MyAccount from "./InnerComponents/MyAccount";
import Plan from "./InnerComponents/Plan";

interface ProfileResultProps {
    active : 'Acc'|'Pla'|'Lang'|string,
    Type : string 
    
}
 
const ProfileResult: React.SFC<ProfileResultProps> = ({active,Type}) => {

    const [Data , setData] = useState<AdminType>();
    const [Loading , setLoading] = useState<boolean>(true);

    const [PlanLoading,SetPlanLoading] = useState(true);
    useEffect(()=>{
        if (Type === 'Admin') {
            getAdminData().then(result =>{
                setLoading(false);
                setData(result)
            }).catch(err => console.log(err));
        } else {
            getEmpData().then(result =>{
                console.log("🚀 ~ file: ProfileResult.tsx ~ line 28 ~ getEmpData ~ result", result)
                setLoading(false);
                setData(result)
            }).catch(err => console.log(err));
        }
        
    },[Loading])
    return ( 
        <div className='ProfileResult'>
            {active === 'Acc' &&(
                <MyAccount Data={Data} Loading={Loading} setLoading={setLoading} Type={Type}/>
            )}
            {(active === 'Pla' && Type === 'Admin') &&(
                <Plan  setLoading={SetPlanLoading} Loading={PlanLoading}/>
            )}
            {active === 'Lang' &&(
                <Lang Data={Data} Loading={Loading}/>
            )}
        </div>
     );
}
 
export default ProfileResult;