import React, {useState} from 'react';
import axios from 'axios';
import {externals} from 'webpack.config';
import { notification, Select  } from 'antd';
import Lottie from 'react-lottie';
import animationData from 'Animations/Loading2.json';
// MUI Components


// stripe
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
// Util imports
import {makeStyles} from '@material-ui/core/styles';
// Custom Components
import CardInput from './CardInput';
import { SelectValue } from 'antd/lib/select';
import { Plans } from '../Constants/CONSTS';
import i18n from 'i18nextinit';


interface CheckoutFormProps {
    email : string,
    data: {
        values: {};
    },
    setScreen: React.Dispatch<React.SetStateAction<number>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    Loading: boolean
}
const { Option } = Select;



const CheckoutForm: React.SFC<CheckoutFormProps> = ({email,data,setScreen,setLoading,Loading}) => {
  // State

  const stripe = useStripe();
  const elements = useElements();
  const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid",
        },
  };

  const [Plan , setPlan] = useState<SelectValue | string>();
  const [Errors , setError] = useState({Plan : false , Card : false});
  const [CardErrMsg , setCardErr] = useState<string | undefined>('');


  const handleSubmit = async () => {
    setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (cardElement) {

        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
              email: email,
            },
        });
      
        if (result.error) {
            setError({...Errors , Card : true});
            setCardErr(result.error.message);
            setLoading(false);
        } else {
            const BodyData = {...data.values,payment_method :  result.paymentMethod.id ,priceId :  Plan} ;
            console.log(BodyData);
            
            const res = await axios.post(`${externals.config.apiUrl}/Auth/stripe`, BodyData);
            // eslint-disable-next-line camelcase
            const {client_secret, status} = res.data;
      
            if (status === 'requires_action') {
              stripe.confirmCardPayment(client_secret).then(function(result) {
                if (result.error) {
                  console.log('There was an issue!');
                  console.log(result.error);
                  setCardErr(i18n.t("Pages.MyProfile.MyAccount.PLans.Perview.Notification.CardErr"));

                  setLoading(false);
                  // Display error message in your UI.
                  // The card was declined (i.e. insufficient funds, card has expired, etc)
                } else {
                  setLoading(false);
                  setScreen(3);
                  console.log('You got the money!');
                  // Show a success message to your customer
                }
              });
            } else {
              setLoading(false);
              setScreen(3);
              console.log('You got the money!');
              // No additional information was needed
              // Show a success message to your customer
            }
        }
    }
  };

  const handleSubmitWithoutCard = async ()=>{
    setLoading(true);
    const BodyData = {...data.values,priceId :  Plan} ;    
    await axios.post(`${externals.config.apiUrl}/Auth/stripe`, BodyData).then(result =>{
        if (result.status === 200) {
          setLoading(false);
          setScreen(3);
          
        }else
        {
          console.log('There was an issue!');
          setLoading(false);
          notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Pages.MyProfile.MyAccount.PLans.Perview.Notification.ErrorMess"),
            description:
            i18n.t("Pages.MyProfile.MyAccount.PLans.Perview.Notification.ErrorDesc"),
        });
        }
    }).catch(err =>{
      console.log(err);
      setLoading(false);
      notification['error']({
        style : {fontFamily : 'CatamaranBold'},
        message: i18n.t("Pages.MyProfile.MyAccount.PLans.Perview.Notification.ErrorMess"),
        description:
        i18n.t("Pages.MyProfile.MyAccount.PLans.Perview.Notification.ErrorDesc"),
      });
    })
    
  }

    return (
      <div className="menu">
        <span className="Title">{i18n.t("Pages.Payments.Checkoutform.Title")}</span><br/>
        <p className="Description" style={{ marginTop: "15px" }}>
          🚨 {i18n.t("Pages.Payments.Checkoutform.Desc1")} <br/><br />
          {"✍ "} {i18n.t("Pages.Payments.Checkoutform.Desc2")} {Plans.find(el=> el.PriceId === Plan)?.TotalDue} $<br/><br />
          {"✍ "} {i18n.t("Pages.Payments.Checkoutform.Desc3")} {Plans.find(el=> el.PriceId === Plan)?.Plan}
        </p>
        <br />

        <div className="ContentContainer">
          <div className="Plan">
            <span className="SecondTitle">{i18n.t("Pages.Payments.Checkoutform.Plan")}</span>
            <Select
              placeholder="Select your plan"
              style={{ width: "100%", marginTop: "10px" }}
              size="large"
              dropdownStyle={{ fontFamily: "CatamaranBold" }}
              onChange={(value, option) => {
                setPlan(value);
              }}
            >
              {Plans?.map((item) => (
                <Option
                  style={{ padding: "10px 18px", fontSize: "17px" }}
                  key={item.PriceId}
                  value={item.PriceId}
                >
                  {item.Plan + ' - $' + item.TotalDue + 'USD / month'}
                </Option>
              ))}
            </Select>
          </div>
          {Plan !== 'free_price' && (
            <div className="CardContainer">
              <label className="label">{i18n.t("Pages.Payments.Checkoutform.CreditCard")}</label>
              <div
                className={Errors.Card ? "Field normal inv" : "Field normal"}
                style={{ marginTop: "10px" }}
              >
                <CardInput Errors={Errors} setError={setError} />
              </div>
              {Errors.Card ? <div className="inv_msg" style={{fontFamily:'CatamaranBold'}}>{CardErrMsg}</div> : null}
            </div>
          )}
          
        </div>

        <div className="SigninButton">
          {(Object.values(Errors).includes(true) || Plan === "") && (
            <button
              className="Button loading"
              type="button"
              disabled
              style={{ width: "110px", height: "35px" }}
            >
              {i18n.t("Pages.Payments.Checkoutform.Next")}
            </button>
          )}
          {Loading && (
            <button className="Button loading" type="button" disabled>
              <Lottie options={defaultOptions} height={35} width={110} />
            </button>
          )}
          {!Loading && !Object.values(Errors).includes(true) && Plan !== "" && (
            <button
              type="submit"
              className="Button next"
              style={{ float: "right" }}
              onClick={Plan === 'free_price' ? handleSubmitWithoutCard  : handleSubmit}
            >
              {i18n.t("Pages.Payments.Checkoutform.Done")}
            </button>
          )}
        </div>
      </div>
    );
}
 
export default CheckoutForm;