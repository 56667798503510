import { AddToLivrstock, DeleteFromLivrstock, UpdateProductInLivrStock } from "Services/Inventory/LivrStockCalls";
import {
  AddToWarehouse,
  DeleteFromWarehouse,
  UpdateProductInWarehouse,
} from "Services/Inventory/WarehouseCalls";
import {
  AddToWarehouseTypes,
  DeleteFromWarehouseTypes,
  UpdateWarehouseTypes,
} from "../Constants/InventoryTypes";

export const AddToWarehouseHelper: AddToWarehouseTypes = (
  Data,
  setconfirmLoading,
  setLoading,
  InventorysStore,
  setDataSource,
  setPageSize,
  setOpen,
  pagination,
  setTypes , 
  setChecker,Type
) => {
  setconfirmLoading(true);
  if (Type === "LivrStock") {
    AddToLivrstock(
      Data,
      setconfirmLoading,
      setLoading,
      InventorysStore,
      setDataSource,
      setPageSize,
      setOpen,
      pagination,
      setTypes , 
      setChecker
    )
  }
  if (Type === "Warehouse") {
    AddToWarehouse(
      Data,
      setconfirmLoading,
      setLoading,
      InventorysStore,
      setDataSource,
      setPageSize,
      setOpen,
      pagination,
      setTypes , 
      setChecker
    );
  }
  
  
};

export const DeleteFromWarehouseById: DeleteFromWarehouseTypes = async (Ids,setconfirmLoading,setLoading,InventorysStore,setDataSource,setPageSize,setOpen,pagination,Type) => {
  try {
    let Url = "";
    for await (let id of Ids) {
      Url += `ProductId[]=${id}&`;
    }
    setconfirmLoading(true);
    if (Type === "LivrStock") {
      DeleteFromLivrstock(
        Url,
        setconfirmLoading,
        setLoading,
        InventorysStore,
        setDataSource,
        setPageSize,
        setOpen,
        pagination
      )
    }
    if (Type === "Warehouse") {
      DeleteFromWarehouse(
        Url,
        setconfirmLoading,
        setLoading,
        InventorysStore,
        setDataSource,
        setPageSize,
        setOpen,
        pagination
      );
    }
    
  } catch (error) {
    return [];
  }
};

export const UpdateWareHouse : UpdateWarehouseTypes = (Data,setconfirmLoading,setLoading,InventorysStore,setDataSource,setPageSize,setOpen,pagination,Type)=>{
  setconfirmLoading(true);

  if (Type === "LivrStock") {
    UpdateProductInLivrStock(
      Data,
      setconfirmLoading,
      setLoading,
      InventorysStore,
      setDataSource,
      setPageSize,
      setOpen,
      pagination
    )
  }

  if (Type === "Warehouse") {
    UpdateProductInWarehouse(
      Data,
      setconfirmLoading,
      setLoading,
      InventorysStore,
      setDataSource,
      setPageSize,
      setOpen,
      pagination
    )
  }
}