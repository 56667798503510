import { AdminType } from "Components/Constants/UserTypes";
import { Moment } from "moment";
import { useState } from "react";
import ChangePWD from "./EditComponents/ChangePWD";
import DelAccount from "./EditComponents/DelAccount";
import EditDate from "./EditComponents/EditDate";
import EditName from "./EditComponents/EditName";
import EditStore from "./EditComponents/EditStoreName";
import EditTel from "./EditComponents/EditTel";

interface EditModalProps {
    active : 'Del' | 'Name' | 'Tel' | 'Date' | 'pwd' | 'Store' |undefined
    EditedData: AdminType
    setEditedData: React.Dispatch<React.SetStateAction<AdminType>>
    IsPasswd: boolean
    setIsPasswd: React.Dispatch<React.SetStateAction<boolean>>
    IsDate: boolean
    setIsDate: React.Dispatch<React.SetStateAction<boolean>>
    IsNewPassword: boolean
    setIsNewPassword: React.Dispatch<React.SetStateAction<boolean>>
    IsTel : boolean
    setIsTel : React.Dispatch<React.SetStateAction<boolean>>
    IsStore: boolean
    setIsStore: React.Dispatch<React.SetStateAction<boolean>>
}
 
const EditModal: React.SFC<EditModalProps> = ({
    active,EditedData,setEditedData,IsPasswd,setIsPasswd,
    IsDate,setIsDate,IsNewPassword,setIsNewPassword,IsTel,setIsTel,IsStore,setIsStore}) => {

    // Validation 

    const [IsName , setIsName] = useState(true); 

    // Validation 

    
    // Data 
    
    const [Date , setDate] = useState<Moment | null>();

    

    return ( 
        <div className='EditContainer'>
            {active === 'Name' && (
                <EditName 
                EditedData={EditedData}
                setEditedData={setEditedData}
                IsPasswd={IsPasswd}
                setIsPasswd={setIsPasswd}
                IsName={IsName}
                />
            )}
            {active === 'Tel' && (
                <EditTel
                EditedData={EditedData}
                setEditedData={setEditedData}
                IsPasswd={IsPasswd}
                setIsPasswd={setIsPasswd}
                IsTel={IsTel}
                setIsTel={setIsTel}
                />
            )}
            {active === 'Date' && (
                <EditDate 
                    EditedData={EditedData} setEditedData={setEditedData}
                    IsPasswd={IsPasswd} setIsPasswd={setIsPasswd}
                    IsDate={IsDate} setIsDate={setIsDate}
                    Date={Date} setDate={setDate}
                />
            )}
            {active === 'pwd' && (
                <ChangePWD 
                    EditedData={EditedData}
                    setEditedData={setEditedData}
                    IsPasswd={IsPasswd}
                    setIsPasswd={setIsPasswd}
                    IsNewPassword={IsNewPassword}
                    setIsNewPassword={setIsNewPassword}
                />
            )}
            {active === 'Del' && (
                <DelAccount
                    EditedData={EditedData}
                    setEditedData={setEditedData}
                    IsPasswd={IsPasswd}
                    setIsPasswd={setIsPasswd} 
                />
            )}
            {active === 'Store' && (
                <EditStore
                    EditedData={EditedData}
                    setEditedData={setEditedData}
                    IsPasswd={IsPasswd}
                    setIsPasswd={setIsPasswd}
                    IsStore={IsStore}
                    setIsStore={setIsStore}
                />
            )}
        </div>
     );
}
 
export default EditModal;