import ListInventory from './Components/ListInventory';
import InventorysStore from "Store/InventoryStore";
import { Provider } from 'mobx-react';
import { MainOrdersProps } from 'Pages/Orders/MainOrders';
import i18n from 'i18nextinit';


const MainInventory: React.SFC<MainOrdersProps> = ({type}) => {

    return (
        <Provider InventoryStore={InventorysStore}>
            <title>{i18n.t("Pages.Inventory.TabTitle")} | CodScale</title>
            <div className="MainContainer">
                <ListInventory InventorysStore={InventorysStore} type={type}/>
            </div>
        </Provider>
    );
}
 
export default MainInventory;