import { Button, Modal, Table, TablePaginationConfig } from "antd";
import i18n from "i18nextinit";
import {observer , inject } from 'mobx-react';
import { FacturesColumns } from "Pages/Orders/Components/Consts";
import React, { useEffect, useState } from "react";
import { StatusUpdate, UpdateFields } from "Services/Factures/UpdateFacture";
import FacturesStore from "Store/FacturesStore";
import { FactureData, FactureDataTypes } from "../Constants/FacturesTypes";
import CreateFacture from "./CreateFacture";
import FactureModal from "./FactureModal";
import RowFactureModal from "./RowFactureModal";

interface ListFacturesProps {
    FacturesStore : typeof FacturesStore,
    type ?: string
}
 
const ListFactures: React.SFC<ListFacturesProps> =  inject(
    'FacturesStore'
  )(
    observer(({FacturesStore , type}) =>  {

    const [loading , setLoading ] = useState<boolean | undefined>(false);
    const [Open , setOpen] = useState(false);
    const [pagination , setPagination] = useState<number|undefined>(1);
    const [confirmLoading , setconfirmLoading] = useState(false);

    const [Data , setData ] = useState<FactureDataTypes>(FactureData);
    const [dataSource ,setDataSource] = useState<FactureDataTypes[]>(FacturesStore.dataSource);
    const [pageSize , setPagesize] = useState<number>(FacturesStore.count);
    const [selectedRowKeys , setSelectedRowKeys] = useState<React.Key[]>([]);



    useEffect(()=>{
        setLoading(true);
        FacturesStore.getDataSource(1)
        .then(()=>{
            setDataSource(FacturesStore.dataSource);
            setPagesize(FacturesStore.count);
            setLoading(false);
        })
        .catch(err => console.log(err));
    },[])

    const HandelRow = (row : FactureDataTypes )=>{
        setData(row) ;
        setOpen(true);
    }
    const handleChange = (page: TablePaginationConfig) => {
      setLoading(true);
      FacturesStore.getDataSource(page.current)
        .then(()=>{
            setDataSource(FacturesStore.dataSource);
            setPagesize(FacturesStore.count);
            setLoading(false);
        })
        .catch(err => console.log(err));
    }
    return (
      <>
        {(type === "Admin" || "Livr") && (
          <div
            className="ButtonsContainer"
            style={{ justifyContent: "flex-start" }}
          >
            <CreateFacture
              type={type}
              FacturesStore={FacturesStore}
              setDataSource={setDataSource}
              setLoading={setLoading}
              setPagesize={setPagesize}
              pagination={pagination}
            />
          </div>
        )}
        <div className="ListContainer">
          <Table
            dataSource={dataSource}
            style={{overflowX:"auto"}}
            columns={FacturesColumns}
            pagination={{ pageSize: 10, total: pageSize }}
            onChange={(pagination) => handleChange(pagination)}
            loading={loading}
            rowKey={(row: FactureDataTypes) =>
              row.FactureId === undefined ? Math.random() : row.FactureId
            }
            onRow={(row) => ({ onClick: () => HandelRow(row) })}
          />
          <Modal
            title={Data?.FactureId}
            visible={Open}
            onCancel={() => setOpen(!Open)}
            style={{ top: "9%" }}
            footer={(type === 'Admin' || Data.Status === 'Pending')?[
                <Button key="back" type="default" onClick={() => setOpen(!Open)}>
                  {i18n.t("Pages.Invoices.Create.cancel")}
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={confirmLoading}
                  disabled={Data.LivrId === "" || Data.Num_Order.length === 0}
                  onClick={() => {
                    setconfirmLoading(true);
                    {
                      type === "Admin"
                        ? UpdateFields(
                            Data,
                            setconfirmLoading,
                            setLoading,
                            FacturesStore,
                            setDataSource,
                            setPagesize,
                            setOpen,
                            pagination
                          )
                        : StatusUpdate(
                            Data,
                            setconfirmLoading,
                            setLoading,
                            FacturesStore,
                            setDataSource,
                            setPagesize,
                            setOpen,
                            pagination
                          );
                    }
                  }}
                >
                  {type === "Admin" ? i18n.t("Pages.Invoices.Create.Update") : i18n.t("Pages.Invoices.Create.confirm")}
                </Button>
              ]:null}
            destroyOnClose
            width="85vw"
            bodyStyle={{
              height: "60vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            {type === "Admin" && (
              <FactureModal
                Data={Data}
                setData={setData}
                selectedRowKeys={selectedRowKeys}
                type={type}
                setSelectedRowKeys={setSelectedRowKeys}
                action="Row"
              />
            )}
            
            {type === "Livr" && <RowFactureModal Data={Data} type={type} />}
            
          </Modal>
        </div>
      </>
    );
}))
 
export default ListFactures;