import { Select } from "antd";
import i18n from "i18nextinit";
import { ClientType } from "../Constants/ClientTypes";

interface RowCLientProps {
    Data: ClientType,
    setData: React.Dispatch<React.SetStateAction<ClientType>>
}
 
const RowCLient: React.SFC<RowCLientProps> = ({Data,setData}) => {
    return ( 
        <div className='ClientContainer'>
        <div className='back'>
            <div className='Row2 '>
                <div className='Row1' style={{width : '45%'}}>
                    <label>{i18n.t("Pages.Clients.Modal.Firstname")}</label>
                    <input  className='Field edit' value={Data.Firstname} type='text' onChange={(e)=>setData({...Data, Firstname : e.target.value})} />
                    
                </div>
                <div className='Row1' style={{width : '45%'}}>
                    <label>{i18n.t("Pages.Clients.Modal.Lastname")}</label>
                    <input  className='Field edit' value={Data.Lastname} type='text' onChange={(e)=>setData({...Data, Lastname : e.target.value})} />
                </div>
            </div>
            <div className='Row2 '>
                <div className='Row1' style={{width : '45%'}}>
                    <label>{i18n.t("Pages.Clients.Modal.Phone")} </label>
                    <input  className='Field edit' value={Data.Phone} type='tel' onChange={(e)=>setData({...Data, Phone : e.target.value})} />
                </div>
                <div className='Row1' style={{width : '45%'}}>
                    <label>{i18n.t("Pages.Clients.Modal.City")} </label>
                    <input readOnly className='Field edit' value={Data.City} type='text'/>
                </div>
                
            </div>
                
        </div>
        
    </div>
     );
}
 
export default RowCLient;