import { AdminType } from "Components/Constants/UserTypes";
import i18n from "i18nextinit";

interface EditNameProps {
    EditedData: AdminType
    setEditedData: React.Dispatch<React.SetStateAction<AdminType>>
    IsPasswd: boolean
    setIsPasswd: React.Dispatch<React.SetStateAction<boolean>>
    IsName: boolean
}
 
const EditName: React.SFC<EditNameProps> = ({EditedData,setEditedData,IsPasswd,setIsPasswd,IsName}) => {
    return ( 
        <>
            <p>{i18n.t("Pages.MyProfile.MyAccount.EditName.Paragraph")}</p>
            <div className="TwoFields">
                <div className="Field1">
                    <label className="label">{i18n.t("Pages.MyProfile.MyAccount.EditDate.Lastname")}</label>
                    <input
                        type="text"
                        name="lastname"
                        className={
                            !IsName
                            ? "Field normal inv"
                            : "Field normal"
                        }
                        onChange={(e)=>{
                            setEditedData({...EditedData , LastName : e.target.value})
                        }}
                        value={EditedData.LastName}
                    />
                    
                </div>
                <div className="Field2">
                    <label className="label">{i18n.t("Pages.MyProfile.MyAccount.EditDate.Firstname")}</label>
                    <input
                        type="text"
                        name="firstname"
                        className={
                            !IsName
                            ? "Field normal inv"
                            : "Field normal"
                        }
                        onChange={(e)=>{
                            setEditedData({...EditedData , FirstName : e.target.value})
                        }}
                        value={EditedData.FirstName}
                    />
                    
                </div>
                {!IsName ? (
                    <div className="inv_msg">{i18n.t("Pages.MyProfile.MyAccount.EditDate.InvCurrLabel")}</div>
                ) : null}
            </div>
            <div className="Field1" style={{width : '100%'}}>
                <label className="label">{i18n.t("Pages.MyProfile.MyAccount.EditDate.LabelCurr")}</label>
                <input
                    type="password"
                    name="password"
                    className={
                        !IsPasswd
                        ? "Field normal inv"
                        : "Field normal"
                    }
                    onChange={(e)=>{
                        setEditedData({...EditedData , Password : e.target.value})
                        setIsPasswd(true);
                    }}
                    value={EditedData.Password}
                />
            </div>
            {!IsPasswd ? (
                <div className="inv_msg">{i18n.t("Pages.MyProfile.MyAccount.EditDate.InvCurrLabel")}</div>
            ) : null}
        </>
     );
}
 
export default EditName;