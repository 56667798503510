import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      'color': '#32325d',
      'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
      'fontSmoothing': 'antialiased',
      'fontSize': '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

interface CardInputProps {
  setError: React.Dispatch<React.SetStateAction<{
    Plan: boolean;
    Card: boolean;
  }>>,
  Errors: {
    Plan: boolean;
    Card: boolean;
  }
}
 
const CardInput: React.SFC<CardInputProps> = ({setError,Errors}) => {
    return ( 
        <CardElement options={CARD_ELEMENT_OPTIONS} onChange={(e) => setError({...Errors , Card : false})} />
     );
}
 
export default CardInput;