import { Modal, Button } from 'antd';
import {useState} from 'react';
import {ReactComponent as AddIcon } from 'Assets/Svgs/addIcon.svg';
import OrderModal from "../OrderModal";
import {handleDataChange , updateRowProduct ,handleAdd , handleRemove , HandleOk} from '../../helpers/AdminOrdersHandlers'
import {newProduct , DataFields} from '../Consts';
import { DataFieldsTypes, newProductTypes } from '../OrderTypes';
import OrderStore from 'Store/OrderStore';
import { DataCounter } from 'Components/Routes/CounterComponent';
import i18n from 'i18nextinit';

export interface CreateTypes
{
        orderStore: typeof OrderStore ,
        setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>,
        setDataSource: React.Dispatch<React.SetStateAction<DataFieldsTypes[]>>,
        setPagesize: React.Dispatch<React.SetStateAction<number>>,
        pagination: number | undefined,
        setLimitOpen: React.Dispatch<React.SetStateAction<boolean>>
        setDataCounter: React.Dispatch<React.SetStateAction<DataCounter | undefined>>,
        setLimitMess: React.Dispatch<React.SetStateAction<string>>
}

const CreateOrder:React.SFC<CreateTypes> = ({orderStore ,setLoading, setDataSource , setPagesize, pagination,setLimitOpen,setDataCounter,setLimitMess}) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading , setconfirmLoading] = useState(false);
    const [LivrStatus , setLivr] = useState(false);
    const [productRows , setRows] = useState<newProductTypes[]>([newProduct]);
    const [Data , setData] = useState<DataFieldsTypes>(DataFields);
    const reset = {
        id : '',
        upsell : false,
        cost_per_unit : 0,
        name : '',
        variant : '',
        quantity : 1,
        price : 0
    }

    return (  
        <div className='CreateOrder'>
            <button onClick={()=>setOpen(!open)}><AddIcon/><span>{i18n.t("Pages.Orders.Create.Title")}</span></button>
            <Modal 
            style={{top : '9%'}}
            title={i18n.t("Pages.Orders.Create.Title")} 
            visible={open} 
            width='85vw'
            destroyOnClose 
            onCancel={()=>{
                setOpen(!open);
                setData(DataFields);
                setRows([reset]);
            }}
            bodyStyle={{height: '60vh' , overflowY : 'scroll'}}
            footer={[
                <Button key="back" type="default"  
                onClick={()=>{
                    setOpen(!open);
                    setData(DataFields);
                    setRows([reset]);
                }}
                >
                  {i18n.t("Pages.Orders.Create.cancel")}
                </Button>,
                <Button key="submit" type="primary" loading={confirmLoading}
                 disabled={(
                     Data.Address ==='' || Data.City ==='' || Data.Client_Id ==='' || Data.Livr_Id ==='' || Data.Status === undefined  || Data.Status === '' || Data.Products.length === 0 || LivrStatus === true)} 
                    onClick={()=>{
                        HandleOk(Data,setconfirmLoading,orderStore,setLoading,setDataSource,
                            setPagesize,setOpen,pagination,setData,setDataCounter,setLimitOpen,setLimitMess,setLivr)
                        console.log(productRows);
                        setRows([{id : '',
                        upsell : false,
                        cost_per_unit : 0,
                        name : '',
                        variant : '',
                        quantity : 1,
                        price : 0}]);
                        console.log(productRows);

                    }
                    }>
                  {i18n.t("Pages.Orders.Create.create")}
                </Button>,
            ]}
            >
                <OrderModal 
                 LivrStatus={LivrStatus}
                 setLivr={setLivr}
                 productRows={productRows}
                 newProduct={newProduct}
                 Data={Data}
                 setData={setData}
                 setRows={setRows}
                 handleDataChange={handleDataChange} 
                 handleAdd={handleAdd} 
                 handleRemove={handleRemove}
                 updateRowProduct={updateRowProduct}
                 type='Create'
                />
            </Modal>
        </div> 
    );
}
 
export default CreateOrder;