import StatusComponent from "Components/Routes/StatusComponent";
import i18n from "i18nextinit";
import moment from "moment";
import { newProductTypes } from "Pages/Orders/Components/OrderTypes";
import React from "react";

export const Productcolumns = [
    {
      title: i18n.t("Pages.Bon.ProdColumns.ProdNumb"),
      dataIndex: 'ProductId',
      key: 'ProductId'
    },
    {
      title: i18n.t("Pages.Bon.ProdColumns.Name"),
      dataIndex: 'ProductName',
      key: 'ProductName'
    },
    {
        title: i18n.t("Pages.Bon.ProdColumns.Price"),
        dataIndex: 'ProductPrice',
        key: 'ProductPrice',
        render: (ProductPrice:string) => (
        <span>{ProductPrice} Dh</span>
      ),
    },
    {
      title: i18n.t("Pages.Bon.ProdColumns.UpsellQuant"),
      dataIndex: 'UpSell_Quantity',
      key: 'UpSell_Quantity',
        render: (UpSell_Quantity:string) => (
        <span>{(UpSell_Quantity === "" || UpSell_Quantity=== undefined ) ? "--"  :UpSell_Quantity} </span>
      ),
    },
    {
        title: i18n.t("Pages.Bon.ProdColumns.UpsellPrice"),
        dataIndex: 'UpSell_Price',
        key: 'UpSell_Price',
        render: (UpSell_Price:string) => (
          <span>{(UpSell_Price === "" || UpSell_Price=== undefined ) ? "--"  :UpSell_Price + ' Dh'} </span>
        ),
    },
    {
      title: i18n.t("Pages.Bon.ProdColumns.Quantity"),
      key: 'quan',
      dataIndex: 'Quantity',
    }
];

export const Ordercolumns = [
    {
      title: i18n.t("Pages.Bon.Pdf.OrderId"),
      dataIndex: 'Num_Order',
      key: 'Num_Order'
    },
    {
      title: i18n.t("Pages.Bon.Pdf.Products"),
      dataIndex: 'Products',
      key: 'Products',
      render : (Product : newProductTypes[] ) => (
        <span>{Product.length}</span>
      )
    },
    {
      title: i18n.t("Pages.Bon.Pdf.City"),
      dataIndex: 'City',
      key: 'City',
    },
    {
      title: i18n.t("Pages.Bon.Pdf.Status"),
      key: 'Status',
      dataIndex: 'Status',
      render: (status:string) => (
        <StatusComponent  Type={status}/>
      ),
    },
    {
      title: i18n.t("Pages.Bon.Pdf.Address"),
      key: 'Address',
      dataIndex: 'Address'
    },
];

export const DataTableColumns = [
  {
    title: i18n.t("Pages.Bon.Pdf.BonId"),
    dataIndex: 'BonId',
    key: 'BonId'
  },
  {
    title: i18n.t("Pages.Bon.Pdf.Orders"),
    dataIndex: 'Num_Order',
    key: 'Num_Order',
    render: (Num_Order:string[]) => (
      <span>{Num_Order !== undefined ? Num_Order.length : "--"}</span>
    ),
  },
  {
    title: i18n.t("Pages.Bon.Pdf.Date"),
    dataIndex: 'Date',
    key: 'Date',
    render: (Date:Date) => (
      <span>{Date !== undefined ? moment(Date).format('MMMM Do YYYY') : "--"}</span>
    ),
  },
  {
    title: i18n.t("Pages.Bon.Pdf.City"),
    dataIndex: 'City',
    key: 'City'
  },
  {
      title: i18n.t("Pages.Bon.Pdf.Price"),
      dataIndex: 'Price',
      key: 'Price',
      render: (Price:string) => (
        <span>{(Price === "" || Price=== undefined ) ? "--"  :Price + ' Dh'} </span>
      ),
  },
  {
    title: i18n.t("Pages.Bon.Pdf.Status"),
    key: 'Status',
    dataIndex: 'Status',
    render: (status:string) => (
      <StatusComponent  Type={status}/>
    ),
  }
];
export const ProdTableColumns = [
  {
    title: i18n.t("Pages.Bon.Pdf.BonId"),
    dataIndex: 'BonId',
    key: 'BonId'
  },
  {
    title: i18n.t("Pages.Bon.Pdf.Products"),
    dataIndex: 'Num_Prod',
    key: 'Num_Prod',
    render: (Num_Prod:string[]) => (
      <span>{Num_Prod !== undefined ? Num_Prod.length : "--"}</span>
    ),
  },
  {
    title: i18n.t("Pages.Bon.Pdf.Date"),
    dataIndex: 'Date',
    key: 'Date',
    render: (Date:Date) => (
      <span>{Date !== undefined ? moment(Date).format('MMMM Do YYYY') : "--"}</span>
    ),
  },
  {
    title: i18n.t("Pages.Bon.Pdf.City"),
    dataIndex: 'City',
    key: 'City'
  },
  {
      title: i18n.t("Pages.Bon.Pdf.Price"),
      dataIndex: 'Price',
      key: 'Price',
      render: (Price:string) => (
        <span>{(Price === "" || Price=== undefined ) ? "--"  :Price + ' Dh'} </span>
      ),
  },
  {
    title: i18n.t("Pages.Bon.Pdf.Status"),
    key: 'Status',
    dataIndex: 'Status',
    render: (status:string) => (
      <StatusComponent  Type={status}/>
    ),
  }
];

export const RetourColumns = [
  {
    title: i18n.t("Pages.Bon.Pdf.BonId"),
    dataIndex: 'BonId',
    key: 'BonId'
  },
  {
    title: i18n.t("Pages.Bon.Pdf.Date"),
    dataIndex: 'Date',
    key: 'Date',
    render: (Date:Date) => (
      <span>{Date !== undefined ? moment(Date).format('MMMM Do YYYY') : "--"}</span>
    ),
  },
  {
    title: i18n.t("Pages.Bon.Pdf.City"),
    dataIndex: 'City',
    key: 'City'
  },
  {
      title: i18n.t("Pages.Bon.Pdf.Price"),
      dataIndex: 'Price',
      key: 'Price',
      render: (Price:string) => (
        <span>{(Price === "" || Price=== undefined ) ? "--"  :Price + ' Dh'} </span>
      ),
  },
  {
    title: i18n.t("Pages.Bon.Pdf.Status"),
    key: 'Status',
    dataIndex: 'Status',
    render: (status:string) => (
      <StatusComponent  Type={status}/>
    ),
  }
];