import { Input, Select } from "antd";
import i18n from "i18nextinit";
import React from "react";
import { Cities } from "../Constants/EmployeData";
import { EmployeType } from "../Constants/EmployeTypes";


interface RowEmpModalProps {
    Data: EmployeType | undefined
    setData: React.Dispatch<React.SetStateAction<EmployeType | undefined>>
}

const {Option}=Select ;
const RowEmpModal: React.SFC<RowEmpModalProps> = ({Data,setData}) => {
    const TypeValues = [
        {
            value: "Maga",
            label: i18n.t("Pages.Staff.Modal.StoreKepper")
        },
        {
            value: "Call",
            label: i18n.t("Employe")
        },
        {
            value: "Livr",
            label: i18n.t("Pages.Staff.Modal.Deliv")
        },
        {
            value: "LivrExt",
            label: i18n.t("Pages.Staff.Modal.Deliv")
        }
    ];
    return ( 
        <div className='EmployeContainer'>
        <div className='Row2' style={{width : '90%'}}>
                <div className='Row1' style={{width : '45%'}}>
                        <label>{i18n.t("Pages.Clients.Modal.Type")} </label>
                        <span className='Field edit'>
                            {TypeValues.find(el => el.value === Data?.Type).label}
                        </span>
                </div>
                {(Data?.Type==='Livr' || Data?.Type==='LivrExt')&&(
                    <div className='Row1' style={{width : '45%'}}>
                        <label>{i18n.t("Pages.Clients.Modal.City")} </label>
                        <Select
                        showSearch
                        optionFilterProp="children"
                        dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                        placeholder={i18n.t("Pages.Clients.Modal.CityPH")}
                        onChange={(value : string)=>{setData({...Data , City : value})}}
                        defaultValue={Data?.City}
                        >
                        {Cities.map(city =>(
                            <Option key={city} value={city}>{city}</Option> 
                        ))}
                        </Select> 
                    </div>
                )}
                
        </div>
        {Data?.Type !== undefined &&(
            <div className='back'>
                <div className='Row2'>
                    <div className='Row1' style={{width : '45%'}}>
                        <label>{i18n.t("Pages.Clients.Modal.Firstname")} </label>
                        <input className='Field edit' value={Data?.FirstName} type='text'  onChange={(e)=>{setData({...Data , FirstName : e.target.value})}} />
                    </div>
                    <div className='Row1' style={{width : '45%'}}>
                        <label>{i18n.t("Pages.Clients.Modal.Lastname")}</label>
                        <input className='Field edit' value={Data?.LastName} type='text' onChange={(e)=>setData({...Data, LastName : e.target.value})}  />
                    </div>
                </div>
                <div className='Row2'>
                    <div className='Row1' style={{width : '45%'}}>
                        <label>{i18n.t("Auth.Form.Email")}</label>
                        <input className='Field edit' value={Data?.Email} type='email' onChange={(e)=>setData({...Data, Email : e.target.value})} />
                    </div>
                    <div className='Row1' style={{width : '45%'}}>
                            <label>{i18n.t("Auth.Actions.SignUp.Screen1.Phone")}</label>
                            <input className='Field edit' value={Data?.Tel} type='tel' onChange={(e)=>setData({...Data , Tel :e.target.value })} />
                    </div>
                </div>
                <div className='Row2'>
                    {Data?.Type === 'LivrExt' &&(
                        <div className='Row1' style={{width : '45%'}}>
                            <label>{i18n.t("Pages.Staff.Modal.DelCut")}</label>
                            <Input 
                            placeholder='Delivery man price' 
                            style={{width : '100%'}} addonAfter="Dh" 
                            type='number'
                            value={Data.Price !== '0' ? Data.Price : ''}
                            onChange={(e)=>setData({...Data, Price : e.target.value})}
                            />
                        </div>
                    )}
                    
                </div>
                <div className="SecretKey">
                    <label className="label">{i18n.t("Pages.SalesChannel.SaleModal.Status")} :</label><br/>
                    <Select
                        style={{ borderRadius : '5px' , width : '30%' }}
                        dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                        value={Data?.Enabled.toString()}
                        onChange={(value: string) =>
                        setData({...Data , Enabled : value})
                        }
                    >
                        <Option key="true" value="true">
                            {i18n.t("Pages.SalesChannel.SaleModal.Active")}
                        </Option>
                        <Option key="false" value="false">
                            {i18n.t("Pages.SalesChannel.SaleModal.Disabled")}
                        </Option>

                    </Select>
                </div>
            </div>
        )}
    </div>
     );
}
 
export default RowEmpModal;