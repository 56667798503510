import { AdminType } from "Components/Constants/UserTypes";
import i18n from "i18nextinit";

interface ChangePWDProps {
    EditedData: AdminType
    setEditedData: React.Dispatch<React.SetStateAction<AdminType>>
    IsPasswd: boolean
    setIsPasswd: React.Dispatch<React.SetStateAction<boolean>>
    IsNewPassword: boolean
    setIsNewPassword: React.Dispatch<React.SetStateAction<boolean>>
}
 
const ChangePWD: React.SFC<ChangePWDProps> = ({
    EditedData,setEditedData,IsPasswd,setIsPasswd,IsNewPassword,setIsNewPassword
}) => {
    return ( 
        <>
            <p>{i18n.t("Pages.MyProfile.MyAccount.ChangePWD.Paragraph")}</p>
            <div className="Field1" style={{width : '100%'}}>
                <label className="label">{i18n.t("Pages.MyProfile.MyAccount.ChangePWD.LabelCurr")}</label>
                <input
                    type="password"
                    name="password"
                    className={
                        !IsPasswd
                        ? "Field normal inv"
                        : "Field normal"
                    }
                    onChange={(e)=>{
                        setEditedData({...EditedData , Password : e.target.value})
                        setIsPasswd(true);
                    }}
                    value={EditedData.Password}
                />
            </div>
            {!IsPasswd ? (
                <div className="inv_msg">{i18n.t("Pages.MyProfile.MyAccount.ChangePWD.InvCurrLabel")}</div>
            ) : null}
            <div className="Field1" style={{width : '100%'}}>
                <label className="label">{i18n.t("Pages.MyProfile.MyAccount.ChangePWD.LabelNew")}</label>
                <input
                    type="password"
                    name="newpassword"
                    className={
                        !IsNewPassword
                        ? "Field normal inv"
                        : "Field normal"
                    }
                    onChange={(e)=>{
                        setEditedData({...EditedData , NewPassword : e.target.value})
                        setIsNewPassword(true);
                    }}
                    onBlur={(e)=>{
                        if(e.target.value.length <8)
                        {
                            setIsNewPassword(false);
                        }
                    }}
                    value={EditedData.NewPassword}
                />
            </div>
            {!IsNewPassword ? (
                <div className="inv_msg">{i18n.t("Pages.MyProfile.MyAccount.ChangePWD.InvNewLabel")}</div>
            ) : null}
        </>
     );
}
 
export default ChangePWD;