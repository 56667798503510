import {Switch , Route , Redirect} from 'react-router-dom';
import SideBar from 'Components/Routes/SideBar';
import Header from 'Components/Routes/Header';
import {useEffect , useState} from 'react';
import { RouteType } from './AdminRoutes';
import { requestFirebaseNotificationPermission } from 'Utils/firebase-config';
import { SaveTokenNotif } from 'Services/Auth/AuthCalls';
import useViewportSizes from 'use-viewport-sizes';



const LivrRoute: React.SFC<RouteType> = ({type,setLimitOpen,Data,setData,Routes}) => {
        
    const [Collapsed , setCollapsed] = useState(false);
    const [Closed , setClosed] = useState(true);
    const [vpWidth] = useViewportSizes({ dimension: 'w' });
    const [width , setwidth] = useState(1280);

    useEffect(()=>{
        if(vpWidth > 0)
        {
            setwidth(vpWidth);
        }
    },[vpWidth])


    useEffect(()=>{
        requestFirebaseNotificationPermission() .then((firebaseToken) => {
            // eslint-disable-next-line no-console
            SaveTokenNotif({Token : firebaseToken})
            .then(result => console.log(result))
            .catch(err => console.log(err))
        })
        .catch((err) => {
            console.log(err);
        });
    },[])

    
    return ( 
        <div className="Routes">
            {!Closed &&(
                     <div className="SideBar Mobile">
                        <SideBar  Data={Data} setData={setData}  AdminRoutes={Routes} Collapsed={Collapsed} Closed={Closed} setClosed={setClosed} type={type} />
                    </div>
               
            )}
            <div className="SideBar Web" style={Collapsed ? {width : '57px'} : {width : '15%'}}>
                <SideBar  Data={Data} setData={setData}  AdminRoutes={Routes} Collapsed={Collapsed} Closed={Closed} setClosed={setClosed} type={type} />
            </div>
            
            
            <div className="Content" style={Collapsed ? {marginLeft : '57px'} : {marginLeft : '15%'} }>
                <Header  setCollapsed={setCollapsed} Collapsed={Collapsed} type={type} Closed={Closed} setClosed={setClosed} />
                <Switch>
                    <Redirect exact from="/" to="/orders" />
                    {Routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            children={<route.main />}
                        />
                    ))}
                    {/* <Route component={notfound}/> */}
                </Switch>
            </div>
            
        </div >
     );
}
 
export default LivrRoute;