import { notification } from "antd";
import { FormikValues } from "formik";
import  moment  from 'moment';
import { CheckEmail, CheckStore, SignInCall, SignupCall } from "Services/Auth/AuthCalls";
import firebase from 'Utils/firebase-config';
import i18n  from "i18nextinit";

require('firebase/auth')

// Global variables and types ---------------------------

declare global {
    interface Window {
        recaptchaVerifier:any;
        confirmationResult : any
    }
}


// Types declarations ---------------------------
// ---------------------------------

interface handleSubmitTypes {
  (values: FormikValues,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    formik: FormikValues,
    setScreen: React.Dispatch<React.SetStateAction<number>>,
    setSwitch: React.Dispatch<React.SetStateAction<boolean>>,
    setData: React.Dispatch<React.SetStateAction<{
      values: {};
    }>>
    ) : void
}

interface HandleSigninTypes {
  (values: FormikValues,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  formik: FormikValues,
  setEmail: React.Dispatch<React.SetStateAction<boolean>>,) : void
}

interface HandleLastSubmitTypes {
  (values: FormikValues,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  formik: FormikValues,
  setScreen: React.Dispatch<React.SetStateAction<number>>,
  {values: {}} ) : void
}
interface handlePhoneTypes {
  (
    setData: React.Dispatch<
    React.SetStateAction<{
      values: {};
      }>
    >,
    values: FormikValues,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    formik: FormikValues,
    setScreen: React.Dispatch<React.SetStateAction<number>>,
    setSwitch: React.Dispatch<React.SetStateAction<boolean>>
  ):void
}
interface CheckDateTypes 
{
  (birthdate : string ):boolean
}
// ------------------------------------------------------------

// Functions ------------------------------

export const CheckDate :CheckDateTypes  = (birthdate)=>{
  const Difference = moment(Date.now()).diff(birthdate, "years");
    if (Difference < 13 && Difference >= 0) {
      return false
    }else
    {
      return true
    }
}

const handlePhone :handlePhoneTypes = (setData,values,setLoading,formik,setScreen,setSwitch) => {

  var phoneNumber = values.tel.includes('+212') ? values.tel : values.tel.replace("0", "+212");
  setData({ values });
  var appVerifier = window.recaptchaVerifier;
  firebase
    .auth()
    .signInWithPhoneNumber(phoneNumber, appVerifier)
    .then(function (confirmationResult) {
      window.confirmationResult = confirmationResult;
      setData({ values });
      setLoading(false);
      setScreen(1);
      setSwitch(true);
    })
    .catch(function (error) {
      setLoading(false);
      if (error.code === "auth/too-many-requests") {
        console.log(error);
        notification["error"]({
          style : {fontFamily : 'CatamaranBold'},
          message : i18n.t('Auth.Errors.TooManyPhoneReq.message'),
          description : i18n.t('Auth.Errors.TooManyPhoneReq.description'),
          duration : 10
        })
      }else
      {
        formik.setFieldError("tel", i18n.t('Auth.Errors.InvPhone'));
        console.log(error);
      }
      
    });
};

// ---------------------------------------------------


// Exports ---------------------------------------------
// -----------------------------------------------------

export const handleSubmit : handleSubmitTypes = async (values,setLoading,formik,setScreen,setSwitch,setData) => {

  setLoading(true);
  // check storename if exist
  const store = await CheckStore(values.storename);
  const email = await CheckEmail(values.email) ;
  const date = await CheckDate(values.birthdate) ;
  if (!store) {
    formik.setFieldError("storename", i18n.t('Auth.Errors.InvStore'));
    setLoading(false);
  }
  if (!email) {
    formik.setFieldError("email", i18n.t('Auth.Errors.InvEmail'));
    setLoading(false);
  }
  if (!date) {
      formik.setFieldError("birthdateview", i18n.t('Auth.Errors.InvBirth'));
      setLoading(false);
  }
  if (store && email && date) {
    handlePhone(
      setData,
      values,
      setLoading,
      formik,
      setScreen,
      setSwitch
    );
  }

};



export const HandleLastSubmit : HandleLastSubmitTypes = (values,setLoading,formik,setScreen , data)=>{
  setLoading(true);
    window.confirmationResult.confirm(values.codeverif).then((result : object)=>{
      setLoading(false);
      setScreen(2);
    }).catch((e : Event)=>{
      setLoading(false);
      formik.setFieldError("codeverif", i18n.t('Auth.Errors.InvCodeverif'));
    })
  
}

export const HandleSignin : HandleSigninTypes = (values , setLoading , formik,setEmail)=>{
  setLoading(true);
  SignInCall(values,setLoading,setEmail);
}