import {useEffect , useState} from 'react';
import { Progress, Skeleton , List, Modal, Button, notification } from "antd";
import { DataCounter } from 'Components/Routes/CounterComponent';
import { GetCountersData } from 'Services/Auth/AuthCalls';
import { Plans } from '../../Constants/PLANS';
import Stats from './PlanComponents/Stats';
import { CancelSub, IsCustomerExist, UpcomingInvoice, UpgradeSUb } from 'Services/Payments/PaymentCalls';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import PreviewSubscription from './PlanComponents/PreviewSubscription';
import {Elements} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import i18n from 'i18nextinit';
// const PUBLIC_KEY = "pk_live_a3k3YuSLjHnYmvSTbwtJgZAa";
const PUBLIC_KEY = "pk_test_FUo5Zuk3BiQWMZJjNdznbcxO";

const stripeTestPromise = loadStripe(PUBLIC_KEY);
interface PlanProps {
        Loading: boolean
        setLoading: React.Dispatch<React.SetStateAction<boolean>>
}


const Plan: React.SFC<PlanProps> = ({Loading,setLoading}) => {

    const [Data , setData] = useState<DataCounter>()
    const [OrderPerc , setOrderPerc] = useState<number>();
    const [ExtPerc , setExtPerc] = useState<number>();
    const [SalesPerc , setSalesPerc] = useState<number>();
    const [StaffPerc , setStaffPerc] = useState<number>();
    const [open , setopen]= useState(false);
    const [Screen , setScreen] = useState<number>();

    const [previewdata , setPrev]= useState<any>();
    const [loadingData , setLoadingData] = useState<boolean>();

    const [popVis , setpopVis]= useState(false);
    const [PopConfirm , setPopConfirm]= useState(false);

    const [priceId , setPriceId] = useState<string>();
    const {confirm }= Modal;
    function showDeleteConfirm() {
        confirm({
          title: 'Are you sure you want to cancel your subscription ?',
          style : {fontFamily : 'CatamaranBold'},
          icon: <ExclamationCircleOutlined />,
          content: `you want be able to recover any remaining credit and you'll be downgraded to free plan ` ,
          okText: `I am sure !`,
          okType: 'danger',
          cancelText: 'No',
          visible : popVis ,
          okButtonProps : {loading : PopConfirm},
          onOk() {
    
                CancelSub().then(result =>{
                    if(result === 200)
                    {
                        notification["success"]({
                            style: { fontFamily: "CatamaranBold" },
                            message: `Subscription canceled ✅`,
                            description:
                              "Your subscription canceled successfully !",
                        });
                        setTimeout(() => {
                          window.location.reload()
                        }, 1000);
                    }
                }).catch(err =>{
                    console.log(err);
                })
                
          },
          onCancel() {
            
          },
        });
    }
    useEffect(()=>{
        GetCountersData().then((result : DataCounter) =>{
            setData(result);
            const Perc = Number((result.OrdersCount / result.OrdersCountLimit)*100);
            const PercExt = Number((result.ExtDelCount / result.ExtDelCountLimit)*100);
            const PercSales = Number((result.SalesChanCount / result.SalesChanCountLimit)*100);
            const PercStaff = Number((result.StaffCount / result.StaffCountLimit)*100);

            setOrderPerc(Perc);
            setExtPerc(PercExt);
            setSalesPerc(PercSales);
            setStaffPerc(PercStaff);
            setLoading(false);
        })
    },[])

    const Provision = (priceId : string | undefined)=>{
        setPriceId(priceId);
        setopen(true);
        setLoadingData(true);
        UpcomingInvoice({priceId : priceId}).then(result =>{
            setPrev(result);
        }).finally(()=>setLoadingData(false))
    }
    const handleClick = (priceId : string) =>{
        IsCustomerExist().then(async result =>{
            if (result === true) {
                setScreen(1);
                Provision(priceId);
            }else
            {
                setPriceId(priceId);
                setScreen(0);
                setopen(true);
            }
        })
    }
    return (
      <div className="Account">
        <Skeleton loading={Loading} active round={true}>
          <Elements stripe={stripeTestPromise}>
            <PreviewSubscription
              Screen={Screen}
              Provision={Provision}
              open={open}
              setopen={setopen}
              previewdata={previewdata}
              priceId={priceId}
              PopConfirm={PopConfirm}
              setPopConfirm={setPopConfirm}
              loadingData={loadingData}
            />
          </Elements>
          <span className="Heading">
            {i18n.t("Pages.MyProfile.MyAccount.Plans.Subscription.Title")}
          </span>
          <div
            className="UserContainer"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="CurrentPlan">
              <span className="titlePlan">
                {i18n.t(
                  "Pages.MyProfile.MyAccount.Plans.Subscription.CurrPlan"
                )}
              </span>
              <div className="PlanCont">
                <span className="titleCurrPlan">
                  {" "}
                  {Plans.find((el) => el.Plan === Data?.Plan)?.Name} Plan{" "}
                </span>
                <div className="CurrPlanDescr">
                  <List itemLayout="horizontal">
                    <List.Item
                      style={{ fontFamily: "CatamaranBold", fontSize: "15px" }}
                    >
                      {"✅  "}
                      {i18n.t(
                        "Pages.MyProfile.MyAccount.Plans.Stats.Orders"
                      )} : {Plans.find((el) => el.Plan === Data?.Plan)?.Orders}
                    </List.Item>
                    <List.Item
                      style={{ fontFamily: "CatamaranBold", fontSize: "15px" }}
                    >
                      {"✅  "}
                      {i18n.t(
                        "Pages.MyProfile.MyAccount.Plans.Stats.ExtDeliv"
                      )}{" "}
                      : {Plans.find((el) => el.Plan === Data?.Plan)?.ExtDeliv}
                    </List.Item>
                    <List.Item
                      style={{ fontFamily: "CatamaranBold", fontSize: "15px" }}
                    >
                      {"✅  "}
                      {i18n.t(
                        "Pages.MyProfile.MyAccount.Plans.Stats.Sales"
                      )} :{" "}
                      {Plans.find((el) => el.Plan === Data?.Plan)?.SalesChan}
                    </List.Item>
                    <List.Item
                      style={{ fontFamily: "CatamaranBold", fontSize: "15px" }}
                    >
                      {"✅  "}
                      {i18n.t(
                        "Pages.MyProfile.MyAccount.Plans.Stats.Staff"
                      )} : {Plans.find((el) => el.Plan === Data?.Plan)?.Staff}
                    </List.Item>
                  </List>
                </div>
                <br />
                <br />
                {Data?.Plan !== "free_price" && (
                  <span
                    style={{
                      fontFamily: "CatamaranBold",
                      textDecoration: "underline",
                      color: "#C52233",
                      cursor: "pointer",
                    }}
                    onClick={showDeleteConfirm}
                  >
                    {i18n.t(
                      "Pages.MyProfile.MyAccount.Plans.Subscription.Cancel"
                    )}
                  </span>
                )}
              </div>
            </div>
            <Stats
              Data={Data}
              OrderPerc={OrderPerc}
              ExtPerc={ExtPerc}
              StaffPerc={StaffPerc}
              SalesPerc={SalesPerc}
            />
          </div>
          <br />
          <div className="SwitchContainer">
            <span className="Heading">
              {i18n.t("Pages.MyProfile.MyAccount.Plans.Subscription.Switch")}
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                padding: "16px 32px 16px 32px",
              }}
            >
              {Plans.map((Plan, index) => (
                <>
                  {Plan.Plan !== Data?.Plan && Plan.Plan !== "free_price" && (
                    <div className="UpgradePlan" key={Plan.Plan}>
                      <span className="titleCurrPlan"> {Plan.Name} </span>
                      <br />
                      <br />
                      <span className="titlePrice">
                        {" "}
                        {Plan.price} $ /{" "}
                        {i18n.t(
                          "Pages.MyProfile.MyAccount.Plans.Subscription.Month"
                        )}{" "}
                      </span>
                      <div className="CurrPlanDescr">
                        <List
                          itemLayout="horizontal"
                          style={{ marginTop: "10px" }}
                        >
                          <List.Item
                            style={{
                              fontFamily: "CatamaranBold",
                              fontSize: "15px",
                            }}
                          >
                            {"✅  "}
                            {i18n.t(
                              "Pages.MyProfile.MyAccount.Plans.Stats.Orders"
                            )}{" "}
                            : {Plan.Orders}
                          </List.Item>
                          <List.Item
                            style={{
                              fontFamily: "CatamaranBold",
                              fontSize: "15px",
                            }}
                          >
                            {"✅  "}
                            {i18n.t(
                              "Pages.MyProfile.MyAccount.Plans.Stats.ExtDeliv"
                            )}{" "}
                            : {Plan.ExtDeliv}
                          </List.Item>
                          <List.Item
                            style={{
                              fontFamily: "CatamaranBold",
                              fontSize: "15px",
                            }}
                          >
                            {"✅  "}
                            {i18n.t(
                              "Pages.MyProfile.MyAccount.Plans.Stats.Sales"
                            )}{" "}
                            : {Plan.SalesChan}
                          </List.Item>
                          <List.Item
                            style={{
                              fontFamily: "CatamaranBold",
                              fontSize: "15px",
                            }}
                          >
                            {"✅  "}
                            {i18n.t(
                              "Pages.MyProfile.MyAccount.Plans.Stats.Staff"
                            )}{" "}
                            : {Plan.Staff}
                          </List.Item>
                        </List>
                      </div>
                      <br />
                      <br />
                      <div
                        className="actButton"
                        onClick={() => {
                          handleClick(Plan.Plan);
                        }}
                      >
                        <button>
                          {Plans.indexOf(
                            Plans.find((el) => el.Plan === Data?.Plan)
                          ) < index
                            ? i18n.t(
                                "Pages.MyProfile.MyAccount.Plans.Subscription.Upgrade"
                              )
                            : i18n.t(
                                "Pages.MyProfile.MyAccount.Plans.Subscription.Downgrade"
                              )}
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        </Skeleton>
      </div>
    );
}
 
export default Plan;