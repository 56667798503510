import {Switch , Route , Redirect } from 'react-router-dom';
import {useEffect , useState} from 'react';

// Components ----------------------------

import SideBar from 'Components/Routes/SideBar';
import Header from 'Components/Routes/Header';

// --------------

// Css  ------

import 'Sass/Router.scss';
import useViewportSizes from 'use-viewport-sizes';
import { DataCounter } from 'Components/Routes/CounterComponent';


// -----
export interface RouteType {
    type ?:string,
    setLimitOpen: React.Dispatch<React.SetStateAction<boolean>>,
    Data: DataCounter | undefined,
    setData: React.Dispatch<React.SetStateAction<DataCounter | undefined>>,
    Routes: {
      path: string;
      name: string;
      main: () => JSX.Element;
      icon: () => JSX.Element;
  }[]
}
const AdminRoute : React.SFC<RouteType> = ({type,setLimitOpen,Data,setData,Routes}) => {

    const [Collapsed , setCollapsed] = useState(false);
    const [Closed , setClosed] = useState(true);
    const [vpWidth] = useViewportSizes({ dimension: 'w' });
    const [width , setwidth] = useState(1280);

    useEffect(()=>{
        if(vpWidth > 935)
        {
            setClosed(true);
        }
    },[vpWidth])
    
    
    return ( 
        <div className="Routes">
            {!Closed &&(
                     <div className="SideBar Mobile">
                        <SideBar  Data={Data} setData={setData}  AdminRoutes={Routes} Collapsed={Collapsed} Closed={Closed} setClosed={setClosed} type={type} />
                    </div>
               
            )}
            <div className="SideBar Web" style={Collapsed ? {width : '57px'} : {width : '15%'}}>
                <SideBar  Data={Data} setData={setData}  AdminRoutes={Routes} Collapsed={Collapsed} Closed={Closed} setClosed={setClosed} type={type} />
            </div>
            
            
            <div className="Content" style={Collapsed ? {marginLeft : '57px'} : {marginLeft : '15%'} }>
                <Header  setCollapsed={setCollapsed} Collapsed={Collapsed} type={type} Closed={Closed} setClosed={setClosed} />
                <Switch>
                    <Redirect exact from="/" to="/orders" />
                    {Routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            children={<route.main />}
                        />
                    ))}
                    {/* <Route component={notfound}/> */}
                </Switch>
            </div>
            
        </div >
     );
}
 
export default AdminRoute;