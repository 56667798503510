import { ReactComponent as CloseIcon } from "Assets/Svgs/MyProfile/cancel.svg";
import i18n from "i18nextinit";
import { useEffect } from "react";
import { useHistory , useLocation} from "react-router-dom";

interface ProfileSidebarProps {
    active : 'Acc'|'Pla'|'Lang'|string,
    setTab: React.Dispatch<React.SetStateAction<"Acc" | "Pla" | "Lang"|string>>,
    Type : string 
}

export const AvRoutes =['bon','dashboard','factures','clients','inventory','orders','products','salechannel','shipping','staff'];
export function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const ProfileSidebar: React.SFC<ProfileSidebarProps> = ({active,setTab,Type}) => {
    
    let history = useHistory();
    let from = useQuery().get('from');

    function handleEsc(event) {
        if (event.keyCode === 27) {
            handleClick();
        }
    }
    
    useEffect(() => {
      window.addEventListener("keydown", handleEsc);
      return () => {
        window.removeEventListener("keydown", handleEsc);
      };
    }, []);

    const handleClick = ()=>{
        if (from !== undefined && from !== null && AvRoutes.includes(from)) {
            history.push(`/${from}`)
        }else
        {
            history.push("/orders");
        }
    }
    return ( 
        <div className='ProfileSideBar'>
            
            <div className="LinksContainer">
                <div className='CloseContainer' onClick={()=>handleClick()}>
                    <div style={{display:'flex',flexDirection:"column"}}>
                        <CloseIcon />
                        <span style={{fontFamily: 'CatamaranRegular',color:'#a1a2a3',marginTop:'5px',marginLeft:'5px',fontSize:'15px'}}>ESC</span>
                    </div>
                    
                </div>
                <ul className="NavContainer">
                    <span className='title'>{i18n.t('Pages.MyProfile.Title')}</span>
                    <li className={active === 'Acc' ? "nav-item active" : "nav-item"} onClick={()=>setTab('Acc')}>{i18n.t('Pages.MyProfile.Side_Account')}</li>
                    {Type === "Admin" && (
                        <li className={active === 'Pla' ? "nav-item active" : "nav-item"} onClick={()=>setTab('Pla')}>{i18n.t('Pages.MyProfile.Side_Subscriptions')}</li>
                    )}
                    <li className={active === 'Lang' ? "nav-item active" : "nav-item"} onClick={()=>setTab('Lang')}>{i18n.t('Pages.MyProfile.Side_Languages')}</li>
                </ul>
            </div>
        </div> 
     );
}
 
export default ProfileSidebar;