// import Footer from "Components/Auth/Footer";
import Header from "Components/Auth/Header";
import IllusComp from "Components/Auth/IllusComp";
import SignIn from "Components/Auth/Signin/signin" ;
import SignUp from "Components/Auth/Signup/signup";
import { useLocation , Link} from 'react-router-dom' ;
import {useState , useEffect} from 'react';
import { CheckUser } from 'Services/Auth/AuthCalls';

import 'Sass/Auth.scss';
import { message } from "antd";
import LoadingPage from "Components/LoadingPage";
import i18n from "i18nextinit";

const Auth = ()=>{
    const [Loading , setLoading] = useState(true);
    const [SignedIn,setSignedIn] = useState(false);
    useEffect(()=>{
        CheckUser().then(response =>{
            setLoading(false);
            if ( response.isSignedIn) {
                
                setSignedIn(true);
                setTimeout(() => {
                    window.location.assign("/");
                }, 3000);
            }else
            {
                setLoading(false);
                var url = new URL(window.location.href);
                var ret = url.searchParams.get("returnTo");
                const ref = url.searchParams.get("ref");
                if (ret !== null && ret !== undefined) {
                    
                    setTimeout(() => {
                        message.warning(i18n.t("Pages.Auth.Errors.signin"));
                    }, 1000); 
                }else
                {
                    if (ref === 'invalidId') {
                        setTimeout(() => {
                            message.error(i18n.t("Pages.Auth.Errors.InvEmail"));
                        }, 2000);
                    }
                }
                
            }
        })
        .catch(err =>{
           
            setLoading(false);
            var url = new URL(window.location.href);
            var ret = url.searchParams.get("returnTo");
            const ref = url.searchParams.get("ref");
            if (ret !== null && ret !== undefined) {
                setTimeout(() => {
                    message.warning(i18n.t("Pages.Auth.Errors.signin"));
                }, 1000); 
            }else
            {
                if (ref === 'invalidId') {
                    setTimeout(() => {
                        message.error(i18n.t("Pages.Auth.Errors.InvEmail"));
                    }, 2000);
                }
            }
        })
       
    },[])

    const location = useLocation();  
    return(
        <>
        {!Loading && (
            <div className='AuthContainer'>
            <div className="content split">
                <main>
                    <div className='RespCont'>
                        <div className='RespLogo' onClick={()=>window.location.assign('/')}>
                        </div>
                    </div>
                    
                    {location.pathname === "/signin" && SignedIn === false && (<SignIn/>)}
                    {location.pathname === "/signup" && SignedIn === false &&(<SignUp/>)}  
                    {SignedIn === true && (
                        <div className="Container">
                        <span className="Title">{i18n.t("Pages.Auth.SignedIn.Title")}!</span>
                        <p className="SecondTitle" style={{color: '#355070' , fontSize : '17px'}}>{i18n.t("Pages.Auth.SignedIn.SecondTitle")}</p>
                        <div className="Description">
                            {i18n.t("Pages.Auth.SignedIn.Desc1")}{' '}<Link to="/"><a>{i18n.t("Pages.Auth.SignedIn.Desc2")}</a></Link>
                        </div>
                        </div>
                        )} 
                </main>
            </div>
            
            <div className="auth-sidebar split">
                {/* <Header /> */}
                <IllusComp/>
            </div>
            {/* <Footer/> */}
            </div>
        )}
        {Loading &&(
            <LoadingPage />
        )}
        </>
        
    );
}

export default Auth ;