
// signin => /Auth/signin
// signup => /Users/admin/createAccount | checkEmail | checkStore
// employe => /Users/employe/createEmploye 

import { notification } from 'antd';
import axios from 'axios';
import { FormikValues } from 'formik';
import i18n from 'i18nextinit';
import {externals} from 'webpack.config';

interface HandleSigninTypes {
    (values: FormikValues,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setEmail: React.Dispatch<React.SetStateAction<boolean>>) : void
}

interface HandleSignupTypes {
    (values : FormikValues ,
    setLoading : React.Dispatch<React.SetStateAction<boolean>> ) : void 
}

interface CheckinTypes {
    (value ?: string) : Promise<boolean>
}

interface CheckUser 
{
    ():Promise<{ isSignedIn: true; Type: string; } | { isSignedIn: false; Type: string; }>
}

export const SaveTokenNotif  = async (values : {Token : string})=>{
    axios.post(`${externals.config.apiUrl}/events/saveToken`,values,{withCredentials : true})
    .then(response =>{
        console.log(response);
    })
    .catch(err =>{
        console.log(err);
    })
}
export const SignInCall : HandleSigninTypes = (values , setLoading,setEmail)=>{
    axios.post(`${externals.config.apiUrl}/Auth/signin`,values)
    .then(response =>{
        
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const returnTo = urlParams.get('returnTo') !== null ? urlParams.get('returnTo') : '/' ;
        if(response.status === 200)
        {
          window.location.href = returnTo + '';
        }else
        {
            
          setLoading(false);
          notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.WrongCredentials"),
            description: i18n.t("Services.Errors.verifyInf"),
          });
        }
    })
    .catch(err =>{
        setLoading(false);
        console.log(err.response.data);
        console.log(externals.config.apiUrl,process.env.REACT_APP_baseURL)
        if (err.response.data === 'password' || err.response.data === 'not found') {
            setLoading(false);
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.WrongCredentials"),
                description: i18n.t("Services.Errors.verifyInf"),
            });
        }
        if (err.response.data === 'verif') {
            setLoading(false);
            setEmail(true);
        }
        
    })
}

export const GetCountersData  = async () => {
    try {
        const response = await  axios.get(`${externals.config.apiUrl}/Users/admin/getCounters`,{withCredentials : true}) ;
        if (response.status === 200) {
            return response.data
        } else {
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            });
        }
    } catch (error) {
        console.log(error);
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description: i18n.t("Services.Errors.ErrorDesc"),
        });
    }
}


export const SignupCall : HandleSignupTypes = (values , setLoading) => {
    axios.post(`${externals.config.apiUrl}/Users/admin/createAccount`,values)
    .then(response => {
        
    })
    .catch(err =>{
        
        console.log(err);
        setLoading(false);
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description: i18n.t("Services.Errors.ErrorDesc"),
        });
    })
}

export const VerifyEmailService = async (verifId : string) =>{
    try {
        const response = await axios.get(`${externals.config.apiUrl}/Auth/verifymail?verifid=${verifId}`,{withCredentials : true})
        console.log(response)
        if (response.status === 200) {
            return true ;
        } else {
            return false ;
        }
    } catch (error) {
        return false ;
    }
}

export const CheckEmail:CheckinTypes = async (email) => {
    try {
        const response = await axios.post(`${externals.config.apiUrl}/Users/admin/checkEmail`,{email : email})
        if (response.status === 200) {
            return true ;
        } else {
            return false ;
        }
    } catch (error) {
        return false ;
    }
    
}

export const CheckStore:CheckinTypes = async (storename) => {

    try {
        const response = await axios.post(`${externals.config.apiUrl}/Users/admin/checkStore`,{storename : storename}) ;
        if (response.status === 200) {
            return true ;
        } else {
            return false ;
        }
    } catch (error) {
        return false
    }
}


export const CheckUser :CheckUser  = async ()=> {

    try {
        const response = await  axios.get(`${externals.config.apiUrl}/Auth/CheckUser`,{withCredentials : true}) ;
        if (response.status === 200) {
            return {
                isSignedIn : response.data.isSignedIn ,
                Type : response.data.Type
            }
        } else {
            return {
                isSignedIn : false ,
                Type : ''
            }
        }
    } catch (error) {
        return {
            isSignedIn : false ,
            Type : ''
        }
    }
}