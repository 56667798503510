// export const Plans = [{
//     Name : 'Free',
//     Plan : 'free_price',
//     Orders : 100,
//     ExtDeliv : 7 ,
//     SalesChan : 2 ,
//     Staff : 0 ,
//     price : '0.00'
// },{
//     Name : 'Standard',
//     Plan : 'price_1Jf5RMEwTdG6UpenODIi0QwR',
//     Orders : 500,
//     ExtDeliv : 15 ,
//     SalesChan : 5 ,
//     Staff : 3 ,
//     price : '34.99'
// },{
//     Name : 'Premium',
//     Plan : 'price_1Jf5S7EwTdG6UpenjOFjGCXK',
//     Orders : 1500,
//     ExtDeliv : 30 ,
//     SalesChan : 10 ,
//     Staff : 8 ,
//     price : '59.99'
// },{
//     Name : 'Advanced',
//     Plan : 'price_1Jf5SEEwTdG6UpenbnjG181K',
//     Orders : 2500,
//     ExtDeliv : 50 ,
//     SalesChan : 20 ,
//     Staff : 20 ,
//     price : '69.99'
// }];

export const Plans = [{
    Name : 'Free',
    Plan : 'free_price',
    Orders : 100,
    ExtDeliv : 7 ,
    SalesChan : 2 ,
    Staff : 0 ,
    price : '0.00'
},{
    Name : 'Standard',
    Plan : 'price_1JTSPUEwTdG6Upen8pc6F5X9',
    Orders : 500,
    ExtDeliv : 15 ,
    SalesChan : 5 ,
    Staff : 3 ,
    price : '9.99'
},{
    Name : 'Pro',
    Plan : 'price_1JTSREEwTdG6UpenDoarpZ7d',
    Orders : 1500,
    ExtDeliv : 30 ,
    SalesChan : 10 ,
    Staff : 8 ,
    price : '34.99'
},{
    Name : 'Advanced',
    Plan : 'price_1JXPcYEwTdG6UpenJTDTksH6',
    Orders : 2500,
    ExtDeliv : 50 ,
    SalesChan : 20 ,
    Staff : 20 ,
    price : '54.99'
}];