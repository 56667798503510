import {Document , Page , Text , View , StyleSheet , Image , Font } from '@react-pdf/renderer';


import CompanyLogo from 'Assets/Icons/wl.png';
import font from 'Assets/Fonts/Montserrat-Bold.ttf';
import fontReg from 'Assets/Fonts/Montserrat-Regular.ttf';
import font2 from 'Assets/Fonts/Catamaran-Bold.ttf';
import font2Reg from 'Assets/Fonts/Catamaran-Regular.ttf';
import { BonTypes } from '../Constants/BonTypes';
import {EmployeType } from 'Pages/Orders/Components/OrderTypes';
import moment from 'moment';
import { styles } from '../Constants/BonStyles';
import { ProductTypes } from 'Pages/Products/Components/ProductTypes';
import i18n from 'i18nextinit';

interface PdfProdExportProps {
    Data : BonTypes ,
    Employe: EmployeType | undefined ,
    dataSource: ProductTypes[] | undefined   
}
Font.register({ family: 'MontserratBold', src: font});
Font.register({ family: 'MontserratRegular', src: fontReg});

Font.register({ family: 'CatamaranBold', src: font2});
Font.register({ family: 'CatamaranRegular', src: font2Reg});
const PdfProdExport: React.SFC<PdfProdExportProps> = ({Data,Employe,dataSource}) => {
    return ( 
        <Document>
        <Page size="A4" >
          <View style={styles.section}>
            {/* <Image source={CompanyLogo} style={styles.image} /> */}
            <Text style={styles.Title}>{i18n.t("Pages.Bon.Pdf.Bon")}</Text>
          </View>
          <View style={styles.Info}>
            <View style={styles.row}>
              <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.BonId")} </Text>
              <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>{Data.BonId}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.Type")}</Text>
              <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>
                  {Data.Type === 'Livr' ? 'Delivery sheet' : 'Return sheet'}
                  
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.Status")}</Text>
              <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>{Data.Status}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.Date")}</Text>
              <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>
                {Data.Date !== undefined ? moment(Data.Date).format('MMMM Do YYYY'):''}
              </Text>
            </View>
          </View>
          <View style={styles.Orders}>
            <Text style={styles.Title}>{i18n.t("Pages.Bon.Pdf.Products")}</Text>
            <View style={styles.table}>

              <View style={styles.tableHeader}>
                
                <View style={styles.idcool}>
                  
                  <Text style={styles.tableCell}>{i18n.t("Pages.Bon.Pdf.ProdId")}</Text>
                </View>
                <View style={styles.tableCol}>
                  
                  <Text style={styles.tableCell}>{i18n.t("Pages.Bon.Pdf.Name")}</Text>
                </View>
                <View style={styles.tableCol}>
                  
                  <Text style={styles.tableCell}>{i18n.t("Pages.Bon.Pdf.Price")}</Text>
                </View>
                <View style={styles.tableCol}>
                  
                  <Text style={styles.tableCell}>{i18n.t("Pages.Bon.Pdf.Quantity")}</Text>
                </View>
              </View>
              {dataSource !== undefined ? dataSource?.map(source =>{
                  return(
                    <View style={styles.tableRow} key={source.ProductId}>
                
                      <View style={styles.idcool}>
                        <Text style={styles.tableCell}>{source.ProductId} </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{source.ProductName}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{source.ProductPrice}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{Data.Num_Prod.find(element => element.ProductId === source.ProductId)?.quantity}</Text>
                      </View>

                    </View>
                  )
              }):''}
              
            </View>
          </View>
          <View style={styles.bottom}>
            <View style={{margin: 10,padding: 10,width : '70%'}}>
              <Text style={styles.Title}>{i18n.t("Pages.Bon.Pdf.BillTo")}</Text>
              <View style={styles.billto}>
                <View style={styles.row}>
                  <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.DistrMan")} :</Text>
                  <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>{Employe?.FirstName + ' ' + Employe?.LastName}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.DistrId")} :</Text>
                  <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>{Employe?.EmplyeId}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.Phone")}</Text>
                  <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>{Employe?.Tel}</Text>
                </View> 
                <View style={styles.row}>
                  <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.Price")}</Text>
                  <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>{Employe?.Price} Dh</Text>
                </View> 
              </View>
              
            </View>
            <View style={styles.price}>
              
              <View style={styles.row}>
                  <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.Total")}</Text>
                  <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>
                    {Data.Price}
                  </Text>
              </View>
            </View>
            
              
          </View>
        </Page>
      </Document>
     );
}
 
export default PdfProdExport;