import {observable  , action , computed} from 'mobx'; 
import { ProductTypes } from 'Pages/Products/Components/ProductTypes';
import { getProducts } from 'Services/Products/ProductCalls';

interface datafetchedType {
    result : ProductTypes[],
    count : number
}
class ProductStore {
    @observable dataSource : ProductTypes[] = [];
    @observable count : number = 0 ;
    @observable dataSize : number = 0 ;
    @action getDataSource = (pageg : number | undefined) => {
        return new Promise ((resolve,reject) =>{
    
            const page = pageg === undefined ? 1 : pageg ;
            getProducts(page).then((data : datafetchedType)=>{
                this.count = data.count ;
                this.dataSource = data.result ;
                if (data.result !== undefined) {
                    this.dataSize = data.result.length ;
                }

                resolve(this.dataSource);
            }).catch(err =>{
                reject(err);
            })
            
        })
    }
    @action UpdateDataSize = (dataSize : number)=>{
        this.dataSize = dataSize ;
    } 
    @computed get 
    getTotal(){
        return this.count ;
    }
    @computed get
    HowManyPosts(){
        return this.dataSize ;
    }
}

const ProductsStore = new ProductStore();
export default ProductsStore ;