
import {Link, useLocation} from 'react-router-dom';
import { ReactComponent as CloseIcon } from "Assets/Svgs/MyProfile/cancel.svg";
import {ReactComponent as Logo} from 'Assets/Icons/logol.svg';
import {ReactComponent as SLogo} from 'Assets/Icons/slogo.svg';
import SmallIcon from 'Assets/Icons/light_wl.png';
import { useState, useEffect } from 'react';
import useViewportSizes from 'use-viewport-sizes';
import CounterComponent, { DataCounter } from './CounterComponent';

export interface SideBarProps {
    AdminRoutes : {
        path: string;
        name: string;
        main: () => JSX.Element;
        icon: () => JSX.Element;
    }[],
    Collapsed: boolean,
    Closed: boolean,
    setClosed: React.Dispatch<React.SetStateAction<boolean>>,
    type ?:string,
    Data: DataCounter | undefined
    setData: React.Dispatch<React.SetStateAction<DataCounter | undefined>>
}
 
const SideBar: React.SFC<SideBarProps> = ({AdminRoutes,Collapsed,Closed,setClosed,type,Data , setData}) => {
    const [ActifRoute , setActif] = useState('');
    let location = useLocation() ;

    

    useEffect(()=>{
            setActif(location.pathname.split('/')[1]);
        
    },[location])

    return (
        <>
        
        
        <div className="navbar-nav">
                <div className='CloseContainer' onClick={()=>setClosed(!Closed)}>
                    <CloseIcon />
                </div>
            
            <div className='IconContainer' >
                {Collapsed && <SLogo  width='25px' />}
                {!Collapsed && <Logo width="150px"  className='Icon' />}
            </div>

            <div className="LinksContainer" style={{marginTop : '10px'}}>
                <ul className="NavContainer">
                   {AdminRoutes.map(route =>(
                       
                    <li key={route.name}><Link className={ActifRoute.toLocaleLowerCase() === route.path.split('/')[1].toLocaleLowerCase() ? "nav-item active" : "nav-item"} to={route.path}><i>{<route.icon/>}</i><span>{route.name}</span></Link></li>
                    ))}  
                </ul>
            </div>
            {type === 'Admin' && (
                <CounterComponent Data={Data} setData={setData}  Collapsed={Collapsed} />
            )}
            
        </div>
        </>
        
     );
}
 
export default SideBar;