import axios from "axios"
import { notification } from 'antd';
import { CallProducts, DeleteProductsTypes } from "Pages/Products/Components/ProductTypes";
import config from 'webpack.config';
import { ProductFields } from "Pages/Products/Constants/Products";
import i18n from "i18nextinit";

// Searched products services
export const getProductsSearchedById = async (productId : string | undefined) =>{
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Products/searchProductsById?productId=${productId}`,{withCredentials : true})
        return response.data ;
    } catch (error) {
        
    }
}

export const getProductsSearchedPage = async (productId : string | undefined,page:number | undefined) =>{
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Products/searchProductsByIdPage?productId=${productId}&page=${page}`,{withCredentials : true})
        return response.data ;
    } catch (error) {
        
    }
}

export const getProducts = async (page : number | undefined) =>{
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Products/getProducts?page=${page}`,{withCredentials : true})
        return response.data ;
    } catch (error) {
        
    }
}

export const getPorductsInStock = async (page : number | undefined) =>{
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Products/getProductsInStock?page=${page}`,{withCredentials : true})
        return response.data ;
    } catch (error) {
        
    }
}
export const getProductById = async (ProdId : string | undefined) =>{
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Products/getById?ProdId=${ProdId}`,{withCredentials : true})
        return response.data ;
    } catch (error) {
        
    }
}

export const EditProductsCall : CallProducts = (Data , setconfirmLoading , setOpen,productStore, setLoading, setDataSource, setPagesize, pagination,setData)=>{
    axios.post(`${config.externals.config.apiUrl}/Content/Products/editProduct?productId=${Data.ProductId}`,Data,{withCredentials : true})
    .then(result =>{
        if (result.status === 200) {
            notification['success']({
                style : {fontFamily : 'CatamaranBold'},
                message:  i18n.t("Services.Success.SuccessMess"),
                description: i18n.t("Services.Success.ProdUpdate"),
            })
            setconfirmLoading(false);
                setOpen(false);
                setLoading(true);
                productStore.getDataSource(pagination).then(()=>{
                    setDataSource(productStore.dataSource);
                    setPagesize(productStore.count);
                }).finally(()=>{
                    setLoading(false);
                });
        } else {
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            setconfirmLoading(false);
            setOpen(false);
            productStore.getDataSource(pagination).then(()=>{
                setDataSource(productStore.dataSource);
                setPagesize(productStore.count);
            }).finally(()=>{
                setLoading(false);
                setData(ProductFields);
            });
        }
    }).catch(err => {
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description: i18n.t("Services.Errors.ErrorDesc"),
        })
        setconfirmLoading(false);
        setOpen(false);
        productStore.getDataSource(pagination).then(()=>{
            setDataSource(productStore.dataSource);
            setPagesize(productStore.count);
        }).finally(()=>{
            setLoading(false);
            setData(ProductFields);
        });
    })
}
export const CreateProduct : CallProducts = (Data , setconfirmLoading , setOpen,productStore, setLoading, setDataSource, setPagesize, pagination,setData,isProdIdValid,setisProdIdValid)=>{
    axios.post(`${config.externals.config.apiUrl}/Content/Products/createProduct`,Data , {withCredentials : true})
    .then(result => {
        if (result.status === 200) {
            notification['success']({
                style : {fontFamily : 'CatamaranBold'},
                message:  i18n.t("Services.Success.SuccessMess"),
                description: i18n.t("Services.Success.ProdSucc"),
            })
                setconfirmLoading(false);
                setOpen(false);
                setLoading(true);
                productStore.getDataSource(pagination).then(()=>{
                    setDataSource(productStore.dataSource);
                    setPagesize(productStore.count);
                }).finally(()=>{
                    setLoading(false);
                    setData(ProductFields);
                });
        }
    })
    .catch(err => {
        setconfirmLoading(false);
        if (err.response.data === 'Internal error') {

            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            
        }else
        {
            if (err.response.data === 'Product already exist') {
                setisProdIdValid(false);
            }
        }
        
    })
}
export const DeleteProducts : DeleteProductsTypes= (Url , setconfirmLoading , setOpen,productStore, setLoading, setDataSource, setPagesize, pagination)=>{
    axios.delete(`${config.externals.config.apiUrl}/Content/Products/deleteProducts?${Url}` ,{withCredentials : true} )
    .then(result => {
        if (result.status === 200) {
            notification['success']({
                style : {fontFamily : 'CatamaranBold'},
                message:  i18n.t("Services.Success.SuccessMess"),
                description: i18n.t("Services.Success.ProdDellMain"),
            })
            setconfirmLoading(false);
                setOpen(false);
                setLoading(true);
                productStore.getDataSource(pagination).then(()=>{
                    setDataSource(productStore.dataSource);
                    setPagesize(productStore.count);
                }).finally(()=>{
                    setLoading(false);
                });
        } else {
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            setconfirmLoading(false);
            setOpen(false);
            productStore.getDataSource(pagination).then(()=>{
                setDataSource(productStore.dataSource);
                setPagesize(productStore.count);
            }).finally(()=>{
                setLoading(false);
            });
        }
    })
    .catch(err => {
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description: i18n.t("Services.Errors.ErrorDesc"),
        })
        console.log(err);
    })
}