import axios from "axios";
import config from 'webpack.config';

export const getDashData = async (Status : string[])=>{
    try {
        let Url = '';
        for await (let stat of Status)
        {
            Url += `Status[]=${stat}&` ;
        }
    
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Orders/getDashData?${Url}`,{withCredentials : true}) ;
        return response.data ;

    } catch (error) {
        return [] ;
    }
}

export const getWidgetData = async () =>{
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Orders/getWidgetData`,{withCredentials : true}) ;
        return response.data ;
    } catch (error) {
        
    }
}