import React, { useState } from "react";
import { observer, inject } from "mobx-react";
import { ReactComponent as AddIcon } from "Assets/Svgs/addIcon.svg";
import FacturesStore from "Store/FacturesStore";
import { Button, Modal, Table } from "antd";
import { FactureDataTypes } from "../Constants/FacturesTypes";
import { FactureFields } from "Pages/Bon/Constants/DataConsts";
import FactureModal from "./FactureModal";
import { CreateFactureAdmin } from "Services/Factures/FactureCalls";
import i18n from "i18nextinit";

interface CreateFactureProps {
    FacturesStore : typeof FacturesStore,
    setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    setDataSource: React.Dispatch<React.SetStateAction<FactureDataTypes[]>>,
    setPagesize: React.Dispatch<React.SetStateAction<number>>,
    pagination: number | undefined
    type ?: string
}
 
const CreateFacture: React.SFC<CreateFactureProps> = inject(
    "FacturesStore"
  )(
    observer( ({FacturesStore , type , setDataSource,setLoading,setPagesize,pagination}) =>  {
        
    const [open, setOpen] = useState(false);
    const [Data ,setData] = useState<FactureDataTypes>(FactureFields)
    const [selectedRowKeys , setSelectedRowKeys] = useState<React.Key[]>([]);
    const [confirmLoading , setconfirmLoading] = useState(false);
    return ( 
      <>
        <div className="CreateOrder" style={{ float: "right" }}>
            <button onClick={() => setOpen(!open)}>
              <AddIcon />
              <span>{i18n.t("Pages.Invoices.Create.Add")}</span>
            </button>
        </div>
        <Modal
            style={{top : '9%'}}
            title={i18n.t("Pages.Invoices.Create.Add")}
            visible={open} 
            destroyOnClose
            width='85vw'
            onCancel={()=>setOpen(!open)}
            bodyStyle={{height: '60vh' , overflowY : 'scroll', overflowX : 'hidden'}}
            footer={[
                <Button key="back" type="default"  onClick={()=>setOpen(!open)}>
                  {i18n.t("Pages.Invoices.Create.cancel")}
                </Button>,
                <Button key="submit" type="primary" loading={confirmLoading}
                  disabled={type === 'Admin' ? (Data.LivrId ==="" || Data.Num_Order.length === 0 ) : (Data.Num_Order.length === 0 )}
                  onClick={()=>{
                    setconfirmLoading(true);
                    CreateFactureAdmin(
                      Data,
                      setconfirmLoading,
                      setLoading,
                      FacturesStore,
                      setDataSource,
                      setPagesize,
                      setOpen,
                      pagination)
                  }}>
                  {i18n.t("Pages.Invoices.Create.create")}
                </Button>,
            ]}
            >
          
          <FactureModal Data={Data} setData={setData} selectedRowKeys={selectedRowKeys} 
          setSelectedRowKeys={setSelectedRowKeys} action='Create' type={type} />

        </Modal>
      </>
     );
}))
 
export default CreateFacture;