
import {EmployeType, OrderModalProps} from './OrderTypes';
import React, { useState , useEffect } from 'react';
import { getAllProducts,getLivr, getLivrExtern } from 'Services/Orders/CreateOrderCalls';
import { ProductTypes } from 'Pages/Products/Components/ProductTypes';
import { FieldProduct, UpsellProduct } from './Consts';
import { getAllClients } from 'Services/Clients/ClientServices';
import { ClientType } from 'Pages/Clients/Constants/ClientTypes';
import ShippiComponent from './NestedComponent/OrderModal/ShippingComponent';
import CustomersDetails from './NestedComponent/OrderModal/CustomersDetails';
import ProductsComponent from './NestedComponent/OrderModal/ProductsComponent';
import ActionComponent from './NestedComponent/OrderModal/ActionComponent';
import i18n from 'i18nextinit';
import RowInputConponent from './NestedComponent/RowSpan';


const OrderModal: React.SFC<OrderModalProps> = ({
  productRows,
  newProduct,
  Data,
  setData,
  setRows,
  handleDataChange,
  updateRowProduct,
  handleAdd,
  handleRemove,
  LivrStatus,
  setLivr,
  type
}) => {
  
  const [Clients , setClients] = useState<ClientType[]>([]);
  const [Client , setCLient] = useState<ClientType>();
  const [loading, setloading] = useState(true);
  const [Employes, setEmployes] = useState<EmployeType[]>();
  const [externEmp,setExtEm] = useState<EmployeType[]>();
  const [Products , setProducts] = useState<ProductTypes[]>([FieldProduct]);
  const [UpsellProducts , setUpsellProducts] = useState<ProductTypes[]>([UpsellProduct]);
  const [isAssigned, setisAssigned] = useState(true);
  
  const CalculatePrice = async ()=>{
    var total = 0 ;
    for await (let product of productRows)
    {
      total += product.price ;
    }
    return total ;

  }

  useEffect(()=>{
    CalculatePrice().then(result =>{
      console.log(result);
      if (!isNaN(result)) {
         setData({...Data , OrderPrice : result});
      }
    })
  },[productRows])

  useEffect(()=>{
    setUpsellProducts(Products?.filter(Product => Product.UpSell == true));
  },[Products])

  useEffect(()=>{
    setCLient(Clients.find(element => element.Client_Id === Data.Client_Id));
  },[Data])

  useEffect(() => {
    getAllProducts().then((response : ProductTypes[])=>{
      setProducts(response);
    }).catch((err) => {console.log(err);setloading(false);});

    getLivr().then((response) => {
        setEmployes(response);
    }).catch((err) => {console.log(err);setloading(false);});

    getLivrExtern().then((response) =>{
      setExtEm(response);
      if((Employes?.find(el => el.EmplyeId === Data.Livr_Id) === undefined && externEmp?.find(el => el.EmplyeId === Data.Livr_Id) === undefined)){
        
        setisAssigned(false)
      }else{
          setisAssigned(true)
      }
    }).catch((err) => {console.log(err);setloading(false);});

    getAllClients().then((result:ClientType[]) =>{
      setClients(result);
      setCLient(result.find(element => element.Client_Id === Data.Client_Id));
      setloading(false);
    }).catch((err) => {console.log(err);setloading(false);});
    
  }, []);




  return (
    <div className="OrderContainer">
      <div className="LeftSide">
        <div className="Details">
          <span className="smallTitle">{i18n.t("Pages.Orders.Details")} :</span>
          <div className="dataFields">
            <div className="Row1">
              {type === "Edit" && <RowInputConponent Title='Source' value={Data?.Source}  />}
              {type === "Create" && <input placeholder={i18n.t("Pages.SalesChannel.SaleModal.Source")} className="Field  Create" type="text" name="Source" value={Data?.Source} onChange={(e) => handleDataChange(e, setData, Data)}
              />}
              <textarea placeholder={i18n.t("Pages.SalesChannel.SaleModal.Notes")} className="Field Create" name="Notes" rows={5} onChange={(e) => handleDataChange(e, setData, Data)} value={Data?.Notes}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div className="MiddleSide">
        <ShippiComponent
          LivrStatus={LivrStatus}
          setLivr={setLivr}
          Assigned={isAssigned}
          Data={Data}
          setData={setData}
          Employes={Employes}
          ExtEmp={externEmp}
          loading={loading}
          handleDataChange={handleDataChange}
        />
      </div>
      <div className="RightSide">
        <CustomersDetails 
          Data={Data}
          setData={setData}
          loading={loading}
          handleDataChange={handleDataChange}
          Client={Client}
          Clients={Clients}
          setCLient={setCLient}
        />
      </div>
      <div className="BottomPart">
        <ProductsComponent
          productRows ={productRows}
          setData ={setData}
          setRows  ={setRows}
          Data  ={Data}
          loading  ={loading}
          updateRowProduct  ={updateRowProduct}
          handleRemove  ={handleRemove}
          handleAdd  ={handleAdd}
          newProduct ={newProduct}
          Products={Products}
          UpsellProducts={UpsellProducts}
        />
          <ActionComponent
          setData ={setData}
          Data  ={Data}
        />
        
      </div>
      
    </div>
  );
};
 
export default OrderModal;