import {useHistory, useLocation } from 'react-router-dom';
import {useEffect , useState} from 'react';
import { Progress } from 'antd';
import { GetCountersData } from 'Services/Auth/AuthCalls';
import i18n from 'i18nextinit';

// Components ----------------------------

export interface DataCounter  {
    isLastPaymentValid : boolean ,
    OrdersCount : number ,
    OrdersCountLimit : number ,
    ExtDelCount : number ,
    ExtDelCountLimit : number ,
    SalesChanCount : number ,
    SalesChanCountLimit : number ,
    StaffCount : number ,
    StaffCountLimit : number ,
    Plan : string,
}
interface CounterComponentProps {
    Collapsed : boolean
    Data: DataCounter | undefined
    setData: React.Dispatch<React.SetStateAction<DataCounter | undefined>>
}
const CounterComponent: React.SFC<CounterComponentProps>  = ({Collapsed,Data , setData}) => {

    const [percentage , setPercentage] = useState<number>();
    let history = useHistory();
    let location = useLocation() ;
    const [Loading , setLoading] = useState<boolean>(true);
    useEffect(()=>{
        GetCountersData().then((result : DataCounter) =>{
            setData(result);
            const Perc = Number((result.OrdersCount / result.OrdersCountLimit)*100);
            setPercentage(Perc);
            setLoading(false);
        })
    },[])
    
    const handleClick = () =>{
        let Ret = location.pathname.split('/')[1];
        history.push(`/myprofile?tab=Pla&from=${Ret}`);
    }

    return ( 

        <>
        {(!Loading && !Collapsed) && (
            <div className='BottomContainer'>
                <div className='Upgrade'>
                    <div className='Item' onClick={()=>handleClick()} >
                        <span style={{fontSize : '14px' , color : '#FFFAE3'}}>{i18n.t("Routes.Orders")} : {Data?.OrdersCount + ' / ' +  Data?.OrdersCountLimit} </span>
                        <Progress
                            strokeColor={{
                                from: '#b376f0',    
                                to: '#6D2EAB',
                            }}
                            percent={percentage}
                            status="active"
                            showInfo={false}
                        />
                    </div>

                </div>
            </div>
        )}
        {(!Loading && Collapsed) && (
            <div className='BottomContainer'>
                <div className='Upgrade' >
                    <div className='Item' style={{height : "1.25rem" }} onClick={()=>handleClick()} >
                        <span style={{fontSize : '14px' , color : '#FFFAE3'}}>{Data?.OrdersCount} </span>
                    </div>

                </div>
            </div>
        )}
        </>
        
     );
}
 
export default CounterComponent;