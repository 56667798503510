import { InputNumber, Select , Space } from 'antd';
import { ProductsComponentProps} from '../../OrderTypes';
import { SelectValue } from 'antd/lib/select';
import { OptionsType, OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { ProductTypes } from 'Pages/Products/Components/ProductTypes';
import { newUpsellProduct } from '../../Consts';
import {ReactComponent as TrashIcon} from 'Assets/Svgs/trash.svg';
import { handleAddUpsell } from '../../../helpers/AdminOrdersHandlers';
import { useEffect } from 'react';
import i18n from 'i18nextinit';


const { Option } = Select;
 
const ProductsComponent: React.SFC<ProductsComponentProps> = ({productRows,setData,setRows , Data , loading , updateRowProduct , handleRemove , handleAdd , newProduct , UpsellProducts , Products }) => {


    
    const HandleQuantityCHange = (value : string | number | null | undefined , index : number) =>{
        if (value !== undefined && value !== null) {
            const Products = [...productRows];
            Products[index].quantity = Number(value) ;
            Products[index].price = Products[index].cost_per_unit * Products[index].quantity ;
            setRows(Products);
            setData({...Data , Products : Products })  
        }
    }

    function onChange(value: SelectValue,option: OptionsType | OptionData | OptionGroupData , index: number,Productss: ProductTypes[]  , upsell : boolean) {

        const Products = [...productRows];
        Products[index].id = value.toString() ;
        Products[index].name = Productss?.find(element => element.ProductId === value)?.ProductName;
        if (upsell) {
          Products[index].cost_per_unit = Productss.filter(element => element.ProductId === value)[0].UpSell_Price;
          Products[index].price = Products[index].cost_per_unit * Products[index].quantity ;
        }else
        {
          Products[index].cost_per_unit = Productss.filter(element => element.ProductId === value)[0].ProductPrice ;
          Products[index].price = Products[index].cost_per_unit * Products[index].quantity ;
        }
          
        setRows(Products);
        setData({...Data , Products : Products });
      }

    return ( 
        <>
            <div className="Products CreateP">
                <span className="smallTitle">{i18n.t("Pages.Orders.Products")} :</span>
                <table id="ListOfProducts">
                <thead>
                    <tr>
                    <th>{i18n.t("Pages.Orders.Product")}</th>
                    <th>{i18n.t("Pages.Orders.Variant")}</th>
                    <th>{i18n.t("Pages.Orders.Quantity")}</th>
                    <th>{i18n.t("Pages.Orders.Cost")}</th>
                    <th>{i18n.t("Pages.Orders.Action")}</th>
                    </tr>
                </thead>
                <tbody>
                    {productRows.map((product, index) => (
                    <tr key={index} className={product.upsell ? 'UpsellRow' : ''}>
                        {product.upsell && (
                            <td>
                                {product.id !== '' &&(
                                    <Select
                                    showSearch
                                    placeholder={i18n.t("Pages.Orders.ProdUpsellPH")}
                                    optionFilterProp="children"
                                    loading={loading}
                                    style={{ width: "200px" }}
                                    dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                                    onChange={(value,option)=>{onChange(value,option,index,Products,true)}}
                                    defaultValue={product.id}
                                    >
                                    {UpsellProducts?.map(product =>(
                                        <Option key={product.ProductId} value={product.ProductId}>{product.ProductName}</Option>
                                    ))}
                                    
                                    </Select>
                                )}
                                {product.id === '' &&(
                                    <Select
                                    showSearch
                                    placeholder={i18n.t("Pages.Orders.ProdUpsellPH")}
                                    optionFilterProp="children"
                                    loading={loading}
                                    style={{ width: "200px" }}
                                    dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                                    onChange={(value,option)=>{onChange(value,option,index,Products,true)}}
                                    >
                                    {UpsellProducts?.map(product =>(
                                        <Option key={product.ProductId} value={product.ProductId}>{product.ProductName}</Option>
                                    ))}
                                    
                                    </Select>
                                )}
                            </td>
                        )}
                        {!product.upsell && (
                        <td>
                            {product.id !== '' &&(
                                <Select
                                showSearch
                                placeholder={i18n.t("Pages.Orders.ProdPH")}
                                optionFilterProp="children"
                                loading={loading}
                                style={{ width: "80%" }}
                                dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                                onChange={(value,option)=>{onChange(value,option,index,Products,false)}}
                                defaultValue={product.id}
                            >
    
                                {Products?.map(product =>(
                                <Option key={product.ProductId} value={product.ProductId}>{product.ProductName}</Option>
                                ))}
                                
                            </Select>
                            )}
                            {product.id === '' &&(
                                <Select
                                showSearch
                                placeholder={i18n.t("Pages.Orders.ProdPH")}
                                optionFilterProp="children"
                                loading={loading}
                                style={{ width: "80%" }}
                                dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                                onChange={(value,option)=>{onChange(value,option,index,Products,false)}}
                            >
    
                                {Products?.map(product =>(
                                <Option key={product.ProductId} value={product.ProductId}>{product.ProductName}</Option>
                                ))}
                                
                            </Select>
                            )}
                        </td>
                        )}
                        <td>
                        <input className="Field Create" type="text" name="variant" value={product.variant}
                            style={{ width: "100px" }}
                            onChange={(e) =>
                            updateRowProduct(
                                index,
                                e,
                                productRows,
                                setRows,
                                setData,
                                Data
                            )
                            }
                        />
                        </td>
                        <td>
                        <Space>
                            <InputNumber 
                            min={1} 
                            name="quantity" 
                            style={{borderRadius : '10px' ,fontFamily: "CatamaranRegular" }}
                            value={product.quantity} 
                            defaultValue={1} 
                            onChange={(value) => HandleQuantityCHange(value , index)} />
                        </Space>
                        </td>
                        <td>
                        <input className="Field Create" type="number" name="cost_per_unit" value={product.cost_per_unit}
                            style={{ width: "100px" }}
                            readOnly
                        />
                        </td>
                        <td>
                        <div className='ic'>
                        <TrashIcon onClick={() => handleRemove(index, productRows, setRows,Data,setData)}
                            style={
                            productRows.length <= 1 ? { cursor: "not-allowed" } : {}
                            }
                        />    
                        </div>
                        
                        </td>
                    </tr>
                    ))}
                </tbody>
                </table>
                <div className='ButtonsContainer'>
                <button className="Add Product" onClick={() => handleAdd(newProduct , setRows , productRows)}
                >
                    {i18n.t("Pages.Orders.AddProd")}
                </button>
                <button className="Add Upsell" onClick={() => handleAddUpsell(newUpsellProduct , setRows)}
                >
                    {i18n.t("Pages.Orders.AddUpProd")}
                </button>
                </div>

                
            </div>
           
        </>
     );
}
 
export default ProductsComponent;