import { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

// Components ----------------------

import { CheckUser } from "Services/Auth/AuthCalls";
import AdminsRoute from "./helpers/AdminRoutes";
import MagasRoutes from "./helpers/MagaRoutes";
import LivrsRoutes from "./helpers/LivrRoutes";
import CallsRoutes from "./helpers/CallRoutes";
import LoadingPage from "Components/LoadingPage";
import MainProfile from "Pages/MyProfile/MainProfile";
import FourO4 from "Components/404";
import LimitReachedModal from "Components/Routes/LimitReachedModal";
import { DataCounter } from "Components/Routes/CounterComponent";

import MainDash from "Pages/Dashboard/MainDash";
import MainOrders from "Pages/Orders/MainOrders";
import MainProducts from "Pages/Products/MainProducts";
import MainBon from "Pages/Bon/MainBon";
import MainFactures from "Pages/Factures/MainFactures";
import MainInventory from "Pages/Inventory/MainInventory";
import MainStaff from "Pages/Staff/MainStaff";
import MainShipping from "Pages/Shipping/MainShipping";
import MainClients from "Pages/Clients/MainClients";
import MainSales from "Pages/SalesChannel/MainSales";
import { ReactComponent as DashBoardIcon } from "Assets/Svgs/dashboardIcon.svg";
import { ReactComponent as ClientsIcon } from "Assets/Svgs/ClientsIcon.svg";
import { ReactComponent as BonIcon } from "Assets/Svgs/BonIcon.svg";
import { ReactComponent as FacturesIcon } from "Assets/Svgs/FacturesIcon.svg";
import { ReactComponent as InventoryIcon } from "Assets/Svgs/InventoryIcon.svg";
import { ReactComponent as OrdersIcon } from "Assets/Svgs/OrdersIcon.svg";
import { ReactComponent as ProductsIcon } from "Assets/Svgs/ProductsIcon.svg";
import { ReactComponent as SalesIcon } from "Assets/Svgs/SalesIcon.svg";
import { ReactComponent as ShippingIcon } from "Assets/Svgs/ShippingIcon.svg";
import { ReactComponent as StaffIcon } from "Assets/Svgs/StaffIcon.svg";
import { ReactComponent as HistoryIcon } from "Assets/Svgs/history.svg";
import { ReactComponent as History1Icon } from "Assets/Svgs/history1.svg";


import i18n from "i18nextinit";

export const PrivateRouter = () => {
  const [Loading, setLoading] = useState(true);
  const [LimitOpen, setLimitOpen] = useState(false);
  const [Data, setData] = useState<DataCounter>();
  const [LimitMess, setLimitMess] = useState("");

  let location = useLocation();
  const [Type, setType] = useState("");
  useEffect(() => {
    console.log(location);
    CheckUser()
      .then((response) => {
        if (response.isSignedIn) {
          let type = response.Type;
          setType(type.toString());
          setLoading(false);
        } else {
          if (window.location.pathname === "/") {
            window.location.assign("/signin");
          } else {
            window.location.assign("/signin?returnTo=" + window.location.href);
          }
        }
      })
      .catch((err) => {
        if (window.location.pathname === "/") {
          window.location.assign("/signin");
        } else {
          window.location.assign("/signin?returnTo=" + window.location.href);
        }
      });
  }, []);
  const AdminRoutes = [
    {
      path: "/dashboard",
      name: i18n.t("Routes.Dashboard"),
      main: () => <MainDash />,
      icon: () => <DashBoardIcon />,
    },
    {
      path: "/orders",
      name: i18n.t("Routes.Orders"),
      main: () => (
        <MainOrders
          setLimitMess={setLimitMess}
          type="Admin"
          setLimitOpen={setLimitOpen}
          setDataCounter={setData}
        />
      ),
      icon: () => <OrdersIcon />,
    },
    {
      path: "/products",
      name: i18n.t("Routes.Products"),
      main: () => (
        <MainProducts
          setLimitMess={setLimitMess}
          type="Admin"
          setLimitOpen={setLimitOpen}
          setDataCounter={setData}
        />
      ),
      icon: () => <ProductsIcon />,
    },
    {
      path: "/bon",
      name: i18n.t("Routes.Sheets"),
      main: () => (
        <MainBon
          setLimitMess={setLimitMess}
          type="Admin"
          setLimitOpen={setLimitOpen}
          setDataCounter={setData}
        />
      ),
      icon: () => <BonIcon />,
    },
    {
      path: "/factures",
      name: i18n.t("Routes.Invoices"),
      main: () => <MainFactures type="Admin" />,
      icon: () => <FacturesIcon />,
    },
    {
      path: "/inventory",
      name: i18n.t("Routes.Inventory"),
      main: () => (
        <MainInventory
          setLimitMess={setLimitMess}
          type="Admin"
          setLimitOpen={setLimitOpen}
          setDataCounter={setData}
        />
      ),
      icon: () => <InventoryIcon />,
    },
    {
      path: "/staff",
      name: i18n.t("Routes.Staff"),
      main: () => (
        <MainStaff setLimitMess={setLimitMess} setLimitOpen={setLimitOpen} />
      ),
      icon: () => <StaffIcon />,
    },
    {
      path: "/shipping",
      name: i18n.t("Routes.Shipping"),
      main: () => (
        <MainShipping setLimitMess={setLimitMess} setLimitOpen={setLimitOpen} />
      ),
      icon: () => <ShippingIcon />,
    },
    {
      path: "/clients",
      name: i18n.t("Routes.Clients"),
      main: () => <MainClients />,
      icon: () => <ClientsIcon />,
    },
    {
      path: "/salechannel",
      name: i18n.t("Routes.Sales"),
      main: () => (
        <MainSales setLimitMess={setLimitMess} setLimitOpen={setLimitOpen} />
      ),
      icon: () => <SalesIcon />,
    },
  ];

  const CallRoutes = [
    {
      path: "/orders",
      name: i18n.t("Routes.Orders"),
      main: () => (
        <MainOrders
          setLimitMess={setLimitMess}
          type="Call"
          setLimitOpen={setLimitOpen}
          setDataCounter={setData}
        />
      ),
      icon: () => <OrdersIcon />,
    },
    {
      path: "/history",
      name: i18n.t("Routes.History"),
      main: () => (
        <MainOrders
          setLimitMess={setLimitMess}
          type="CallH"
          setLimitOpen={setLimitOpen}
          setDataCounter={setData}
        />
      ),
      icon: () => <History1Icon />,
    },
  ];
  const LivrRoutes = [
    {
      path: "/orders",
      name: i18n.t("Routes.Orders"),
      main: () => (
        <MainOrders
          setLimitMess={setLimitMess}
          type="Livr"
          setLimitOpen={setLimitOpen}
          setDataCounter={setData}
        />
      ),
      icon: () => <OrdersIcon />,
    },
    {
      path: "/bon",
      name: i18n.t("Routes.Sheets"),
      main: () => (
        <MainBon
          setLimitMess={setLimitMess}
          type="Livr"
          setLimitOpen={setLimitOpen}
          setDataCounter={setData}
        />
      ),
      icon: () => <BonIcon />,
    },
    {
      path: "/factures",
      name: i18n.t("Routes.Invoices"),
      main: () => <MainFactures type="Livr" />,
      icon: () => <FacturesIcon />,
    },
    {
      path: "/inventory",
      name: i18n.t("Routes.Inventory"),
      main: () => (
        <MainInventory
          setLimitMess={setLimitMess}
          type="Livr"
          setLimitOpen={setLimitOpen}
          setDataCounter={setData}
        />
      ),
      icon: () => <InventoryIcon />,
    },
  ];
  const MagRoutes = [
    {
      path: "/orders",
      name: i18n.t("Routes.Orders"),
      main: () => (
        <MainOrders
          setLimitMess={setLimitMess}
          type="Maga"
          setLimitOpen={setLimitOpen}
          setDataCounter={setData}
        />
      ),
      icon: () => <OrdersIcon />,
    },
    {
      path: "/products",
      name: i18n.t("Routes.Products"),
      main: () => (
        <MainProducts
          setLimitMess={setLimitMess}
          type="Maga"
          setLimitOpen={setLimitOpen}
          setDataCounter={setData}
        />
      ),
      icon: () => <ProductsIcon />,
    },
    {
      path: "/bon",
      name: i18n.t("Routes.Sheets"),
      main: () => (
        <MainBon
          setLimitMess={setLimitMess}
          type="Maga"
          setLimitOpen={setLimitOpen}
          setDataCounter={setData}
        />
      ),
      icon: () => <BonIcon />,
    },
    {
      path: "/inventory",
      name: i18n.t("Routes.Inventory"),
      main: () => (
        <MainInventory
          setLimitMess={setLimitMess}
          type="Maga"
          setLimitOpen={setLimitOpen}
          setDataCounter={setData}
        />
      ),
      icon: () => <InventoryIcon />,
    },
  ];
  return (
    <>
      {Loading && <LoadingPage />}
      {!Loading && (
        <>
          <LimitReachedModal
            setLimitOpen={setLimitOpen}
            LimitOpen={LimitOpen}
            LimitMess={LimitMess}
            setLimitMess={setLimitMess}
          />

          {Type === "Admin" && (
            <>
              {(AdminRoutes.some((el) => el.path === location.pathname) ||
                location.pathname === "/" ||
                location.pathname === "/myprofile") && (
                <Switch>
                  <Route path="/myprofile">
                    <MainProfile Type={Type} />
                  </Route>
                  <AdminsRoute
                    setLimitOpen={setLimitOpen}
                    type={Type}
                    Data={Data}
                    setData={setData}
                    Routes={AdminRoutes}
                  />
                </Switch>
              )}
              {!AdminRoutes.some((el) => el.path === location.pathname) &&
                location.pathname !== "/" &&
                location.pathname !== "/myprofile" && <FourO4 />}
            </>
          )}
          {Type === "Maga" && (
            <>
              {(MagRoutes.some((el) => el.path === location.pathname) ||
                location.pathname === "/" ||
                location.pathname === "/myprofile") && (
                <Switch>
                  <Route path="/myprofile">
                    <MainProfile Type={Type} />
                  </Route>
                  <MagasRoutes
                    setLimitOpen={setLimitOpen}
                    type={Type}
                    Data={Data}
                    setData={setData}
                    Routes={MagRoutes}
                  />
                </Switch>
              )}
              {!MagRoutes.some((el) => el.path === location.pathname) &&
                location.pathname !== "/" &&
                location.pathname !== "/myprofile" && <FourO4 />}
            </>
          )}
          {Type === "Livr" && (
            <>
              {(LivrRoutes.some((el) => el.path === location.pathname) ||
                location.pathname === "/" ||
                location.pathname === "/myprofile") && (
                <Switch>
                  <Route path="/myprofile">
                    <MainProfile Type={Type} />
                  </Route>
                  <LivrsRoutes
                    setLimitOpen={setLimitOpen}
                    type={Type}
                    Data={Data}
                    setData={setData}
                    Routes={LivrRoutes}
                  />
                </Switch>
              )}
              {!LivrRoutes.some((el) => el.path === location.pathname) &&
                location.pathname !== "/" &&
                location.pathname !== "/myprofile" && <FourO4 />}
            </>
          )}
          {Type === "LivrExt" && (
            <>
              {(LivrRoutes.some((el) => el.path === location.pathname) ||
                location.pathname === "/" ||
                location.pathname === "/myprofile") && (
                <Switch>
                  <Route path="/myprofile">
                    <MainProfile Type={Type} />
                  </Route>
                  <LivrsRoutes
                    setLimitOpen={setLimitOpen}
                    type={Type}
                    Data={Data}
                    setData={setData}
                    Routes={LivrRoutes}
                  />
                </Switch>
              )}
              {!LivrRoutes.some((el) => el.path === location.pathname) &&
                location.pathname !== "/" &&
                location.pathname !== "/myprofile" && <FourO4 />}
            </>
          )}
          {Type === "Call" && (
            <>
              {(CallRoutes.some((el) => el.path === location.pathname) ||
                location.pathname === "/" ||
                location.pathname === "/myprofile") && (
                <Switch>
                  <Route path="/myprofile">
                    <MainProfile Type={Type} />
                  </Route>
                  <CallsRoutes
                    setLimitOpen={setLimitOpen}
                    type={Type}
                    Data={Data}
                    setData={setData}
                    Routes={CallRoutes}
                  />
                </Switch>
              )}
              {!CallRoutes.some((el) => el.path === location.pathname) &&
                location.pathname !== "/" &&
                location.pathname !== "/myprofile" && <FourO4 />}
            </>
          )}
        </>
      )}
    </>
  );
};
