import {useEffect} from 'react';
import { Provider } from 'mobx-react';
import ListProducts from './Components/ListProducts';
import ProductStore from 'Store/ProductStore';
import { MainOrdersProps } from 'Pages/Orders/MainOrders';
import i18n from 'i18nextinit';


const MainProducts: React.SFC<MainOrdersProps> = ({type}) => {



    return (
        <>
            <title>{i18n.t("Pages.Products.Title")} | CodScale</title>
            <div className='MainContainer'>
                <Provider productStore={ProductStore}>
                    <ListProducts productStore={ProductStore} type={type}/>
                </Provider>
                
            </div>
        </>
    );
}
 
export default MainProducts;