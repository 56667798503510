import { DataFieldsTypes, EmployeType } from "Pages/Orders/Components/OrderTypes";
import { FactureDataTypes } from "../Constants/FacturesTypes";
import { useState , useEffect} from "react";
import { getLivrById, getOrdersById } from "Services/Bon/BonLivrCalls";
import { Table } from "antd";
import { Ordercolumns } from "Pages/Bon/Constants/Columns";
import { FacturesTypes } from "../Validators/AvaiFacType";
import { PDFDownloadLink } from '@react-pdf/renderer';
import {ReactComponent as DownloadIcon} from 'Assets/Svgs/direct-download.svg';

import PdfExport from "./PdfExport";
import i18n from "i18nextinit";

interface RowFactureModalProps {
    Data: FactureDataTypes
    type ?: string
}

interface OrderFetchedType{
    result : DataFieldsTypes[] ,
    count : number 
}
const RowFactureModal: React.SFC<RowFactureModalProps> = ({Data,type}) => {
    
    const [Employe, setEmployes] = useState<EmployeType>();
    const [Loading, setloading] = useState(true);
    const [dataSource , setDataSource] = useState<DataFieldsTypes[]>();
    const [countOrders , setOcount] = useState<number>();
    
    useEffect(()=>{
        setloading(true);
        getLivrById(Data.LivrId).then((result : EmployeType) =>{
          setEmployes(result);
        }).catch(err =>{
          console.log(err);
        })

        getOrdersById(Data).then((result : OrderFetchedType) =>{
          setDataSource(result.result);
          setOcount(result.count);
          setloading(false);
        }).catch(err =>{
          console.log(err);
        })
        
      },[Data])
    return (
        <div className="BonContainer">

            <div className="LeftSide">
                <span className="smallTitle">{i18n.t("Pages.Bon.Form.Options")} :</span>
                <div className='DataContainer'>
                    
                    <div className="ligne1">
                        <div className='label'>{i18n.t("Pages.Bon.Form.DelMan")}</div>
                        {(Employe !== undefined && Employe !== null && !Loading) &&(
                            <div className="Field"> 
                                {Employe?.FirstName} {Employe?.LastName} | {Employe?.Price} Dh
                                | {Employe?.City}
                            </div>
                        )}
                        {((Employe === undefined || Employe === null) && !Loading) &&(
                            <div className="Field"> 
                                {i18n.t("Pages.Bon.Form.UserDel")}
                            </div>
                        )}
                    </div>
                    <div className="ligne2">
                        <div className='label'>{i18n.t("Pages.Bon.Pdf.Type")} : </div>
                         <span className='Field'>{type === FacturesTypes.Facture ? i18n.t("Pages.Invoices.Modal.Inv") : i18n.t("Pages.Invoices.Modal.InvReq")}</span>
                    </div>
                </div>
            </div>

            <div className="RightSide">
                <>
                <span className="smallTitle">{i18n.t("Pages.Bon.Pdf.Orders")} :</span>
                <Table

                    columns={Ordercolumns}
                    pagination={{ pageSize: 10, total: countOrders }}
                    dataSource={dataSource}
                    loading={Loading}
                    rowKey={(row: DataFieldsTypes) =>
                    row.Num_Order === undefined ? Math.random() : row.Num_Order
                    }
                />
                </>
            </div>
            <div className="Recap">
                <span className='Label'>{Employe?.Type === 'LivrExt' ? `${i18n.t("Pages.Invoices.Modal.TotalDesc1")}(${Employe.Price}) :` : `${i18n.t("Pages.Invoices.Modal.TotalDesc2")}:`}</span>
                <span className='Value' >{Data.Price} Dh</span><br/>
                
            </div>
            <div>

            
                <PDFDownloadLink 
                style={{textDecoration : 'none' , fontFamily :'CatamaranBold'}}
                document={<PdfExport Data={Data} Employe={Employe} dataSource={dataSource}/>}
                fileName={`${Data.FactureId}.pdf`}
                >
                
                {({ blob, url, loading, error }) =>
                    loading ? (
                    <div className='PdfDownload'>
                        <span>{i18n.t("Pages.Bon.Pdf.Loading")}...</span>
                    </div>
                    ): (
                    <div className='PdfDownload' >
                        <DownloadIcon  style={{marginRight : '10px'}}/>
                        <span>{i18n.t("Pages.Bon.Pdf.Download")}</span>
                    </div>
                    )
                }
                </PDFDownloadLink>
          

            </div>
        </div>
      );
}
 
export default RowFactureModal;