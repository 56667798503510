import {observable  , action , computed} from 'mobx'; 
import { DataFieldsTypes } from 'Pages/Orders/Components/OrderTypes';
import { getCallOrders } from 'Services/Orders/CallOrderCalls';
import { getNextOrders } from 'Services/Orders/CreateOrderCalls';

interface datafetchedType {
    result : DataFieldsTypes[],
    count : number
}
class OrderStore {

    // Admin data 
    @observable dataSource : DataFieldsTypes[] = [];
    @observable count : number = 0 ;
    @observable dataSize : number = 0 ;
    @observable loading : boolean = true ;

    @action UpdateDataSize = (dataSize : number)=>{
        this.dataSize = dataSize ;
    } 
    @computed get 
    getTotal(){
        return this.count ;
    }
    @computed get 
    LoadingState(){
        return this.loading ;
    }
    @computed get
    HowManyPosts(){
        return this.dataSize ;
    }

    @action getDataSource = (pageg : number | undefined , status ?: string |undefined) => {
        return new Promise ((resolve,reject) =>{
            this.loading = true ;
            const page = pageg === undefined ? 1 : pageg ;
            getNextOrders(page,status).then((data : datafetchedType) => {
                this.count = data.count ;
                this.dataSource = data.result ;
                this.dataSize = data.result.length ;
                this.loading = false
                resolve(this.dataSource);
            })
            .catch(err =>{
                reject(err);
            })
        })
    }

    // Call center orders 

    @observable CallData : DataFieldsTypes[] = [];
    @observable Callcount : number = 0 ;
    @observable CalldataSize : number = 0 ;
    @observable Callloading : boolean = true ;


    @action getCallData = (page:number|undefined) =>{
        return new Promise((resolve , reject)=>{
            getCallOrders(page).then((data : datafetchedType)=>{
                this.Callcount = data.count ;
                this.CallData = data.result ;
                if (data.result !== undefined) {
                    this.CalldataSize = data.result.length ;

                }

                resolve(this.CallData);
            })
        })
    }

    @action UpdateCallData = (dataSize : number)=>{
        this.CalldataSize = dataSize ;
    } 
    @computed get 
    getCallTotal(){
        return this.Callcount ;
    }
    
    @computed get
    HowManyCallPosts(){
        return this.CalldataSize ;
    }
    
}
const Order = new OrderStore();
export default Order ;