import { FactureDataTypes } from "Pages/Factures/Constants/FacturesTypes";
import { BonTypes } from "./BonTypes";

export const DataFields : BonTypes = {
    BonId : '',
    Num_Order : [],
    Num_Prod : [],
    City : '',
    LivrId : '',
    MagaId : '',
    AdminId : '' ,
    Price : 0 ,
    Type : '',
    Status : ''
}

export const FactureFields : FactureDataTypes = {
    FactureId : '',
    Num_Order : [],
    Status : '',
    AdminId : '',
    LivrId : '',
    type : '',
    Price : 0
}