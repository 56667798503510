import { Input, Select } from "antd";
import i18n from "i18nextinit";
import moment from "moment";
import { SalesTypes } from "../Constants/SalesTypes";



interface SaleRowModalProps {
    Data: SalesTypes | undefined
    setData: React.Dispatch<React.SetStateAction<SalesTypes | undefined>>
    setShopStatus: React.Dispatch<React.SetStateAction<boolean>>
    shopMsg: "Exist" | "Ivalid" | undefined
    isShopValid: boolean
}
// TODO: Add a i18n for the modal

const SaleRowModal: React.SFC<SaleRowModalProps> = ({Data , setData , setShopStatus , shopMsg,isShopValid}) => {
    const { Option } = Select;

    return ( 
        <div className='RowSaleContainer'>
            <div className='fieldCont'>
                    <span className='label' style={{marginRight : '5px'}}>{i18n.t("Pages.SalesChannel.SaleModal.Created")} :</span>
                    <span className='Value'>{moment(Data?.Date).format('MMMM Do YYYY')}</span>
            </div>
            <div className='readOnly' style={{display:"flex",flexDirection:"column"}} >
                <div className='fieldCont'>
                    <div className='label'>{i18n.t("Pages.SalesChannel.SaleModal.Source")}</div>
                    <span className='Value'>{Data?.Source}</span>
                </div>
                {Data?.Source === "YouCan" && (
                    <div className='fieldCont'>
                        <br/>
                        <div className='label'>Spreadsheet Id</div>
                        <span className='Value'>{Data?.SheetId}</span>
                    </div>
                )}
                {Data.Source !== "YouCan" && (
                    <div className='fieldCont'  style={{marginTop:"20px"}} >
                    <div className='label'>{i18n.t("Pages.SalesChannel.SaleModal.Shop")}</div>
                    <Input
                    addonBefore="https://www."
                    name="shopUrl"
                    className={isShopValid ? "" : "inv"}
                    value={Data?.ShopUrl}
                    onChange={(e) => {
                      setShopStatus(true);
                      setData({ ...Data, ShopUrl: e.target.value });
                    }}
                  />
                  {!isShopValid ? (
                    <div className="inv_msg">
                      {shopMsg === "Exist"
                        ? i18n.t("Pages.SalesChannel.SaleModal.InvMsg1")
                        : i18n.t("Pages.SalesChannel.SaleModal.InvMsg2")}
                    </div>
                  ) : null}
                </div>
                )}
                
                
            </div>
            <div className='Editable'>
                {Data.Source !== "YouCan" && (
                    <div className="SecretKey">
                        <label className="label">{i18n.t("Pages.SalesChannel.SaleModal.Secret")}</label>
                        <input
                        type="text"
                        name="secretKey"
                        className="Field normal"
                        value={Data?.Key}
                        onChange={(e)=>{
                            setData({...Data , Key : e.target.value})
                        }}
                        />
                    </div>
                )}
                
                <div className="SecretKey">
                    <label className="label">{i18n.t("Pages.SalesChannel.SaleModal.Status")} :</label>
                    <Select
                        style={{ borderRadius : '5px' , width : '30%' }}
                        dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                        value={Data?.Status}
                        onChange={(value: string) =>
                        setData({...Data , Status : value})
                        }
                    >
                        <Option key="Livraison" value="Active">
                            {i18n.t("Pages.SalesChannel.SaleModal.Active")}
                        </Option>
                        <Option key="Stock" value="Disabled">
                            {i18n.t("Pages.SalesChannel.SaleModal.Disabled")}
                        </Option>

                    </Select>
                </div>
            </div>
        </div>
     );
}
 
export default SaleRowModal;