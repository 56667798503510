import { useEffect } from "react";
import 'Sass/Auth.scss';
import image from 'Assets/res/Shades.png';
import Icon from 'Assets/Icons/codscale-logo.png';
import { useState } from "react";
import { VerifyEmailService } from "Services/Auth/AuthCalls";
import LoadingPage from "Components/LoadingPage";
import i18n from "i18nextinit";

interface EmailVerifProps {
    match : any
}
 
const EmailVerif: React.SFC<EmailVerifProps> = ({match}) => {
    const [loading , setLoanding] = useState(true);
    useEffect(()=>{
        VerifyEmailService(match.params.verifid)
        .then(result => {
            if(result)
            {
                setLoanding(false);
            }else
            {
               window.location.assign("/signin?ref=invalidId");
            }
        })
    },[])
    return (
      <>
        {loading && <LoadingPage />}
        {!loading && (
          <div className="EmailContainer">
            <title>{i18n.t("Pages.Auth.EmailVerif.Title")} | CodScale</title>
            <img src={Icon} alt="codscal logo" className="Icon" width="300px" />
            <div className="PreviewContainer">
              <h2 className="title">{i18n.t("Pages.Auth.EmailVerif.PreviewContainer.Title")}</h2>
              <p className="description">
                {i18n.t("Pages.Auth.EmailVerif.PreviewContainer.description1")}
                <br />
                {i18n.t("Pages.Auth.EmailVerif.PreviewContainer.description2")}
                <br />
                {i18n.t("Pages.Auth.EmailVerif.PreviewContainer.description3")}
              </p>
              <button className="LoginButt" onClick={()=>{
                  window.location.replace('/signin')
              }}>{i18n.t("Pages.Auth.EmailVerif.PreviewContainer.LogButt")}</button>
            </div>
            <img id="bg" src={image} alt="background illustration" />
          </div>
        )}
      </>
    );
}
 
export default EmailVerif;