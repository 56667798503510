import i18n from 'i18nextinit';
import { Provider } from 'mobx-react';
import {useEffect , useState} from 'react';
import StaffStores from 'Store/StaffStore';
import ListStaff from './Components/ListStaff';

export interface MainStaffProps {
    setLimitOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLimitMess: React.Dispatch<React.SetStateAction<string>>
}
 
const MainStaff: React.SFC<MainStaffProps> = ({setLimitMess,setLimitOpen}) => {

    

    return (
        <Provider StaffStore={StaffStores}>
            <title>{i18n.t("Pages.Staff.Title")} | CodScale</title>
            <div className="MainContainer">
                <ListStaff setLimitMess={setLimitMess} setLimitOpen={setLimitOpen} StaffStore={StaffStores}/>
            </div>
        </Provider>
    );
}
 
export default MainStaff;