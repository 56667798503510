import {observer , inject } from 'mobx-react';
import { ListOrderProps } from '../Admin/ListOrders';
import React, {useEffect , useState} from 'react';
import { DataFieldsTypes, newProductTypes } from '../OrderTypes';
import {Columns, DataFields, ListOrderscolumns} from '../Consts';
import { AutoComplete, Button, Modal, Table, TablePaginationConfig } from 'antd';
import RowOrderModal from '../RowOrderModal';
import CallCreateOrder from './CallCreateOrder';
import { CountUnsetOrders, getOrdersByCallId } from 'Services/Orders/CallOrderCalls';
import GetCallOrder from './getCallOrder';
import { HandleConfirm } from 'Pages/Orders/helpers/ClientOrdersHandlers';

import {ReactComponent as RefreshIcon} from 'Assets/Svgs/refreshIcon.svg';
import i18n from 'i18nextinit';
import { getSearchOrders, getSearchOrdersByPage } from 'Services/Orders/CreateOrderCalls';
import {ReactComponent as SearchIcon} from 'Assets/Svgs/search.svg';


const { Option } = AutoComplete;
 
const CallListOrders: React.SFC<ListOrderProps> = inject(
    'orderStore'
  )(
    observer(({orderStore,setLimitOpen,setDataCounter,setLimitMess,Type}) => {
      
    const [NumSearchData,setNumSearchData] = useState<string>('');
    const [SearchedData , setSearchedData]= useState<string[]>([]);
    const [LivrStatus , setLivr] = useState(false);
    
    const [Data , setData ] = useState<DataFieldsTypes>(DataFields);
    const [count , setCount] = useState<number>(0);
    const [pagination , setPagination] = useState<number|undefined>(1);
    const [pageSize , setPagesize] = useState<number>(orderStore.Callcount);
    const [productRows , setRows] = useState<newProductTypes[]>([]);
    const [Loading , setLoading] = useState<boolean>();
    const [Open , setOpen] = useState(false);
    const [dataSource , setDataSource] = useState<DataFieldsTypes[]>(orderStore.CallData);
    const [confirmLoading , setConfirmLoading] = useState<boolean>(false);
    const [refresh,setrefresh] = useState(false);
    
    useEffect(()=>{
        
      if(NumSearchData === '')
      {
        setLoading(true);
        if (Type === "CallH") {

          getOrdersByCallId(1).then(res => {
            setDataSource(res.result);
            setPagesize(res.count);
            setLoading(false);
          });

        } else {
          orderStore.getCallData(1).then(()=>{
            setDataSource(orderStore.dataSource);
            setPagesize(orderStore.count);
            setLoading(false);
          });
        }

      }else{
        handleSearch(1);
      }

      CountUnsetOrders().then(result =>{
        setCount(result)
      }).catch(err => console.log(err));


    },[refresh])

    const HandelRow = (row : DataFieldsTypes )=>{
        setData(row) ;
        setRows(row.Products);
        setOpen(true);
    }
    const onSelectPhone = (value: string) => {
      setNumSearchData(value);
    }

    const onSearchPhone = (value: string) => {
      setNumSearchData(value);
      getSearchOrders(value).then(res => {
        setSearchedData(res.newResult);
      }).catch(err => console.log(err));
    }

    const handleSearch = (pagination:number)=>{
      setLoading(true);
      getSearchOrdersByPage(NumSearchData,pagination).then(res => {
        setDataSource(res.result);
        setPagesize(res.count);
        setLoading(false);
      }).catch(err => console.log(err));
    }

    const handleClearSearch = ()=>{
      setNumSearchData('');
      setSearchedData([]);
      setLoading(true);
      orderStore.getCallData(1).then(()=>{
        setDataSource(orderStore.CallData);
        setPagesize(orderStore.Callcount);
        setLoading(false);
      });

    }
    
    const handleChange = (pagination: TablePaginationConfig) => {
      if(NumSearchData === '')
      {
        
        setPagination(pagination.current);
        setLoading(true);
        if (Type === "CallH") {

          getOrdersByCallId(1).then(res => {
            setDataSource(res.result);
            setPagesize(res.count);
            setLoading(false);
          });
  
        } else {
          orderStore.getCallData(pagination.current).then(()=>{
            setDataSource(orderStore.dataSource);
            setPagesize(orderStore.count);
            setLoading(false);
          });
        }
      }else
      {
        handleSearch(pagination.current);
      }
    }
    
    return ( 
      <>
      <div style={{display:'flex',justifyContent:"space-between",alignItems:"baseline"}}>
        <div className="ButtonsContainer" style={{justifyContent : 'flex-start'}} >
          {Type !== "CallH" &&(
            <> 
              <CallCreateOrder setLimitMess={setLimitMess} setDataCounter={setDataCounter} setLimitOpen={setLimitOpen} orderStore={orderStore} setLoading={setLoading} setDataSource={setDataSource} setPagesize={setPagesize} pagination={pagination} />
              <GetCallOrder setCount={setCount} count={count} orderStore={orderStore} setLoading={setLoading} setDataSource={setDataSource} setPagesize={setPagesize} />
            </>
          )}
           <RefreshIcon width='15px' height='15px' className='refresh' onClick={()=>{setrefresh(!refresh)}} style={{marginLeft : '15px'}}/>
        </div>
        {Type !== "CallH" &&(
          <div className="ButtonsContainer" style={{width: '40%',justifyContent:"flex-end"}}>
            <span className='ClearFilter' onClick={handleClearSearch}>{i18n.t('Pages.Orders.ClearTxt')}</span>
            <AutoComplete
                style={{width: '60%',paddingTop:"5px",paddingBottom:"5px",paddingRight:"10px",paddingLeft:"10px",marginRight:"20px"}}
                onSelect={onSelectPhone}
                onSearch={onSearchPhone}
                value={NumSearchData}
                dropdownStyle={{fontFamily:"CatamaranBold"}}
                placeholder={i18n.t('Pages.Orders.SearchPH')}
              >
                {SearchedData?.map(element =>(
                  <Option key={element} value={element}>
                    {element}
                  </Option>
                ))}
            </AutoComplete>
            <SearchIcon width='1.25rem' height='1.25rem' className='Search' onClick={()=>{handleSearch(1)}}/>
          </div>
        )}
      </div>
      
      <div className="ListContainer">
        <Table
          dataSource={dataSource}
          columns={ListOrderscolumns}
          onChange={(pagination) => handleChange(pagination)}
          pagination={{ pageSize: 10, total: pageSize , showSizeChanger : false }}
          loading={Loading}
          rowKey={(row: DataFieldsTypes) =>
            row.Num_Order === undefined ? Math.random() : row.Num_Order
          }
          onRow={(row) => ({ onClick: () => HandelRow(row) })}
        />
        <Modal
          title={Data?.Num_Order}
          visible={Open}
          destroyOnClose
          onCancel={()=>{
            setOpen(!Open);
            setData(DataFields);
          }}
          style={{ top: "9%" }}
          width="85vw"
          bodyStyle={{ height: "60vh", overflowY: "scroll" }}
          footer={
            Data.ConfirmedBy === undefined || Data.ConfirmedBy === ""
              ? [
                  <Button
                    key="back"
                    type="default"
                    onClick={()=>{
                      setOpen(!Open);
                      setData(DataFields);
                  }}
                  >
                    {i18n.t("Pages.Orders.Create.cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    loading={confirmLoading}
                    onClick={() => {
                      HandleConfirm(
                        Data,
                        setConfirmLoading,
                        setLoading,
                        orderStore,
                        setDataSource,
                        setPagesize,
                        setOpen,
                        pagination,
                        setData,setDataCounter,setLimitOpen,setLimitMess,setLivr
                      );
                    }}
                  >
                    {i18n.t("Pages.Orders.Create.submit")}
                  </Button>,
                ]
              : [
                  (Data.ConfirmedBy !== undefined &&
                    (Data.Status === "Confirmed" || Data.Status === "Declined")) && (
                      <div
                        style={{
                          fontFamily: "CatamaranBold",
                          color: "#2a2a2a",
                        }}
                      >
                        {i18n.t("Pages.Orders.Create.content1")} {Data.Status === "Confirmed" ? i18n.t("Status.Confirmed") : i18n.t("Status.Declined")} {i18n.t("Pages.Orders.Create.by")} : {Data.ConfirmedBy}
                      </div>
                    ),
                ]
          }
        >

          <RowOrderModal
            productRows={productRows}
            Data={Data}
            setData={setData}
            type={Type}
          />
        </Modal>
      </div>
      </>
     );
}))
 
export default CallListOrders;