import {StyleSheet} from '@react-pdf/renderer';

export const styles = StyleSheet.create({

  TitleComp: {
    fontSize : '16px',
    fontFamily :'MontserratBold',
    color : '#2C1338'
  },
  Title: {
    fontSize : '16px',
    fontFamily :'MontserratBold',
  },
  header: {
    
  },
  Info: {
    margin: 10,
    width : '70%',
    padding: 10,
    backgroundColor : '#f2f2f2',
    borderRadius : '10px',
    fontSize : '15px'
  },
  Info2: {
    margin: 10,
    width : '45%',
    padding: 15,
    backgroundColor : '#f2f2f2',
    borderRadius : '10px',
    fontSize : '15px',
    borderStyle : 'dashed',
    borderWidth : 2,
    borderColor:'#2C1338'
  },
  row: {
    flexDirection: 'row',
    justifyContent : 'space-between',
    marginTop : '5px'
  },
  Orders: {
    margin: 10,
    padding: 10,
  },
  bottom: {
    flexDirection: 'row',
    justifyContent : 'space-between',
    margin: 10
  },
  billto: {
    fontSize : '15px',
    backgroundColor : '#f2f2f2',
    borderRadius : '10px',
    padding: 10,
    marginTop : '10px',
  },
  price: {
    margin: 10,
    padding: 10,
    backgroundColor : '#f2f2f2',
    borderRadius : '10px',
    fontSize : '15px',
    width : '35%',
  },
  boldText :{
    fontFamily :'CatamaranBold' ,
    fontSize : '12px'
  },
  section: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
    justifyContent : 'space-between',

  },
  image: {
    width: 100,
  },
  table: {
    marginTop : '10px',
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableHeader: { margin: "auto", flexDirection: "row", fontFamily :'CatamaranBold'},
  idcool :{
    width: "34%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: "22%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: { margin: "auto", marginTop: 5, fontSize: 10 },
}); 