import axios from "axios";
import { notification } from "antd";
import { CreateFactureTypes } from "Pages/Factures/Constants/FacturesTypes";
import config from 'webpack.config';
import i18n from "i18nextinit";
export const getFacturesByType = async (page : number | undefined)=>{
    try {
        const response = await axios.get(
          `${config.externals.config.apiUrl}/Content/Factures/getFacture?page=${page}`,
          { withCredentials: true }
        );
        return response.data;
      } catch (error) {
        return [];
      }
}

export const getShippedOrders = async (status : string | undefined,LivrId ?: string,includeFac ?: boolean) =>{
  try {
      const response = await axios.get(`${config.externals.config.apiUrl}/Content/Orders/getFactOrders?status=${status}&LivrId=${LivrId}&includeFac=${includeFac}`,{withCredentials : true}) ;
      return response.data ;
  } catch (error) {
      return [] ;
  }
}

export const CreateFactureAdmin:CreateFactureTypes = (Data , setconfirmLoading ,setLoading , FactureStore ,setDataSource , setPageSize , setOpen,pagination) => {
  axios.post(`${config.externals.config.apiUrl}/Content/Factures/addFacture`,Data , {withCredentials : true})
  .then(result => {
      if (result.status === 200) {
          notification['success']({
              style : {fontFamily : 'CatamaranBold'},
              message: i18n.t("Services.Success.SuccessMess"),
              description: i18n.t("Services.Success.InvSucc"),
          })
          setconfirmLoading(false);
          setOpen(false);
          setLoading(true);
          FactureStore.getDataSource(1)
          .then(()=>{
              setDataSource(FactureStore.dataSource);
              setPageSize(FactureStore.count);
              setLoading(false);
          })
          .catch(err => console.log(err));

      } else {
          setconfirmLoading(false);
          notification['error']({
              style : {fontFamily : 'CatamaranBold'},
              message: i18n.t("Services.Errors.ErrorOccur"),
              description: i18n.t("Services.Errors.ErrorDesc"),
          })
          
      }
  })
  .catch(err => {
      setconfirmLoading(false);
      notification['error']({
          style : {fontFamily : 'CatamaranBold'},
          message: i18n.t("Services.Errors.ErrorOccur"),
          description: i18n.t("Services.Errors.ErrorDesc"),
      })
     
  })
}