import axios from "axios";
import { notification } from "antd";
import {
  AddToWarehouseTypes,
  DeleteFromWarehouseTypes,
  UpdateWarehouseTypes,
} from "Pages/Inventory/Constants/InventoryTypes";
import config from 'webpack.config';
import i18n from "i18nextinit";

export const GetWarehouseData = async (pageg: number | undefined) => {
  try {
    const response = await axios.get(
      `${config.externals.config.apiUrl}/Content/Warehouse/getWarehouse?&page=${pageg}`,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const AddToWarehouse: AddToWarehouseTypes = (
  Data,
  setconfirmLoading,
  setLoading,
  InventorysStore,
  setDataSource,
  setPageSize,
  setOpen,
  pagination,
  setType ,
  setChecker
) => {
  axios
    .post(`${config.externals.config.apiUrl}/Content/Warehouse/createWarehouse`, Data, { withCredentials: true })
    .then((result) => {
      if (result.status === 200) {
        notification["success"]({
          style: { fontFamily: "CatamaranBold" },
          message: i18n.t("Services.Success.SuccessMess"),
          description:
            i18n.t("Services.Success.ProdSucc"),
        });
        setconfirmLoading(false);
        setOpen(false);
        setType(undefined);
        setChecker(true);
        setTimeout(() => {
          setChecker(false);
          setLoading(true);
        }, 100);
        InventorysStore.getWarehouseDataSource(pagination)
          .then(() => {
            setDataSource(InventorysStore.dataSource);
            setPageSize(InventorysStore.count);
            
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        notification["error"]({
          style: { fontFamily: "CatamaranBold" },
          message: i18n.t("Services.Errors.ErrorOccur"),
          description:
            i18n.t("Services.Errors.ErrorDesc"),
        });
        setconfirmLoading(false);
        setOpen(false);
        setLoading(true);
        InventorysStore.getWarehouseDataSource(pagination)
          .then(() => {
            setDataSource(InventorysStore.dataSource);
            setPageSize(InventorysStore.count);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    })
    .finally(()=>{
      
    })
    .catch((err) => {
      notification["error"]({
        style: { fontFamily: "CatamaranBold" },
        message: i18n.t("Services.Errors.ErrorOccur"),
        description:
          i18n.t("Services.Errors.ErrorDesc"),
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
};

export const DeleteFromWarehouse: DeleteFromWarehouseTypes = (
  Url,
  setconfirmLoading,
  setLoading,
  InventorysStore,
  setDataSource,
  setPageSize,
  setOpen,
  pagination
) => {
  axios
    .delete(`${config.externals.config.apiUrl}/Content/Warehouse/deleteFromWarehouse?${Url}`, {
      withCredentials: true,
    })
    .then((result) => {
      if (result.status === 200) {
        notification["success"]({
          style: { fontFamily: "CatamaranBold" },
          message: i18n.t("Services.Success.SuccessMess"),
          description:
            i18n.t("Services.Success.ProdDell2"),
        });
        setconfirmLoading(false);
        setOpen(false);
        setLoading(true);
        InventorysStore.getWarehouseDataSource(pagination)
          .then(() => {
            setDataSource(InventorysStore.dataSource);
            setPageSize(InventorysStore.count);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        notification["error"]({
          style: { fontFamily: "CatamaranBold" },
          message: i18n.t("Services.Errors.ErrorOccur"),
          description:
            i18n.t("Services.Errors.ErrorDesc"),
        });
        setconfirmLoading(false);
        setOpen(false);
        setLoading(true);
        InventorysStore.getWarehouseDataSource(pagination)
          .then(() => {
            setDataSource(InventorysStore.dataSource);
            setPageSize(InventorysStore.count);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    })
    .catch((err) => {
      notification["error"]({
        style: { fontFamily: "CatamaranBold" },
        message: i18n.t("Services.Errors.ErrorOccur"),
        description:
          i18n.t("Services.Errors.ErrorDesc"),
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
};

export const UpdateProductInWarehouse : UpdateWarehouseTypes = 
(
  Data,
  setconfirmLoading,
  setLoading,
  InventorysStore,
  setDataSource,
  setPageSize,
  setOpen,
  pagination
)=>{
  axios
    .post(`${config.externals.config.apiUrl}/Content/Warehouse/UpdateProductWarehouse`, Data, { withCredentials: true })
    .then((result) => {
      if (result.status === 200) {
        notification["success"]({
          style: { fontFamily: "CatamaranBold" },
          message: i18n.t("Services.Success.SuccessMess"),
          description:
            i18n.t("Services.Success.LivrStockUpdSucc2"),
        });
        setconfirmLoading(false);
        setOpen(false);
        setLoading(true);
        InventorysStore.getWarehouseDataSource(pagination)
          .then(() => {
            setDataSource(InventorysStore.dataSource);
            setPageSize(InventorysStore.count);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        notification["error"]({
          style: { fontFamily: "CatamaranBold" },
          message: i18n.t("Services.Errors.ErrorOccur"),
          description:
            i18n.t("Services.Errors.ErrorDesc"),
        });
        setconfirmLoading(false);
        setOpen(false);
        setLoading(true);
        InventorysStore.getWarehouseDataSource(pagination)
          .then(() => {
            setDataSource(InventorysStore.dataSource);
            setPageSize(InventorysStore.count);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    })
    .catch((err) => {
      notification["error"]({
        style: { fontFamily: "CatamaranBold" },
        message: i18n.t("Services.Errors.ErrorOccur"),
        description:
          i18n.t("Services.Errors.ErrorDesc"),
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
}

export const getProductsNotInWarehouse = async () =>{
  try {
      const response = await axios.get(`${config.externals.config.apiUrl}/Content/Products/getProductsNotInWarehouse`,{withCredentials : true})
      return response.data ;
  } catch (error) {
      
  }
}
