
import {ReactComponent as PendingIcon} from 'Assets/Svgs/ClockIcon.svg';
import {ReactComponent as CheckIcon} from 'Assets/Svgs/check-mark.svg';
import {ReactComponent as PaidIcon} from 'Assets/Svgs/money.svg';
import {ReactComponent as WaintingIcon} from 'Assets/Svgs/time-left.svg';
import {ReactComponent as DeclinedIcon} from 'Assets/Svgs/close.svg';
import {ReactComponent as Packaging} from 'Assets/Svgs/Stock/package.svg';
import {ReactComponent as Delivry} from 'Assets/Svgs/Stock/scooter.svg';
import {ReactComponent as Delivered} from 'Assets/Svgs/Stock/delivered.svg';
import i18n from 'i18nextinit';

export interface StatusComponentProps {
    Type : string
}
 
const StatusComponent: React.SFC<StatusComponentProps> = ({Type}) => {
    
    return ( 
        
        <div>

            {Type === 'Pending' && (
                <div className='pending StatusComponent '>
                    <i> <PendingIcon /> </i>
                    <span>{i18n.t("Status.Pending")}</span>
                </div>
            )}
            {(Type === 'Confirmed' || Type === 'InStock') && (
                <div className='StatusComponent confirmed'>
                <i> <CheckIcon /> </i>
                <span>{Type === 'Confirmed' ? i18n.t("Status.Confirmed") : i18n.t("Status.InStock")}</span>
                </div>
                
            )}
            {(Type === 'Declined' || Type === 'OutStock' ) && (
                <div className='StatusComponent declined'>
                <i> <DeclinedIcon/> </i>
                <span>{Type === 'Declined' ? i18n.t("Status.Declined") : i18n.t("Status.OutStock")}</span>
                </div>
                
            )}

            {Type === 'Packaging' && (
                <div className='pending StatusComponent '>
                    <i> <Packaging /> </i>
                    <span>{i18n.t("Status.Packaging")}</span>
                </div>
            )}

            {Type === 'Delivry' && (
                <div className='pending StatusComponent '>
                    <i> <Delivry style={{width : '18px' , height : '18px'}}/> </i>
                    <span>{i18n.t("Status.Delivery")}</span>
                </div>
            )}

            {Type === 'Shipped' && (
                <div className='confirmed StatusComponent '>
                    <i> <Delivered style={{width : '18px' , height : '18px'}} /> </i>
                    <span>{i18n.t("Status.Shipped")}</span>
                </div>
            )}
            {Type === 'Paid' && (
                <div className='confirmed StatusComponent '>
                    <i> <PaidIcon style={{width : '18px' , height : '18px'}} /> </i>
                    <span>{i18n.t("Status.Paid")}</span>
                </div>
            )}
            {Type === 'Waiting' && (
                <div className='pending StatusComponent '>
                    <i> <WaintingIcon style={{width : '16px' , height : '16px'}}/> </i>
                    <span>{i18n.t("Status.Waiting")}</span>
                </div>
            )}
        </div>
     );
}
 
export default StatusComponent;