import {observable  , action , computed} from 'mobx'; 
import { BonTypes } from 'Pages/Bon/Constants/BonTypes';
import { getBon } from 'Services/Bon/BonLivrCalls';

interface datafetchedType {
    result : BonTypes[],
    count : number
}
class BonStore {
    @observable dataSource : BonTypes[] = [];
    @observable type : string | undefined = '';
    @observable count : number = 0 ;
    @observable dataSize : number = 0 ;
    @action getDataSource = (type : string | undefined , pageg : number | undefined) => {
        return new Promise ((resolve,reject) =>{
            getBon(type,pageg).then((data : datafetchedType)=>{
                this.type = type ;
                this.count = data.count ;
                this.dataSource = data.result ;
                if (data.result !== undefined) {
                    this.dataSize = data.result.length ;   
                }
                resolve(this.dataSource);
            }).catch(err =>{
                reject(err);
            })
            
        })
    }
    @action UpdateDataSize = (dataSize : number)=>{
        this.dataSize = dataSize ;
    } 
    @computed get 
    getTotal(){
        return this.count ;
    }
    @computed get
    HowManyPosts(){
        return this.dataSize ;
    }
}

const BonsStore = new BonStore();
export default BonsStore ;