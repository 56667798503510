import React, { useEffect ,useState } from "react";
import InventorysStore from "Store/InventoryStore";
import { WarehouseTypes } from "../Constants/InventoryTypes";
import InventoryDataTable from "./InventoryDataTable";
import {observer , inject } from 'mobx-react';
import { TablePaginationConfig } from "antd";

export interface WareHouseProps {
    InventorysStore : typeof InventorysStore,
    loading : boolean | undefined,
    setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    dataSource: WarehouseTypes[],
    setDataSource: React.Dispatch<React.SetStateAction<WarehouseTypes[]>>,
    pageSize: number,
    setPagesize: React.Dispatch<React.SetStateAction<number>>,
    pagination: number | undefined,
    setPagination: React.Dispatch<React.SetStateAction<number | undefined>>,
    selectedRowKeys: React.Key[],
    setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
    type ?: string,
    current ?:string
}
 
const WareHouse: React.SFC<WareHouseProps> = inject(
    'InventorysStore'
  )(
    observer(({InventorysStore,loading,setLoading,dataSource,setDataSource,pageSize ,setPagesize,pagination,setPagination,selectedRowKeys,
      setSelectedRowKeys,type,current}) => {

    const handleChange = (pagination: TablePaginationConfig) => {
        setPagination(pagination.current);
        setLoading(true);
        InventorysStore.getWarehouseDataSource(pagination.current).then(()=>{
            setDataSource(InventorysStore.dataSource);
            setPagesize(InventorysStore.count);
        }).finally(()=>{
            setLoading(false);
        });
      }
    return (
      <>
        <div className="ListContainer">
          <InventoryDataTable 
            handleChange={handleChange}
            pagination={pagination}
            setPagesize={setPagesize}
            setDataSource={setDataSource}
            setLoading={setLoading}
            InventorysStore={InventorysStore}
            Loading={loading}
            dataSource={dataSource}
            pageSize={pageSize}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            type={type}
            current={current}
          />
        </div>
      </>
    );
}))
 
export default WareHouse;