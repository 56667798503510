import i18n  from "i18nextinit";

export const validate = (values ) =>
{
    const errors = {};

    if (!values.email) {
        errors.email = i18n.t('Auth.Errors.requiredField');
    }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = i18n.t('Auth.Errors.SignUp.InvEmail');
    }

    if (!values.password) {
        errors.password = i18n.t('Auth.Errors.requiredField');
    }else if (values.password.length < 8) {
        errors.password = i18n.t('Auth.Errors.SignUp.InvPassword');
    }

    if (!values.confirmpass) {
        errors.confirmpass = i18n.t('Auth.Errors.requiredField');
    }else if(values.confirmpass !== values.password)
    {
        errors.confirmpass = i18n.t('Auth.Errors.SignUp.InvConfirmpass');
    }
    
    if (!values.storename) {
        errors.storename = i18n.t('Auth.Errors.requiredField');
    }else if (values.storename.length > 20) {
        errors.storename = i18n.t('Auth.Errors.SignUp.CharcLimit');
    }

    if (!values.firstname) {
        errors.firstname = i18n.t('Auth.Errors.requiredField');
    }else if (values.firstname.length > 20) {
        errors.firstname = i18n.t('Auth.Errors.SignUp.CharcLimit');
    }

    if (!values.lastname) {
        errors.lastname = i18n.t('Auth.Errors.requiredField');
    }else if (values.lastname.length > 20) {
        errors.lastname = i18n.t('Auth.Errors.SignUp.CharcLimit');
    }

    if (!values.birthdate) {
        errors.birthdate = i18n.t('Auth.Errors.requiredField');
    }
    if (!values.tel) {
        errors.tel = i18n.t('Auth.Errors.requiredField');
    }else if (values.tel.length < 9) {
        errors.tel = i18n.t('Auth.Errors.InvPhone');
    }
    
    return errors;
}
export function validate2(values)
{
    const errors = {}
    if (!values.codeverif) {
        errors.phone = i18n.t('Auth.Errors.requiredField');
    }
    return errors ;
}
