import { Add2Staff, DeleteEmployesCall , UpdateExtLivr, UpdateStaff } from "Services/Staff/StaffCalls";
import { AddStaffTypes, DeleteEmpType, handleChangeType, UpdateStaffTypes } from "../Constants/EmployeTypes";


// helpers -----

export const AddStaff:AddStaffTypes = 
(
    Data,setconfirmLoading,setLoading,StaffStore,
    setDataSource,setPagesize,setOpen,pagination,setEmailErr,setData,setLimitOpen,setLimitMess
)=>{
    setconfirmLoading(true);
    Add2Staff(
        Data,setconfirmLoading,setLoading,StaffStore,
        setDataSource,setPagesize,setOpen,pagination,setEmailErr,setData,setLimitOpen,setLimitMess
    )
}

// ----- 

export const DeleteEmployes : DeleteEmpType = async (Ids , setconfirmLoading , setOpen,StaffStore, setLoading, setDataSource, setPagesize, pagination,type) =>{
    try {
        let Url = '';
        setconfirmLoading(true);
        for await (let id of Ids)
        {
            Url += `employeId[]=${id}&` ;
        }
        DeleteEmployesCall(Url , setconfirmLoading , setOpen,StaffStore, setLoading, setDataSource, setPagesize, pagination,type);

    } catch (error) {
        return [] ;
    }
}

export const UpdStaff : UpdateStaffTypes = 
(
    Data,setconfirmLoading,setLoading,StaffStore,
    setDataSource,setPagesize,setOpen,pagination,setLimitOpen,setLimitMess
)=>{
    setconfirmLoading(true);
    if (Data.Type === 'LivrExt') {
        UpdateExtLivr(
            Data,setconfirmLoading,setLoading,StaffStore,
            setDataSource,setPagesize,setOpen,pagination,setLimitOpen,setLimitMess
        )
    }else{
        UpdateStaff(
            Data,setconfirmLoading,setLoading,StaffStore,
            setDataSource,setPagesize,setOpen,pagination,setLimitOpen,setLimitMess
        )   
    }
    
}

// handlers -----

export const handleChange : handleChangeType = (
    pagination,type,setSelected,setPagination,setLoading,setCallData
    ,setPagesize,StaffStore,setMagaData,setMagaSize,setLivrData,setLivrSize
) =>{
    setSelected([]);
    if (type === 'Call') {
        setPagination(pagination.current);
        setLoading(true)
        StaffStore.getStaffData(pagination.current,'Call').then(()=>{
            setCallData(StaffStore.CallCenter)
            setPagesize(StaffStore.Callcount);
        }).finally(()=>{
            setLoading(false);
        });  
    }
    if (type === 'Maga') {
        setPagination(pagination.current);
        setLoading(true)
        StaffStore.getStaffData(pagination.current,'Maga').then(()=>{
            setMagaData(StaffStore.MagaCenter)
            setMagaSize(StaffStore.Magacount);
        }).finally(()=>{
            setLoading(false);
        });   
    }
    if (type === 'Livr') {
        setPagination(pagination.current);
        setLoading(true)
        StaffStore.getStaffData(pagination.current,'Livr').then(()=>{
            setLivrData(StaffStore.LivrCenter)
            setLivrSize(StaffStore.Livrcount);
        }).finally(()=>{
            setLoading(false);
        }); 
    }
}