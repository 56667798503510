import {useState , useEffect} from 'react';
import Header from "Components/Auth/Header";
import IllusComp from "Components/Auth/IllusComp";
import 'Sass/Auth.scss';
import LoadingPage from "Components/LoadingPage";
import {CSSTransition} from 'react-transition-group';
import animationData from 'Animations/Loading2.json';
import Lottie from 'react-lottie';
import { CheckEmail } from 'Services/Auth/AuthCalls';
import { resetPwd, SendCodeEmail, VerifCode } from 'Services/Auth/ResetCalls';
import { notification } from 'antd';
import i18n from 'i18nextinit';

interface ResetPwdProps {
    
}
type errors = 
{
    email : boolean ,
    codeverif : boolean ,
    newpwd : boolean
}
type datatypes = 
{
    email ?: string ,
    codeverif ?: string ,
    newpwd ?: string
}
const ResetPwd: React.SFC<ResetPwdProps> = () => {
    const [Loading , setLoading] = useState(false);
    const [Screen , setScreen] =  useState(0) ;
    const [data,setData] = useState<datatypes>();
    const [switchScreen , setSwitch] = useState(false);

    const [errs , setErrors] = useState<errors>({
        email : false ,
        codeverif : false ,
        newpwd : false
    });

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid",
        },
    };
    const handleCLick = ()=>{
        setLoading(true);
        CheckEmail(data?.email)
        .then(result => {
            if (result) {
                setLoading(false);
                setErrors({...errs , email : true});
            }else
            {
                SendCodeEmail(data).then(response => {
                    if (response) {
                        setLoading(false);
                        setScreen(1);
                        setTimeout(() => {
                            setSwitch(true);
                        }, 200);
                    }else
                    {
                        setLoading(false);
                        console.log(response);
                    }
                })
            }
        })
        
    }
    const handleVerif = ()=>{
        setLoading(true);
        VerifCode(data).then(response => {
            if (response) {
                setLoading(false);
                setScreen(2);
                setTimeout(() => {
                    setSwitch(true);
                }, 200);
            }else
            {
                setLoading(false);
                setErrors({...errs , codeverif : true});
            }
        })
    }
    const HandleBlur = ()=> {
        CheckEmail(data?.email)
        .then(result => {
            if (result) {
                setLoading(false);
                setErrors({...errs , email : true});
            }
        })
    }
    const handlePwdBlur = ()=> {
        if (data?.newpwd !== undefined) {
            if (data?.newpwd?.length < 8) {
                setErrors({...errs , newpwd : true});
            } 
        }else
        {
            setErrors({...errs , newpwd : true});
        }
       
    }

    const handleSubmit = () => {
        setLoading(true);
        if (data?.newpwd !== undefined) {
            if (data?.newpwd?.length < 8) {
                setErrors({...errs , newpwd : true});
                setLoading(false);
            }else
            {
                resetPwd(data).then(response => {
                    if (!response) {
                        setLoading(false);
                        notification['error']({
                            style : {fontFamily : 'CatamaranBold'},
                            message: i18n.t("Pages.Auth.EmailVerif.ResetPwd.Errors.message"),
                            description: i18n.t("Pages.Auth.EmailVerif.ResetPwd.Errors.description"),
                        })
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }
                })
            }
        }else
        {
            setLoading(false);
            setErrors({...errs , newpwd : true});
            
        }
        
    }

    return ( 
        <>
            <div className='AuthContainer'>
                <title>{i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.TabTitle")} | CodScale</title>
            <div className="content split">
                <div className='RespCont'>
                    <div className='RespLogo' onClick={()=>window.location.assign('/')}>
                    </div>
                </div>
                <div className='Signup Container'>
                <div className='SignForm'>
                        <span className='Title'>{i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.TabTitle")}</span>
                        <CSSTransition
                        in={Screen === 0}
                        unmountOnExit
                        timeout={500}
                        classNames="menu-primary"
                        >
                            <div className="menu"  style={{marginTop : '70px'}} >
                                <p style={{fontSize : '15px',color:'#2C1338' ,fontFamily : 'CatamaranRegular'}}>
                                    {i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.Desc1")}<br/><br/>
                                    {i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.Desc2")}
                                </p><br/>
                                    <div className="Email">
                                        <label className="label">{i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.Email")}</label>
                                        <input
                                        type="email"
                                        name="email"
                                        className={
                                            errs.email
                                            ? "Field normal inv"
                                            : "Field normal"
                                        }
                                        onChange={(e)=>{
                                            setErrors({...errs , email : false});
                                            setData({...data , email : e.target.value})
                                        }}
                                        onBlur={HandleBlur}
                                        value={data?.email}
                                        />
                                        {errs.email ? (
                                        <div className="inv_msg">{i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.InvEmail")}</div>
                                        ) : null}
                                    </div>
                                <div className="SigninButton">
                                {(Object.values(errs).includes(true) || data?.email === '') && (
                                    <button className="Button loading" type="button" disabled 
                                    style={{width : '110px' , height : '35px'}}>
                                        Next
                                    </button>
                                )}
                                {Loading &&(
                                    <button className="Button loading" type="button" disabled>
                                    <Lottie options={defaultOptions} height={35} width={110} />
                                    </button>
                                )}
                                {(!Loading && !Object.values(errs).includes(true) && data?.email !== '') &&(
                                    <button type='submit' className="Button next" onClick={handleCLick}>{i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.Next")}</button>
                                )}
                                    
                                </div>
                            </div>

                            

                        </CSSTransition>
                        <CSSTransition
                        in={Screen === 1}
                        unmountOnExit
                        timeout={500}
                        classNames={!switchScreen ? "menu-third" : "menu-primary"}
                        >
                        <div className="menu"  style={{marginTop : '70px'}} >
                                <p style={{fontSize : '16px',color:'#2C1338' ,fontFamily : 'CatamaranRegular'}}>
                                {i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.Verif1")}{data?.email} .<br/> {i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.Verif2")}  </p>
                                <div className="Verif">
                                    <label className="label">{i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.VerifCode")}</label>
                                    <input
                                    type="text"
                                    name="verif"
                                    className={
                                        errs.codeverif
                                        ? "Field normal inv"
                                        : "Field normal"
                                    }
                                    onChange={(e)=>{
                                        setErrors({...errs , codeverif : false});
                                        setData({...data , codeverif : e.target.value})

                                    }}
                                    value={data?.codeverif}
                                    />
                                    {errs.email ? (
                                    <div className="inv_msg">{i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.InvCode")}</div>
                                    ) : null}
                                </div>
                            <div className="SigninButton">
                            {(Object.values(errs).includes(true) || data?.codeverif === '') && (
                                    <button className="Button loading" type="button" disabled
                                    style={{width : '110px' , height : '35px'}}>
                                        {i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.Next")}
                                    </button>
                            )}
                            {Loading &&(
                                <button className="Button loading" type="button" disabled>
                                <Lottie options={defaultOptions} height={35} width={110} />
                                </button>
                            )}
                            {(!Loading && !Object.values(errs).includes(true) && data?.codeverif !== '') &&(
                                <button type='submit' className="Button next" onClick={handleVerif}>{i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.Next")}</button>
                            )}
                                
                            </div>
                        </div>
                        </CSSTransition>
                        <CSSTransition
                        in={Screen === 2}
                        unmountOnExit
                        timeout={500}
                        classNames="menu-third"
                        >
                        <div className="menu"  style={{marginTop : '70px'}}>
                            <p style={{fontSize : '16px',color:'#2C1338' ,fontFamily : 'CatamaranRegular'}}>{i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.NewPwdTitle")}</p>
                                <div className="Verif">
                                    <label className="label">{i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.NewPwd")}</label>
                                    <input
                                    type="password"
                                    name="newpwd"
                                    className={
                                        errs.newpwd
                                        ? "Field normal inv"
                                        : "Field normal"
                                    }
                                    onChange={(e)=>{
                                        setErrors({...errs , newpwd : false});
                                        setData({...data , newpwd : e.target.value});

                                    }}
                                    onBlur={handlePwdBlur}
                                    value={data?.newpwd}
                                    />
                                    {errs.newpwd ? (
                                    <div className="inv_msg">{i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.PwdInv")}</div>
                                    ) : null}
                                </div>
                            <div className="SigninButton">
                                {(Object.values(errs).includes(true) || data?.newpwd === '') && (
                                    <button className="Button loading" type="button" disabled
                                    style={{width : '110px' , height : '35px'}}>
                                        {i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.Submit")}
                                    </button>
                                )}
                                {Loading &&(
                                    <button className="Button loading" type="button" disabled>
                                    <Lottie options={defaultOptions} height={35} width={110} />
                                    </button>
                                )}
                                {(!Loading && !Object.values(errs).includes(true) && data?.newpwd !== '')&&(
                                    <button type='submit' className="Button next" onClick={handleSubmit}>{i18n.t("Pages.Auth.EmailVerif.ResetPwd.Form.Submit")}</button>
                                )}
                            </div>
                        </div>
                        </CSSTransition>
                </div>
                </div>
            </div>
            
            <div className="auth-sidebar split">
                <Header />
                <IllusComp/>
            </div>
            {/* <Footer/> */}
            </div>
    
        </>
     );
}
 
export default ResetPwd;