import React, { useState } from "react";
import { ReactComponent as AddIcon } from "Assets/Svgs/addIcon.svg";
import { EmployeType } from "../Constants/EmployeTypes";
import Modal from "antd/lib/modal/Modal";
import { Button } from "antd";
import EmployeModal from "./EmloyeModal";
import { inject, observer } from "mobx-react";
import StaffStores from "Store/StaffStore";
import { AddStaff } from "../Helpers/EmployesHandlers";
import i18n from "i18nextinit";

interface AddToStaffProps {
  StaffStore : typeof StaffStores
  setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>,
  setCallData: React.Dispatch<React.SetStateAction<EmployeType[]>>,
  setPagesize: React.Dispatch<React.SetStateAction<number>>
  setMagaData : React.Dispatch<React.SetStateAction<EmployeType[]>>  
  setMagaSize: React.Dispatch<React.SetStateAction<number>>
  setLivrData: React.Dispatch<React.SetStateAction<EmployeType[]>>
  setLivrSize: React.Dispatch<React.SetStateAction<number>>
  pagination: number | undefined,
  setLimitOpen: React.Dispatch<React.SetStateAction<boolean>>
  setLimitMess: React.Dispatch<React.SetStateAction<string>>
}
 
const AddToStaff: React.SFC<AddToStaffProps> =  inject(
  'StaffStore'
)(
  observer(({
    StaffStore,setLoading,setCallData,setPagesize,
    setMagaData,setMagaSize,setLivrData,setLivrSize,pagination,setLimitMess,setLimitOpen
  }) =>  {
    const [open, setOpen] = useState(false);
    const [Data , setData] = useState<EmployeType>();
    const [EmailErr , setEmailErr] = useState<boolean>(false);
    const [confirmLoading, setconfirmLoading] = useState(false);
    const disabledButton : boolean = 
    (
      Data?.Email === undefined || 
      Data?.FirstName === undefined || Data?.LastName === undefined ||
      Data?.Password === undefined || Data?.Tel === undefined ||
      Data?.Type === undefined 
    )
    const disabledButton2 : boolean = 
    (
      Data?.City === '' || Data?.Email === '' || 
      Data?.FirstName === '' || Data?.LastName === '' ||
      Data?.Password === '' || Data?.Tel === '' ||
      Data?.Type === '' 
    )

    const HandleAdd = ()=>{
        if (Data?.Type  ==='Livr') {
          AddStaff(Data,setconfirmLoading,setLoading,StaffStore,setLivrData,setLivrSize,setOpen,pagination,setEmailErr,setData,setLimitOpen,setLimitMess);
        }
        if (Data?.Type  ==='Maga') {
          AddStaff(Data,setconfirmLoading,setLoading,StaffStore,setMagaData,setMagaSize,setOpen,pagination,setEmailErr,setData,setLimitOpen,setLimitMess);
          
        }
        if (Data?.Type  ==='Call') {
          AddStaff(Data,setconfirmLoading,setLoading,StaffStore,setCallData,setPagesize,setOpen,pagination,setEmailErr,setData,setLimitOpen,setLimitMess);
          
        }
    }
    return ( 
        <>
            <div className="CreateOrder" style={{ float: "right" }}>
                <button onClick={() => setOpen(!open)}>
                <AddIcon />
                <span>{i18n.t("Pages.Staff.Create.Add")}</span>
                </button>
            </div>
            <Modal
            title={i18n.t("Pages.Staff.Create.Add")}
            destroyOnClose
            visible={open}
            onCancel={() => {
              setOpen(!open);
              setData(undefined);
            }}
            onOk={()=>HandleAdd()}
            footer={[
              <Button key="back" type="default" onClick={() => setOpen(!open)}>
                {i18n.t("Pages.Clients.Create.cancel")}
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={confirmLoading}
                disabled={disabledButton || disabledButton2}
                onClick={()=>HandleAdd()}
              >
                {i18n.t("Pages.Clients.Create.Add")}
              </Button>,
            ]}
            >
            <EmployeModal type='Employe' EmailErr={EmailErr} Data={Data} setData={setData} setEmailErr={setEmailErr} />
          </Modal>
        </>
     );
}))
 
export default AddToStaff;