import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import RouterConfig from 'Routers/RouterConfig';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import './i18nextinit';

// Import the ant.less file in your project
import 'Sass/antd.css';
const Hello = ()=>{
  return(
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <RouterConfig/>
      </BrowserRouter>
    </I18nextProvider >
  )
}
ReactDOM.render(<Hello/>,document.getElementById('root'));


