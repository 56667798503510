import { Button, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import i18n from "i18nextinit";
import React, { useState } from "react";
import { ConfirmOrder } from "Services/Bon/BonLivrCalls";
import BonsStore from "Store/BonStore";
import { BonTypes } from "../Constants/BonTypes";
import { DataFields } from "../Constants/DataConsts";
import RowModal from "./RowModal";
export interface DataTableProps {
  handleChange: (pagination: TablePaginationConfig) => void ,
  pagination : number | undefined ,
  BonStore: typeof BonsStore;
  DataTableColumns: ColumnsType<BonTypes> | undefined;
  Loading: boolean | undefined;
  dataSource: BonTypes[];
  pageSize: number;
  setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setDataSource: React.Dispatch<React.SetStateAction<BonTypes[]>>;
  setPagesize: React.Dispatch<React.SetStateAction<number>>;
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
  type ?: string

}

const DataTable: React.SFC<DataTableProps> = ({
  handleChange,
  pagination,
  BonStore,
  DataTableColumns,
  Loading,
  dataSource,
  pageSize,
  setLoading,
  setDataSource,
  setPagesize,
  setSelectedRowKeys,
  selectedRowKeys,
  type
}) => {

  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [Data, setData] = useState<BonTypes>(DataFields);
  const [Open, setOpen] = useState(false);

  const HandelRow = (row: BonTypes) => {
    setData(row);
    setOpen(true);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[],selectedRows: BonTypes[]) => {
      setSelectedRowKeys(selectedRowKeys);
    } 
  };

  return (
    <>
      <Table
        rowSelection={type === 'Admin'?{
          type: "checkbox",
          ...rowSelection,
        }:undefined}
        style={{ marginTop: "15px" , overflowX:"auto"}}
        loading={Loading}
        onChange={(pagination) => handleChange(pagination)}
        dataSource={dataSource}
        pagination={{ pageSize: 10, total: pageSize }}
        columns={DataTableColumns}
        rowKey={(row: BonTypes) =>
          row.BonId === undefined ? Math.random() : row.BonId
        }
        onRow={(row) => ({ onClick: () => HandelRow(row) })}
      />
      <Modal
        title={Data?.BonId}
        visible={Open}
        destroyOnClose
        onCancel={()=>{
          setOpen(!Open);
          setData(DataFields);
        }}
        style={{ top: "9%" }}
        width="85vw"
        bodyStyle={{ height: "60vh", overflowY: "auto" }}
        footer={
          (Data.Status === "Pending" &&  (type==='Admin' || type==='Livr' || type==='LivrExt'))
            ? [
                <Button
                  key="back"
                  type="default"
                  onClick={() => setOpen(!Open)}
                >
                  {i18n.t("Pages.Bon.Create.cancel")}
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={confirmLoading}
                  onClick={() =>
                    ConfirmOrder(
                      
                      Data,
                      setConfirmLoading,
                      BonStore,
                      setLoading,
                      setOpen,
                      dataSource,
                      setDataSource,
                      pageSize,
                      setPagesize,
                      setData,
                      pagination,
                    )
                  }
                >
                  {i18n.t("Pages.Bon.Create.confirm")}
                </Button>,
              ]
            : null
        }
        
      >
        <RowModal Data={Data} />
      </Modal>
    </>
  );
};

export default DataTable;
