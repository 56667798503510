import { AdminType } from "Components/Constants/UserTypes";
import i18n from "i18nextinit";

interface DelAccountProps {
    EditedData: AdminType
    setEditedData: React.Dispatch<React.SetStateAction<AdminType>>
    IsPasswd: boolean
    setIsPasswd: React.Dispatch<React.SetStateAction<boolean>>
}
 
const DelAccount: React.SFC<DelAccountProps> = ({
    EditedData,setEditedData,IsPasswd,setIsPasswd
}) => {
    return ( 
        <>
            <p>{i18n.t("Pages.MyProfile.MyAccount.DelAccount.Paragraph")}</p>
            <div className="Field1" style={{width : '100%'}}>
                <label className="label">{i18n.t("Pages.MyProfile.MyAccount.DelAccount.LabelCurr")}</label>
                <input
                    type="password"
                    name="password"
                    className={
                        !IsPasswd
                        ? "Field normal inv"
                        : "Field normal"
                    }
                    onChange={(e)=>{
                        setEditedData({...EditedData , Password : e.target.value})
                        setIsPasswd(true);
                    }}
                    value={EditedData.Password}
                />
            </div>
            {!IsPasswd ? (
                <div className="inv_msg">{i18n.t("Pages.MyProfile.MyAccount.DelAccount.InvCurrLabel")}</div>
            ) : null}
            
        </>
     );
}
 
export default DelAccount;