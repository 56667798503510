import {observable  , action , computed} from 'mobx'; 
import { EmployeType } from 'Pages/Staff/Constants/EmployeTypes';
import { GetEmploye } from 'Services/Staff/StaffCalls';

interface EmployefetchedType {
    result : EmployeType[],
    count : number
}

class StaffStore {

    // Call center  ----------------------
    @observable CallCenter : EmployeType[]  = [];
    @observable Callcount : number = 0 ;
    @observable CalldataSize : number = 0 ;

    // Magasinier  ----------------------
    @observable MagaCenter : EmployeType[]  = [];
    @observable Magacount : number = 0 ;
    @observable MagadataSize : number = 0 ;
    
    // Livreur  ----------------------
    @observable LivrCenter : EmployeType[]  = [];
    @observable Livrcount : number = 0 ;
    @observable LivrdataSize : number = 0 ;

    // Livreur externe  ----------------------
    @observable LivrExtData : EmployeType[]  = [];
    @observable LivrExCount : number = 0 ;
    @observable LivrExDataSize : number = 0 ;

    @action getStaffData = (pageg : number | undefined,type : string | undefined) => {
        return new Promise ((resolve,reject) =>{
            GetEmploye(pageg,type).then((response : EmployefetchedType)=>{
                if (type === 'Call') {
                    this.CallCenter = response.result  ;
                    this.Callcount = response.count ;
                    if (response.result !== undefined) {
                        this.CalldataSize = response.result.length
                    }
                    resolve(this.CallCenter);
                }
                if (type === 'Maga') {
                    this.MagaCenter = response.result  ;
                    this.Magacount = response.count ;

                    if (response.result !== undefined) {
                        this.MagadataSize = response.result.length
                    }

                    resolve(this.MagaCenter);
                }
                if (type === 'Livr') {
                    this.LivrCenter = response.result  ;
                    this.Livrcount = response.count ;
                    if (response.result !== undefined) {
                        this.LivrdataSize = response.result.length
                    }
                    resolve(this.LivrCenter);
                }
                if (type === 'LivrExt') {
                    this.LivrExtData = response.result  ;
                    this.LivrExCount = response.count ;
                    if (response.result !== undefined) {
                        this.LivrExDataSize = response.result.length
                    }
                    resolve(this.LivrExtData);
                }
            }).catch(err =>{
                reject(err);
            })
            
        })
    }
    
    // ---------------------------------------------


    @computed get
    HowManyLivr(){
        return this.LivrdataSize ;
    }
    @computed get
    HowManyCall(){
        return this.CalldataSize ;
    }
    @computed get
    HowManyMaga(){
        return this.MagadataSize ;
    }
    
    // ---------------------------------------------


}

const StaffStores = new StaffStore();
export default StaffStores ;