import  React, { useEffect, useState } from 'react';
import { List, Modal, Button, Skeleton } from "antd";
import { UpgradeSUb } from 'Services/Payments/PaymentCalls';
import { notification } from 'antd';
import { Plans } from '../../../Constants/PLANS';
import { loadStripe } from "@stripe/stripe-js";
import {useStripe, useElements, CardElement , Elements} from '@stripe/react-stripe-js';
import {externals} from 'webpack.config';

import CardInput from 'Pages/Payments/Components/CardInput';
import axios from 'axios';
import i18n from 'i18nextinit';


// const PUBLIC_KEY = "pk_live_a3k3YuSLjHnYmvSTbwtJgZAa";
const PUBLIC_KEY = "pk_test_FUo5Zuk3BiQWMZJjNdznbcxO";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

interface IPreviewSubscriptionProps {
    open: boolean,
    setopen: React.Dispatch<React.SetStateAction<boolean>>
    previewdata: any,
    priceId: string | undefined,
    PopConfirm: boolean,
    setPopConfirm: React.Dispatch<React.SetStateAction<boolean>>
    loadingData: boolean | undefined
    Provision: (priceId: string|undefined) => void
    Screen: number | undefined,
}

const  PreviewSubscription: React.SFC<IPreviewSubscriptionProps> =  ({open,setopen,previewdata,priceId,PopConfirm,setPopConfirm,loadingData,Provision,Screen}) => {

    const [Errors , setError] = useState({Card : false , Plan : false});
    const [CardErrMsg , setCardErr] = useState<string | undefined>('');
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async () => {

        setPopConfirm(true);
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          setPopConfirm(false);
          return;
        }
    
        const cardElement = elements.getElement(CardElement);
        if (cardElement) {
    
            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
          
            if (result.error) {
                setError({...Errors , Card : true});
                setCardErr(result.error.message);
                setPopConfirm(false);
            } else {
                const BodyData = {payment_method :  result.paymentMethod.id ,priceId :  priceId} ;
                
                const res = await  axios.post(`${externals.config.apiUrl}/Auth/upgradeSub`,BodyData,{withCredentials : true}) ;

                // eslint-disable-next-line camelcase
                const {client_secret, status} = res.data;
          
                if (status === 'requires_action') {
                  stripe.confirmCardPayment(client_secret).then(function(result) {
                    if (result.error) {
                        notification['error']({
                            style : {fontFamily : 'CatamaranBold'},
                            message: i18n.t("Pages.MyProfile.MyAccount.Plans.Perview.Notification.ErrorMess"),
                            description:
                            i18n.t("Pages.MyProfile.MyAccount.Plans.Perview.Notification.ErrorDesc"),
                        });
                        setPopConfirm(false);
                        console.log(result.error);
                        setCardErr(i18n.t("Pages.MyProfile.MyAccount.Plans.Perview.Notification.CardErr"));
                      // Display error message in your UI.
                      // The card was declined (i.e. insufficient funds, card has expired, etc)
                    } else {
                      notification["success"]({
                          style: { fontFamily: "CatamaranBold" },
                          message: i18n.t("Pages.MyProfile.MyAccount.Plans.Perview.Notification.SuccMess"),
                          description:
                            i18n.t("Pages.MyProfile.MyAccount.Plans.Perview.Notification.SuccDesc"),
                      });
                      setTimeout(() => {
                          window.location.reload();
                      }, 1000);
                      console.log('You got the money!');
                      // Show a success message to your customer
                    }
                  });
                } else {
                  notification["success"]({
                      style: { fontFamily: "CatamaranBold" },
                      message: i18n.t("Pages.MyProfile.MyAccount.Plans.Perview.Notification.SuccMess"),
                      description:
                        i18n.t("Pages.MyProfile.MyAccount.Plans.Perview.Notification.SuccDesc"),
                  });
                  setTimeout(() => {
                      window.location.reload();
                  }, 1000);
                  console.log('You got the money!');
                  // No additional information was needed
                  // Show a success message to your customer
                }
            
            }
        }
    }
  return (
      
    <Modal
        title={i18n.t("Pages.MyProfile.MyAccount.Plans.Perview.Modal.Title")}
        visible={open} 
        destroyOnClose 
        onCancel={()=>{
            setopen(!open);
        }}
        footer={[
            <Button key="back" type="default"  
            onClick={()=>{
                setopen(!open);
            }}
            >
            {i18n.t("Pages.MyProfile.MyAccount.Plans.Perview.Modal.cancel")}
            </Button>,
            <Button key="submit" type="primary" loading={PopConfirm} onClick={()=>{
                if (Screen === 0) {
                    handleSubmit()
                }else
                {
                    setPopConfirm(true);
                    UpgradeSUb({ priceId: priceId })
                    .then((result) => {
                        notification["success"]({
                        style: { fontFamily: "CatamaranBold" },
                        message: i18n.t("Pages.MyProfile.MyAccount.Plans.Perview.Notification.SuccMess"),
                        description:
                             i18n.t("Pages.MyProfile.MyAccount.Plans.Perview.Notification.SuccDesc"),
                        });
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                        setPopConfirm(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        setPopConfirm(false);
                    });
                }
                
            }}>
            {i18n.t("Pages.MyProfile.MyAccount.Plans.Perview.Modal.save")}
            </Button>,
        ]}>
            <>
                <Skeleton loading={loadingData} active round={true}>
                {( !loadingData && Screen === 0) && (
                    
                         <div className="CardContainer">
                            <span style={{fontFamily:'CatamaranBold',fontSize:'1.25rem',color:'#355070'}}>{i18n.t("Pages.MyProfile.MyAccount.Plans.Perview.Form.CardInfo")}</span>
                            <div
                            className={Errors.Card ? "Field normal inv" : "Field normal"}
                            style={{ marginTop: "10px" }}
                            >
                                <CardInput Errors={Errors} setError={setError} />
                            </div>
                            {Errors.Card ? <div className="inv_msg">{CardErrMsg}</div> : null}
                        </div>
                )}
                {(previewdata !== undefined && !loadingData && Screen === 1) && (
                    <div>
                         <span style={{fontFamily:'CatamaranBold',fontSize:'1.25rem',color:'#355070'}}>{i18n.t("Pages.MyProfile.MyAccount.Plans.Perview.Form.SubTitle")}{Plans.find(el => el.Plan === priceId)?.Name} </span>
                         <List
                            itemLayout="horizontal"
                            style={{marginTop : '10px'}}
                            >
                                <List.Item style={{fontFamily : 'CatamaranBold',fontSize : '15px'}}>
                                    {'✍ '}{i18n.t("Pages.MyProfile.MyAccount.Plans.Perview.Form.refund1")}  {Math.abs(Number(previewdata.lines.data[0].amount / 100)) + ' $ ' + `(${previewdata.lines.data[0].description})`} 
                                </List.Item>
                                <List.Item style={{fontFamily : 'CatamaranBold',fontSize : '15px'}}>
                                    {'✍ '}{i18n.t("Pages.MyProfile.MyAccount.Plans.Perview.Form.refund2")} {Math.abs(Number(previewdata.lines.data[1].amount / 100)) + ' $ ' + `(${previewdata.lines.data[0].description})`}
                                </List.Item>
                                
                        </List>
                    </div>
                )}
                </Skeleton>
                
            </>
    </Modal>

  );
};

export default PreviewSubscription;
