import { TablePaginationConfig } from "antd";
import { EmployeType } from "../Constants/EmployeTypes";
import { ColumnsType } from "antd/lib/table";
import StaffDataTable from "./StaffDataTable"
import StaffStores from "Store/StaffStore";

export interface EmployeProps {
    StaffStore : typeof StaffStores
    CallData: EmployeType[] ,
    pageSize: number,
    type : string ,
    Loading: boolean | undefined
    handleChange: (pagination: TablePaginationConfig,type : string) => void
    setSelected: React.Dispatch<React.SetStateAction<React.Key[]>>
    selectedRowKeys: React.Key[]
    StaffTableColumns: ColumnsType<EmployeType> | undefined
    setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    setCallData: React.Dispatch<React.SetStateAction<EmployeType[]>>,
    setPagesize: React.Dispatch<React.SetStateAction<number>>
    setMagaData : React.Dispatch<React.SetStateAction<EmployeType[]>>  
    setMagaSize: React.Dispatch<React.SetStateAction<number>>
    setLivrData: React.Dispatch<React.SetStateAction<EmployeType[]>>
    setLivrSize: React.Dispatch<React.SetStateAction<number>>
    pagination: number | undefined
    setLimitOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setLimitMess: React.Dispatch<React.SetStateAction<string>>
}
 
const Employe: React.SFC<EmployeProps> = ({
    CallData , pageSize,type,Loading,handleChange,setSelected,selectedRowKeys,StaffTableColumns,
    StaffStore,setLoading,setCallData,setPagesize,setMagaData,setMagaSize,setLivrData,setLivrSize,pagination,setLimitOpen,setLimitMess
    }) => {
    
    return ( 
        <div className="ListContainer">
            <StaffDataTable 
                setLimitMess={setLimitMess} setLimitOpen={setLimitOpen}
                StaffTableColumns={StaffTableColumns} selectedRowKeys={selectedRowKeys} setSelected={setSelected} type={type}
                handleChange={handleChange}  CallData={CallData} pageSize={pageSize} Loading={Loading}
                StaffStore={StaffStore} setLoading={setLoading}  setCallData={setCallData}setPagesize={setPagesize}
                setMagaData={setMagaData}setMagaSize={setMagaSize} setLivrData={setLivrData} setLivrSize={setLivrSize} pagination={pagination}
            />
        </div>
     );
}
 
export default Employe;