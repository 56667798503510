export interface RowInputConponentProps {
    Title : string , 
    value : string | number | undefined,
    type ? : string
}
 
const RowInputConponent: React.SFC<RowInputConponentProps> = ({Title,value,type}) => {
    const CssValue = type === 'Address' ? '100%' : '50%' ;
    return ( 
        <div style={{ marginRight: "10px" , width : CssValue ,fontFamily : 'CatamaranBold' }}>
                <span style={{ color : '#355070'}}>{Title}</span><br/>
                <span style={{fontFamily : 'CatamaranRegular' , color : '#2C1338'}} >{value}</span>
        </div>
     );
}
 
export default RowInputConponent;