import { CreateTypes } from "../Admin/CreateOrder";
import { Modal, Button } from 'antd';
import {useState} from 'react';
import { DataFieldsTypes, newProductTypes } from '../OrderTypes';
import {newProduct , DataFields} from '../Consts';
import OrderModal from "../OrderModal";
import {ReactComponent as AddIcon } from 'Assets/Svgs/addIcon.svg';
import {handleDataChange , updateRowProduct ,handleAdd , handleRemove} from '../../helpers/AdminOrdersHandlers'
import { HandleOk } from "Pages/Orders/helpers/ClientOrdersHandlers";
import i18n from "i18nextinit";

 
const CallCreateOrder: React.SFC<CreateTypes> = ({orderStore ,setLoading, setDataSource , setPagesize, pagination,setLimitOpen,setDataCounter,setLimitMess}) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading , setconfirmLoading] = useState(false);
    const [LivrStatus , setLivr] = useState(false);
    const [productRows , setRows] = useState<newProductTypes[]>([newProduct]);
    const [Data , setData] = useState<DataFieldsTypes>(DataFields);
    const reset = {    id : '',
        upsell : false,
        cost_per_unit : 0,
        name : '',
        variant : '',
        quantity : 0,
        price : 0
    }
    return ( 
    <div className='CreateOrder'>
        <button onClick={()=>setOpen(!open)}><AddIcon/><span>{i18n.t("Pages.Orders.Create.Title")}</span></button>
        <Modal 
        style={{top : '9%'}}
        title={i18n.t("Pages.Orders.Create.Title")}
        visible={open} 
        width='85vw'
        destroyOnClose 
        onCancel={()=>{
            setOpen(!open);
            setData(DataFields);
            setRows([{    id : '',
                            upsell : false,
                            cost_per_unit : 0,
                            name : '',
                            variant : '',
                            quantity : 0,
                            price : 0
            }]);
        }}
        bodyStyle={{height: '60vh' , overflowY : 'scroll'}}
        footer={[
            <Button key="back" type="default"  onClick={()=>{
                setOpen(!open);
                setData(DataFields);
                setRows([reset]);
            }}>
            {i18n.t("Pages.Orders.Create.cancel")}
            </Button>,
            <Button key="submit" type="primary" loading={confirmLoading}
            disabled={(
                Data.Address ==='' || Data.City ==='' || Data.Client_Id ==='' || Data.Livr_Id ==='' || Data.Notes ===''|| Data.Status === undefined  || Data.Products.length === 0)} 
                onClick={()=>{HandleOk(
                    Data,
                    setconfirmLoading,
                    orderStore,
                    setLoading,
                    setDataSource,
                    setPagesize,
                    setOpen,
                    pagination,
                    setData,setDataCounter,setLimitOpen,setLimitMess,setLivr
                    );
                    setRows([reset]);
            }}
                >
            {i18n.t("Pages.Orders.Create.create")}
            </Button>,
        ]}
        >
            <OrderModal 
            LivrStatus={LivrStatus}
                 setLivr={setLivr}
            productRows={productRows}
            newProduct={newProduct}
            Data={Data}
            setData={setData}
            setRows={setRows}
            handleDataChange={handleDataChange} 
            handleAdd={handleAdd} 
            handleRemove={handleRemove}
            updateRowProduct={updateRowProduct}
            />
        </Modal>
    </div>  
);
}
 
export default CallCreateOrder;