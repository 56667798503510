import i18n from "i18nextinit";
import {ReactComponent as Circle } from 'Assets/Svgs/Sales/dry-clean.svg';

export const StaffLivrColumns = [
    {
      title: i18n.t("Pages.Clients.Modal.Firstname"),
      dataIndex: 'FirstName',
      key: 'FirstName'
    },
    {
      title: i18n.t("Pages.Clients.Modal.Lastname"),
      dataIndex: 'LastName',
      key: 'LastName'
    },
    {
      title: i18n.t("Pages.Clients.Modal.City"),
      dataIndex: 'City',
      key: 'City',
      render: (City:string) => (
        <span>{(City === "" || City=== undefined ) ? "--"  :City} </span>
      )
    },
    
    {
      title: i18n.t("Pages.Clients.Modal.Phone"),
      dataIndex: 'Tel',
      key: 'Tel'
    },
    {
      title: i18n.t("Pages.SalesChannel.SaleModal.Status"),
      dataIndex: 'Enabled',
      key: 'Enabled',
      render: (Enabled:string) => (
        <div style={{display : 'flex' , alignItems : 'baseline'}}>
          <Circle width='10px' height='10px' style={Enabled ? {fill : '#2B9348' , marginRight :'5px'} : {fill : '#C52233', marginRight :'5px'}}  />
          <span style={Enabled ? {color : '#2B9348'} : {color : '#C52233'}} >{Enabled=== 'true' ? "Active" : "Disabled"}</span>
        </div>
      )
    }
  ];

  export const StaffTableColumns = [
    {
      title:  i18n.t("Pages.Clients.Modal.Firstname"),
      dataIndex: 'FirstName',
      key: 'FirstName'
    },
    {
      title:  i18n.t("Pages.Clients.Modal.Lastname"),
      dataIndex: 'LastName',
      key: 'LastName'
    },
    {
      title: i18n.t("Pages.Clients.Modal.Phone"),
      dataIndex: 'Tel',
      key: 'Tel'
    },
    {
      title: i18n.t("Pages.SalesChannel.SaleModal.Status"),
      dataIndex: 'Enabled',
      key: 'Enabled',
      render: (Enabled:boolean) => (
        <div style={{display : 'flex' , alignItems : 'baseline'}}>
          <Circle width='10px' height='10px' style={Enabled ? {fill : '#2B9348' , marginRight :'5px'} : {fill : '#C52233', marginRight :'5px'}}  />
          <span style={Enabled ? {color : '#2B9348'} : {color : '#C52233'}} >{Enabled ? "Active" : "Disabled"}</span>
        </div>
      )
    }
  ];