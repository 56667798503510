import { DatePicker } from "antd";
import { Moment } from "moment";
import { AdminType } from "Components/Constants/UserTypes";
import i18n from "i18nextinit";

interface EditDateProps {
    EditedData: AdminType
    setEditedData: React.Dispatch<React.SetStateAction<AdminType>>
    IsPasswd: boolean
    setIsPasswd: React.Dispatch<React.SetStateAction<boolean>>
    IsDate: boolean
    setIsDate: React.Dispatch<React.SetStateAction<boolean>>
    Date: Moment | null | undefined
    setDate: React.Dispatch<React.SetStateAction<Moment | null | undefined>>
}
 
const EditDate: React.SFC<EditDateProps> = ({
    EditedData,setEditedData,IsPasswd,setIsPasswd,IsDate,setIsDate,Date,setDate
}) => {

    const HandleDateChange = (value: Moment | null, dateString: string)=>{
        setIsDate(true);
        setDate(value);
        setEditedData({...EditedData , date : dateString})
    }
    return ( 
        <>
            <div className="Field1" style={{width : '100%'}}>
                <label className="label">{i18n.t("Pages.MyProfile.MyAccount.EditDate.LabelDate")}</label>
                <DatePicker
                onChange={HandleDateChange}
                placeholder="Select a date"
                picker="date"
                className="Field normal"
                name="birthdate"
                id="birthdate"
                value={Date}
                style={
                    IsDate
                    ? { }
                    : {
                        backgroundColor: "#d8000b19"
                        }
                }
                />
                {!IsDate ? (
                <div className="inv_msg">{i18n.t("Pages.MyProfile.MyAccount.EditDate.InvDate")}</div>
                ) : null}
            </div>
            <div className="Field1" style={{width : '100%'}}>
                <label className="label">{i18n.t("Pages.MyProfile.MyAccount.EditDate.LabelCurr")}</label>
                <input
                    type="password"
                    name="password"
                    className={
                        !IsPasswd
                        ? "Field normal inv"
                        : "Field normal"
                    }
                    onChange={(e)=>{
                        setEditedData({...EditedData , Password : e.target.value})
                        setIsPasswd(true);
                    }}
                    value={EditedData.Password}
                />
            </div>
            {!IsPasswd ? (
                <div className="inv_msg">{i18n.t("Pages.MyProfile.MyAccount.EditDate.InvCurrLabel")}</div>
            ) : null}
        </>
     );
}
 
export default EditDate;