import { Badge } from "antd";
import React from "react";
import {ReactComponent as Receive } from 'Assets/Svgs/receivedIcon.svg';
import OrderStore from "Store/OrderStore";
import { DataFieldsTypes } from "../OrderTypes";
import { handleGet } from "Pages/Orders/helpers/ClientOrdersHandlers";
import i18n from "i18nextinit";

interface GetCallOrderProps {
    setCount: React.Dispatch<React.SetStateAction<number>>
    count : number  
    orderStore: typeof OrderStore ,
    setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    setDataSource: React.Dispatch<React.SetStateAction<DataFieldsTypes[]>>,
    setPagesize: React.Dispatch<React.SetStateAction<number>>,
}
 
const GetCallOrder: React.SFC<GetCallOrderProps> = ({orderStore,count,setLoading,setDataSource,setPagesize,setCount}) => {

    const GetHandler = ()=>{
        handleGet(setCount,count,orderStore,setLoading,setDataSource,setPagesize)
    }
    return ( 
        
            <div className={count <= 0 ? 'disabled' : 'getOrder'}>
                <Badge count={count} size='small'>
                    <button disabled={count <= 0} onClick={GetHandler}><Receive/>{i18n.t("Pages.Orders.Create.getOrder")}</button>
                </Badge>
            </div>  
        
        
     );
}
 
export default GetCallOrder;