import { DualAxes } from '@antv/g2plot';
import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { ChartComponentProps, FieldsTypes, handleUseEffectTypes } from '../Constants/DashTypes';
import { Line } from "react-chartjs-2"
import moment from 'moment';


const handleUseEffect: handleUseEffectTypes = (
  setData,
  totalData,
  statusData,
  Data,
  PackagingData,
  ShippedData,
  DelivryData,
  ConfirmedData,
  DeclinedData,
  createdAt
) => {
  return new Promise(async (resolve, reject) => {
    try {
      //array of dates between two dates async
      const getDates = async (startDate: Date, endDate: Date) => {
        var dates = [];

        for (var m = moment(startDate); m.isBefore(endDate); m.add(1, "days")) {
          dates.push(m.format("DD-MM-YYYY"));
        }
        return dates;
      };

      const Dates = await getDates(new Date(createdAt), new Date());
      let dataitems = Data;
      dataitems.labels = Dates;
      setData(dataitems);

      for await (let dateItem of Dates) {
        let dataitems = Data;
        dataitems.datasets[0].data.push(0);
        dataitems.datasets[1].data.push(0);
        dataitems.datasets[2].data.push(0);
        dataitems.datasets[3].data.push(0);
        dataitems.datasets[4].data.push(0);
        dataitems.datasets[5].data.push(0);
        // dataitems.datasets[6].data.push(0);
        setData(dataitems);
      }

      for await (let item of totalData) {
        let dataitems = Data;
        let length = dataitems.labels.indexOf(item.month);
        dataitems.datasets[0].data[length] = item.number;
        setData(dataitems);
      }
      for await (let item of statusData) {
        let dataitems = Data;
        let length = dataitems.labels.indexOf(item.month);
        dataitems.datasets[1].data[length] = item.number;
        setData(dataitems);
      }
      for await (let item of PackagingData) {
        let dataitems = Data;
        let length = dataitems.labels.indexOf(item.month);
        dataitems.datasets[2].data[length] = item.number;
        setData(dataitems);
      }
      for await (let item of ShippedData) {
        let dataitems = Data;
        let length = dataitems.labels.indexOf(item.month);
        dataitems.datasets[3].data[length] = item.number;
        setData(dataitems);
      }
      for await (let item of DelivryData) {
        let dataitems = Data;
        let length = dataitems.labels.indexOf(item.month);
        dataitems.datasets[4].data[length] = item.number;
        setData(dataitems);
      }
      // for await (let item of ConfirmedData) {
      //   let dataitems = Data;
      //   let length = dataitems.labels.indexOf(item.month);
      //   dataitems.datasets[5].data[length] = item.number;
      //   setData(dataitems);
      // }
      for await (let item of DeclinedData) {
        let dataitems = Data;
        let length = dataitems.labels.indexOf(item.month);
        dataitems.datasets[5].data[length] = item.number;
        setData(dataitems);
      }
      resolve("done");
    } catch (error) {
      reject(error);
    }
  });
};


  
const ChartComponent: React.SFC<ChartComponentProps> = ({totalData,pendingData,PackagingData,ShippedData,DelivryData,ConfirmedData,DeclinedData,createdAt}) => {
    const [loading , setLoading ] = useState(true);
    const [Data, setData] = useState({
      labels: [],
      datasets: [
        {
          label: "Total",
          data: [],
          fill: false,
          borderColor: "#457dcc",
          backgroundColor: "#457dcc",
          borderWidth: 2,
          radius: 0,
          tension: 0.3,
        },
        {
          label: "Pending",
          data: [],
          fill: false,
          borderColor: "#fcba03",
          backgroundColor: "#fcba03",
          borderWidth: 2,
          radius: 0,
          tension: 0.3,
        },
        {
          label: "Packaging",
          data: [],
          fill: false,
          borderColor: "#eb984b",
          backgroundColor: "#eb984b",
          borderWidth: 2,
          radius: 0,
          tension: 0.3,
        },
        {
          label: "Shipped",
          data: [],
          fill: false,
          borderColor: "#00992e",
          backgroundColor: "#00992e",
          borderWidth: 2,
          radius: 0,
          tension: 0.3,
        },
        {
          label: "Delivery",
          data: [],
          fill: false,
          borderColor: "#620078",
          backgroundColor: "#620078",
          borderWidth: 2,
          radius: 0,
          tension: 0.3,
        },
        // {
        //   label: "Confirmed",
        //   data: [],
        //   fill: false,
        //   borderColor: "#009436",
        //   backgroundColor: "#009436",
        //   borderWidth: 2,
        //   radius: 0,
        //   tension: 0.3,
        // },
        {
          label: "Declined",
          data: [],
          fill: false,
          borderColor: "#940007",
          backgroundColor: "#940007",
          borderWidth: 2,
          radius: 0,
          tension: 0.3,
        },
      ],
    });

    useEffect(() => {

        setLoading(true);
        handleUseEffect(setData,totalData,pendingData,Data,PackagingData,ShippedData,DelivryData,ConfirmedData,DeclinedData,createdAt).then(result =>{
          setLoading(false)
        }).catch(err => console.log(err))

    }, [])
        
    return ( 
        <>
        <br/>
        <div className='ChartContainer'
         >
           {!loading && (
              <Line data={Data} options={{
                responsive: true,
                interaction: {
                  mode: 'index',
                  intersect: false,
                },
                scales: {
                  y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                  }, 
                  x: {
                    display: false // Hide X axis labels
                  } 
                }
              }} />
           )}
        </div>
        </>
     ); 
}
 
export default ChartComponent;