import { StaffTableColumns } from "../Constants/Columns";
import { Button, Modal, Table, TablePaginationConfig } from "antd";
import { LivrExterneProps } from "./LivrExterne";
import { EmployeType } from "Pages/Staff/Constants/EmployeTypes";
import { useState } from "react";
import RowEmpModal from "Pages/Staff/Components/RowEmpModal";
import { UpdStaff } from "Pages/Staff/Helpers/EmployesHandlers";
import i18n from "i18nextinit";


 
const ShippDataTable: React.SFC<LivrExterneProps> = ({
    StaffStore,LivrExtData,pageSize,Loading,setSelected,
    selectedRowKeys,setLoading,setLivrExtData,setPagesize,pagination,setLimitMess,setLimitOpen
}) => {

    const [Data, setData] = useState<EmployeType >();
    const [open , setOpen] = useState<boolean>(false);
    const [confirmLoading , setconfirmLoading] = useState<boolean>(false);
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelected(selectedRowKeys);
        } 
    };
    const HandelRow = (row: EmployeType) => {
        setData(row);
        setOpen(true);
    };
    const handleCLick = ()=>{
        if (Data?.Type === 'LivrExt') {
            UpdStaff(
                Data,setconfirmLoading,setLoading,StaffStore,
                setLivrExtData,setPagesize,setOpen,pagination,setLimitOpen,setLimitMess
            )
        }
    }
    return ( 
        <>
            <Table
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection
                }}
                style={{overflowX: "auto"}}
                dataSource={LivrExtData}
                loading={Loading}
                pagination={{ pageSize: 10, total: pageSize }}
                rowKey={(row: EmployeType) =>
                    row.EmplyeId === undefined ? Math.random() : row.EmplyeId
                }
                columns={StaffTableColumns}
                onRow={(row) => ({ onClick: () => HandelRow(row) })}
            />
            <Modal
            title={Data?.EmplyeId}
            visible={open}
            destroyOnClose
            onCancel={() => setOpen(!open)}
            footer={[
              <Button key="back" type="default" onClick={() => setOpen(!open)}>
                  {i18n.t("Pages.Inventory.Create.cancel")}
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={confirmLoading}
                onClick={handleCLick}
              >
                  {i18n.t("Pages.Inventory.Create.Update")}
              </Button>,
            ]}
            >
                <RowEmpModal Data={Data} setData={setData} />
            </Modal>
        </>
     );
}
 
export default ShippDataTable;