import { Button, Table, TablePaginationConfig ,Modal} from "antd";
import { SalesColumns } from "../Constants/Consts";
import {useEffect , useLayoutEffect, useState} from 'react';
import {ReactComponent as AddIcon } from 'Assets/Svgs/addIcon.svg';
import SaleModal from './SaleModal';
import { FieldsType, SalesTypes } from '../Constants/SalesTypes';
import SalesStore from 'Store/SalesStore';
import {observer , inject } from 'mobx-react';
import { checkSheet, CreateSale, DeleteSales, UpdateSale } from "Services/Sales/SalesChannelServices";
import SaleRowModal from "./SaleRowModal";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import i18n from "i18nextinit";

interface ListSalesProps {
    SaleStore : typeof SalesStore
    setLimitOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLimitMess: React.Dispatch<React.SetStateAction<string>>
}

declare global {
    interface Window {
        google:any;
    }
}


const { confirm } = Modal;

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  
const ListSales: React.SFC<ListSalesProps> = inject(
    'SaleStore'
  )(
    observer(({SaleStore,setLimitMess,setLimitOpen}) => {

    // Data table states -----

    const [pagination , setPagination] = useState<number|undefined>(1); // table
    const [Loading , setLoading] = useState<boolean | undefined>(true); // table
    const [dataSource , setDataSource] = useState<SalesTypes[]>(SaleStore.dataSource); // table
    const [pageSize , setPagesize] = useState<number>(SaleStore.count); // table
    const [selectedRowKeys , setSelectedRowKeys] = useState<React.Key[]>([]);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    // layout for catching window resize
    useLayoutEffect(() => {
        function handleResize() {
        setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[],selectedRows: SalesTypes[]) => {
          setSelectedRowKeys(selectedRowKeys);
        } 
    };

    
    // -----------------

    // Create Sale modal states ---------

    const [Screen , setScreen] =  useState(0) ; 
    const [open , setopen] = useState(false); 
    const [switchScreen , setSwitch] = useState(false); 
    const [buttonLoading , setButtonLoading] = useState(false); 
    const [selected , setSelected] = useState<string>(''); 
    const [isShopValid , setShopStatus] = useState(true); 
    const [isSheetIdValid , setisSheetIdValid] = useState(true); 
    const [isSheetNameValid , setisSheetNameValid] = useState(true); 

    const [shopMsg , setShopMsg] = useState<'Exist'|'Ivalid'>(); 
    const requiredFields = [
      "Phone",
      "City",
      "Address",
      "ProductId",
      "ProductName",
      "ProductQte",
      "ProductPrice",
      "Notes",
      "OrderPrice",
      "Variant",
      "Firstname",
      "Lastname",
    ];
    // -----------------

    // Row modal 

    const [Data ,setData] = useState<SalesTypes>(); // row modal
    const [RowOpen , setRowOpen] = useState(false); 
    const [UpdateLoading , setUpdateLoading] = useState(false); 

    // -----------------

    // Delete popup confirm

    const [popVis , setpopVis] = useState(false); 
    const [popConfirm , setpopConfirm] = useState(false); 

    // ---------------------
    
    // YouCan data 

    const [sheetHeader , setHeaders] = useState<string[]>([]);
    const [Fields , setFields] = useState<FieldsType>({});
    const [fieldsValid , setFieldsValid] = useState(true);

    useEffect(()=>{
        async function CheckFields()
        {
            let Check = false ;
            for await (let val of requiredFields)
            {
                if ((Fields[val] === '' || Fields[val] === undefined)) {
                    Check = true ;
                }
            }
            setFieldsValid(Check);
        }
        CheckFields();
        
    },[Fields])
    
    
    // --------------------------------- ***

    // Handlers and hooks =================== 

    const handleClick = async () =>{
        if (Screen === 1) {
            if (selected === 'YouCan') {
              setButtonLoading(true);
              await checkSheet(Data.SheetId).then((res:any) => {
                  if (res.status === 200) {
                      setButtonLoading(false);
                      setHeaders(res.data.valHead);
                      setisSheetIdValid(true);
                      setSwitch(true);
                      setScreen(2);
                  }else{
                      setisSheetIdValid(false);
                      setButtonLoading(false);
                  }
              })
            }else
            {
              setSwitch(true);
              setScreen(Screen+1);
            }
        }else
        {
            if (Screen === 2) {
                if (selected === 'YouCan') {
                  
                  setButtonLoading(true);
                  CreateSale(
                    Data,
                    setButtonLoading,
                    setLoading,
                    SaleStore,
                    setDataSource,
                    setPagesize,
                    setopen,
                    pagination,
                    setShopStatus,
                    setShopMsg,
                    setLimitOpen,
                    setLimitMess,
                    "CreateSheetSales"
                  );
                } else {
                    setButtonLoading(true);
                    CreateSale(
                      Data,
                      setButtonLoading,
                      setLoading,
                      SaleStore,
                      setDataSource,
                      setPagesize,
                      setopen,
                      pagination,
                      setShopStatus,
                      setShopMsg,
                      setLimitOpen,
                      setLimitMess,
                      "CreateSale"
                    );
                }
                
            } else {
                setScreen(Screen+1); 
            }
        }
        
    }
    useEffect(()=>{
        setData({...Data , Source : selected})
    },[selected])

    useEffect(()=>{

        SaleStore.getDataSource(1)
        .then(res =>{
            console.log("🚀 ~ file: ListSales.tsx ~ line 182 ~ useEffect ~ SaleStore.dataSource", SaleStore.dataSource)
            setDataSource(SaleStore.dataSource);
            setPagesize(SaleStore.count);
            setLoading(false);
        })
        .catch(err => console.log(err));

    },[])

    const handleChange = (pagination: TablePaginationConfig) => {

        setPagination(pagination.current);
        setLoading(true);
        SaleStore.getDataSource(pagination.current)
        .then(res =>{
            setDataSource(SaleStore.dataSource);
            setPagesize(SaleStore.count);
            setLoading(false);
        })
        .catch(err => console.log(err));
    }

    const DeleteHandler  = async () =>{

    
        try {
            let Url = '';
            for await (let id of selectedRowKeys)
            {
                Url += `ShopId[]=${id}&` ;
            }
            setpopConfirm(true);
            DeleteSales(Url,setpopConfirm,setLoading,SaleStore,setDataSource,setPagesize,setpopVis,pagination);
        } catch (error) {
            return [] ;
        }
    }
    function showDeleteConfirm() {
        confirm({
          title: i18n.t("Pages.SalesChannel.Errors.Title"),
          style : {fontFamily : 'CatamaranBold'},
          icon: <ExclamationCircleOutlined />,
          content: `${i18n.t("Pages.SalesChannel.Errors.content1")} ${selectedRowKeys.length} ${i18n.t("Pages.SalesChannel.Errors.content2")}` ,
          okText: i18n.t("Pages.SalesChannel.Errors.ok"),
          okType: 'danger',
          cancelText: i18n.t("Pages.SalesChannel.Errors.cancel"),
          visible : popVis ,
          okButtonProps : {loading : popConfirm},
          onOk() {
            setpopConfirm(true);
            setSelectedRowKeys([]);
            DeleteHandler();
          },
          onCancel() {
          },
        });
    }
    // ===================================

    return (
      <>
        <div className="ButtonsContainer">
          <div className="CreateOrder" onClick={() => setopen(true)}>
            <button>
              <AddIcon />
              <span>{i18n.t("Pages.SalesChannel.Create.Add")}</span>
            </button>
          </div>
          <Button
            type="dashed"
            danger
            onClick={showDeleteConfirm}
            disabled={!(selectedRowKeys.length > 0)}
          >
            {i18n.t("Pages.SalesChannel.Create.Delete")}
          </Button>
        </div>

        <Modal
          style={{ top: "9%", overflow: "overlay", width: "55vw" }}
          title={i18n.t("Pages.SalesChannel.Create.Add")}
          visible={open}
          width={windowDimensions.width > 935 ? "55vw" : "90vw"}
          destroyOnClose
          onCancel={() => {
            setopen(!open);
            setData(undefined);
            setSelected("");
            setScreen(0);
          }}
          bodyStyle={{
            overflowY: "auto",
            overflowX: "hidden",
            height: "300px",
          }}
          footer={[
            <Button
              key="back"
              type="default"
              onClick={() => {
                if (Screen === 1) {
                  setSwitch(false);
                  setScreen(Screen - 1);
                } else {
                  setScreen(Screen - 1);
                }
              }}
              disabled={Screen === 0}
            >
              {i18n.t("Pages.SalesChannel.Create.Previous")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={buttonLoading}
              disabled={
                Screen >= 3 ||
                selected === "" ||
                (Screen === 1 &&
                  selected === "YouCan" &&
                  (Data.SheetId === undefined ||
                    Data.SheetName === undefined)) ||
                (Screen === 2 && fieldsValid && selected === "YouCan")
              }
              onClick={handleClick}
            >
              {Screen === 2
                ? i18n.t("Pages.SalesChannel.Create.Done")
                : i18n.t("Pages.SalesChannel.Create.Next")}
            </Button>,
          ]}
        >
          <SaleModal
            sheetHeader={sheetHeader}
            requiredFields={requiredFields}
            Fields={Fields}
            setFields={setFields}
            Screen={Screen}
            switchScreen={switchScreen}
            setScreen={setScreen}
            selected={selected}
            setSelected={setSelected}
            Data={Data}
            setData={setData}
            isShopValid={isShopValid}
            setShopStatus={setShopStatus}
            shopMsg={shopMsg}
            setSwitch={setSwitch}
            isSheetIdValid={isSheetIdValid}
            isSheetNameValid={isSheetNameValid}
            setIsSheetIdValid={setisSheetIdValid}
            setIsSheetNameValid={setisSheetNameValid}
          />
        </Modal>
        <div className="ListContainer" style={{ marginTop: "15px" }}>
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            style={{ overflowX: "auto" }}
            dataSource={dataSource}
            columns={SalesColumns}
            pagination={{ pageSize: 10, total: pageSize }}
            loading={Loading}
            onChange={(pagination) => handleChange(pagination)}
            rowKey={(row: SalesTypes) =>row.ShopId}
            onRow={(row) => ({
              onClick: () => {
                setData(row);
                setRowOpen(true);
              },
            })}
          />
        </div>
        <Modal
          title={Data?.ShopUrl}
          visible={RowOpen}
          destroyOnClose
          onCancel={() => {
            setRowOpen(!RowOpen);
            setData(undefined);
          }}
          footer={[
            <Button
              key="back"
              type="default"
              onClick={() => {
                setRowOpen(!RowOpen);
                setData(undefined);
              }}
            >
              {i18n.t("Pages.SalesChannel.Create.cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={UpdateLoading}
              onClick={() => {
                setUpdateLoading(true);
                UpdateSale(
                  Data,
                  setUpdateLoading,
                  setLoading,
                  SaleStore,
                  setDataSource,
                  setPagesize,
                  setRowOpen,
                  pagination,
                  setShopStatus,
                  setShopMsg,
                  setLimitOpen,
                  setLimitMess,
                  Data.Source === "YouCan" ? "UpdateSheet":"UpdateSale"
                );
              }}
            >
              {i18n.t("Pages.SalesChannel.Create.Update")}
            </Button>,
          ]}
        >
          <SaleRowModal
            Data={Data}
            setData={setData}
            setShopStatus={setShopStatus}
            shopMsg={shopMsg}
            isShopValid={isShopValid}
          />
        </Modal>
      </>
    );
}))
 
export default ListSales;