import { Button, Modal, Table, TablePaginationConfig } from "antd"
import { EmployeType } from "../Constants/EmployeTypes";
import { useState } from "react";
import RowEmpModal from "./RowEmpModal";
import { ColumnsType } from "antd/lib/table";
import StaffStores from "Store/StaffStore";
import { inject, observer } from "mobx-react";
import { UpdStaff } from "../Helpers/EmployesHandlers";
import i18n from "i18nextinit";

export interface StaffDataTableProps {
    StaffStore : typeof StaffStores
    CallData: EmployeType[] ,
    pageSize: number,
    type : string ,
    Loading: boolean | undefined
    handleChange: (pagination: TablePaginationConfig,type : string) => void
    setSelected: React.Dispatch<React.SetStateAction<React.Key[]>>
    selectedRowKeys: React.Key[]
    StaffTableColumns: ColumnsType<EmployeType> | undefined
    setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    setCallData: React.Dispatch<React.SetStateAction<EmployeType[]>>,
    setPagesize: React.Dispatch<React.SetStateAction<number>>
    setMagaData : React.Dispatch<React.SetStateAction<EmployeType[]>>  
    setMagaSize: React.Dispatch<React.SetStateAction<number>>
    setLivrData: React.Dispatch<React.SetStateAction<EmployeType[]>>
    setLivrSize: React.Dispatch<React.SetStateAction<number>>
    pagination: number | undefined
    setLimitOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setLimitMess: React.Dispatch<React.SetStateAction<string>>
}
 
const StaffDataTable: React.SFC<StaffDataTableProps> =  inject(
    'StaffStore'
  )(
    observer(({CallData,pageSize,type,Loading,handleChange,setSelected,selectedRowKeys,StaffTableColumns,
        StaffStore,setLoading,setCallData,setPagesize,setMagaData,setMagaSize,setLivrData,setLivrSize,pagination,setLimitMess,setLimitOpen}) => {
    
    const [Data, setData] = useState<EmployeType >();
    const [open , setOpen] = useState<boolean>(false);
    const [confirmLoading , setconfirmLoading] = useState<boolean>(false);
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelected(selectedRowKeys);
        } 
    };
    const HandelRow = (row: EmployeType) => {
        console.log(CallData);
        setData(row);
        setOpen(true);
    };
    const handleCLick = ()=>{
       if (Data?.Type === 'Call') {
        UpdStaff(
            Data,setconfirmLoading,setLoading,StaffStore,
            setCallData,setPagesize,setOpen,pagination,setLimitOpen,setLimitMess
        )
       }
       if (Data?.Type === 'Maga') {
        UpdStaff(
            Data,setconfirmLoading,setLoading,StaffStore,
            setMagaData,setMagaSize,setOpen,pagination,setLimitOpen,setLimitMess
        )
       } 
       if (Data?.Type === 'Livr') {
        UpdStaff(
            Data,setconfirmLoading,setLoading,StaffStore,
            setLivrData,setLivrSize,setOpen,pagination,setLimitOpen,setLimitMess
        )
       }  
    }
    return ( 
        <>
            <Table
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                }}
                style={{ overflowX : 'auto'}}
                loading={Loading}
                onChange={(pagination) => handleChange(pagination,type)}
                dataSource={CallData}
                pagination={{ pageSize: 10, total: pageSize }}
                rowKey={(row: EmployeType) =>
                    row.EmplyeId === undefined ? Math.random() : row.EmplyeId
                }
                columns={StaffTableColumns}
                onRow={(row) => ({ onClick: () => HandelRow(row) })}
            />
            <Modal
            title={Data?.EmplyeId}
            visible={open}
            destroyOnClose
            onCancel={() => {
                setOpen(!open);
                setData(undefined);
              }}
            footer={[
              <Button key="back" type="default" onClick={() => setOpen(!open)}>
                    {i18n.t("Pages.Clients.Create.cancel")}
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={confirmLoading}
                onClick={handleCLick}
              >
                    {i18n.t("Pages.Clients.Create.Update")}
              </Button>,
            ]}
            >
                <RowEmpModal setData={setData} Data={Data} />
            </Modal>
        </>
     );
}))
 
export default StaffDataTable;