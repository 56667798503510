import { AddCLient, UpdateClient , DeleteClients } from "Services/Clients/ClientServices";
import { AddCLientTypes, DeleteClType, UpdateClinetTypes } from "../Constants/ClientTypes";

export const HandleAddClient:AddCLientTypes = (
    Data,setconfirmLoading,setLoading,ClientsStore,setDataSource,
    setPagesize,setOpen,pagination,setPhoneErr,setData
)=>{
    setconfirmLoading(true);
    AddCLient( Data,setconfirmLoading,setLoading,ClientsStore,setDataSource,
        setPagesize,setOpen,pagination,setPhoneErr,setData)
}

export const HandleUpdateClient:UpdateClinetTypes = (
    Data,setconfirmLoading,setLoading,ClientsStore,setDataSource,
    setPagesize,setOpen,pagination
)=>{
    setconfirmLoading(true);
    UpdateClient(Data,setconfirmLoading,setLoading,ClientsStore,setDataSource,
        setPagesize,setOpen,pagination)
}

export const HandleDeleteCLients:DeleteClType = async(
    Ids , setconfirmLoading , setOpen,ClientStore, setLoading, setDataSource, setPagesize, pagination
    )=>{
    try {
        let Url = '';
        setconfirmLoading(true);
        for await (let id of Ids)
        {
            Url += `ClientId[]=${id}&` ;
        }   
        DeleteClients(Url , setconfirmLoading , setOpen,ClientStore, setLoading, setDataSource, setPagesize, pagination);

    } catch (error) {
        return [] ;
    }
}