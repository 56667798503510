import { AutoComplete, Button, Modal, Table, TablePaginationConfig } from "antd"
import CreateProduct from "./CreateProduct";
import { ProductTypes } from 'Pages/Products/Components/ProductTypes';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, {useEffect , useState} from 'react';
import {observer , inject } from 'mobx-react';
import ProductStore from 'Store/ProductStore';
import ProductModal from "./ProductModal";
import { EditProductFields } from "../Constants/Products";
import { DeleteProductsByIds, EditProduct } from "../Helpers/ProductsHandlers";
import StatusComponent from "Components/Routes/StatusComponent";
import i18n from "i18nextinit";
import { getProductsSearchedById, getProductsSearchedPage } from "Services/Products/ProductCalls";
import {ReactComponent as SearchIcon} from 'Assets/Svgs/search.svg';

export interface ListProductsProps {
    productStore : typeof ProductStore
    type ?: string
}
 
const {Option} = AutoComplete;

const ListProducts: React.SFC<ListProductsProps> = inject(
  'productStore'
)(
  observer(({productStore,type}) => {

    const [NumSearchData,setNumSearchData] = useState<string>('');
    const [SearchedData , setSearchedData]= useState<string[]>([]);

    const [Data , setData ] = useState<ProductTypes>(EditProductFields);
    const [pageSize , setPagesize] = useState<number>(productStore.count);
    const [dataSource , setDataSource] = useState<ProductTypes[]>(productStore.dataSource);
    const [Loading , setLoading] = useState<boolean>();
    const [Open , setOpen] = useState(false);
    const [pagination , setPagination] = useState<number|undefined>(1);
    const [confirmLoading , setconfirmLoading] = useState(false);
    const [selectedRowKeys , setSelectedRowKeys] = useState<React.Key[]>([]);

    const [popVis , setPopVis] = useState(false);
    const [PopConfirm , setPopConfirm] = useState(false);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[],selectedRows: ProductTypes[]) => {
          setSelectedRowKeys(selectedRowKeys);
        } 
    };
    const { confirm } = Modal;

    useEffect(()=>{
      if(NumSearchData === '')
      {
        setLoading(true);
        productStore.getDataSource(1).then(()=>{
          setDataSource(productStore.dataSource);
          setPagesize(productStore.count);
          setLoading(false);
        });
      }else
      {
          handleSearch(1);
      }
    },[]);

    const handleChange = (pagination: TablePaginationConfig) => {
      if(NumSearchData === '')
      {
          setPagination(pagination.current);
          setLoading(true);
          productStore?.getDataSource(pagination.current).then(()=>{
            setDataSource(productStore.dataSource);
            setLoading(false);
          }).catch(err => console.log(err));
      }else
      {
          handleSearch(1);
      }
    }
    const HandelRow = (row : ProductTypes )=>{
      setData(row) ;
      setOpen(true);
    }

    const columns = [
        {
          title: i18n.t("Pages.Bon.ProdColumns.ProdNumb"),
          dataIndex: 'ProductId',
          key: 'ProductId'
        },
        {
          title: i18n.t("Pages.Bon.ProdColumns.Name"),
          dataIndex: 'ProductName',
          key: 'ProductName'
        },
        {
            title: i18n.t("Pages.Bon.ProdColumns.Price"),
            dataIndex: 'ProductPrice',
            key: 'ProductPrice',
            render: (ProductPrice:string) => (
            <span>{ProductPrice} Dh</span>
          ),
        },
        {
          title: i18n.t("Pages.Bon.ProdColumns.UpsellQuant"),
          dataIndex: 'UpSell_Quantity',
          key: 'UpSell_Quantity',
            render: (UpSell_Quantity:string) => (
            <span>{(UpSell_Quantity === "" || UpSell_Quantity=== undefined ) ? "--"  :UpSell_Quantity} </span>
          ),
        },
        {
            title: i18n.t("Pages.Bon.ProdColumns.UpsellPrice"),
            dataIndex: 'UpSell_Price',
            key: 'UpSell_Price',
            render: (UpSell_Price:string) => (
              <span>{(UpSell_Price === "" || UpSell_Price=== undefined ) ? "--"  :UpSell_Price + ' Dh'} </span>
            ),
        },
        {
          title: i18n.t("Pages.Bon.Pdf.Status"),
          key: 'inStock',
          dataIndex: 'inStock',
          render : (Type : boolean | undefined) => (
            <StatusComponent  Type={(Type === undefined || Type === false) ? 'OutStock' : 'InStock'}/>
          )
        }
    ];

    function showDeleteConfirm() {
      confirm({
        title: i18n.t("Pages.Inventory.Errors.Title"),
        style : {fontFamily : 'CatamaranBold'},
        icon: <ExclamationCircleOutlined />,
        content: `${i18n.t("Pages.Inventory.Errors.content1")} ${selectedRowKeys.length} ${i18n.t("Pages.Inventory.Errors.content2")}` ,
        okText: i18n.t("Pages.Inventory.Errors.Yes"),
        okType: 'danger',
        cancelText: i18n.t("Pages.Inventory.Errors.No"),
        visible : popVis ,
        okButtonProps : {loading : PopConfirm},
        onOk() {
          DeleteProductsByIds(selectedRowKeys,setPopConfirm , setPopVis , productStore , setLoading , setDataSource,setPagesize , pagination);
          setSelectedRowKeys([]);
        },
        onCancel() {
          //console.log('Cancel');
        },
      });
    }

    const onSelectPhone = (value: string) => {
      setNumSearchData(value);
    }

    const onSearchPhone = (value: string) => {
      setNumSearchData(value);
      getProductsSearchedById(value).then(res => {    
      console.log("🚀 ~ file: ListProducts.tsx ~ line 158 ~ getProductsSearchedById ~ res", res)
              
        setSearchedData(res.newResult);
      }).catch(err => console.log(err));
    }

    const handleSearch = (pagination:number)=>{
      setLoading(true);
      getProductsSearchedPage(NumSearchData,pagination).then(res => {
        setDataSource(res.result);
        setPagesize(res.count);
        setLoading(false);
      }).catch(err => console.log(err));
    }

    const handleClearSearch = ()=>{
      setNumSearchData('');
      setSearchedData([]);
      setLoading(true);
      productStore.getDataSource(1).then(()=>{
        setDataSource(productStore.dataSource);
        setPagesize(productStore.count);
         setLoading(false);
      })
      .catch(err =>console.log(err));
    }

    return (
      <>
        
        <div className="headCont" style={{display:'flex',justifyContent:"space-between",alignItems:"baseline"}}>
              
              <div className='ButtonsContainer'>
                {type === 'Admin' && (
                  <>
                    <CreateProduct productStore={productStore} setLoading={setLoading} setDataSource={setDataSource} setPagesize={setPagesize} pagination={pagination} type={type} />
                    <Button type="dashed"  danger onClick={showDeleteConfirm} disabled={!(selectedRowKeys.length > 0)}>
                    {i18n.t("Pages.Inventory.Errors.Delete")}
                    </Button>
                  </>
                )}
              </div>
            <div className="ButtonsContainer autoComp" >
                <span className='ClearFilter' onClick={handleClearSearch}>{i18n.t('Pages.Orders.ClearTxt')}</span>
                <AutoComplete
                    style={{width: '60%',paddingTop:"5px",paddingBottom:"5px",paddingRight:"10px",paddingLeft:"10px",marginRight:"20px"}}
                    onSelect={onSelectPhone}
                    onSearch={onSearchPhone}
                    value={NumSearchData}
                    dropdownStyle={{fontFamily:"CatamaranBold"}}
                    placeholder={i18n.t('Pages.Orders.ProdSPH')}
                >
                    {SearchedData?.map(element =>(
                    <Option key={element} value={element}>
                        {element}
                    </Option>
                    ))}
                </AutoComplete>
                <SearchIcon width='1.25rem' height='1.25rem' className='Search' onClick={()=>{handleSearch(1)}}/>
            </div>
        </div>
        <div className="ListContainer" style={type === 'Admin' ? {} : {marginTop : '40px'}}>
          <Table
            rowSelection={type === 'Admin'?{
              type: "checkbox",
              ...rowSelection,
            }:undefined}
            dataSource={dataSource}
            style={{overflowX:"auto"}}
            columns={columns}
            pagination={{ pageSize: 10, total: pageSize }}
            onChange={(pagination) => handleChange(pagination)}
            loading={Loading}
            rowKey={(row : ProductTypes )=>row.ProductId}
            onRow={(row) => ({ onClick: () => HandelRow(row) })}
          />
          <Modal
            title={Data.ProductId} 
            visible={Open} 
            destroyOnClose
            onCancel={()=>{
              setOpen(!Open);
              setData(EditProductFields)
            }}
            footer={type === 'Admin'?[
                <Button key="back" type="default"  >
                  {i18n.t("Pages.Inventory.Create.cancel")}
                </Button>,
                <Button key="submit" loading={confirmLoading} type="primary" onClick={()=> EditProduct(Data,setconfirmLoading,setOpen,productStore,setLoading,setDataSource , setPagesize,pagination,setData)}>
                  {i18n.t("Pages.Inventory.Create.Update")}
                </Button>,
            ]:null}
            >
                <ProductModal setData={setData} Data={Data} type={type} modaltype="edit" />
          </Modal>
        </div>
      </>
    );
}))
 
export default ListProducts;