import { Input, Result } from "antd";
import i18n from "i18nextinit";
import { EmployeType } from "Pages/Orders/Components/OrderTypes";
import { ProductTypes } from "Pages/Products/Components/ProductTypes";
import React, { useEffect, useState } from "react";
import { getLivrById } from "Services/Bon/BonLivrCalls";
import { getProductById } from "Services/Products/ProductCalls";
import { LivrStock, WarehouseTypes } from "../Constants/InventoryTypes";

export interface RowInvModalProps {
    Type : string ,
    Data : WarehouseTypes | LivrStock,
    setData: React.Dispatch<React.SetStateAction<WarehouseTypes | LivrStock>>
    usertype ?:string
}

const RowInvModal: React.SFC<RowInvModalProps> = ({Type , Data , setData , usertype}) => {
    const [Product , setProduct] = useState<ProductTypes>();
    const [Employe , setEmploye] = useState<EmployeType>();
    useEffect(()=>{
        getProductById(Data.ProductId).then(result =>{
            setProduct(result.result)
        });
        if (Data.LivrId !== undefined) {
            getLivrById(Data.LivrId).then(result =>{
                setEmploye(result);
            })
        }
    },[])
    return ( 
        <div className='InventoryContainer'>

            <div className='Row2'>
                <div className='Row1' style={{width : '40%'}}>
                        <label>{i18n.t("Pages.Inventory.Modal.InvType")}</label>
                        <input readOnly={usertype === 'Admin' ? false : true} className='Field' value={Type}/>
                </div>
                <div className='Row1' style={{width : '40%'}}>
                        <label>{i18n.t("Pages.Inventory.Modal.ProductName")}</label>
                        <input readOnly={usertype === 'Admin' ? false : true}  className='Field' value={Product?.ProductName}/>
                </div>
            </div><br/>
            {(Data.LivrId !== undefined && usertype !== 'Livr') && (
                <div className='Row2'>
                    <div className='Row1'>
                        <label>{i18n.t("Pages.Inventory.Modal.Distr")}</label>
                        <input readOnly={usertype === 'Admin' ? false : true}  className='Field' value={Employe?.FirstName+' '+Employe?.LastName + ' | ' + Employe?.City}/>
                        <br/>
                    </div>  
                </div>
                
            )}
            <div className='Row2 back'>
                
                <div className='Row1' style={{width : '40%'}}>
                    <label>{i18n.t("Pages.Inventory.Modal.ProductPrice")}</label>
                    <Input readOnly={usertype === 'Admin' ? false : true}  style={{width : '100%'}} addonAfter="Dh" value={Product?.ProductPrice}/>
                </div>
                <div className='Row1' style={{width : '40%'}}>
                    <label>{i18n.t("Pages.Inventory.Modal.Qte")}</label>
                    <input type='number' readOnly={(usertype === 'Admin' || usertype === 'Livr') ? false : true}  className='Field edit' value={Data.Quantity} onChange={(e)=> setData({...Data , Quantity : e.target.value})} />
                </div>
                
            </div>
        </div>
     );
}
 
export default RowInvModal;