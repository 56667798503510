import { Modal, Button } from 'antd';
import {useState} from 'react';
import {ReactComponent as AddIcon } from 'Assets/Svgs/addIcon.svg';
import ProductModal from './ProductModal';
import { ProductTypes } from './ProductTypes';
import { ProductFields } from '../Constants/Products';
import { HandleOk } from '../Helpers/ProductsHandlers';
import ProductsStore from 'Store/ProductStore';
import i18n from 'i18nextinit';

export interface CreateProductProps {
    productStore: typeof ProductsStore ,
    setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    setDataSource: React.Dispatch<React.SetStateAction<ProductTypes[]>>,
    setPagesize: React.Dispatch<React.SetStateAction<number>>,
    pagination: number | undefined,
    type ?: string
}

const CreateProduct: React.SFC<CreateProductProps> = ({productStore, setLoading, setDataSource, setPagesize, pagination,type}) => {
    const [open, setOpen] = useState(false);
    const [Data , setData] = useState<ProductTypes>(ProductFields);
    const [confirmLoading , setconfirmLoading] = useState(false);
    const [isProdIdValid , setisProdIdValid] = useState(true);
    return (
      <div className="CreateOrder">
        <button onClick={() => setOpen(!open)}>
          <AddIcon />
          <span>{i18n.t("Pages.Clients.Create.Add")}</span>
        </button>
        <Modal
          title={i18n.t("Pages.Products.Title")}
          visible={open}
          destroyOnClose
          onCancel={() => {
            setOpen(!open);
            setData(ProductFields);
          }}
          footer={[
            <Button
              key="back"
              type="default"
              onClick={() => {
                setOpen(!open);
                setData(ProductFields);
              }}
            >
              {i18n.t("Pages.Bon.Create.cancel")}
            </Button>,
            <Button
              key="submit"
              loading={confirmLoading}
              disabled={
                Data.ProductName === "" ||
                Data.ProductPrice === 0 ||
                (Data.Source === "Woocommerce" ||
                Data.Source === "Shopify" ||
                Data.Source === "YouCan")
                  ? Data.ProductId === ""
                  : false
              }
              type="primary"
              onClick={() =>
                HandleOk(
                  Data,
                  setconfirmLoading,
                  setOpen,
                  productStore,
                  setLoading,
                  setDataSource,
                  setPagesize,
                  pagination,
                  setData,
                  isProdIdValid,
                  setisProdIdValid
                )
              }
            >
              {i18n.t("Pages.Bon.Create.create")}
            </Button>,
          ]}
        >
          <ProductModal
            setData={setData}
            Data={Data}
            type={type}
            modaltype="add"
            isProdIdValid={isProdIdValid}
            setisProdIdValid={setisProdIdValid}
          />
        </Modal>
      </div>
    );
}
 
export default CreateProduct;