import CountUp from 'react-countup';
interface WidgetCounterProps {
    Title : string ,
    Value : string | number | undefined ,
    type : 'Percent' | 'Price' | 'Count' ,
    Icon : React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
    rate : 'up' | 'down'
    stats : string
}
  
const WidgetCounter: React.SFC<WidgetCounterProps> = ({Title,Value,type,Icon,stats,rate}) => {
    return ( 
        <div className='WidgetContainer'>

            <div className='FlexContainer'>
                <div className='leftPart'>
                    <div className='Title'>
                        {Title}
                    </div>
                    <div className='value'>
                        
                        {(type==='Count' && Value !== undefined)&&(<span><CountUp end={Number(Value)} duration={3} /></span>)}
                        {(type==='Price'&& Value !== undefined)&&(<span><CountUp end={Number(Value)} duration={3} /> Dh</span>)}
                        {(type==='Percent'&& Value !== undefined)&&(<span><CountUp end={Number(Value)} duration={3} /> %</span>)}
                    </div>
                </div>
                <div className='RightPart'>
                    <div className='Icon'>
                        <Icon />
                    </div>
                </div>
            </div>

            {/* <span className={rate === 'up' ? 'stats up' : 'stats down'}>{stats} </span> */}

        </div>
     );
}
 
export default WidgetCounter;