import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import translationEN from 'Locales/en/en.json';
import translationFR from 'Locales/fr/fr.json';
import translationAR from 'Locales/ar/ar.json';

const LOCALE_PERSISTENCE_KEY = "language";
const languageDetector:any = {
  type: "languageDetector",
  async: true,
  detect: async (language: any) => {
    const persistedLocale = await localStorage.getItem(LOCALE_PERSISTENCE_KEY);
    if (!persistedLocale) {
      // Find best available language from the resource ones
      // Return detected locale or default language
      return language("en");
    }
    language(persistedLocale);
  },
  init: () => {},
  cacheUserLanguage: (locale: any) => {
    localStorage.setItem(LOCALE_PERSISTENCE_KEY, locale);
  },
};

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  },
  ar: {
    translation: translationAR
  }
};

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: localStorage.getItem(LOCALE_PERSISTENCE_KEY),
    debug: true,

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;