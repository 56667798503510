import { Input, Select, Switch } from 'antd';
import i18n from 'i18nextinit';
import {useState} from 'react';
import { ProductSource } from '../Constants/Products';
import { ProductTypes } from './ProductTypes';


// TODO: Add i18n to source of product 
export interface ProductModalProps {
    setData: React.Dispatch<React.SetStateAction<ProductTypes>>,
    Data: ProductTypes,
    type ?: string,
    modaltype ?: "add" | "edit",
    isProdIdValid ?: boolean ,
    setisProdIdValid ?: React.Dispatch<React.SetStateAction<boolean>>
}
const {Option} = Select;
const ProductModal: React.SFC<ProductModalProps> = ({setData , Data,type,modaltype,isProdIdValid,setisProdIdValid}) => {

    
    const handleChange = (checked : boolean)=>{
        setData({...Data , UpSell : checked})
    }
    const handleDataChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
        const {name , value} = e.target ;
        setData({...Data , [name] : value})
    }
    return (
      <div className="ProductContainer">
        {modaltype === "add" && (
          <div className="RightSide">
            <span className="smallTitle">Source of product</span>
            <div style={{ marginTop: "10px" }}>
              <Select
                style={{ width: "100%" }}
                dropdownStyle={{ fontFamily: "CatamaranBold" }}
                placeholder="Select source of the product"
                onChange={(value) =>
                  setData({ ...Data, Source: value.toString() })
                }
              >
                {ProductSource.map((item, index) => {
                  return (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        )}
        {modaltype === "edit" && (
          <div className="RightSide">
            <span className="smallTitle">Source of product</span>

            <div style={{ marginTop: "10px" }}>
              <span style={{ fontFamily: "CatamaranBold" }}>
                {Data.Source}
              </span>
            </div>
          </div>
        )}

        <div className="LeftSide" style={{ marginTop: "10px" }}>
          {(Data.Source === "Woocommerce" ||
            Data.Source === "Shopify" ||
            Data.Source === "Google") && (
            <div className="Row1">
                {modaltype === "add" && (
                  <div style={{ width: "100%" }}>
                    <input
                    style={{ width: "100%" }}
                    placeholder="Product id"
                    value={Data.ProductId}
                    className="Field"
                    type="text"
                    name="ProductName"
                    onChange={(e) =>
                      {
                        setisProdIdValid(true);
                        setData({ ...Data, ProductId: e.target.value })
                      }
                    }
                  />
                  {!isProdIdValid && (
                    <span style={{ color: "#D8000C" , fontFamily : "CatamaranBold" }}>
                      Product id already exists .
                    </span>
                  )}
                  </div>
                    
                )}
            </div>
          )}
          <div className="Row1">
            <input
              style={{ width: "47%" }}
              placeholder={i18n.t("Pages.Bon.ProdColumns.Name")}
              value={Data.ProductName}
              readOnly={type === "Admin" ? false : true}
              className="Field"
              type="text"
              name="ProductName"
              onChange={handleDataChange}
            />
            <Input
              style={{ width: "47%" }}
              readOnly={type === "Admin" ? false : true}
              addonAfter="Dh"
              placeholder={i18n.t("Pages.Bon.ProdColumns.Price")}
              value={Data.ProductPrice === 0 ? "" : Data.ProductPrice}
              onChange={handleDataChange}
              name="ProductPrice"
            />
          </div>
        </div>
        <div className="RightSide">
          <span className="smallTitle">
            {i18n.t("Pages.Products.Modal.Upsell")}
          </span>
          <div style={{ marginTop: "10px" }}>
            <Switch
              checked={Data.UpSell}
              onChange={handleChange}
              size="small"
              disabled={type === "Admin" ? false : true}
            />
            {Data.UpSell && (
              <div className="Row1" style={{ marginTop: "10px" }}>
                <input
                  style={{ width: "47%" }}
                  placeholder={i18n.t("Pages.Bon.ProdColumns.UpsellQuant")}
                  value={Data.UpSell_Quantity}
                  onChange={handleDataChange}
                  className="Field"
                  type="text"
                  name="UpSell_Quantity"
                />
                <Input
                  style={{ width: "47%" }}
                  addonAfter="Dh"
                  placeholder={i18n.t("Pages.Bon.ProdColumns.UpsellPrice")}
                  value={Data.UpSell_Price === 0 ? "" : Data.UpSell_Price}
                  onChange={handleDataChange}
                  name="UpSell_Price"
                  className="AfInp"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
}
 
export default ProductModal;