import { Button, Modal, Table, TablePaginationConfig } from "antd"
import { InventoryTableColumns , InventoryAdTableColumns } from "../Constants/Columns";
import InventorysStore from "Store/InventoryStore";
import { LivrAdStock, LivrStock, WarehouseTypes } from "../Constants/InventoryTypes";
import React, { useState } from "react";
import { WarehouseData } from "../Constants/InventoryData";
import RowInvModal from "./RowInvModal";
import { UpdateWareHouse } from "../Helpers/WarehouseHelpers";
import StockAdModal from "./StockAdModal";
import i18n from "i18nextinit";

export interface InventoryDataTableProps {
    handleChange: (pagination: TablePaginationConfig) => void ,
    pagination : number | undefined ,
    InventorysStore: typeof InventorysStore,
    Loading: boolean | undefined,
    dataSource: WarehouseTypes[] | LivrStock[],
    pageSize: number,
    setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    setDataSource: React.Dispatch<React.SetStateAction<WarehouseTypes[] | LivrStock[]>>,
    setPagesize: React.Dispatch<React.SetStateAction<number>>,
    selectedRowKeys: React.Key[],
    setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
    type ?: string
    current ?:string

}
 
const InventoryDataTable: React.SFC<InventoryDataTableProps> = ({
    handleChange,
    pagination,
    InventorysStore,
    Loading,
    dataSource,
    pageSize,
    setLoading,
    setDataSource,
    setPagesize,
    selectedRowKeys,
    setSelectedRowKeys,
    type,
    current
}) => {
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[]) => {
          setSelectedRowKeys(selectedRowKeys);
        } 
    };
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [Data, setData] = useState<WarehouseTypes | LivrStock>(WarehouseData);
    const [LivrAdData , setLivrData]= useState<LivrAdStock>();
    const Type : string = Data.LivrId === undefined ? 'Warehouse' : 'LivrStock';
    const [Open, setOpen] = useState(false);

    const HandelRow = (row: WarehouseTypes | LivrStock|LivrAdStock) => {
        if (type === 'Admin' && current === 'LivrStock') {
            setLivrData(row);
            setOpen(true);
        }else
        {
            setData(row);
            setOpen(true);
        }
        
    };
    const Checker = (type === 'Admin' && current !== 'LivrStock') || type === 'Livr' || type === 'LivrExt'
    return ( 
        <>
            <Table
                loading={Loading}
                rowSelection={Checker?{
                    type: "checkbox",
                    ...rowSelection,
                }:undefined}
                onChange={(pagination) => handleChange(pagination)}
                style={{overflowX: 'auto'}}
                dataSource={dataSource}
                pagination={{ pageSize: 10, total: pageSize }}
                columns={(type === 'Admin' && current === 'LivrStock') ? InventoryAdTableColumns : InventoryTableColumns}
                rowKey={(type === 'Admin' && current === 'LivrStock') ? (row: LivrAdStock) => row.LivrId
                 : (row: WarehouseTypes | LivrStock) => row.ProductId
                }
                onRow={(row) => ({ onClick: () => HandelRow(row) })}
            />
            <Modal
                title={(type === 'Admin' && current === 'LivrStock')? i18n.t("Pages.Inventory.Modal.Title") :Data?.ProductName}
                visible={Open}
                destroyOnClose={true}
                onCancel={() => setOpen(!Open)}
                width={(type === 'Admin' && current === 'LivrStock') ?"75vw":undefined}
                style={{justifyContent:'center'}}
                footer={((type === 'Admin' && current !== 'LivrStock') || type === 'Livr') ? 
                [
                    <Button
                    key="back"
                    type="default"
                    onClick={() => setOpen(!Open)}
                    >
                    {i18n.t("Pages.Inventory.Create.cancel")}
                    </Button>,
                    <Button
                    key="submit"
                    type="primary"
                    loading={confirmLoading}
                    onClick={() =>UpdateWareHouse(
                        Data,
                        setConfirmLoading,
                        setLoading,
                        InventorysStore,
                        setDataSource,
                        setPagesize,
                        setOpen,
                        pagination,
                        Type
                    )}
                    >
                    {i18n.t("Pages.Inventory.Create.Update")}
                    </Button>,
                ]
                : null}
                
            >
                {(type === 'Admin' && current === 'LivrStock') && (

                    <StockAdModal Data={LivrAdData} />
 
                )}
                {(type !== 'Admin' || current === 'Warehouse') && (
                    <RowInvModal usertype={type} setData={setData} Data={Data} Type={Data?.LivrId === undefined ? 'Warehouse' : 'Stock livreur'} />
                )}
            </Modal>
        </>
     );
}
 
export default InventoryDataTable;