import { InputNumber, Select, Space, Table, TablePaginationConfig } from "antd";
import i18n from "i18nextinit";
import { DataFieldsTypes, EmployeType, newProductTypes } from "Pages/Orders/Components/OrderTypes";
import { ProductTypes } from "Pages/Products/Components/ProductTypes";
import React, { useEffect, useState } from "react";
import { getConfirmedOrders } from "Services/Bon/BonLivrCalls";
import { getLivr, getLivrExtern } from "Services/Orders/CreateOrderCalls";
import { getProducts } from "Services/Products/ProductCalls";
import { BonModalProps, BonTypes, SelectedProducts } from "../Constants/BonTypes";
import { Ordercolumns } from "../Constants/Columns";
import { HandleEmploye, handleTypeChange } from "../Helpers/BonHandlers";


const getItemIndex = (arr : SelectedProducts[], prodId : string  | React.Key) => {
  return arr.findIndex((e) => e.ProductId === prodId);
};

 
const BonModal: React.SFC<BonModalProps> = (
  {Data,setData,selectedRowKeys,setSelectedRowKeys , 
    selectedProducts,setselectedProducts, type
  }) => {
    const { Option ,OptGroup } = Select;
    const [Employes, setEmployes] = useState<EmployeType[]>([]);
    const [Loading, setloading] = useState(true);
    const [dataSource , setDataSource] = useState<DataFieldsTypes[]>();
    const [productsource,setProductSource] = useState<ProductTypes[]>();
    const [countProducts , setPcount] = useState<number>();
    const [countOrders , setOcount] = useState<number>();
    const [externEmp,setExtEm] = useState<EmployeType[]>();


    useEffect(()=>{
        setloading(true);
        getLivrExtern().then((response) =>{
          setExtEm(response)
        }).catch((err) => {console.log(err);})
        
        getLivr().then((response) => {
          setEmployes(response);
        }).catch((err) => {console.log(err);});
        setloading(false);
    },[]);

    useEffect(()=>{
      const status = Data.Type === 'Ret' ? 'Declined' : 'Delivry';
      if (Data.Type !== '' && (Data.LivrId !== '' || Data.Type === 'Ret')) {
        handleTypeChange(
          Data,
          setData,
          dataSource,
          setloading,
          getConfirmedOrders,
          setOcount,
          setDataSource,
          getProducts,
          setPcount,
          setProductSource,
          status
        )
      }
    },[Data.LivrId,Data.Type])

    const handleChange = (value : string | number | null | undefined , id : string | React.Key) =>{
      if(value !== undefined && value !== null)
      {
        let Index = getItemIndex(selectedProducts,id);
        let newArr = [...selectedProducts];
        newArr[Index].quantity = Number(value);
        setselectedProducts(newArr);
      }
      
    }


    const Productcolumns = [
      {
        title: i18n.t("Pages.Bon.ProdColumns.ProdNumb"),
        dataIndex: 'ProductId',
        key: 'ProductId'
      },
      {
        title: i18n.t("Pages.Bon.ProdColumns.Name"),
        dataIndex: 'ProductName',
        key: 'ProductName'
      },
      {
          title: i18n.t("Pages.Bon.ProdColumns.Price"),
          dataIndex: 'ProductPrice',
          key: 'ProductPrice',
          render: (ProductPrice:string) => (
          <span>{ProductPrice} Dh</span>
        ),
      },
      {
        title: i18n.t("Pages.Bon.ProdColumns.UpsellQuant"),
        dataIndex: 'UpSell_Quantity',
        key: 'UpSell_Quantity',
          render: (UpSell_Quantity:string) => (
          <span>{(UpSell_Quantity === "" || UpSell_Quantity=== undefined ) ? "--"  :UpSell_Quantity} </span>
        ),
      },
      {
          title: i18n.t("Pages.Bon.ProdColumns.UpsellPrice"),
          dataIndex: 'UpSell_Price',
          key: 'UpSell_Price',
          render: (UpSell_Price:string) => (
            <span>{(UpSell_Price === "" || UpSell_Price=== undefined ) ? "--"  :UpSell_Price + ' Dh'} </span>
          ),
      },
      {
        title: i18n.t("Pages.Bon.ProdColumns.Quantity"),
        key: 'ProductId',
        dataIndex: 'ProductId',
        render: ( ProductId : string) => (
        <Space>
          <InputNumber 
            min={1}
            disabled={selectedProducts.find(element => element.ProductId === ProductId) === undefined || selectedRowKeys.find(element => element === ProductId) === undefined}
            value={selectedProducts.find(element => element.ProductId === ProductId)?.quantity} 
            onChange={(value)=>handleChange(value,ProductId)} 
          />
        </Space>
        )
      }
  ];

    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys: React.Key[],selectedRows: DataFieldsTypes[]) => {
        var price = 0 ; 
        selectedRows.map( row =>{
          
          price = +row.OrderPrice + +price;
          
          setData({...Data , Price : price })
        })
        setData({...Data , Num_Order : selectedRowKeys })

        setSelectedRowKeys(selectedRowKeys);
      } 
    };
    const rowSelection2 = {
      selectedRowKeys,
      onChange: (selectedRowKeys: React.Key[],selectedRows: ProductTypes[]) => {

        // Clearing the array

        let clrArr = selectedProducts ;
        clrArr.splice(0,clrArr.length);
        setselectedProducts(clrArr);

        // -----------------------
        selectedRows.map( row =>{
          
          let newArr = selectedProducts ;
          newArr.push({ProductId : row.ProductId , quantity : 1});
          setselectedProducts(newArr);
        })
        setSelectedRowKeys(selectedRowKeys);

      } 
    }
    
    return (
      <div className="BonContainer">
        <div className="LeftSide ">
          <span className="smallTitle">{i18n.t("Pages.Bon.Form.Options")}</span>
          <div className="Row1">
            {(type === 'Admin'|| type === 'Maga') &&(
              <>

                <Select
                className="Select"
                style={{ width: "40%" }}
                dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                placeholder={i18n.t("Pages.Bon.Form.TypeSheet")}
                onChange={(value: string) =>
                  setData({...Data , Type : value})
                }
                >
                  <Option key="Livraison" value="Livr">
                    {i18n.t("Pages.Bon.Form.DelSheet")}
                  </Option>
                  <Option key="Stock" value="Stock">
                    {i18n.t("Pages.Bon.Form.StockSheet")}
                  </Option>
                </Select>

                <Select
                  className="Select"
                  style={{ width: "50%" }}
                  dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                  placeholder={i18n.t("Pages.Bon.Form.DelMan")}
                  onChange={(value : string) => {
                    HandleEmploye(value,Data,setData,Employes)
                  }}
                >
                  <OptGroup label={i18n.t("Pages.Bon.Form.StaffDelv")}>
                    {Employes.map((Employe) => (
                      <Option key={Employe.EmplyeId} value={Employe.EmplyeId}>
                        {Employe.FirstName+" "+Employe.LastName+" | "+Employe.City}
                      </Option>
                    ))}
                  </OptGroup>
                  <OptGroup label={i18n.t("Pages.Bon.Form.ExtDelv")}>
                    {externEmp?.map((Employe) => (
                      <Option key={Employe.EmplyeId} value={Employe.EmplyeId}>
                        {Employe.FirstName} {Employe.LastName} | {Employe.Price} Dh
                        | {Employe.City}{" "}
                      </Option>
                    ))}
                  </OptGroup>
                </Select>

              </>
            )}

            {type === 'Livr' &&(

              <>
              
                <Select
                  className="Select"
                  style={{ width: "40%" }}
                  dropdownStyle={{ fontFamily: "CatamaranRegular" }}
                  placeholder={i18n.t("Pages.Bon.Form.TypeSheet")}
                  onChange={(value: string) =>
                    setData({...Data , Type : value})
                  }
                >

                  <Option key="retour" value="Ret">
                    {i18n.t("Pages.Bon.Form.RetSheet")}
                  </Option>

                </Select>

              </>

            )}
          </div>
        </div>
        <div className="RightSide">

          {(Data?.Type === "Livr" && Data.LivrId !== '') && (
            <>
              <span className="smallTitle">{i18n.t("Pages.Bon.Form.Orders")}</span>
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                style={{overflowX:"auto"}}
                columns={Ordercolumns}
                pagination={{ pageSize: 10, total: countOrders }}
                dataSource={dataSource}
                loading={Loading}
                rowKey={(row: DataFieldsTypes) =>
                  row.Num_Order === undefined ? Math.random() : row.Num_Order
                }
              />
            </>
          )}

          {(Data?.Type === "Stock" && Data.LivrId !== '') && (
            <>
              <span className="smallTitle">{i18n.t("Pages.Bon.Form.Products")}</span>
              <Table
                rowSelection={{
                  type:"checkbox",
                  ...rowSelection2
                }}
                style={{overflowX:"auto"}}
                columns={Productcolumns}
                dataSource={productsource}
                pagination={{ pageSize: 10, total: countProducts }}
                loading={Loading}
                rowKey={(row: ProductTypes) => row.ProductId}
              />
            </>
          )}

          {(Data?.Type === "Ret" ) && (
            <>
              <span className="smallTitle">{i18n.t("Pages.Bon.Form.Orders")}</span>
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                style={{overflowX:"auto"}}
                columns={Ordercolumns}
                pagination={{ pageSize: 10, total: countOrders }}
                dataSource={dataSource}
                loading={Loading}
                rowKey={(row: DataFieldsTypes) =>
                  row.Num_Order === undefined ? Math.random() : row.Num_Order
                }
              />
            </>
          )}

        </div>
      </div>
    );
}
 
export default BonModal;