import { Button, Divider, Skeleton , Modal} from "antd";
import { CloseCircleOutlined  } from '@ant-design/icons';
import { AdminType } from "Components/Constants/UserTypes";
import { useState } from "react";
import { ChangePhone, ChangePwd, CheckPwd, DeleteAccount, EditAdminData } from "Services/MyProfile/AccountServices";
import EditModal from "./EditModal";
import { useEffect } from "react";
import moment from "moment";
import { getPaymentMethod, IsCustomerExist } from "Services/Payments/PaymentCalls";
import i18n from "i18nextinit";

interface MyAccountProps {
    Data: AdminType | undefined
    Loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    Type : string
}

const MyAccount: React.SFC<MyAccountProps> = ({Data,Loading,setLoading,Type}) => {
    const [Active , SetActive] = useState<'Del' | 'Name' | 'Tel' | 'Date'| 'pwd'| 'Store'>();
    const [buttonLoad , SetbuttonLoad] = useState(false);
    const [open , setOpen] = useState(false);
    const [popVis , setpopVis] = useState(false);
    const [PopConfirm , setPopConfirm] = useState(false);
    const [SubData,setSubData] = useState<any>();

    const [IsTel , setIsTel] = useState(true);
    const [IsDate , setIsDate] = useState(true);
    const [IsPasswd , setIsPasswd] = useState(true);
    const [IsNewPassword , setIsNewPassword] = useState(true);
    const [IsStore , setIsStore] = useState(true); 
 
    const [EditedData ,setEditedData] = useState<AdminType>({
        StoreName : '',
        FirstName : '',
        LastName : '',
        BadgeId : '',
        AdminImg : ''
    })

    useEffect(()=>{
      if(Type === 'Admin'){

        IsCustomerExist().then(async result =>{
            if (result === true) {
                getPaymentMethod().then(result=>{
                    setSubData(result);
                })
            }
        })
      }
    },[])
    const handleClick = () =>{
      
        if (Active === 'pwd') {
            SetbuttonLoad(true);
            ChangePwd(EditedData,SetbuttonLoad,setIsPasswd,setIsNewPassword,setLoading,setOpen)
        } else {
            if (Active === 'Del') {
                SetbuttonLoad(true);
                CheckPwd(EditedData,SetbuttonLoad,setIsPasswd,setOpen,setpopVis)
            } else {
                if (Active === 'Tel') {
                    SetbuttonLoad(true);
                    ChangePhone(EditedData,SetbuttonLoad,setIsPasswd,setIsTel,setLoading,setOpen)
                } else {
                    console.log("🚀 ~ file: MyAccount.tsx ~ line 56 ~ handleClick ~ Active", EditedData); 
                    SetbuttonLoad(true);
                    EditAdminData(EditedData,SetbuttonLoad,setIsPasswd,setIsDate,setLoading,setOpen,setIsStore,Active)
                }
                
            }
        }
    }
    const { confirm } = Modal;

    function showDeleteConfirm() {
        confirm({
          title: i18n.t('Pages.MyProfile.MyAccount.PopUps.ConfirmDel.title'),
          style : {fontFamily : 'CatamaranBold'},
          icon: <CloseCircleOutlined style={{color : '#E50914'}} />,
          content: i18n.t('Pages.MyProfile.MyAccount.PopUps.ConfirmDel.content'),
          okText: i18n.t('Pages.MyProfile.MyAccount.PopUps.ConfirmDel.oktext'),
          okType: 'danger',
          cancelText: i18n.t('Pages.MyProfile.MyAccount.PopUps.ConfirmDel.canceltext'),
          visible : popVis ,
          okButtonProps : {loading : PopConfirm},
          onOk() {
            SetbuttonLoad(true);
            DeleteAccount(EditedData,setPopConfirm);
          },
          onCancel() {
            
          },
        });
    }
    useEffect(()=>{
        if (popVis === true) {
            showDeleteConfirm();
        }
    },[popVis])
    
    return (
      <div className="Account">
        {Type === 'Admin' && (
          <Modal
            title={
              Active === "pwd"
                ? i18n.t("Pages.MyProfile.MyAccount.PopUps.Modal.ChangePwdTitle")
                : Active === "Del"
                ? i18n.t("Pages.MyProfile.MyAccount.PopUps.Modal.DelAccTitle")
                : i18n.t("Pages.MyProfile.MyAccount.PopUps.Modal.ChangeInfTitle")
            }
            visible={open}
            destroyOnClose
            onCancel={() => {
              setOpen(!open);
              setIsPasswd(true);
              setIsDate(true);
              setIsNewPassword(true);
              setIsStore(true);
            }}
            footer={[
              <Button
                key="back"
                type="default"
                onClick={() => {
                  setOpen(!open);
                  setIsPasswd(true);
                  setIsDate(true);
                  setIsNewPassword(true);
                  setIsStore(true);
                }}
              >
                {i18n.t("Pages.MyProfile.MyAccount.PopUps.Modal.cancel")}
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={buttonLoad}
                disabled={!IsDate || !IsPasswd || !IsNewPassword || !IsStore}
                onClick={handleClick}
              >
                {i18n.t("Pages.MyProfile.MyAccount.PopUps.Modal.save")}
              </Button>,
            ]}
          >
            <EditModal
              IsStore={IsStore}
              setIsStore={setIsStore}
              IsTel={IsTel}
              setIsTel={setIsTel}
              active={Active}
              EditedData={EditedData}
              setEditedData={setEditedData}
              IsPasswd={IsPasswd}
              setIsPasswd={setIsPasswd}
              IsDate={IsDate}
              setIsDate={setIsDate}
              IsNewPassword={IsNewPassword}
              setIsNewPassword={setIsNewPassword}
            />
          </Modal>
        )}
        <Skeleton loading={Loading} active round={true}>
          <span className="Heading">{i18n.t("Pages.MyProfile.MyAccount.Form.Title")}</span>
          <div className="UserContainer">
            <div className="UserHeader">
              <div className="userTh">
                <img src={Type==='Admin' ? Data?.AdminImg : Data?.EmployeImg} alt="User image" />
                <span className="username">
                  {Data?.FirstName + " " + Data?.LastName}
                </span>
              </div>

              {Type === 'Admin' && (
                <div className="editButton actButton">
                  <button
                    onClick={() => {
                      SetActive("Name");
                      setOpen(true);
                    }}
                  >
                    {i18n.t("Pages.MyProfile.MyAccount.Form.Edit")}
                  </button>
                </div> 
              )}
              
            </div>
            <div className="UserInfo">
              <div className="Section">
                <div className="Lft">
                  <span className="hea">{i18n.t("Pages.MyProfile.MyAccount.Form.Email")}</span>
                  <br />
                  <span className="Value">{Data?.Email} </span>
                </div>
              </div>
              <div className="Section">
                <div className="Lft">
                  <span className="hea">{i18n.t("Pages.MyProfile.MyAccount.Form.Phone")}</span>
                  <br />
                  <span className="Value">{Data?.Tel}</span>
                </div>
                
                {Type === 'Admin' && (
                  <div
                    className="rowBut"
                    onClick={() => {
                      SetActive("Tel");
                      setOpen(true);
                    }}
                  >
                    <button> {i18n.t("Pages.MyProfile.MyAccount.Form.Edit")}</button>
                  </div>
                )}

              </div>
              {Type === 'Admin' && (
                <div className="Section">
                  <div className="Lft">
                    <span className="hea">{i18n.t("Pages.MyProfile.MyAccount.Form.birthdate")}</span>
                    <br />
                    <span className="Value">
                      {" "}
                      {moment(Data?.BirthDate).format("MMMM Do YYYY")}
                    </span>
                  </div>
                    <div
                      className="rowBut"
                      onClick={() => {
                        SetActive("Date");
                        setOpen(true);
                      }}
                    >
                      <button>{i18n.t("Pages.MyProfile.MyAccount.Form.Edit")}</button>
                    </div>
                  
                </div>
              )}
              {Type === 'Admin' && (
                <div className="Section">
                <div className="Lft">
                  <span className="hea">{i18n.t("Pages.MyProfile.MyAccount.Form.Store")}</span>
                  <br />
                  <span className="Value">{Data?.StoreName}</span>
                </div>
                <div
                      className="rowBut"
                      onClick={() => {
                        SetActive("Store");
                        setOpen(true);
                      }}
                    >
                      <button>{i18n.t("Pages.MyProfile.MyAccount.Form.Edit")}</button>
                    </div>
              </div>)}

              {Type === 'Admin' && (
                <div className="Section">
                  <div className="Lft">
                    <span className="hea">{i18n.t("Pages.MyProfile.MyAccount.Form.BillingInfo")}</span>
                    <br />
                    {SubData !== undefined && (
                      <span
                        className="Value"
                        style={{ fontFamily: "CatamaranBold" }}
                      >
                        {SubData.card.brand + "  "}•••• {SubData.card.last4}{" "}
                      </span>
                    )}
                    {SubData === undefined && (
                      <span className="Value">{i18n.t("Pages.MyProfile.MyAccount.Form.NoBill")}</span>
                    )}
                  </div>
                </div>
              )}

              {Type !== 'Admin' && (
                <div className="Section">
                <div className="Lft">
                  <span className="hea">{i18n.t("Pages.MyProfile.MyAccount.Form.Store")}</span>
                  <br />
                  <span className="Value">{Data?.StoreName}</span>
                </div>
              </div>
              )}
              <div className="Section">
                <div className="Lft">
                  <span className="hea">{i18n.t("Pages.MyProfile.MyAccount.Form.badge")}</span>
                  <br />
                  <span className="Value">{Type === 'Admin' ? Data?.BadgeId : Data?.EmplyeId} </span>
                </div>
              </div>
            </div>
            <br />
          </div>
          <div style={{ width: "92%" }}>
            <Divider />
          </div>

          {Type === 'Admin' && (
            <>
              <div className="passCont">
                <span className="Heading">{i18n.t("Pages.MyProfile.MyAccount.Form.PasswordTitle")}</span>
                <p>
                {i18n.t("Pages.MyProfile.MyAccount.Form.PasswordNotice")}
                </p>
                <div
                  className="editButton actButton"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    SetActive("pwd");
                    setOpen(true);
                  }}
                >
                  <button>{i18n.t("Pages.MyProfile.MyAccount.Form.ChangePwd")}</button>
                </div>
              </div>
              <div style={{ width: "92%" }}>
                <Divider />
              </div>
              <div className="disableAcc">
                <span className="Heading">{i18n.t("Pages.MyProfile.MyAccount.Form.AccountDelTitle")}</span>
                <p>
                {i18n.t("Pages.MyProfile.MyAccount.Form.AccountDelDsc")}
                </p>
                <div className="buttonCont">
                  <div
                    className="disButt actButton"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      SetActive("Del");
                      setOpen(true);
                    }}
                  >
                    <button>{i18n.t("Pages.MyProfile.MyAccount.Form.DelButton")}</button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Skeleton>
      </div>
    );
}
 
export default MyAccount;