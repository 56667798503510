import axios from 'axios';
import {externals} from 'webpack.config';

export const Logout = ()=>{
    axios.get(`${externals.config.apiUrl}/Auth/logout`,{withCredentials : true})
    .then(res => window.location.assign('/signin'))
    .catch(err => console.log(err));
}

export const GetUserInfo = async ()=>{

    try {
        
        const response = await axios.get(`${externals.config.apiUrl}/Auth/getInfo`,{withCredentials : true}) ;
        return response.data ;

    } catch (error) {
        return [] ;
    }
}