import { DataCounter } from 'Components/Routes/CounterComponent';
import { Progress } from "antd";
import * as React from 'react';
import i18n from 'i18nextinit';

interface IStatsProps {
    Data: DataCounter | undefined,
    OrderPerc: number | undefined,
    ExtPerc: number | undefined,
    SalesPerc: number | undefined,
    StaffPerc: number | undefined
}

const Stats: React.FunctionComponent<IStatsProps> = ({Data,OrderPerc,ExtPerc,SalesPerc,StaffPerc}) => {
  return (
        <div className='Stats'>
            <span className='titlePlan'>{i18n.t("Pages.MyProfile.MyAccount.Plans.Stats.Title")}</span>
            <div className='StatCont'>
                <span className='elemTit'>{i18n.t("Pages.MyProfile.MyAccount.Plans.Stats.Orders")}</span><br/><br/>
                <span className='perc'>{Data?.OrdersCount + ' / ' +  Data?.OrdersCountLimit}</span>
                <Progress
                    strokeColor={{
                        from: '#b376f0',
                        to: '#6D2EAB',
                    }}
                    percent={OrderPerc}
                    status="active"
                    showInfo={false}
                    trailColor='#a1a2a344'
                /><br/><br/>
                <span className='elemTit'>{i18n.t("Pages.MyProfile.MyAccount.Plans.Stats.ExtDeliv")}</span><br/><br/>
                <span className='perc'>{Data?.ExtDelCount + ' / ' +  Data?.ExtDelCountLimit}</span>
                <Progress
                    strokeColor={{
                        from: '#b376f0',
                        to: '#6D2EAB',
                    }}
                    percent={ExtPerc}
                    status="active"
                    showInfo={false}
                    trailColor='#a1a2a344'
                /><br/><br/>
                <span className='elemTit'>{i18n.t("Pages.MyProfile.MyAccount.Plans.Stats.Sales")}</span><br/><br/>
                <span className='perc'>{Data?.SalesChanCount + ' / ' +  Data?.SalesChanCountLimit}</span>
                <Progress
                    strokeColor={{
                        from: '#b376f0',
                        to: '#6D2EAB',
                    }}
                    percent={SalesPerc}
                    status="active"
                    showInfo={false}
                    trailColor='#a1a2a344'
                /><br/><br/>
                <span className='elemTit'>{i18n.t("Pages.MyProfile.MyAccount.Plans.Stats.Staff")}</span><br/><br/>
                <span className='perc'>{Data?.StaffCount + ' / ' +  Data?.StaffCountLimit}</span>
                <Progress
                    strokeColor={{
                        from: '#b376f0',
                        to: '#6D2EAB',
                    }}
                    percent={StaffPerc}
                    status="active"
                    showInfo={false}
                    trailColor='#a1a2a344'
                /><br/><br/>
            </div>
                            
        </div>
  );
};

export default Stats;
