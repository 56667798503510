import i18n from 'i18nextinit';
import { Provider } from 'mobx-react';
import React, {useEffect , useState} from 'react';
import StaffStores from 'Store/StaffStore';
import ListShipping from './Components/ListShipping';
export interface MainShippingProps {
    setLimitOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLimitMess: React.Dispatch<React.SetStateAction<string>>
}
 
const MainShipping: React.SFC<MainShippingProps> = ({setLimitMess,setLimitOpen}) => {

    return (
        <>
            <Provider StaffStore={StaffStores}>
                <title>{i18n.t("Pages.Shipping.Title")} | CodScale</title>
                <div className="MainContainer">
                    <ListShipping setLimitMess={setLimitMess} setLimitOpen={setLimitOpen} StaffStore={StaffStores} />
                </div>
            </Provider>
        </>
    );
}

 
export default MainShipping;