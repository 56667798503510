import Logo from "Assets/Icons/wl.png";

const Header = () => {
  return (
    <header className="Header logo">
      <img src={Logo} width="150" alt="Logo"/>
    </header>
  );
};
export default Header;
