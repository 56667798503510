import i18n from "i18nextinit";

export const InventoryTableColumns = [
    {
      title: i18n.t("Pages.Bon.ProdColumns.ProdNumb"),
      dataIndex: 'ProductId',
      key: 'ProductId'
    },
    {
      title: i18n.t("Pages.Bon.ProdColumns.Name"),
      dataIndex: 'ProductName',
      key: 'ProductName'
    },
    {
      title: i18n.t("Pages.Bon.ProdColumns.Price"),
      dataIndex: 'ProductPrice',
      key: 'ProductPrice'
    },
    {
      title:  i18n.t("Pages.Bon.ProdColumns.Quantity"),
      dataIndex: 'Quantity',
      key: 'Quantity'
    }
  ];

  export const InventoryAdTableColumns = [
    {
      title: i18n.t("Pages.Clients.Modal.Firstname"),
      dataIndex: 'FirstName',
      key: 'FirstName'
    },
    {
      title: i18n.t("Pages.Clients.Modal.Lastname"),
      dataIndex: 'LastName',
      key: 'LastName'
    },
    {
      title: i18n.t("Pages.Bon.Form.Products"),
      dataIndex: 'Products',
      key: 'Products'
    },
    {
      title: i18n.t("Pages.Inventory.Modal.DelCut"),
      dataIndex: 'LivrCut',
      key: 'LivrCut'
    },
    {
      title: i18n.t("Pages.Bon.Pdf.DistrId"),
      dataIndex: 'LivrId',
      key: 'LivrId'
    }
  ];