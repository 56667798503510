import {observable  , action , computed} from 'mobx'; 
import { ClientType } from 'Pages/Clients/Constants/ClientTypes';
import { getClients } from 'Services/Clients/ClientServices';


interface datafetchedType {
    result : ClientType[],
    count : number
}
class CLientStore {
    @observable dataSource : ClientType[] = [];
    @observable count : number = 0 ;
    @observable dataSize : number = 0 ;
    @action getDataSource = (pageg : number | undefined) => {
        return new Promise ((resolve,reject) =>{
    
            const page = pageg === undefined ? 1 : pageg ;
            getClients(page).then((data : datafetchedType)=>{
                this.count = data.count ;
                this.dataSource = data.result ;
                if (data.result !== undefined) {
                this.dataSize = data.result.length ;

                }

                resolve(this.dataSource);
            }).catch(err =>{
                reject(err);
            })
            
        })
    }
    @action UpdateDataSize = (dataSize : number)=>{
        this.dataSize = dataSize ;
    } 
    @computed get 
    getTotal(){
        return this.count ;
    }
    @computed get
    HowManyPosts(){
        return this.dataSize ;
    }
}

const ClientsStore = new CLientStore();
export default ClientsStore ;