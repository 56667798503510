import axios from "axios";
import { AddStaffTypes, DeleteEmpType, UpdateStaffTypes } from "Pages/Staff/Constants/EmployeTypes";
import { notification } from "antd";
import config from 'webpack.config';
import i18n from "i18nextinit";

export const GetEmploye = async (pageg: number | undefined , type : string | undefined) => {
    try {
      const response = await axios.get(
        `${config.externals.config.apiUrl}/Users/employes/getEmployesByType?&page=${pageg}&type=${type}`,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      return [];
    }
};

export const UpdateStaff:UpdateStaffTypes = 
(
    Data,setconfirmLoading,setLoading,StaffStore,
    setDataSource,setPagesize,setOpen,pagination,
    setLimitOpen,setLimitMess
)=>{
  axios
  .post(`${config.externals.config.apiUrl}/Users/employes/editEmploye?employeid=${Data.EmplyeId}`, Data, { withCredentials: true })
  .then((result) => {
    if (result.status === 200) {
      notification["success"]({
        style: { fontFamily: "CatamaranBold" },
        message:  i18n.t("Services.Success.SuccessMess"),
        description:
        i18n.t("Services.Success.EmpUpdate"),
      });
      setconfirmLoading(false);
      setOpen(false);
      setLoading(true);
      StaffStore.getStaffData(pagination,Data.Type).then(()=>{
        if (Data.Type === 'LivrExt') {
          setDataSource(StaffStore.LivrExtData)
          setPagesize(StaffStore.LivrExDataSize);
        }
        if (Data.Type === 'Livr') {
          setDataSource(StaffStore.LivrCenter)
          setPagesize(StaffStore.Livrcount);
        }
        if (Data.Type === 'Maga') {
          setDataSource(StaffStore.MagaCenter)
          setPagesize(StaffStore.Magacount);
        }
        if (Data.Type === 'Call') {
          setDataSource(StaffStore.CallCenter)
          setPagesize(StaffStore.Callcount);
        }
      }).finally(()=>{
          setLoading(false);
      });  
    }
    
  })
  .catch(function (err) {
    setconfirmLoading(false);
    setLoading(false);
    if (err.response.data.error === 'ExternalDel' || err.response.data.error === 'StaffCount' || err.response.data.error === 'Payment') {
      setLimitMess(err.response.data.error);
      setLoading(false);
      setLimitOpen(true);
    }else
    {
      // notification['error']({
      //     style : {fontFamily : 'CatamaranBold'},
      //     message: 'Internal error , please try again later',
      //     description: 'An error occured while uploading your order , please try again later !',
      // })
    }

  });
}

export const Add2Staff:AddStaffTypes = 
(
    Data,setconfirmLoading,setLoading,StaffStore,
    setDataSource,setPagesize,setOpen,pagination,setEmailErr,setData,setLimitOpen,setLimitMess
)=>{
  axios
  .post(`${config.externals.config.apiUrl}/Users/employes/createEmploye`, Data, { withCredentials: true })
  .then((result) => {
    console.log(result);
    if (result.status === 200) {
      notification["success"]({
        style: { fontFamily: "CatamaranBold" },
        message:  i18n.t("Services.Success.SuccessMess"),
        description:
        i18n.t("Services.Success.EmpAdd"),
      });
      setconfirmLoading(false);
      setOpen(false);
      setLoading(true);
      StaffStore.getStaffData(pagination,Data.Type).then(()=>{
        if (Data.Type === 'LivrExt') {
          setDataSource(StaffStore.LivrExtData)
          setPagesize(StaffStore.LivrExDataSize);
        }
        if (Data.Type === 'Livr') {
          setDataSource(StaffStore.LivrCenter)
          setPagesize(StaffStore.Livrcount);
        }
        if (Data.Type === 'Maga') {
          setDataSource(StaffStore.MagaCenter)
          setPagesize(StaffStore.Magacount);
        }
        if (Data.Type === 'Call') {
          setDataSource(StaffStore.CallCenter)
          setPagesize(StaffStore.Callcount);
        }
      }).finally(()=>{

          setLoading(false);
          setData(undefined);
      });  
    }
    if (result.status === 409) {
      setconfirmLoading(false);
      setEmailErr(true);
    }
  })
  .catch(err => {
    setconfirmLoading(false);
    setLoading(false);
    console.log(err.response);
    if (err.response.data.message === 'Email') {
      setconfirmLoading(false);
      setEmailErr(true);
    }else
    {
      if (err.response.data.error === 'ExternalDel' || err.response.data.error === 'StaffCount' || err.response.data.error === 'Payment') {
        setLimitMess(err.response.data.error);
        setLoading(false);
        setLimitOpen(true);
      }else
      {
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: 'Internal error , please try again later',
            description: 'An error occured while uploading your order , please try again later !',
        })
      }
    }
    
  })
}

export const DeleteEmployesCall : DeleteEmpType = (
  Url , setconfirmLoading , setOpen,StaffStore, setLoading, setDataSource, setPagesize, pagination,type
)=>{
  axios
    .delete(`${config.externals.config.apiUrl}/Users/employes/deleteEmploye?${Url}`, {
      withCredentials: true,
    })
    .then((result) => {
      if (result.status === 200) {
        notification["success"]({
          style: { fontFamily: "CatamaranBold" },
          message:  i18n.t("Services.Success.SuccessMess"),
          description:
          i18n.t("Services.Success.EmpDel"),
        });
        setconfirmLoading(false);
        setOpen(false);
        setLoading(true);
        StaffStore.getStaffData(pagination,type).then(()=>{
          if (type === 'Call') {
            setDataSource(StaffStore.CallCenter)
            setPagesize(StaffStore.CalldataSize);
          }
          if (type === 'Maga') {
            setDataSource(StaffStore.MagaCenter)
            setPagesize(StaffStore.MagadataSize);
          }
          if (type === 'Livr') {
            setDataSource(StaffStore.LivrCenter)
            setPagesize(StaffStore.LivrdataSize);
          }
          if (type === 'LivrExt') {
            setDataSource(StaffStore.LivrExtData)
            setPagesize(StaffStore.LivrExDataSize);
          }
          
        }).finally(()=>{
            setLoading(false);
        });
      } else {
        notification["error"]({
          style: { fontFamily: "CatamaranBold" },
          message: i18n.t("Services.Errors.ErrorOccur"),
          description:
            i18n.t("Services.Errors.ErrorDesc"),
        });
        setconfirmLoading(false);
        setOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    })
    .catch((err) => {
      notification["error"]({
        style: { fontFamily: "CatamaranBold" },
        message: i18n.t("Services.Errors.ErrorOccur"),
        description:
          i18n.t("Services.Errors.ErrorDesc"),
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
}

export const UpdateExtLivr:UpdateStaffTypes = 
(
    Data,setconfirmLoading,setLoading,StaffStore,
    setDataSource,setPagesize,setOpen,pagination,
    setLimitOpen,setLimitMess
)=>{
  axios
  .post(`${config.externals.config.apiUrl}/Users/employes/editEmploye?employeid=${Data.EmplyeId}`, Data, { withCredentials: true })
  .then((result) => {
    if (result.status === 200) {
      notification["success"]({
        style: { fontFamily: "CatamaranBold" },
        message:  i18n.t("Services.Success.SuccessMess"),
        description:
          i18n.t("Services.Success.EmpUpdate"),
      });
      setconfirmLoading(false);
      setOpen(false);
      setLoading(true);
      StaffStore.getStaffData(pagination,Data.Type).then(()=>{
        setDataSource(StaffStore.LivrExtData)
        setPagesize(StaffStore.LivrExDataSize);
      }).finally(()=>{
          setLoading(false);
      });  
    }

    
  })
  .catch(function (err) {
    setconfirmLoading(false);
    setLoading(false);
    if (err.response.data.error === 'ExternalDel' || err.response.data.error === 'StaffCount' || err.response.data.error === 'Payment') {
      setLimitMess(err.response.data.error);
      setLoading(false);
      setLimitOpen(true);
    }else
    {
      // notification['error']({
      //     style : {fontFamily : 'CatamaranBold'},
      //     message: 'Internal error , please try again later',
      //     description: 'An error occured while uploading your order , please try again later !',
      // })
    }
  });
}
