import React, {useEffect , useState} from 'react';
import { DataFieldsTypes, newProductTypes } from '../OrderTypes';
import {observer , inject } from 'mobx-react';
import { ListOrderProps } from '../Admin/ListOrders';
import { AutoComplete, Button, Modal, Table, TablePaginationConfig } from 'antd';
import { Columns, DataFields, ListOrderscolumns } from '../Consts';
import RowOrderModal from '../RowOrderModal';
import { HandleConfirm } from 'Pages/Orders/helpers/ClientOrdersHandlers';
import {ReactComponent as RefreshIcon} from 'Assets/Svgs/refreshIcon.svg';
import i18n from 'i18nextinit';
import { getSearchOrders, getSearchOrdersByPage } from 'Services/Orders/CreateOrderCalls';
import {ReactComponent as SearchIcon} from 'Assets/Svgs/search.svg';

const { Option } = AutoComplete;

const MagaListOrders: React.SFC<ListOrderProps> = inject(
    'orderStore'
  )(
    observer(({orderStore,setLimitOpen,setDataCounter,setLimitMess}) => {

        const [NumSearchData,setNumSearchData] = useState<string>('');
        const [SearchedData , setSearchedData]= useState<string[]>([]);
        const [LivrStatus , setLivr] = useState(false);
        
        const [Data , setData ] = useState<DataFieldsTypes>(DataFields);
        const [pagination , setPagination] = useState<number|undefined>(1);
        const [pageSize , setPagesize] = useState<number>(orderStore.count);
        const [productRows , setRows] = useState<newProductTypes[]>([]);
        const [Loading , setLoading] = useState<boolean>();
        const [Open , setOpen] = useState(false);
        const [dataSource , setDataSource] = useState<DataFieldsTypes[]>(orderStore.dataSource);
        const [confirmLoading , setConfirmLoading] = useState<boolean>(false);
        const [refresh,setrefresh] = useState(false);

        useEffect(()=>{
            if(NumSearchData === '')
            {
                setLoading(true);
                orderStore.getDataSource(pagination,'Packaging').then(()=>{
                    setDataSource(orderStore.dataSource);
                    setPagesize(orderStore.count);
                    setLoading(false);
                });
            }else
            {
                handleSearch(1);
            }


        },[refresh])

        const handleChange = (pagination: TablePaginationConfig) => {
            if(NumSearchData === '')
            {
                setPagination(pagination.current);
                setLoading(true);
                orderStore?.getDataSource(pagination.current,'Packaging').then(()=>{
                setDataSource(orderStore.dataSource);
                setLoading(false);
                }).catch(err => console.log(err));
            }else
            {
            handleSearch(1);
            }
        }
        
        const HandelRow = (row : DataFieldsTypes )=>{
            setData(row) ;
            setRows(row.Products);
            setOpen(true);
        }

        const onSelectPhone = (value: string) => {
            setNumSearchData(value);
          }
      
        const onSearchPhone = (value: string) => {
          setNumSearchData(value);
          getSearchOrders(value).then(res => {
            setSearchedData(res.newResult);
          }).catch(err => console.log(err));
        }
    
        const handleSearch = (pagination:number)=>{
          setLoading(true);
          getSearchOrdersByPage(NumSearchData,pagination).then(res => {
            setDataSource(res.result);
            setPagesize(res.count);
            setLoading(false);
          }).catch(err => console.log(err));
        }
    
        const handleClearSearch = ()=>{
          setNumSearchData('');
          setSearchedData([]);
          setLoading(true);
          orderStore.getDataSource(1).then(()=>{
            setDataSource(orderStore.dataSource);
            setPagesize(orderStore.count);
            setLoading(false);
          });
        }

        return ( 
            <>
            <div style={{display:'flex',justifyContent:"space-between",alignItems:"baseline"}}>
                <div className="ButtonsContainer">
                    <RefreshIcon width='15px' height='15px' className='refresh' onClick={()=>{setrefresh(!refresh)}}/>
                </div>
                <div className="ButtonsContainer" style={{width: '40%',justifyContent:"flex-end"}}>
                    <span className='ClearFilter' onClick={handleClearSearch}>{i18n.t('Pages.Orders.ClearTxt')}</span>
                    <AutoComplete
                        style={{width: '60%',paddingTop:"5px",paddingBottom:"5px",paddingRight:"10px",paddingLeft:"10px",marginRight:"20px"}}
                        onSelect={onSelectPhone}
                        onSearch={onSearchPhone}
                        value={NumSearchData}
                        dropdownStyle={{fontFamily:"CatamaranBold"}}
                        placeholder={i18n.t('Pages.Orders.SearchPH')}
                    >
                        {SearchedData?.map(element =>(
                        <Option key={element} value={element}>
                            {element}
                        </Option>
                        ))}
                    </AutoComplete>
                    <SearchIcon width='1.25rem' height='1.25rem' className='Search' onClick={()=>{handleSearch(1)}}/>
                </div>
            </div>
            <div className="ListContainer" style={{marginTop : '40px'}}>
                <Table
                dataSource={dataSource}
                columns={ListOrderscolumns}
                onChange={(pagination) => handleChange(pagination)}
                pagination={{ pageSize: 10, total: pageSize  , showSizeChanger : false}}
                loading={Loading}
                rowKey={(row: DataFieldsTypes) =>
                    row.Num_Order === undefined ? Math.random() : row.Num_Order
                }
                onRow={(row) => ({ onClick: () => HandelRow(row) })}
                />
                <Modal
                title={Data?.Num_Order}
                destroyOnClose
                visible={Open}
                onCancel={()=>{
                    setOpen(!Open);
                    setData(DataFields);
                }}
                style={{ top: "9%" }}
                width="85vw"
                bodyStyle={{ height: "60vh", overflowY: "scroll" }}
                footer={[
                        <Button
                            key="back"
                            type="default"
                            onClick={()=>{
                                setOpen(!Open);
                                setData(DataFields);
                            }}
                        >
                            {i18n.t("Pages.Orders.Create.cancel")}
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            loading={confirmLoading}
                            onClick={() => {
                                HandleConfirm(
                                    Data,
                                    setConfirmLoading,
                                    setLoading,
                                    orderStore,
                                    setDataSource,
                                    setPagesize,
                                    setOpen,
                                    pagination,
                                    setData,setDataCounter,setLimitOpen,setLimitMess,setLivr
                                  );
                            }}
                        >
                            {i18n.t("Pages.Orders.Create.submit")}
                        </Button>,
                        ]
                }
                >

                <RowOrderModal
                    productRows={productRows}
                    Data={Data}
                    setData={setData}
                    type='Maga'

                />
                </Modal>
        </div>
        </>
     );
}))
 
export default MagaListOrders;