import { FactureDataTypes } from "../Constants/FacturesTypes";
import {Document , Page , Text , View , StyleSheet , Image , Font } from '@react-pdf/renderer';

import CompanyLogo from 'Assets/Icons/wl.png';
import font from 'Assets/Fonts/Montserrat-Bold.ttf';
import fontReg from 'Assets/Fonts/Montserrat-Regular.ttf';
import font2 from 'Assets/Fonts/Catamaran-Bold.ttf';
import font2Reg from 'Assets/Fonts/Catamaran-Regular.ttf';

import { EmployeType } from "Pages/Orders/Components/OrderTypes";
import { DataFieldsTypes } from "Pages/Orders/Components/OrderTypes";
import moment from "moment";
import { styles } from "Pages/Bon/Constants/BonStyles";
import i18n from "i18nextinit";

interface PdfExportProps {
    Data : FactureDataTypes ,
    Employe: EmployeType | undefined ,
    dataSource: DataFieldsTypes[] | undefined
}

Font.register({ family: 'MontserratBold', src: font});
Font.register({ family: 'MontserratRegular', src: fontReg});

Font.register({ family: 'CatamaranBold', src: font2});
Font.register({ family: 'CatamaranRegular', src: font2Reg});


const PdfExport: React.SFC<PdfExportProps> = ({Data,Employe,dataSource}) => {
    
    return (
      <>
        <Document>
          <Page size="A4" >
            <View style={styles.section}>
              {/* <Image source={CompanyLogo} style={styles.image} /> */}
              <Text style={styles.TitleComp}>{Employe?.StoreName}</Text>
              <Text style={styles.Title}>{i18n.t("Pages.Invoices.Pdf.Invoice")}</Text>
            </View>
            <View style={styles.Info}>
              <View style={styles.row}>
                <Text style={styles.boldText}>{i18n.t("Pages.Invoices.Pdf.InvoiceId")} </Text>
                <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>{Data.FactureId}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.Type")}</Text>
                <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>{Data.type === 'Req_Fac' ? 'Invoice request' : 'Invoice'}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.Status")}</Text>
                <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>{Data.Status}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.Date")}</Text>
                <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>
                  {Data.Date !== undefined ? moment(Data.Date).format('MMMM Do YYYY'):''}
                </Text>
              </View>
            </View>
            <View style={styles.Orders}>
              <Text style={styles.Title}>{i18n.t("Pages.Bon.Pdf.Orders")}</Text>
              <View style={styles.table}>

                <View style={styles.tableHeader}>
                  
                  <View style={styles.idcool}>
                    
                    <Text style={styles.tableCell}>{i18n.t("Pages.Bon.Pdf.OrderId")}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    
                    <Text style={styles.tableCell}>{i18n.t("Pages.Bon.Pdf.Products")}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    
                    <Text style={styles.tableCell}>{i18n.t("Pages.Bon.Pdf.Status")}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    
                    <Text style={styles.tableCell}>{i18n.t("Pages.Bon.Pdf.City")}</Text>
                  </View>
                </View>
                {dataSource !== undefined ? dataSource?.map((source) =>{
                    return(
                      <View style={styles.tableRow} key={source.Num_Order}>
                  
                        <View style={styles.idcool}>
                          <Text style={styles.tableCell}>{source.Num_Order} </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{source.Products.length}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{source.Status}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{source.City}</Text>
                        </View>
  
                      </View>
                    )
                }):''}
                
              </View>
            </View>
            <View style={styles.bottom}>
              <View style={{margin: 10,padding: 10,width : '70%'}}>
                <Text style={styles.Title}>{i18n.t("Pages.Bon.Pdf.BillTo")}</Text>
                <View style={styles.billto}>
                  <View style={styles.row}>
                    <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.DistrMan")} :</Text>
                    <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>{Employe?.FirstName + ' ' + Employe?.LastName}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.DistrId")} :</Text>
                    <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>{Employe?.EmplyeId}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.Phone")}</Text>
                    <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>{Employe?.Tel}</Text>
                  </View> 
                </View>
                
              </View>
              <View style={styles.price}>
                <View style={styles.row}>
                    <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.SubTotal")}</Text>
                    <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>
                      {
                       Employe?.Price === undefined ?
                       Data.Price :
                       Number(parseFloat(Data.Price.toString())+(parseFloat(Employe.Price)*Data.Num_Order.length)).toFixed(2)
                      } Dh
                    </Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.Shipping")}</Text>
                    <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>
                      {
                       Employe?.Price === undefined ?
                       0 :
                       Employe?.Price
                      } Dh
                    </Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.Total")}</Text>
                    <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>
                      {Data.Price}
                    </Text>
                </View>
              </View>
              
                
            </View>
          </Page>
        </Document>
      </>
    );
}
 
export default PdfExport;