import axios from 'axios';
import { notification } from 'antd';
import { BonTypes, HandleBonConfirm_Types, handleDeleteTypes } from 'Pages/Bon/Constants/BonTypes';
import { DataFields } from 'Pages/Bon/Constants/DataConsts';
import { FactureDataTypes } from 'Pages/Factures/Constants/FacturesTypes';
import config from 'webpack.config';
import i18n from 'i18nextinit';

//Delete products by ids
export const deleteBonLivrCalls:handleDeleteTypes = async (Url,setconfirmLoading,BonStore,setLoading,setOpen,dataSource,setDataSource,pageSize,setPagesize,pagination,current,type) => {
    try {
        axios.delete(`${config.externals.config.apiUrl}/Content/Bon/deleteBon?${Url}` ,{withCredentials : true} )
        .then(result => {
            if (result.status === 200) {
                notification['success']({
                    style : {fontFamily : 'CatamaranBold'},
                    message: i18n.t("Services.Success.SuccessMess"),
                    description: i18n.t("Services.Success.OrderConf"),
                })
                setconfirmLoading(false);
                setOpen(false);
                setLoading(true);
                BonStore.getDataSource(current,pagination).then(()=>{
                    setDataSource(BonStore.dataSource);
                    setPagesize(BonStore.count);
                }).finally(()=>{
                    setLoading(false);
                });
                
            } else {
                notification['error']({
                    style : {fontFamily : 'CatamaranBold'},
                    message: i18n.t("Services.Errors.ErrorOccur"),
                    description: i18n.t("Services.Errors.ErrorDesc"),
                })
                setconfirmLoading(false);
                setOpen(false);
                setLoading(true);
                BonStore.getDataSource(current,1).then(()=>{
                    setDataSource(BonStore.dataSource);
                    setPagesize(BonStore.count);
                }).finally(()=>{
                    setLoading(false);
                });
                
            }
        })
        .catch(err => {
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            setconfirmLoading(false);
                setOpen(false);
                setLoading(true);
                BonStore.getDataSource(current,1).then(()=>{
                    setDataSource(BonStore.dataSource);
                    setPagesize(BonStore.count);
                }).finally(()=>{
                    setLoading(false);
                    
    
                });
        })
    } catch (error) {
        notification.error({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description: i18n.t("Services.Errors.ErrorDesc"),
            duration: 2
        });
    }
};


export const getProductsById = async (Data : BonTypes) => {
    try {
        let Url = '';
        for await (let prod of Data.Num_Prod)
        {
            Url += `Num_Prod[]=${prod.ProductId}&`;
        }
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Products/getProductsByIds?${Url}`,{withCredentials : true}) ;
        return response.data ;

    } catch (err) {
        return [] ;
    }
}

export const getOrdersById = async (Data : BonTypes | FactureDataTypes) =>{
    
    try {
        let Url = '';
        for await (let order of Data.Num_Order)
        {
            Url += `Num_Order[]=${order}&` ;
        }
    
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Orders/getOrderByIds?${Url}`,{withCredentials : true}) ;
        return response.data ;

    } catch (error) {
        return [] ;
    }
}
export const getLivrById = async (employId : string | undefined) => {
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Users/employes/getLivrById?employeid=${employId}`,{withCredentials : true}) ;
        return response.data.result ;
    } catch (error) {
        return [] ;
    }
}

export const getBon = async (type : string | undefined ,pageg : number | undefined) => {
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Bon/getBons?type=${type}&page=${pageg}`,{withCredentials : true}) ;
        return response.data ;
    } catch (error) {
        return [] ;
    }
}

export const getConfirmedOrders = async (status : string | undefined,LivrId ?: string) =>{
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Orders/getByStatus?status=${status}&LivrId=${LivrId}`,{withCredentials : true}) ;
        return response.data ;
    } catch (error) {
        return [] ;
    }
}

export const getOrders4Ret = async () =>{
    try {
        const response = await axios.get(`${config.externals.config.apiUrl}/Content/Orders/getOrder4Ret`,{withCredentials : true}) ;
        return response.data ;
    } catch (error) {
        return [] ;
    }
}

export const ConfirmOrder:HandleBonConfirm_Types = (Data , setconfirmLoading,BonStore,setLoading,setOpen,dataSource,setDataSource,pageSize ,setPagesize ,setData,pagination ) => {
    setconfirmLoading(true);
    axios.post(`${config.externals.config.apiUrl}/Content/Bon/statusUpdate?BonId=${Data?.BonId}&Status=Confirmed`,Data , {withCredentials : true})
    .then(result => {
        if (result.status === 200) {
            notification['success']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Success.SuccessMess"),
                description: i18n.t("Services.Success.OrderConf"),
            })
            setconfirmLoading(false);
            setOpen(false);
            setLoading(true);
            BonStore.getDataSource(Data.Type,pagination).then(()=>{
                setDataSource(BonStore.dataSource);
                setPagesize(BonStore.count);
            }).finally(()=>{
                setLoading(false);
            });
            setData(DataFields);
        } else {
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            setconfirmLoading(false);
            setOpen(false);
            setLoading(true);
            BonStore.getDataSource(Data.Type,1).then(()=>{
                setDataSource(BonStore.dataSource);
                setPagesize(BonStore.count);
            }).finally(()=>{
                setLoading(false);
            });
            setData(DataFields);
        }
    })
    .catch(err => {
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description: i18n.t("Services.Errors.ErrorDesc"),
        })
        setconfirmLoading(false);
            setOpen(false);
            setLoading(true);
            BonStore.getDataSource(Data.Type,1).then(()=>{
                setDataSource(BonStore.dataSource);
                setPagesize(BonStore.count);
            }).finally(()=>{
                setLoading(false);
                setData(DataFields);

            });
    })
}

export const CreateBonCalls:HandleBonConfirm_Types = ( Data , setconfirmLoading,BonStore,setLoading,setOpen,dataSource,setDataSource,pageSize ,setPagesize, setData ,pagination,current) => {
    setconfirmLoading(true);
    axios.post(`${config.externals.config.apiUrl}/Content/Bon/CreateBon`,Data , {withCredentials : true})
    .then(result => {
        if (result.status === 200) {
            notification['success']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Success.SuccessMess"),
                description: i18n.t("Services.Success.BonSuccess"),
            })
            setconfirmLoading(false);
            setOpen(false);
            setLoading(true);
            BonStore.getDataSource(current,1).then(()=>{
                setDataSource(BonStore.dataSource);
                setPagesize(BonStore.count);
            }).finally(()=>{
                setLoading(false);
            });
            setData(DataFields);
        } else {
            notification['error']({
                style : {fontFamily : 'CatamaranBold'},
                message: i18n.t("Services.Errors.ErrorOccur"),
                description: i18n.t("Services.Errors.ErrorDesc"),
            })
            setconfirmLoading(false);
            setOpen(false);
            setLoading(true);
            BonStore.getDataSource('Livr',1).then(()=>{
                setDataSource(BonStore.dataSource);
                setPagesize(BonStore.count);
            }).finally(()=>{
                setLoading(false);
            });
            setData(DataFields);
        }
    })
    .catch(err => {
        notification['error']({
            style : {fontFamily : 'CatamaranBold'},
            message: i18n.t("Services.Errors.ErrorOccur"),
            description: i18n.t("Services.Errors.ErrorDesc"),
        })
        setconfirmLoading(false);
            setOpen(false);
            setLoading(true);
            BonStore.getDataSource('Livr',1).then(()=>{
                setDataSource(BonStore.dataSource);
                setPagesize(BonStore.count);
            }).finally(()=>{
                setLoading(false);
            });
            setData(DataFields);
    })
}