import {useEffect , useState} from 'react';
import WidgetCounter from './Components/WidgetCounter';
import {ReactComponent as MoneyIcon} from 'Assets/Svgs/dashboard/money.svg';
import {ReactComponent as OrderIcon} from 'Assets/Svgs/dashboard/conveyor-belt.svg';
import {ReactComponent as ConfirmedIcon} from 'Assets/Svgs/dashboard/checked.svg';
import {ReactComponent as DeliveredIcon} from 'Assets/Svgs/dashboard/delivery-guy.svg';
import {ReactComponent as NoDataIllus} from 'Assets/Svgs/dashboard/Data/noData.svg';
import {ReactComponent as Sales} from 'Assets/Svgs/dashboard/sales-codscale.svg';
import {ReactComponent as orders} from 'Assets/Svgs/dashboard/orders-codscale.svg';
import {ReactComponent as delivered} from 'Assets/Svgs/dashboard/Delivered-codscale.svg';
import {ReactComponent as confirmed} from 'Assets/Svgs/dashboard/confirmed-codscale.svg';

import ChartComponent from './Components/ChartComponent';
import { getDashData, getWidgetData } from 'Services/Dashboard/DashCalss';
import { Select, Spin } from 'antd';
import { DashDataFetchedTypes, FieldsTypes, WidgetDataTypes } from './Constants/DashTypes';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { StatusAvailable } from 'Pages/Orders/Components/Consts';
import i18n from 'i18nextinit';
export interface MainDashProps {
}
interface datafet {
    results : any ,
    count : number
}

const { Option } = Select;
const MainDash: React.SFC<MainDashProps> = () => {

    const [totalData , settotalData] = useState<FieldsTypes[]>([]);
    const [pendingData , setpendingData] = useState<FieldsTypes[]>([]);
    const [PackagingData , setPackagingData] = useState<FieldsTypes[]>([]);
    const [ShippedData , setShippedData] = useState<FieldsTypes[]>([]);
    const [DelivryData , setDelivryData] = useState<FieldsTypes[]>([]);
    const [ConfirmedData , setConfirmedData] = useState<FieldsTypes[]>([]);
    const [DeclinedData , setDeclinedData] = useState<FieldsTypes[]>([]);
    const [createdAt, setcreatedAt] = useState('')
    const [WidgetData , setWidgetData] = useState<WidgetDataTypes>();
    const [Status , setStatus] = useState<string>("Pending");
    const [loading , setLoading ] = useState(true);
    const [widgL,setWidgL] =useState(true);

    useEffect(()=>{
        getWidgetData().then((result : WidgetDataTypes)=>{
            setWidgetData(result);
            setWidgL(false);
        }).catch(err => console.log(err));
    },[])
    useEffect(()=>{
        setLoading(true);
        getDashData([Status])
        .then(async (data : any) =>{
            console.log("🚀 ~ file: MainDash.tsx ~ line 43 ~ .then ~ data", data)
            settotalData([]);
            setpendingData([]);   
            setPackagingData([]);
            setShippedData([]);
            setDelivryData([]);
            setConfirmedData([]);
            setDeclinedData([]);
            setcreatedAt(data.start);
            for await (let element of data.PendingResults[0])
            {
                let month =   element._id.Day+'-'+ moment(element._id.month  , 'M').format('MM')+'-'+ element._id.year ;
                console.log("🚀 ~ file: MainDash.tsx ~ line 50 ~ .then ~ month", month)
                setpendingData(prevstatus => [...prevstatus ,{
                  month : month,
                  orderType : element.status,
                  number : element.count
                }])
                
            }
            for await (let element of data.PackagingResults[0])
            {
                let month =   element._id.Day+'-'+ moment(element._id.month  , 'M').format('MM')+'-'+ element._id.year ;
                console.log("🚀 ~ file: MainDash.tsx ~ line 50 ~ .then ~ month", month)
                setPackagingData(prevstatus => [...prevstatus ,{
                  month : month,
                  orderType : element.status,
                  number : element.count
                }])
                
            }
            for await (let element of data.ShippedResults[0])
            {
                let month =   element._id.Day+'-'+ moment(element._id.month  , 'M').format('MM')+'-'+ element._id.year ;
                console.log("🚀 ~ file: MainDash.tsx ~ line 50 ~ .then ~ month", month)
                setShippedData(prevstatus => [...prevstatus ,{
                  month : month,
                  orderType : element.status,
                  number : element.count
                }])
                
            }
            for await (let element of data.DelivryResults[0])
            {
                let month =   element._id.Day+'-'+ moment(element._id.month  , 'M').format('MM')+'-'+ element._id.year ;
                console.log("🚀 ~ file: MainDash.tsx ~ line 50 ~ .then ~ month", month)
                setDelivryData(prevstatus => [...prevstatus ,{
                  month : month,
                  orderType : element.status,
                  number : element.count
                }])
                
            }
            for await (let element of data.ConfirmedResults[0])
            {
                let month =   element._id.Day+'-'+ moment(element._id.month  , 'M').format('MM')+'-'+ element._id.year ;
                console.log("🚀 ~ file: MainDash.tsx ~ line 50 ~ .then ~ month", month)
                setConfirmedData(prevstatus => [...prevstatus ,{
                  month : month,
                  orderType : element.status,
                  number : element.count
                }])
                
            }
            for await (let element of data.DeclinedResults[0])
            {
                let month =   element._id.Day+'-'+ moment(element._id.month  , 'M').format('MM')+'-'+ element._id.year ;
                console.log("🚀 ~ file: MainDash.tsx ~ line 50 ~ .then ~ month", month)
                setDeclinedData(prevstatus => [...prevstatus ,{
                  month : month,
                  orderType : element.status,
                  number : element.count
                }])
                
            }
            for await (let element of data.TotalResults[0])
            {
              let month =   element._id.Day+'-'+ moment(element._id.month  , 'M').format('MM')+'-'+ element._id.year ;
              settotalData(prevetotal => [...prevetotal ,{
                  month : month,
                  orderType : 'Total',
                  number : element.count
              }])
            }

        }).finally(()=>{
            setLoading(false);
        })
        .catch(err => console.log(err));
    },[Status])

    return (
      <>
        <title>{i18n.t("Pages.Dashboard.TabTitle")} | CodScale</title>
        <div className="MainContainer">
          <div className="TopPart">
            {!widgL && (
              <>
                <WidgetCounter
                  Title={i18n.t("Pages.Dashboard.Widgets.Sales")}
                  Value={
                    WidgetData?.Sales[0] !== undefined
                      ? WidgetData?.Sales[0].totalAmount
                      : 0
                  }
                  type="Price"
                  Icon={Sales}
                  stats="+8.98"
                  rate="up"
                />
                <WidgetCounter
                  Title={i18n.t("Pages.Dashboard.Widgets.TotOrders")}
                  Value={WidgetData?.TotalOrder}
                  type="Count"
                  Icon={orders}
                  stats="-8.98"
                  rate="down"
                />
                <WidgetCounter
                  Title={i18n.t("Pages.Dashboard.Widgets.ConfOrders")}
                  Value={WidgetData?.Confirmed}
                  type="Count"
                  Icon={confirmed}
                  stats="-8.98"
                  rate="down"
                />
                <WidgetCounter
                  Title={i18n.t("Pages.Dashboard.Widgets.DelOrders")}
                  Value={WidgetData?.Delivered}
                  type="Count"
                  Icon={delivered}
                  stats="+8.98"
                  rate="up"
                />
              </>
            )}
          </div>
          <br />
          <div className="headContainer">
            <span className="heading">
              {i18n.t("Pages.Dashboard.Orders")} :
            </span>
          </div>

          {loading && (
            <div
              className="ChartContainer"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                height: "200px",
              }}
            >
              <Spin size="large" />
            </div>
          )}
          {!loading && WidgetData?.TotalOrder !== undefined && (
            <>
              {WidgetData?.TotalOrder >= 5 && (
                <div className="bottom">
                  <ChartComponent
                    createdAt={createdAt}
                    totalData={totalData}
                    pendingData={pendingData}
                    PackagingData={PackagingData}
                    ShippedData={ShippedData}
                    DelivryData={DelivryData}
                    ConfirmedData={ConfirmedData}
                    DeclinedData={DeclinedData}
                  />
                </div>
              )}
              {WidgetData?.TotalOrder < 5 && (
                <>
                  <br />
                  <div
                    className="ChartContainer"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <NoDataIllus width="35%" />
                    <h2>{i18n.t("Pages.Dashboard.NotEnoughData")} </h2>
                    <button style={{ marginTop: "5px" }}>
                      <NavLink to="/orders">
                        {i18n.t("Pages.Dashboard.OrderButt")}
                      </NavLink>
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </>
    );
}
 
export default MainDash;