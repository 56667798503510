// Types ----------------------------
// ----------------------------------

import { AdminConfirmOrder, ConfirmOrder, CreateOrder } from "Services/Orders/CreateOrderCalls";
import { handleDataChangeTypes, newProductTypes, updaterowTypes , HandleConfirmTypes, CreateOrderTypes, DataFieldsTypes } from "../Components/OrderTypes";

// Handlers -------------------------
// ---------------------------------

export const handleDataChange:handleDataChangeTypes = (e , setData , Data) => {
    const {name , value} = e.target ; 
    setData({...Data , [name] : value})
}

export const updateRowProduct:updaterowTypes = (index,e,productRows,setRows,setData,Data) => {

    const {name,value} = e.target ;
    const Products = [...productRows];
    if (name === 'upsell') {
        Products[index][name] = e.target.checked ;
    }else
    {
        Products[index][name] = value ;
        Products[index].price = Products[index].cost_per_unit * Products[index].quantity ;
    }
    setRows(Products);
    setData({...Data , Products : Products })
}


export const handleAdd = (newProduct: newProductTypes , setRows : React.Dispatch<React.SetStateAction<newProductTypes[] >> , productRows: newProductTypes[])=>{

    setRows(oldArray => [...oldArray, {
        id : '',
        upsell : false,
        cost_per_unit : 0,
        name : '',
        variant : '',
        quantity : 0,
        price : 0
    }]);
}

export const handleAddUpsell = (newProduct: newProductTypes , setRows : React.Dispatch<React.SetStateAction<newProductTypes[] >>)=>{

    setRows(oldArray => [...oldArray, {
        id : '',
        upsell : true,
        cost_per_unit : 0,
        name : '',
        variant : '',
        quantity : 0,
        price : 0
    }]);
}


export const handleRemove = (
  index: number,
  productRows: newProductTypes[],
  setRows: React.Dispatch<React.SetStateAction<newProductTypes[]>>,
  Data: DataFieldsTypes,
  setData: React.Dispatch<React.SetStateAction<DataFieldsTypes>>
) => {
  if (productRows.length > 1) {
    const rows = [...productRows];
    if (Data.OrderPrice > 0) {
      setData({ ...Data, OrderPrice: Data.OrderPrice - rows[index].price });
    }
    rows.splice(index, 1);
    setRows(rows);
  }
};

export const HandleOk:CreateOrderTypes = async (Data , setconfirmLoading , orderStore ,setLoading , setDataSource , setPageSize , setOpen , pagination , setData,setDataCounter,setLimitOpen,setLimitMess,setLivr)=>{
    
    setconfirmLoading(true);
    CreateOrder(Data , setconfirmLoading ,setLoading , orderStore ,setDataSource , setPageSize , setOpen,pagination,setData,setDataCounter,setLimitOpen,setLimitMess,setLivr);
}

export const HandleConfirm : HandleConfirmTypes = (Data , setconfirmLoading ,setLoading , orderStore ,setDataSource , setPageSize , setOpen , pagination , setData,setDataCounter,setLimitOpen ,setLimitMess,setLivr) =>{
    setconfirmLoading(true);
    AdminConfirmOrder(Data , setconfirmLoading ,setLoading , orderStore ,setDataSource , setPageSize , setOpen , pagination , setData,setDataCounter,setLimitOpen,setLimitMess,setLivr)
}