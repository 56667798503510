import { deleteBonLivrCalls, getOrders4Ret } from "Services/Bon/BonLivrCalls";
import { getPorductsInStock } from "Services/Products/ProductCalls";
import { handleDeleteTypes, handleEmploye_Types, handleTypeChange_Types, OrderfetchedType, ProductsfetchedType } from "../Constants/BonTypes";

export const handleTypeChange : handleTypeChange_Types = (Data,setData,dataSource,setloading,getConfirmedOrders,setOcount,setDataSource,getProducts,setPcount,setProductSource,type)=>{

    if ( Data.Type === 'Livr' ) {
        setloading(true);
        getConfirmedOrders(type,Data.LivrId).then((response:OrderfetchedType)=>{
          setOcount(response.count);
          setDataSource(response.result);
        }).then(()=>setloading(false))
      
    }
    if (Data.Type === 'Ret') {
      setloading(true);
      getOrders4Ret().then((response:OrderfetchedType)=>{
        setOcount(response.count);
        setDataSource(response.result);
      }).then(()=>setloading(false))
  }
    if (Data.Type === 'Stock') {
      setloading(true);
      getPorductsInStock(1).then((response:ProductsfetchedType)=>{
        setPcount(response.count);
        setProductSource(response.result);
      }).then(()=>setloading(false))
    }
    
}
export const HandleEmploye : handleEmploye_Types= (value,Data,setData,Employes) =>{
        
     setData({...Data , LivrId : value, City : Employes.find(employe => employe.EmplyeId === value)?.City});
   
}

export const handleDeleteBon : handleDeleteTypes = async (str,setconfirmLoading,BonStore,setLoading,setOpen,dataSource,setDataSource,pageSize,setPagesize,pagination,current,type,Ids) =>{
        
    try {
      console.log(Ids);
      let Url = '';
      for await (let id of Ids)
      {
          Url += `BonId[]=${id}&` ;
      }
      setconfirmLoading(true);
      deleteBonLivrCalls(Url,setconfirmLoading,BonStore,setLoading,setOpen,dataSource,setDataSource,pageSize,setPagesize,pagination,current,type)

  } catch (error) {
      return [] ;
  }
   
}
