import { Button, Modal, Menu } from "antd"
import {observer , inject } from 'mobx-react';
import { EmployeType } from "Pages/Staff/Constants/EmployeTypes";
import React, { useEffect, useState } from "react";
import StaffStores from "Store/StaffStore";
import AddToShipping from "./AddToShipping";
import LivrExterne from "./LivrExterne";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DeleteEmployes } from "Pages/Staff/Helpers/EmployesHandlers";
import i18n from "i18nextinit";
import Lottie from 'react-lottie';
import animationData from 'Animations/waiting.json';
export interface ListShippingProps {
    StaffStore : typeof StaffStores,
    setLimitOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLimitMess: React.Dispatch<React.SetStateAction<string>>
}
 
const ListShipping: React.SFC<ListShippingProps> =  inject(
    'StaffStore'
  )(
    observer(({StaffStore,setLimitOpen,setLimitMess}) => {

    const [current , setCurrent] = useState<string>('LivrExt');
    const [loading , setLoading ] = useState<boolean | undefined>();
    const [selectedRowKeys , setSelected] = useState<React.Key[]>([]);
    const [pagination , setPagination] = useState<number|undefined>(1);
    const [popVis , setPopVis] = useState(false);

    const [PopConfirm , setPopConfirm] = useState(false);
    const [LivrExtData ,setLivrExtData] = useState<EmployeType[]>(StaffStore.LivrExtData);
    const [pageSize , setPagesize] = useState<number>(StaffStore.LivrExCount);

    const { confirm } = Modal;

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid",
        },
    };

    function showDeleteConfirm() {
        confirm({
          title: i18n.t("Pages.Shipping.Errors.Title"),
          style : {fontFamily : 'CatamaranBold'},
          icon: <ExclamationCircleOutlined />,
          content: `${i18n.t("Pages.Shipping.Errors.content1")} ${selectedRowKeys.length} ${i18n.t("Pages.Shipping.Errors.content2")}` ,
          okText: i18n.t("Pages.Shipping.Errors.ok"),
          okType: 'danger',
          cancelText: i18n.t("Pages.Shipping.Errors.cancel"),
          visible : popVis ,
          okButtonProps : {loading : PopConfirm},
          onOk() {
                DeleteEmployes(
                selectedRowKeys , setPopConfirm , setPopVis,StaffStore, 
                setLoading, setLivrExtData, setPagesize, pagination,current)
            setSelected([]);
          },
          onCancel() {
          },
        });
      }

    useEffect(()=>{
        setSelected([]);
        if (StaffStore.LivrExDataSize === 0 && current=== 'LivrExt') {
            setLoading(true)
            StaffStore.getStaffData(1,'LivrExt').then(()=>{
                setLivrExtData(StaffStore.LivrExtData)
                setPagesize(StaffStore.LivrExCount);
            }).finally(()=>{
                setLoading(false);
            });
        }else
        {
            setLoading(false);
        }
    },[current]);
    return ( 
        <>
        <div className="MenuContainer">
                <Menu 
                    selectedKeys={[current]}
                    mode="horizontal"
                    style={{width:"30%"}}
                    onClick={(e) => {
                        setCurrent(e.key.toString());
                    }}
                >
                    <Menu.Item key="LivrExt">{i18n.t("Pages.Shipping.ExternDel")}</Menu.Item>
                    <Menu.Item key="amana">{i18n.t("Pages.Shipping.AMANA")}</Menu.Item>
                </Menu>
                <div className='ButtonsContainer'>
                    <Button style={{marginRight : '15px'}} type="dashed" onClick={showDeleteConfirm}  danger disabled={selectedRowKeys.length === 0}>
                        {i18n.t("Pages.Inventory.Errors.Delete")}
                    </Button>
                    <AddToShipping 
                        setLimitMess={setLimitMess}
                        setLimitOpen={setLimitOpen}
                        StaffStore={StaffStore} setLoading={setLoading} 
                        setPagesize={setPagesize} pagination={pagination} setLivrExtData={setLivrExtData}
                    />
                    
                </div>

            </div>
            {current === "LivrExt" &&(
                <LivrExterne setLimitMess={setLimitMess} setLimitOpen={setLimitOpen} StaffStore={StaffStore} selectedRowKeys={selectedRowKeys} setSelected={setSelected} 
                             LivrExtData={LivrExtData} pageSize={pageSize} Loading={loading}  setLoading={setLoading}
                             setLivrExtData={setLivrExtData} setPagesize={setPagesize} pagination={pagination}
                 />
            )}
            {current === "amana" &&(
                <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                    <Lottie options={defaultOptions} width='350px'/>
                    <h2 style={{fontFamily:"MontserratBlack",fontSize:"25px",color:"#2C1338"}}>Comming soon !</h2>
                </div>
            )}
        </>
     );
}))
 
export default ListShipping;