import Lottie from 'react-lottie';
import animationData from 'Animations/404.json';
import Logo from "Assets/Icons/wl.png";
import image from 'Assets/res/lightShades.png';
import i18n from 'i18nextinit';

const FourO4 = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid",
        },
    };
    return (
        <div className='MainfourContainer'>
            <div className='Logo'>
                <img src={Logo} width="150" alt="Logo"/>
            </div>
        <div className='FourContainer'>
            
            <title>{i18n.t("404.Title")} | CodScale</title>
           
            <div className='Body'>
                <Lottie options={defaultOptions} width='200px'/>
                <div className='descSection'>
                    <span className='desc'>{i18n.t("404.Heading")}</span>
                    <div className="CreateOrder">
                        <button onClick={()=> window.location.replace('/')}>
                            <span>{i18n.t("404.Back")}</span>
                        </button>
                    </div>
                </div>
            </div>
            <img id="bg" src={image} alt="background illustration" />

        </div>
        </div>
     );
}
 
export default FourO4;