
import {ReactComponent as MenuIcon} from 'Assets/Svgs/MenuIcon.svg';
import {ReactComponent as UserIcon} from 'Assets/Svgs/Header/user.svg';
import {ReactComponent as LogoutIcon} from 'Assets/Svgs/Header/Logout.svg';

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { GetUserInfo, Logout } from 'Services/Header/UserCalls';
import { AdminType, EmployeType } from 'Components/Constants/UserTypes';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import useViewportSizes from 'use-viewport-sizes';
import i18n from 'i18nextinit';




export interface HeaderProps {
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>,
    Collapsed :  boolean,
    type ?: string,
    Closed: boolean,
    setClosed: React.Dispatch<React.SetStateAction<boolean>>
}

const DropdownItem = (props:any)=>{
    return(
        <div className="menuItem" onClick={props.onClick}>
            <span className="icon-button">{props.lefticon}</span>
            {props.children}
        </div>
    )
}
interface DropDownType {
    opened : boolean,
    type : string|undefined,
    vpWidth : any,
    userInfo: EmployeType | AdminType | undefined
}
const DropDown :React.SFC<DropDownType> = ({opened , type,vpWidth,userInfo}) =>{
    let history = useHistory();
    return(
        <div className={opened ? 'Dropdown fadein' :'Dropdown fadeout' }>
            <DropdownItem lefticon={<LogoutIcon/>} 
            onClick={Logout}
            >{i18n.t("Header.Logout")}</DropdownItem>
                <DropdownItem lefticon={<UserIcon/>} 
                onClick={()=>{
                    history.push(`/myprofile?from=${window.location.pathname.split('/')[1]}`);
                }}
                >{i18n.t("Header.Profile")}</DropdownItem>
        </div>
    )
}
const Routes = [{
    path: "/dashboard",
    name : i18n.t("Routes.Dashboard") ,
  },
  {
    path: "/orders",
    name : i18n.t("Routes.Orders") ,

  },{
    path: "/products",
    name : i18n.t("Routes.Products")
  },
  {
    path: "/bon",
    name : i18n.t("Routes.Sheets")
  },{
    path: "/factures",
    name : i18n.t("Routes.Invoices")
  },
  {
    path: "/inventory",
    name : i18n.t("Routes.Inventory")
  },
  {
    path: "/staff",
    name : i18n.t("Routes.Staff")
  },
  {
    path: "/shipping",
    name : i18n.t("Routes.Shipping")
  }
  ,
  {
    path: "/clients",
    name : i18n.t("Routes.Clients")
  },
  {
    path: "/salechannel",
    name : i18n.t("Routes.Sales")
  },
  {
    path: "/history",
    name : i18n.t("Routes.History")
  }
];
const Header: React.SFC<HeaderProps> = ({ setCollapsed , Collapsed ,type,setClosed,Closed}) => {
    const [ActifRoute , setActif] = useState<string | undefined >('');
    const [loading ,setLoading] = useState(true);
    const [opened , setOpen] = useState(false);
    const [userInfo,setUser] = useState<EmployeType | AdminType>();
    const [vpWidth] = useViewportSizes({ dimension: 'w' });
    const [width , setwidth] = useState(1280);
    
    useEffect(()=>{
        if(vpWidth > 0)
        {
            setwidth(vpWidth);
        }
    },[vpWidth])

    let location = useLocation() ;

    useEffect(()=>{
        GetUserInfo().then(result =>{
            setUser(result);
            setLoading(false);
        }).catch(err => console.log(err));
    },[])

    useEffect(()=>{
            setActif(Routes.find(el => el.path === location.pathname)?.name);
    },[location])

    function ucfirst(str:string) {
        var firstLetter = str.substr(0, 1);
        return firstLetter.toUpperCase() + str.substr(1);
    }
    return (
        <div  className='Header'>

            <div className='LeftPart'>
                <MenuIcon className='MenuIcon Web' onClick={()=>setCollapsed(!Collapsed)}/>
                <MenuIcon className='MenuIcon Mobile' onClick={ ()=>setClosed(!Closed)}/>

                <div className='Title'>
                    {ActifRoute}
                </div>
            </div>
            
            <div className='RightPart'>
                {/* <NotificationIcon className='NotificationButton' /> */}
                
                <div className='UserContainer' onClick={()=>setOpen(!opened)}>

                    {loading &&(
                        <Spin size="small" />
                    )}
                    {!loading &&(
                        <>
                            <img src={type === 'Admin' ? userInfo?.AdminImg : userInfo?.EmployeImg} className='UserImg' width='24px' height='24px'/>
                            <span className='UserName'>{userInfo?.FirstName +' '+userInfo?.LastName}</span>
                        </>
                    )}
                    
                </div>
            </div>

            
                <>
                <DropDown opened={opened} type={type} vpWidth={width} userInfo={userInfo} />
                </>
        </div>  );
}
 
export default Header;