
import SalesStore from 'Store/SalesStore';
import {observer , inject, Provider } from 'mobx-react';
import ListSales from './Components/ListSales';
import i18n from 'i18nextinit';

interface MainSalesProps {
    setLimitOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLimitMess: React.Dispatch<React.SetStateAction<string>>
}
 
const MainSales: React.SFC<MainSalesProps>  = ({setLimitMess,setLimitOpen}) => {

    
    return (
        <div className='SalesContainer'>
            <Provider FacturesStore={SalesStore}>
                <title>{i18n.t("Pages.SalesChannel.Title")} | CodScale</title>
                <div className="MainContainer">
                    <ListSales setLimitMess={setLimitMess} setLimitOpen={setLimitOpen} SaleStore={SalesStore}/>
                </div>
            </Provider>
        </div>
    );
}
 
export default MainSales;