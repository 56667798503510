
export const validate = (values) =>
{
    const errors = {};

    if (!values.email) {
        errors.email = 'required fields';
    }
    if (!values.storename) {
        errors.storename = 'required fields';
    }
    if (!values.password) {
        errors.password = 'required fields';
    }
    return errors;
}

