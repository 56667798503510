import { AdminType } from "Components/Constants/UserTypes";
import i18n from "i18nextinit";

interface IEditStoreProps {
    EditedData: AdminType
    setEditedData: React.Dispatch<React.SetStateAction<AdminType>>
    IsPasswd: boolean
    setIsPasswd: React.Dispatch<React.SetStateAction<boolean>>
    IsStore: boolean
    setIsStore: React.Dispatch<React.SetStateAction<boolean>>
}

const EditStore: React.SFC<IEditStoreProps> = ({EditedData,setEditedData,IsPasswd,setIsPasswd,IsStore,setIsStore}) => {
  return (
    <>
            <p>{i18n.t("Pages.MyProfile.MyAccount.EditName.Paragraph")}</p>
            <div className="Field1" style={{width : '100%'}}>
                <label className="label">{i18n.t('Auth.Form.Store')}</label>
                <input
                    type="text"
                    name="StoreName"
                    className={
                        !IsStore
                        ? "Field normal inv"
                        : "Field normal"
                    }
                    onChange={(e)=>{
                        setEditedData({...EditedData , StoreName : e.target.value})
                        setIsStore(true)
                    }}
                    value={EditedData.StoreName}
                />
                {!IsStore ? (
                <div className="inv_msg">{i18n.t("Pages.MyProfile.MyAccount.EditStore.InvStoreName")}</div>
                ) : null}
            </div>
            <div className="Field1" style={{width : '100%'}}>
                <label className="label">{i18n.t("Pages.MyProfile.MyAccount.EditDate.LabelCurr")}</label>
                <input
                    type="password"
                    name="password"
                    className={
                        !IsPasswd
                        ? "Field normal inv"
                        : "Field normal"
                    }
                    onChange={(e)=>{
                        setEditedData({...EditedData , Password : e.target.value})
                        setIsPasswd(true);
                    }}
                    value={EditedData.Password}
                />
            </div>
            {!IsPasswd ? (
                <div className="inv_msg">{i18n.t("Pages.MyProfile.MyAccount.EditDate.InvCurrLabel")}</div>
            ) : null}
        </>
  )
};

export default EditStore;
