import {Document , Page , Text , View , StyleSheet , Image , Font } from '@react-pdf/renderer';


import CompanyLogo from 'Assets/Icons/wl.png';
import font from 'Assets/Fonts/Montserrat-Bold.ttf';
import fontReg from 'Assets/Fonts/Montserrat-Regular.ttf';
import font2 from 'Assets/Fonts/Catamaran-Bold.ttf';
import font2Reg from 'Assets/Fonts/Catamaran-Regular.ttf';
import { BonTypes } from '../Constants/BonTypes';
import {EmployeType } from 'Pages/Orders/Components/OrderTypes';
import moment from 'moment';
import { styles } from '../Constants/BonStyles';
import { ProductTypes } from 'Pages/Products/Components/ProductTypes';
import i18n from 'i18nextinit';

Font.register({ family: 'MontserratBold', src: font});
Font.register({ family: 'MontserratRegular', src: fontReg});

Font.register({ family: 'CatamaranBold', src: font2});
Font.register({ family: 'CatamaranRegular', src: font2Reg});

interface PdfProdTicketsProps {
    Data : BonTypes ,
    Employe: EmployeType | undefined ,
    dataSource: ProductTypes[] | undefined   
}
 
const PdfProdTickets: React.SFC<PdfProdTicketsProps> = ({Data,Employe,dataSource}) => {
    return ( 
        <Document>
          <Page size="A4" >
          <View style={{display : 'flex' , flexWrap : 'wrap' , flexDirection : 'row' , width : '100%'}}>
            {dataSource !== undefined ? dataSource?.map(source =>{
                        return(
                            <View style={styles.Info2}>
                            {/* <Image source={CompanyLogo} style={{ width: 100, marginBottom : '10px'}} /> */}
                            <View style={styles.row}>
                                <Text style={styles.boldText}> {source.ProductId} </Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>{Employe?.FirstName + ' ' + Employe?.LastName}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>{Data.City}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.boldText}> {Data.City} </Text>
                            </View>
            
                            <View style={styles.row}>
                                <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.Date")}:</Text>
                                <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}> {moment(Data.Date).format('MMMM Do YYYY')}</Text>
            
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.Quantity")} :</Text>
                                <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}> <Text style={styles.tableCell}>{Data.Num_Prod.find(element => element.ProductId === source.ProductId)?.quantity}</Text></Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.boldText}>{i18n.t("Pages.Bon.Pdf.Total")} : </Text>
                                <Text style={{fontFamily :'CatamaranRegular',fontSize : '12px'}}>  {Data.Price +'Dh'} </Text>
                            </View>
                            </View>
                        )
            }):''}
          </View>
          </Page>
        </Document>
     );
}
 
export default PdfProdTickets;