import { CallProducts, DeleteHandlerTypes, ProductTypes } from "Pages/Products/Components/ProductTypes";
import { CreateProduct, DeleteProducts, EditProductsCall } from "Services/Products/ProductCalls";

export const HandleOk:CallProducts =  (Data , setconfirmLoading , setOpen,productStore, setLoading, setDataSource, setPagesize, pagination,setData,isProdIdValid,setisProdIdValid)=>{

    setconfirmLoading(true);
    CreateProduct(Data,setconfirmLoading,setOpen,productStore, setLoading, setDataSource, setPagesize, pagination,setData,isProdIdValid,setisProdIdValid);
}

export const EditProduct:CallProducts = (Data , setconfirmLoading , setOpen,productStore, setLoading, setDataSource, setPagesize, pagination,setData) =>{
    setconfirmLoading(true);
    EditProductsCall(Data , setconfirmLoading , setOpen,productStore, setLoading, setDataSource, setPagesize, pagination,setData);
}

export const DeleteProductsByIds : DeleteHandlerTypes = async (Ids , setconfirmLoading , setOpen,productStore, setLoading, setDataSource, setPagesize, pagination) =>{

    
        try {
            let Url = '';
            for await (let id of Ids)
            {
                Url += `ProductId[]=${id}&` ;
            }
            setconfirmLoading(true);
            DeleteProducts(Url , setconfirmLoading , setOpen,productStore, setLoading, setDataSource, setPagesize, pagination);
    
        } catch (error) {
            return [] ;
        }
}