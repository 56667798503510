import { ClientTableColumns } from "../Constants/Columns";
import { Button, Modal, Table, TablePaginationConfig } from "antd"
import { ClientType } from "../Constants/ClientTypes";
import { useState } from "react";
import RowCLient from "./RowClient";
import { CLientData } from "../Constants/ClientData";
import ClientsStore from "Store/ClientStore";
import { HandleUpdateClient } from "../Helpers/ClientHelper";
import i18n from "i18nextinit";

export interface ClientDataTableProps {
    Datasource: ClientType[]
    Pagesize: number
    Loading: boolean
    selectedRowKeys: React.Key[]
    ClientsStore : typeof ClientsStore
    setDataSource: React.Dispatch<React.SetStateAction<ClientType[]>>
    setPagesize: React.Dispatch<React.SetStateAction<number>>
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    setSelected: React.Dispatch<React.SetStateAction<React.Key[]>>
    NumSearchText : string,
    handleSearch: (pagination: number) => void
}
 
const ClientDataTable: React.SFC<ClientDataTableProps> = ({setSelected , selectedRowKeys, Datasource,Pagesize,Loading,ClientsStore,setDataSource,setPagesize,setLoading,NumSearchText,handleSearch}) => {
    
    const [Data, setData] = useState<ClientType >(CLientData);
    const [confirmLoading, setconfirmLoading] = useState(false);
    const [pagination , setPagination] = useState<number | undefined>();
    const [open , setOpen] = useState<boolean>(false);
    const HandelRow = (row: ClientType) => {
        setData(row);
        setOpen(true);
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys: React.Key[]) => {
          setSelected(selectedRowKeys);
      } 
    };
    const handleChange = (pagination: TablePaginationConfig) => {
      setLoading(true);
        if(NumSearchText === '')
        {
          setLoading(true);
          ClientsStore.getDataSource(pagination.current).then(()=>{
              setDataSource(ClientsStore.dataSource);
              setPagesize(ClientsStore.count);
              setLoading(false);
          })
          .catch(err =>console.log(err))
        }else
        {
            handleSearch(pagination.current);
        }
      
    }
    return ( 
        <>
            <Table
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                }}
                dataSource={Datasource}
                style={{overflowX: "auto"}}
                columns={ClientTableColumns}
                loading={Loading}
                onChange={(pagination) => handleChange(pagination)}
                pagination={{ pageSize: 10, total: Pagesize }}
                onRow={(row) => ({ onClick: () => HandelRow(row) })}
                rowKey={(row: ClientType) =>
                    row.Client_Id === undefined ? Math.random() : row.Client_Id
                }
            />
            <Modal
            title={Data?.Client_Id}
            destroyOnClose
            visible={open}
            onCancel={() => {
              setOpen(!open);
              setData(CLientData)
            }}
            footer={[
              <Button key="back" type="default" onClick={() => {
                setOpen(!open);
                setData(CLientData)
              }}>
                {i18n.t("Pages.Clients.Modal.cancel")}
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={()=>HandleUpdateClient(Data,setconfirmLoading,setLoading,ClientsStore,setDataSource,setPagesize,setOpen,pagination)}
                loading={confirmLoading}
              >
                {i18n.t("Pages.Clients.Modal.Update")}
              </Button>,
            ]}
            >
                <RowCLient Data={Data} setData={setData} />
            </Modal>
        </>
     );
}
 
export default ClientDataTable;