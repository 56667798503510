import i18n from "i18nextinit";

export const StaffTableColumns = [
    {
      title: i18n.t("Pages.Clients.Modal.Firstname"),
      dataIndex: 'FirstName',
      key: 'FirstName'
    },
    {
      title: i18n.t("Pages.Clients.Modal.Lastname"),
      dataIndex: 'LastName',
      key: 'LastName'
    },
    {
      title: i18n.t("Pages.Clients.Modal.Phone"),
      dataIndex: 'Tel',
      key: 'Tel'
    },
    {
      title: i18n.t("Pages.Clients.Modal.City"),
      dataIndex: 'City',
      key: 'City',
      render: (City:string) => (
        <span>{(City === "" || City=== undefined ) ? "--"  :City} </span>
      )
    }
  ];