import { Key } from "react";
import FactureStore from "Store/FacturesStore";

export interface FactureDataTypes {
    FactureId : string,
    Num_Order : string[] | Key[],
    Status : string,
    AdminId : string,
    LivrId : string,
    type : string,
    Price : number,
    Date ?: Date
}

export const FactureData = {
    FactureId : '',
    Num_Order : [],
    Status : '',
    AdminId : '',
    LivrId : '',
    type : '',
    Price : 0
}

export interface CreateFactureTypes
{
    (Data: FactureDataTypes ,
    setconfirmLoading : React.Dispatch<React.SetStateAction<boolean>> ,
    setLoading :React.Dispatch<React.SetStateAction<boolean | undefined>> ,
    factureStore: typeof FactureStore ,
    setDataSource: React.Dispatch<React.SetStateAction<FactureDataTypes[]>>,
    setPagesize: React.Dispatch<React.SetStateAction<number>>,
    setOpen : React.Dispatch<React.SetStateAction<boolean>>,
    pagination: number | undefined):void
}
