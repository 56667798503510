import i18n from "i18nextinit";
import { useEffect , useState } from "react";
import ProfileResult from "./Components/ProfileResult";
import ProfileSidebar, { useQuery } from "./Components/ProfileSidebar";
import './Scss/Profile.scss';

interface MainProfileProps {
    Type : string 
}

const AvTabs = ['Acc','Pla','Lang'];
 
const MainProfile: React.SFC<MainProfileProps> = ({Type}) => {

    const [Tab , setTab] = useState<'Acc'|'Pla'|'Lang'|string>('Acc');
    let tab = useQuery().get('tab');

    useEffect(()=>{
        if (tab !== undefined && tab !== null && AvTabs.includes(tab)) {
            setTab(tab);
        }
    },[])
    return ( 
        <div className='ProfileContainer'>
                <title>{i18n.t('Pages.MyProfile.TabTitle')} | CodScale</title>
                <ProfileSidebar active={Tab} setTab={setTab} Type={Type}/>
                <ProfileResult active={Tab} Type={Type}/>
        </div>
     );
}
 
export default MainProfile;